// TODO(bshaya): Migrate from `type | null` to `?: type`
export interface Location {
  label: string
  iso3: string | null
  population: string
  casesPastWeek: number
  casesIncreasingPercentage: number
  positiveCasePercentage: number | null
  topLevelGroup: string | null
  subdivisions: string[]
  incompleteVaccinations: number | null
  completeVaccinations: number | null
  unvaccinatedPrevalenceRatio: number | null
  averageFullyVaccinatedMultiplier: number | null
  updatedAt: string
}

// NOTE: anything between 'locations start' and 'locations end',
// or on the 'date' line, will be overwritten the next time someone
// runs update_prevalence.py.

export const Locations: { [key: string]: Location } = {
  // update_prevalence locations start
  US_01: {
    label: 'Alabama',
    iso3: null,
    population: '4,903,185',
    casesPastWeek: 2471,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_01001',
      'US_01003',
      'US_01005',
      'US_01007',
      'US_01009',
      'US_01011',
      'US_01013',
      'US_01015',
      'US_01017',
      'US_01019',
      'US_01021',
      'US_01023',
      'US_01025',
      'US_01027',
      'US_01029',
      'US_01031',
      'US_01033',
      'US_01035',
      'US_01037',
      'US_01039',
      'US_01041',
      'US_01043',
      'US_01045',
      'US_01047',
      'US_01049',
      'US_01051',
      'US_01053',
      'US_01055',
      'US_01057',
      'US_01059',
      'US_01061',
      'US_01063',
      'US_01065',
      'US_01067',
      'US_01069',
      'US_01071',
      'US_01073',
      'US_01075',
      'US_01077',
      'US_01079',
      'US_01081',
      'US_01083',
      'US_01085',
      'US_01087',
      'US_01089',
      'US_01091',
      'US_01093',
      'US_01095',
      'US_01097',
      'US_01099',
      'US_01101',
      'US_01103',
      'US_01105',
      'US_01107',
      'US_01109',
      'US_01111',
      'US_01113',
      'US_01115',
      'US_01117',
      'US_01119',
      'US_01121',
      'US_01123',
      'US_01125',
      'US_01127',
      'US_01129',
      'US_01131',
      'US_01133',
    ],
    incompleteVaccinations: 580314,
    completeVaccinations: 2607186,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_02: {
    label: 'Alaska',
    iso3: null,
    population: '731,545',
    casesPastWeek: 582,
    casesIncreasingPercentage: 29.33333333333332,
    positiveCasePercentage: 12.9,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_02013',
      'US_02016',
      'US_02020',
      'US_02050',
      'US_02060',
      'US_02066',
      'US_02063',
      'US_02068',
      'US_02070',
      'US_02090',
      'US_02100',
      'US_02110',
      'US_02122',
      'US_02130',
      'US_02150',
      'US_02158',
      'US_02164',
      'US_02170',
      'US_02180',
      'US_02185',
      'US_02188',
      'US_02195',
      'US_02198',
      'US_02220',
      'US_02230',
      'US_02240',
      'US_02261',
      'US_02275',
      'US_02282',
      'US_02290',
    ],
    incompleteVaccinations: 57819,
    completeVaccinations: 476567,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_04: {
    label: 'Arizona',
    iso3: null,
    population: '7,278,717',
    casesPastWeek: 3220,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_04001',
      'US_04003',
      'US_04005',
      'US_04007',
      'US_04009',
      'US_04011',
      'US_04012',
      'US_04013',
      'US_04015',
      'US_04017',
      'US_04019',
      'US_04021',
      'US_04023',
      'US_04025',
      'US_04027',
    ],
    incompleteVaccinations: 861359,
    completeVaccinations: 4809730,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05: {
    label: 'Arkansas',
    iso3: null,
    population: '3,017,804',
    casesPastWeek: 1870,
    casesIncreasingPercentage: 49.6,
    positiveCasePercentage: 7.6,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_05001',
      'US_05003',
      'US_05005',
      'US_05007',
      'US_05009',
      'US_05011',
      'US_05013',
      'US_05015',
      'US_05017',
      'US_05019',
      'US_05021',
      'US_05023',
      'US_05025',
      'US_05027',
      'US_05029',
      'US_05031',
      'US_05033',
      'US_05035',
      'US_05037',
      'US_05039',
      'US_05041',
      'US_05043',
      'US_05045',
      'US_05047',
      'US_05049',
      'US_05051',
      'US_05053',
      'US_05055',
      'US_05057',
      'US_05059',
      'US_05061',
      'US_05063',
      'US_05065',
      'US_05067',
      'US_05069',
      'US_05071',
      'US_05073',
      'US_05075',
      'US_05077',
      'US_05079',
      'US_05081',
      'US_05083',
      'US_05085',
      'US_05087',
      'US_05089',
      'US_05091',
      'US_05093',
      'US_05095',
      'US_05097',
      'US_05099',
      'US_05101',
      'US_05103',
      'US_05105',
      'US_05107',
      'US_05109',
      'US_05111',
      'US_05113',
      'US_05115',
      'US_05117',
      'US_05119',
      'US_05121',
      'US_05123',
      'US_05125',
      'US_05127',
      'US_05129',
      'US_05131',
      'US_05133',
      'US_05135',
      'US_05137',
      'US_05139',
      'US_05141',
      'US_05143',
      'US_05145',
      'US_05147',
      'US_05149',
    ],
    incompleteVaccinations: 393425,
    completeVaccinations: 1716584,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_06: {
    label: 'California',
    iso3: null,
    population: '39,512,223',
    casesPastWeek: 22866,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_06001',
      'US_06003',
      'US_06005',
      'US_06007',
      'US_06009',
      'US_06011',
      'US_06013',
      'US_06015',
      'US_06017',
      'US_06019',
      'US_06021',
      'US_06023',
      'US_06025',
      'US_06027',
      'US_06029',
      'US_06031',
      'US_06033',
      'US_06035',
      'US_06037',
      'US_06039',
      'US_06041',
      'US_06043',
      'US_06045',
      'US_06047',
      'US_06049',
      'US_06051',
      'US_06053',
      'US_06055',
      'US_06057',
      'US_06059',
      'US_06061',
      'US_06063',
      'US_06065',
      'US_06067',
      'US_06069',
      'US_06071',
      'US_06073',
      'US_06075',
      'US_06077',
      'US_06079',
      'US_06081',
      'US_06083',
      'US_06085',
      'US_06087',
      'US_06089',
      'US_06091',
      'US_06093',
      'US_06095',
      'US_06097',
      'US_06099',
      'US_06101',
      'US_06103',
      'US_06105',
      'US_06107',
      'US_06109',
      'US_06111',
      'US_06113',
      'US_06115',
    ],
    incompleteVaccinations: 3980279,
    completeVaccinations: 29529176,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08: {
    label: 'Colorado',
    iso3: null,
    population: '5,758,736',
    casesPastWeek: 3069,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_08001',
      'US_08003',
      'US_08005',
      'US_08007',
      'US_08009',
      'US_08011',
      'US_08013',
      'US_08014',
      'US_08015',
      'US_08017',
      'US_08019',
      'US_08021',
      'US_08023',
      'US_08025',
      'US_08027',
      'US_08029',
      'US_08031',
      'US_08033',
      'US_08035',
      'US_08037',
      'US_08039',
      'US_08041',
      'US_08043',
      'US_08045',
      'US_08047',
      'US_08049',
      'US_08051',
      'US_08053',
      'US_08055',
      'US_08057',
      'US_08059',
      'US_08061',
      'US_08063',
      'US_08065',
      'US_08067',
      'US_08069',
      'US_08071',
      'US_08073',
      'US_08075',
      'US_08077',
      'US_08079',
      'US_08081',
      'US_08083',
      'US_08085',
      'US_08087',
      'US_08089',
      'US_08091',
      'US_08093',
      'US_08095',
      'US_08097',
      'US_08099',
      'US_08101',
      'US_08103',
      'US_08105',
      'US_08107',
      'US_08109',
      'US_08111',
      'US_08113',
      'US_08115',
      'US_08117',
      'US_08119',
      'US_08121',
      'US_08123',
      'US_08125',
    ],
    incompleteVaccinations: 586925,
    completeVaccinations: 4234966,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09: {
    label: 'Connecticut',
    iso3: null,
    population: '3,565,287',
    casesPastWeek: 1089,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.7,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_09001',
      'US_09003',
      'US_09005',
      'US_09007',
      'US_09009',
      'US_09011',
      'US_09013',
      'US_09015',
    ],
    incompleteVaccinations: 697843,
    completeVaccinations: 2961528,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_10: {
    label: 'Delaware',
    iso3: null,
    population: '973,764',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: 'US states',
    subdivisions: ['US_10001', 'US_10003', 'US_10005'],
    incompleteVaccinations: 145324,
    completeVaccinations: 714261,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_11: {
    label: 'District of Columbia',
    iso3: null,
    population: '705,749',
    casesPastWeek: 210,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: 'US states',
    subdivisions: ['US_11001'],
    incompleteVaccinations: 191817,
    completeVaccinations: 638828,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_12: {
    label: 'Florida',
    iso3: null,
    population: '21,477,737',
    casesPastWeek: 57684,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_12001',
      'US_12003',
      'US_12005',
      'US_12007',
      'US_12009',
      'US_12011',
      'US_12013',
      'US_12015',
      'US_12017',
      'US_12019',
      'US_12021',
      'US_12023',
      'US_12027',
      'US_12029',
      'US_12031',
      'US_12033',
      'US_12035',
      'US_12037',
      'US_12039',
      'US_12041',
      'US_12043',
      'US_12045',
      'US_12047',
      'US_12049',
      'US_12051',
      'US_12053',
      'US_12055',
      'US_12057',
      'US_12059',
      'US_12061',
      'US_12063',
      'US_12065',
      'US_12067',
      'US_12069',
      'US_12071',
      'US_12073',
      'US_12075',
      'US_12077',
      'US_12079',
      'US_12081',
      'US_12083',
      'US_12085',
      'US_12086',
      'US_12087',
      'US_12089',
      'US_12091',
      'US_12093',
      'US_12095',
      'US_12097',
      'US_12099',
      'US_12101',
      'US_12103',
      'US_12105',
      'US_12107',
      'US_12109',
      'US_12111',
      'US_12113',
      'US_12115',
      'US_12117',
      'US_12119',
      'US_12121',
      'US_12123',
      'US_12125',
      'US_12127',
      'US_12129',
      'US_12131',
      'US_12133',
    ],
    incompleteVaccinations: 2812433,
    completeVaccinations: 14915494,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_13: {
    label: 'Georgia',
    iso3: null,
    population: '10,617,423',
    casesPastWeek: 2792,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_13001',
      'US_13003',
      'US_13005',
      'US_13007',
      'US_13009',
      'US_13011',
      'US_13013',
      'US_13015',
      'US_13017',
      'US_13019',
      'US_13021',
      'US_13023',
      'US_13025',
      'US_13027',
      'US_13029',
      'US_13031',
      'US_13033',
      'US_13035',
      'US_13037',
      'US_13039',
      'US_13043',
      'US_13045',
      'US_13047',
      'US_13049',
      'US_13051',
      'US_13053',
      'US_13055',
      'US_13057',
      'US_13059',
      'US_13061',
      'US_13063',
      'US_13065',
      'US_13067',
      'US_13069',
      'US_13071',
      'US_13073',
      'US_13075',
      'US_13077',
      'US_13079',
      'US_13081',
      'US_13083',
      'US_13085',
      'US_13087',
      'US_13089',
      'US_13091',
      'US_13093',
      'US_13095',
      'US_13097',
      'US_13099',
      'US_13101',
      'US_13103',
      'US_13105',
      'US_13107',
      'US_13109',
      'US_13111',
      'US_13113',
      'US_13115',
      'US_13117',
      'US_13119',
      'US_13121',
      'US_13123',
      'US_13125',
      'US_13127',
      'US_13129',
      'US_13131',
      'US_13133',
      'US_13135',
      'US_13137',
      'US_13139',
      'US_13141',
      'US_13143',
      'US_13145',
      'US_13147',
      'US_13149',
      'US_13151',
      'US_13153',
      'US_13155',
      'US_13157',
      'US_13159',
      'US_13161',
      'US_13163',
      'US_13165',
      'US_13167',
      'US_13169',
      'US_13171',
      'US_13173',
      'US_13175',
      'US_13177',
      'US_13179',
      'US_13181',
      'US_13183',
      'US_13185',
      'US_13187',
      'US_13189',
      'US_13191',
      'US_13193',
      'US_13195',
      'US_13197',
      'US_13199',
      'US_13201',
      'US_13205',
      'US_13207',
      'US_13209',
      'US_13211',
      'US_13213',
      'US_13215',
      'US_13217',
      'US_13219',
      'US_13221',
      'US_13223',
      'US_13225',
      'US_13227',
      'US_13229',
      'US_13231',
      'US_13233',
      'US_13235',
      'US_13237',
      'US_13239',
      'US_13241',
      'US_13243',
      'US_13245',
      'US_13247',
      'US_13249',
      'US_13251',
      'US_13253',
      'US_13255',
      'US_13257',
      'US_13259',
      'US_13261',
      'US_13263',
      'US_13265',
      'US_13267',
      'US_13269',
      'US_13271',
      'US_13273',
      'US_13275',
      'US_13277',
      'US_13279',
      'US_13281',
      'US_13283',
      'US_13285',
      'US_13287',
      'US_13289',
      'US_13291',
      'US_13293',
      'US_13295',
      'US_13297',
      'US_13299',
      'US_13301',
      'US_13303',
      'US_13305',
      'US_13307',
      'US_13309',
      'US_13311',
      'US_13313',
      'US_13315',
      'US_13317',
      'US_13319',
      'US_13321',
    ],
    incompleteVaccinations: 1180269,
    completeVaccinations: 6086702,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_15: {
    label: 'Hawaii',
    iso3: null,
    population: '1,415,872',
    casesPastWeek: 510,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: 'US states',
    subdivisions: ['US_15001', 'US_15003', 'US_15007', 'US_15009'],
    incompleteVaccinations: 140486,
    completeVaccinations: 1154399,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16: {
    label: 'Idaho',
    iso3: null,
    population: '1,787,065',
    casesPastWeek: 1270,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_16001',
      'US_16003',
      'US_16005',
      'US_16007',
      'US_16009',
      'US_16011',
      'US_16013',
      'US_16015',
      'US_16017',
      'US_16019',
      'US_16021',
      'US_16023',
      'US_16025',
      'US_16027',
      'US_16029',
      'US_16031',
      'US_16033',
      'US_16035',
      'US_16037',
      'US_16039',
      'US_16041',
      'US_16043',
      'US_16045',
      'US_16047',
      'US_16049',
      'US_16051',
      'US_16053',
      'US_16055',
      'US_16057',
      'US_16059',
      'US_16061',
      'US_16063',
      'US_16065',
      'US_16067',
      'US_16069',
      'US_16071',
      'US_16073',
      'US_16075',
      'US_16077',
      'US_16079',
      'US_16081',
      'US_16083',
      'US_16085',
      'US_16087',
    ],
    incompleteVaccinations: 133744,
    completeVaccinations: 1009286,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_17: {
    label: 'Illinois',
    iso3: null,
    population: '12,671,821',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_17001',
      'US_17003',
      'US_17005',
      'US_17007',
      'US_17009',
      'US_17011',
      'US_17013',
      'US_17015',
      'US_17017',
      'US_17019',
      'US_17021',
      'US_17023',
      'US_17025',
      'US_17027',
      'US_17029',
      'US_17031',
      'US_17033',
      'US_17035',
      'US_17037',
      'US_17039',
      'US_17041',
      'US_17043',
      'US_17045',
      'US_17047',
      'US_17049',
      'US_17051',
      'US_17053',
      'US_17055',
      'US_17057',
      'US_17059',
      'US_17061',
      'US_17063',
      'US_17065',
      'US_17067',
      'US_17069',
      'US_17071',
      'US_17073',
      'US_17075',
      'US_17077',
      'US_17079',
      'US_17081',
      'US_17083',
      'US_17085',
      'US_17087',
      'US_17089',
      'US_17091',
      'US_17093',
      'US_17095',
      'US_17097',
      'US_17099',
      'US_17101',
      'US_17103',
      'US_17105',
      'US_17107',
      'US_17109',
      'US_17111',
      'US_17113',
      'US_17115',
      'US_17117',
      'US_17119',
      'US_17121',
      'US_17123',
      'US_17125',
      'US_17127',
      'US_17129',
      'US_17131',
      'US_17133',
      'US_17135',
      'US_17137',
      'US_17139',
      'US_17141',
      'US_17143',
      'US_17145',
      'US_17147',
      'US_17149',
      'US_17151',
      'US_17153',
      'US_17155',
      'US_17157',
      'US_17159',
      'US_17161',
      'US_17163',
      'US_17165',
      'US_17167',
      'US_17169',
      'US_17171',
      'US_17173',
      'US_17175',
      'US_17177',
      'US_17179',
      'US_17181',
      'US_17183',
      'US_17185',
      'US_17187',
      'US_17189',
      'US_17191',
      'US_17193',
      'US_17195',
      'US_17197',
      'US_17199',
      'US_17201',
      'US_17203',
    ],
    incompleteVaccinations: 988036,
    completeVaccinations: 9046248,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_18: {
    label: 'Indiana',
    iso3: null,
    population: '6,732,219',
    casesPastWeek: 3482,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_18001',
      'US_18003',
      'US_18005',
      'US_18007',
      'US_18009',
      'US_18011',
      'US_18013',
      'US_18015',
      'US_18017',
      'US_18019',
      'US_18021',
      'US_18023',
      'US_18025',
      'US_18027',
      'US_18029',
      'US_18031',
      'US_18033',
      'US_18035',
      'US_18037',
      'US_18039',
      'US_18041',
      'US_18043',
      'US_18045',
      'US_18047',
      'US_18049',
      'US_18051',
      'US_18053',
      'US_18055',
      'US_18057',
      'US_18059',
      'US_18061',
      'US_18063',
      'US_18065',
      'US_18067',
      'US_18069',
      'US_18071',
      'US_18073',
      'US_18075',
      'US_18077',
      'US_18079',
      'US_18081',
      'US_18083',
      'US_18085',
      'US_18087',
      'US_18089',
      'US_18091',
      'US_18093',
      'US_18095',
      'US_18097',
      'US_18099',
      'US_18101',
      'US_18103',
      'US_18105',
      'US_18107',
      'US_18109',
      'US_18111',
      'US_18113',
      'US_18115',
      'US_18117',
      'US_18119',
      'US_18121',
      'US_18123',
      'US_18125',
      'US_18127',
      'US_18129',
      'US_18131',
      'US_18133',
      'US_18135',
      'US_18137',
      'US_18139',
      'US_18141',
      'US_18143',
      'US_18145',
      'US_18147',
      'US_18149',
      'US_18151',
      'US_18153',
      'US_18155',
      'US_18157',
      'US_18159',
      'US_18161',
      'US_18163',
      'US_18165',
      'US_18167',
      'US_18169',
      'US_18171',
      'US_18173',
      'US_18175',
      'US_18177',
      'US_18179',
      'US_18181',
      'US_18183',
    ],
    incompleteVaccinations: 449861,
    completeVaccinations: 3888678,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19: {
    label: 'Iowa',
    iso3: null,
    population: '3,155,070',
    casesPastWeek: 1916,
    casesIncreasingPercentage: 10.623556581986149,
    positiveCasePercentage: null,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_19001',
      'US_19003',
      'US_19005',
      'US_19007',
      'US_19009',
      'US_19011',
      'US_19013',
      'US_19015',
      'US_19017',
      'US_19019',
      'US_19021',
      'US_19023',
      'US_19025',
      'US_19027',
      'US_19029',
      'US_19031',
      'US_19033',
      'US_19035',
      'US_19037',
      'US_19039',
      'US_19041',
      'US_19043',
      'US_19045',
      'US_19047',
      'US_19049',
      'US_19051',
      'US_19053',
      'US_19055',
      'US_19057',
      'US_19059',
      'US_19061',
      'US_19063',
      'US_19065',
      'US_19067',
      'US_19069',
      'US_19071',
      'US_19073',
      'US_19075',
      'US_19077',
      'US_19079',
      'US_19081',
      'US_19083',
      'US_19085',
      'US_19087',
      'US_19089',
      'US_19091',
      'US_19093',
      'US_19095',
      'US_19097',
      'US_19099',
      'US_19101',
      'US_19103',
      'US_19105',
      'US_19107',
      'US_19109',
      'US_19111',
      'US_19113',
      'US_19115',
      'US_19117',
      'US_19119',
      'US_19121',
      'US_19123',
      'US_19125',
      'US_19127',
      'US_19129',
      'US_19131',
      'US_19133',
      'US_19135',
      'US_19137',
      'US_19139',
      'US_19141',
      'US_19143',
      'US_19145',
      'US_19147',
      'US_19149',
      'US_19151',
      'US_19153',
      'US_19155',
      'US_19157',
      'US_19159',
      'US_19161',
      'US_19163',
      'US_19165',
      'US_19167',
      'US_19169',
      'US_19171',
      'US_19173',
      'US_19175',
      'US_19177',
      'US_19179',
      'US_19181',
      'US_19183',
      'US_19185',
      'US_19187',
      'US_19189',
      'US_19191',
      'US_19193',
      'US_19195',
      'US_19197',
    ],
    incompleteVaccinations: 198507,
    completeVaccinations: 2031863,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20: {
    label: 'Kansas',
    iso3: null,
    population: '2,913,314',
    casesPastWeek: 1753,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_20001',
      'US_20003',
      'US_20005',
      'US_20007',
      'US_20009',
      'US_20011',
      'US_20013',
      'US_20015',
      'US_20017',
      'US_20019',
      'US_20021',
      'US_20023',
      'US_20025',
      'US_20027',
      'US_20029',
      'US_20031',
      'US_20033',
      'US_20035',
      'US_20037',
      'US_20039',
      'US_20041',
      'US_20043',
      'US_20045',
      'US_20047',
      'US_20049',
      'US_20051',
      'US_20053',
      'US_20055',
      'US_20057',
      'US_20059',
      'US_20061',
      'US_20063',
      'US_20065',
      'US_20067',
      'US_20069',
      'US_20071',
      'US_20073',
      'US_20075',
      'US_20077',
      'US_20079',
      'US_20081',
      'US_20083',
      'US_20085',
      'US_20087',
      'US_20089',
      'US_20091',
      'US_20093',
      'US_20095',
      'US_20097',
      'US_20099',
      'US_20101',
      'US_20103',
      'US_20105',
      'US_20107',
      'US_20109',
      'US_20111',
      'US_20113',
      'US_20115',
      'US_20117',
      'US_20119',
      'US_20121',
      'US_20123',
      'US_20125',
      'US_20127',
      'US_20129',
      'US_20131',
      'US_20133',
      'US_20135',
      'US_20137',
      'US_20139',
      'US_20141',
      'US_20143',
      'US_20145',
      'US_20147',
      'US_20149',
      'US_20151',
      'US_20153',
      'US_20155',
      'US_20157',
      'US_20159',
      'US_20161',
      'US_20163',
      'US_20165',
      'US_20167',
      'US_20169',
      'US_20171',
      'US_20173',
      'US_20175',
      'US_20177',
      'US_20179',
      'US_20181',
      'US_20183',
      'US_20185',
      'US_20187',
      'US_20189',
      'US_20191',
      'US_20193',
      'US_20195',
      'US_20197',
      'US_20199',
      'US_20201',
      'US_20203',
      'US_20205',
      'US_20207',
      'US_20209',
    ],
    incompleteVaccinations: 318503,
    completeVaccinations: 1903883,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21: {
    label: 'Kentucky',
    iso3: null,
    population: '4,467,673',
    casesPastWeek: 5251,
    casesIncreasingPercentage: 9.899539556299697,
    positiveCasePercentage: 8.6,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_21001',
      'US_21003',
      'US_21005',
      'US_21007',
      'US_21009',
      'US_21011',
      'US_21013',
      'US_21015',
      'US_21017',
      'US_21019',
      'US_21021',
      'US_21023',
      'US_21025',
      'US_21027',
      'US_21029',
      'US_21031',
      'US_21033',
      'US_21035',
      'US_21037',
      'US_21039',
      'US_21041',
      'US_21043',
      'US_21045',
      'US_21047',
      'US_21049',
      'US_21051',
      'US_21053',
      'US_21055',
      'US_21057',
      'US_21059',
      'US_21061',
      'US_21063',
      'US_21065',
      'US_21067',
      'US_21069',
      'US_21071',
      'US_21073',
      'US_21075',
      'US_21077',
      'US_21079',
      'US_21081',
      'US_21083',
      'US_21085',
      'US_21087',
      'US_21089',
      'US_21091',
      'US_21093',
      'US_21095',
      'US_21097',
      'US_21099',
      'US_21101',
      'US_21103',
      'US_21105',
      'US_21107',
      'US_21109',
      'US_21111',
      'US_21113',
      'US_21115',
      'US_21117',
      'US_21119',
      'US_21121',
      'US_21123',
      'US_21125',
      'US_21127',
      'US_21129',
      'US_21131',
      'US_21133',
      'US_21135',
      'US_21137',
      'US_21139',
      'US_21141',
      'US_21143',
      'US_21145',
      'US_21147',
      'US_21149',
      'US_21151',
      'US_21153',
      'US_21155',
      'US_21157',
      'US_21159',
      'US_21161',
      'US_21163',
      'US_21165',
      'US_21167',
      'US_21169',
      'US_21171',
      'US_21173',
      'US_21175',
      'US_21177',
      'US_21179',
      'US_21181',
      'US_21183',
      'US_21185',
      'US_21187',
      'US_21189',
      'US_21191',
      'US_21193',
      'US_21195',
      'US_21197',
      'US_21199',
      'US_21201',
      'US_21203',
      'US_21205',
      'US_21207',
      'US_21209',
      'US_21211',
      'US_21213',
      'US_21215',
      'US_21217',
      'US_21219',
      'US_21221',
      'US_21223',
      'US_21225',
      'US_21227',
      'US_21229',
      'US_21231',
      'US_21233',
      'US_21235',
      'US_21237',
      'US_21239',
    ],
    incompleteVaccinations: 413682,
    completeVaccinations: 2664153,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22: {
    label: 'Louisiana',
    iso3: null,
    population: '4,648,794',
    casesPastWeek: 3139,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_22001',
      'US_22003',
      'US_22005',
      'US_22007',
      'US_22009',
      'US_22011',
      'US_22013',
      'US_22015',
      'US_22017',
      'US_22019',
      'US_22021',
      'US_22023',
      'US_22025',
      'US_22027',
      'US_22029',
      'US_22031',
      'US_22033',
      'US_22035',
      'US_22037',
      'US_22039',
      'US_22041',
      'US_22043',
      'US_22045',
      'US_22047',
      'US_22049',
      'US_22051',
      'US_22053',
      'US_22055',
      'US_22057',
      'US_22059',
      'US_22061',
      'US_22063',
      'US_22065',
      'US_22067',
      'US_22069',
      'US_22071',
      'US_22073',
      'US_22075',
      'US_22077',
      'US_22079',
      'US_22081',
      'US_22083',
      'US_22085',
      'US_22087',
      'US_22089',
      'US_22091',
      'US_22093',
      'US_22095',
      'US_22097',
      'US_22099',
      'US_22101',
      'US_22103',
      'US_22105',
      'US_22107',
      'US_22109',
      'US_22111',
      'US_22113',
      'US_22115',
      'US_22117',
      'US_22119',
      'US_22121',
      'US_22123',
      'US_22125',
      'US_22127',
    ],
    incompleteVaccinations: 361389,
    completeVaccinations: 2558088,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_23: {
    label: 'Maine',
    iso3: null,
    population: '1,344,212',
    casesPastWeek: 1370,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_23001',
      'US_23003',
      'US_23005',
      'US_23007',
      'US_23009',
      'US_23011',
      'US_23013',
      'US_23015',
      'US_23017',
      'US_23019',
      'US_23021',
      'US_23023',
      'US_23025',
      'US_23027',
      'US_23029',
      'US_23031',
    ],
    incompleteVaccinations: 190379,
    completeVaccinations: 1120822,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_24: {
    label: 'Maryland',
    iso3: null,
    population: '6,045,680',
    casesPastWeek: 2670,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_24001',
      'US_24003',
      'US_24005',
      'US_24009',
      'US_24011',
      'US_24013',
      'US_24015',
      'US_24017',
      'US_24019',
      'US_24021',
      'US_24023',
      'US_24025',
      'US_24027',
      'US_24029',
      'US_24031',
      'US_24033',
      'US_24035',
      'US_24037',
      'US_24039',
      'US_24041',
      'US_24043',
      'US_24045',
      'US_24047',
      'US_24510',
    ],
    incompleteVaccinations: 727215,
    completeVaccinations: 4821895,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_25: {
    label: 'Massachusetts',
    iso3: null,
    population: '6,892,503',
    casesPastWeek: 4092,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_Massachusetts_Dukes_and_Nantucket',
      'US_25001',
      'US_25003',
      'US_25005',
      'US_25009',
      'US_25011',
      'US_25013',
      'US_25015',
      'US_25017',
      'US_25021',
      'US_25023',
      'US_25025',
      'US_25027',
    ],
    incompleteVaccinations: 1556334,
    completeVaccinations: 5810671,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_26: {
    label: 'Michigan',
    iso3: null,
    population: '9,986,857',
    casesPastWeek: 6852,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_26001',
      'US_26003',
      'US_26005',
      'US_26007',
      'US_26009',
      'US_26011',
      'US_26013',
      'US_26015',
      'US_26017',
      'US_26019',
      'US_26021',
      'US_26023',
      'US_26025',
      'US_26027',
      'US_26029',
      'US_26031',
      'US_26033',
      'US_26035',
      'US_26037',
      'US_26039',
      'US_26041',
      'US_26043',
      'US_26045',
      'US_26047',
      'US_26049',
      'US_26051',
      'US_26053',
      'US_26055',
      'US_26057',
      'US_26059',
      'US_26061',
      'US_26063',
      'US_26065',
      'US_26067',
      'US_26069',
      'US_26071',
      'US_26073',
      'US_26075',
      'US_26077',
      'US_26079',
      'US_26081',
      'US_26083',
      'US_26085',
      'US_26087',
      'US_26089',
      'US_26091',
      'US_26093',
      'US_26095',
      'US_26097',
      'US_26099',
      'US_26101',
      'US_26103',
      'US_26105',
      'US_26107',
      'US_26109',
      'US_26111',
      'US_26113',
      'US_26115',
      'US_26117',
      'US_26119',
      'US_26121',
      'US_26123',
      'US_26125',
      'US_26127',
      'US_26129',
      'US_26131',
      'US_26133',
      'US_26135',
      'US_26137',
      'US_26139',
      'US_26141',
      'US_26143',
      'US_26145',
      'US_26147',
      'US_26149',
      'US_26151',
      'US_26153',
      'US_26155',
      'US_26157',
      'US_26159',
      'US_26161',
      'US_26163',
      'US_26165',
    ],
    incompleteVaccinations: 710685,
    completeVaccinations: 6225457,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_27: {
    label: 'Minnesota',
    iso3: null,
    population: '5,639,632',
    casesPastWeek: 5147,
    casesIncreasingPercentage: 58.0288609149524,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_27001',
      'US_27003',
      'US_27005',
      'US_27007',
      'US_27009',
      'US_27011',
      'US_27013',
      'US_27015',
      'US_27017',
      'US_27019',
      'US_27021',
      'US_27023',
      'US_27025',
      'US_27027',
      'US_27029',
      'US_27031',
      'US_27033',
      'US_27035',
      'US_27037',
      'US_27039',
      'US_27041',
      'US_27043',
      'US_27045',
      'US_27047',
      'US_27049',
      'US_27051',
      'US_27053',
      'US_27055',
      'US_27057',
      'US_27059',
      'US_27061',
      'US_27063',
      'US_27065',
      'US_27067',
      'US_27069',
      'US_27071',
      'US_27073',
      'US_27075',
      'US_27077',
      'US_27079',
      'US_27081',
      'US_27083',
      'US_27085',
      'US_27087',
      'US_27089',
      'US_27091',
      'US_27093',
      'US_27095',
      'US_27097',
      'US_27099',
      'US_27101',
      'US_27103',
      'US_27105',
      'US_27107',
      'US_27109',
      'US_27111',
      'US_27113',
      'US_27115',
      'US_27117',
      'US_27119',
      'US_27121',
      'US_27123',
      'US_27125',
      'US_27127',
      'US_27129',
      'US_27131',
      'US_27133',
      'US_27135',
      'US_27137',
      'US_27139',
      'US_27141',
      'US_27143',
      'US_27145',
      'US_27147',
      'US_27149',
      'US_27151',
      'US_27153',
      'US_27155',
      'US_27157',
      'US_27159',
      'US_27161',
      'US_27163',
      'US_27165',
      'US_27167',
      'US_27169',
      'US_27171',
      'US_27173',
    ],
    incompleteVaccinations: 376813,
    completeVaccinations: 4070427,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_28: {
    label: 'Mississippi',
    iso3: null,
    population: '2,976,149',
    casesPastWeek: 1474,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_28001',
      'US_28003',
      'US_28005',
      'US_28007',
      'US_28009',
      'US_28011',
      'US_28013',
      'US_28015',
      'US_28017',
      'US_28019',
      'US_28021',
      'US_28023',
      'US_28025',
      'US_28027',
      'US_28029',
      'US_28031',
      'US_28033',
      'US_28035',
      'US_28037',
      'US_28039',
      'US_28041',
      'US_28043',
      'US_28045',
      'US_28047',
      'US_28049',
      'US_28051',
      'US_28053',
      'US_28055',
      'US_28057',
      'US_28059',
      'US_28061',
      'US_28063',
      'US_28065',
      'US_28067',
      'US_28069',
      'US_28071',
      'US_28073',
      'US_28075',
      'US_28077',
      'US_28079',
      'US_28081',
      'US_28083',
      'US_28085',
      'US_28087',
      'US_28089',
      'US_28091',
      'US_28093',
      'US_28095',
      'US_28097',
      'US_28099',
      'US_28101',
      'US_28103',
      'US_28105',
      'US_28107',
      'US_28109',
      'US_28111',
      'US_28113',
      'US_28115',
      'US_28117',
      'US_28119',
      'US_28121',
      'US_28123',
      'US_28125',
      'US_28127',
      'US_28129',
      'US_28131',
      'US_28133',
      'US_28135',
      'US_28137',
      'US_28139',
      'US_28141',
      'US_28143',
      'US_28145',
      'US_28147',
      'US_28149',
      'US_28151',
      'US_28153',
      'US_28155',
      'US_28157',
      'US_28159',
      'US_28161',
      'US_28163',
    ],
    incompleteVaccinations: 237382,
    completeVaccinations: 1597932,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_29: {
    label: 'Missouri',
    iso3: null,
    population: '6,137,428',
    casesPastWeek: 4109,
    casesIncreasingPercentage: 18.176589013517397,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_Missouri_Kansas_City',
      'US_29001',
      'US_29003',
      'US_29005',
      'US_29007',
      'US_29009',
      'US_29011',
      'US_29013',
      'US_29015',
      'US_29017',
      'US_29019',
      'US_29021',
      'US_29023',
      'US_29025',
      'US_29027',
      'US_29029',
      'US_29031',
      'US_29033',
      'US_29035',
      'US_29037',
      'US_29039',
      'US_29041',
      'US_29043',
      'US_29045',
      'US_29047',
      'US_29049',
      'US_29051',
      'US_29053',
      'US_29055',
      'US_29057',
      'US_29059',
      'US_29061',
      'US_29063',
      'US_29065',
      'US_29067',
      'US_29069',
      'US_29071',
      'US_29073',
      'US_29075',
      'US_29077',
      'US_29079',
      'US_29081',
      'US_29083',
      'US_29085',
      'US_29087',
      'US_29089',
      'US_29091',
      'US_29093',
      'US_29095',
      'US_29097',
      'US_29099',
      'US_29101',
      'US_29103',
      'US_29105',
      'US_29107',
      'US_29109',
      'US_29111',
      'US_29113',
      'US_29115',
      'US_29117',
      'US_29119',
      'US_29121',
      'US_29123',
      'US_29125',
      'US_29127',
      'US_29129',
      'US_29131',
      'US_29133',
      'US_29135',
      'US_29137',
      'US_29139',
      'US_29141',
      'US_29143',
      'US_29145',
      'US_29147',
      'US_29149',
      'US_29151',
      'US_29153',
      'US_29155',
      'US_29157',
      'US_29159',
      'US_29161',
      'US_29163',
      'US_29165',
      'US_29167',
      'US_29169',
      'US_29171',
      'US_29173',
      'US_29175',
      'US_29177',
      'US_29179',
      'US_29181',
      'US_29183',
      'US_29185',
      'US_29186',
      'US_29187',
      'US_29189',
      'US_29195',
      'US_29197',
      'US_29199',
      'US_29201',
      'US_29203',
      'US_29205',
      'US_29207',
      'US_29209',
      'US_29211',
      'US_29213',
      'US_29215',
      'US_29217',
      'US_29219',
      'US_29221',
      'US_29223',
      'US_29225',
      'US_29227',
      'US_29229',
      'US_29510',
    ],
    incompleteVaccinations: 632066,
    completeVaccinations: 3625649,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_30: {
    label: 'Montana',
    iso3: null,
    population: '1,068,778',
    casesPastWeek: 718,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_30001',
      'US_30003',
      'US_30005',
      'US_30007',
      'US_30009',
      'US_30011',
      'US_30013',
      'US_30015',
      'US_30017',
      'US_30019',
      'US_30021',
      'US_30023',
      'US_30025',
      'US_30027',
      'US_30029',
      'US_30031',
      'US_30033',
      'US_30035',
      'US_30037',
      'US_30039',
      'US_30041',
      'US_30043',
      'US_30045',
      'US_30047',
      'US_30049',
      'US_30051',
      'US_30053',
      'US_30055',
      'US_30057',
      'US_30059',
      'US_30061',
      'US_30063',
      'US_30065',
      'US_30067',
      'US_30069',
      'US_30071',
      'US_30073',
      'US_30075',
      'US_30077',
      'US_30079',
      'US_30081',
      'US_30083',
      'US_30085',
      'US_30087',
      'US_30089',
      'US_30091',
      'US_30093',
      'US_30095',
      'US_30097',
      'US_30099',
      'US_30101',
      'US_30103',
      'US_30105',
      'US_30107',
      'US_30109',
      'US_30111',
    ],
    incompleteVaccinations: 97400,
    completeVaccinations: 632502,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31: {
    label: 'Nebraska',
    iso3: null,
    population: '1,934,408',
    casesPastWeek: 1569,
    casesIncreasingPercentage: 18.23662396382819,
    positiveCasePercentage: 15.4,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_31001',
      'US_31003',
      'US_31005',
      'US_31007',
      'US_31009',
      'US_31011',
      'US_31013',
      'US_31015',
      'US_31017',
      'US_31019',
      'US_31021',
      'US_31023',
      'US_31025',
      'US_31027',
      'US_31029',
      'US_31031',
      'US_31033',
      'US_31035',
      'US_31037',
      'US_31039',
      'US_31041',
      'US_31043',
      'US_31045',
      'US_31047',
      'US_31049',
      'US_31051',
      'US_31053',
      'US_31055',
      'US_31057',
      'US_31059',
      'US_31061',
      'US_31063',
      'US_31065',
      'US_31067',
      'US_31069',
      'US_31071',
      'US_31073',
      'US_31075',
      'US_31077',
      'US_31079',
      'US_31081',
      'US_31083',
      'US_31085',
      'US_31087',
      'US_31089',
      'US_31091',
      'US_31093',
      'US_31095',
      'US_31097',
      'US_31099',
      'US_31101',
      'US_31103',
      'US_31105',
      'US_31107',
      'US_31109',
      'US_31111',
      'US_31113',
      'US_31115',
      'US_31117',
      'US_31119',
      'US_31121',
      'US_31123',
      'US_31125',
      'US_31127',
      'US_31129',
      'US_31131',
      'US_31133',
      'US_31135',
      'US_31137',
      'US_31139',
      'US_31141',
      'US_31143',
      'US_31145',
      'US_31147',
      'US_31149',
      'US_31151',
      'US_31153',
      'US_31155',
      'US_31157',
      'US_31159',
      'US_31161',
      'US_31163',
      'US_31165',
      'US_31167',
      'US_31169',
      'US_31171',
      'US_31173',
      'US_31175',
      'US_31177',
      'US_31179',
      'US_31181',
      'US_31183',
      'US_31185',
    ],
    incompleteVaccinations: 138131,
    completeVaccinations: 1282631,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_32: {
    label: 'Nevada',
    iso3: null,
    population: '3,080,156',
    casesPastWeek: 1139,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_32001',
      'US_32003',
      'US_32005',
      'US_32007',
      'US_32009',
      'US_32011',
      'US_32013',
      'US_32015',
      'US_32017',
      'US_32019',
      'US_32021',
      'US_32023',
      'US_32027',
      'US_32029',
      'US_32031',
      'US_32033',
      'US_32510',
    ],
    incompleteVaccinations: 429353,
    completeVaccinations: 1962779,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33: {
    label: 'New Hampshire',
    iso3: null,
    population: '1,359,711',
    casesPastWeek: 679,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_33001',
      'US_33003',
      'US_33005',
      'US_33007',
      'US_33009',
      'US_33011',
      'US_33013',
      'US_33015',
      'US_33017',
      'US_33019',
    ],
    incompleteVaccinations: 220790,
    completeVaccinations: 978992,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34: {
    label: 'New Jersey',
    iso3: null,
    population: '8,882,190',
    casesPastWeek: 5122,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.1,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_34001',
      'US_34003',
      'US_34005',
      'US_34007',
      'US_34009',
      'US_34011',
      'US_34013',
      'US_34015',
      'US_34017',
      'US_34019',
      'US_34021',
      'US_34023',
      'US_34025',
      'US_34027',
      'US_34029',
      'US_34031',
      'US_34033',
      'US_34035',
      'US_34037',
      'US_34039',
      'US_34041',
    ],
    incompleteVaccinations: 1380046,
    completeVaccinations: 7031019,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35: {
    label: 'New Mexico',
    iso3: null,
    population: '2,096,829',
    casesPastWeek: 1212,
    casesIncreasingPercentage: 2.020202020202011,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_35001',
      'US_35003',
      'US_35005',
      'US_35006',
      'US_35007',
      'US_35009',
      'US_35011',
      'US_35013',
      'US_35015',
      'US_35017',
      'US_35019',
      'US_35021',
      'US_35023',
      'US_35025',
      'US_35027',
      'US_35028',
      'US_35029',
      'US_35031',
      'US_35033',
      'US_35035',
      'US_35037',
      'US_35039',
      'US_35041',
      'US_35043',
      'US_35045',
      'US_35047',
      'US_35049',
      'US_35051',
      'US_35053',
      'US_35055',
      'US_35057',
      'US_35059',
      'US_35061',
    ],
    incompleteVaccinations: 403743,
    completeVaccinations: 1577485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36: {
    label: 'New York',
    iso3: null,
    population: '19,453,561',
    casesPastWeek: 10558,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_36001',
      'US_36003',
      'US_36005',
      'US_36007',
      'US_36009',
      'US_36011',
      'US_36013',
      'US_36015',
      'US_36017',
      'US_36019',
      'US_36021',
      'US_36023',
      'US_36025',
      'US_36027',
      'US_36029',
      'US_36031',
      'US_36033',
      'US_36035',
      'US_36037',
      'US_36039',
      'US_36041',
      'US_36043',
      'US_36045',
      'US_36047',
      'US_36049',
      'US_36051',
      'US_36053',
      'US_36055',
      'US_36057',
      'US_36059',
      'US_36061',
      'US_36063',
      'US_36065',
      'US_36067',
      'US_36069',
      'US_36071',
      'US_36073',
      'US_36075',
      'US_36077',
      'US_36079',
      'US_36081',
      'US_36083',
      'US_36085',
      'US_36087',
      'US_36089',
      'US_36091',
      'US_36093',
      'US_36095',
      'US_36097',
      'US_36099',
      'US_36101',
      'US_36103',
      'US_36105',
      'US_36107',
      'US_36109',
      'US_36111',
      'US_36113',
      'US_36115',
      'US_36117',
      'US_36119',
      'US_36121',
      'US_36123',
    ],
    incompleteVaccinations: 2655221,
    completeVaccinations: 15725980,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37: {
    label: 'North Carolina',
    iso3: null,
    population: '10,488,084',
    casesPastWeek: 5418,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_37001',
      'US_37003',
      'US_37005',
      'US_37007',
      'US_37009',
      'US_37011',
      'US_37013',
      'US_37015',
      'US_37017',
      'US_37019',
      'US_37021',
      'US_37023',
      'US_37025',
      'US_37027',
      'US_37029',
      'US_37031',
      'US_37033',
      'US_37035',
      'US_37037',
      'US_37039',
      'US_37041',
      'US_37043',
      'US_37045',
      'US_37047',
      'US_37049',
      'US_37051',
      'US_37053',
      'US_37055',
      'US_37057',
      'US_37059',
      'US_37061',
      'US_37063',
      'US_37065',
      'US_37067',
      'US_37069',
      'US_37071',
      'US_37073',
      'US_37075',
      'US_37077',
      'US_37079',
      'US_37081',
      'US_37083',
      'US_37085',
      'US_37087',
      'US_37089',
      'US_37091',
      'US_37093',
      'US_37095',
      'US_37097',
      'US_37099',
      'US_37101',
      'US_37103',
      'US_37105',
      'US_37107',
      'US_37109',
      'US_37111',
      'US_37113',
      'US_37115',
      'US_37117',
      'US_37119',
      'US_37121',
      'US_37123',
      'US_37125',
      'US_37127',
      'US_37129',
      'US_37131',
      'US_37133',
      'US_37135',
      'US_37137',
      'US_37139',
      'US_37141',
      'US_37143',
      'US_37145',
      'US_37147',
      'US_37149',
      'US_37151',
      'US_37153',
      'US_37155',
      'US_37157',
      'US_37159',
      'US_37161',
      'US_37163',
      'US_37165',
      'US_37167',
      'US_37169',
      'US_37171',
      'US_37173',
      'US_37175',
      'US_37177',
      'US_37179',
      'US_37181',
      'US_37183',
      'US_37185',
      'US_37187',
      'US_37189',
      'US_37191',
      'US_37193',
      'US_37195',
      'US_37197',
      'US_37199',
    ],
    incompleteVaccinations: 2636267,
    completeVaccinations: 7046573,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_38: {
    label: 'North Dakota',
    iso3: null,
    population: '762,062',
    casesPastWeek: 83,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_38001',
      'US_38003',
      'US_38005',
      'US_38007',
      'US_38009',
      'US_38011',
      'US_38013',
      'US_38015',
      'US_38017',
      'US_38019',
      'US_38021',
      'US_38023',
      'US_38025',
      'US_38027',
      'US_38029',
      'US_38031',
      'US_38033',
      'US_38035',
      'US_38037',
      'US_38039',
      'US_38041',
      'US_38043',
      'US_38045',
      'US_38047',
      'US_38049',
      'US_38051',
      'US_38053',
      'US_38055',
      'US_38057',
      'US_38059',
      'US_38061',
      'US_38063',
      'US_38065',
      'US_38067',
      'US_38069',
      'US_38071',
      'US_38073',
      'US_38075',
      'US_38077',
      'US_38079',
      'US_38081',
      'US_38083',
      'US_38085',
      'US_38087',
      'US_38089',
      'US_38091',
      'US_38093',
      'US_38095',
      'US_38097',
      'US_38099',
      'US_38101',
      'US_38103',
      'US_38105',
    ],
    incompleteVaccinations: 81607,
    completeVaccinations: 447421,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39: {
    label: 'Ohio',
    iso3: null,
    population: '11,689,100',
    casesPastWeek: 9325,
    casesIncreasingPercentage: 2.0687390542907247,
    positiveCasePercentage: 9.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_39001',
      'US_39003',
      'US_39005',
      'US_39007',
      'US_39009',
      'US_39011',
      'US_39013',
      'US_39015',
      'US_39017',
      'US_39019',
      'US_39021',
      'US_39023',
      'US_39025',
      'US_39027',
      'US_39029',
      'US_39031',
      'US_39033',
      'US_39035',
      'US_39037',
      'US_39039',
      'US_39041',
      'US_39043',
      'US_39045',
      'US_39047',
      'US_39049',
      'US_39051',
      'US_39053',
      'US_39055',
      'US_39057',
      'US_39059',
      'US_39061',
      'US_39063',
      'US_39065',
      'US_39067',
      'US_39069',
      'US_39071',
      'US_39073',
      'US_39075',
      'US_39077',
      'US_39079',
      'US_39081',
      'US_39083',
      'US_39085',
      'US_39087',
      'US_39089',
      'US_39091',
      'US_39093',
      'US_39095',
      'US_39097',
      'US_39099',
      'US_39101',
      'US_39103',
      'US_39105',
      'US_39107',
      'US_39109',
      'US_39111',
      'US_39113',
      'US_39115',
      'US_39117',
      'US_39119',
      'US_39121',
      'US_39123',
      'US_39125',
      'US_39127',
      'US_39129',
      'US_39131',
      'US_39133',
      'US_39135',
      'US_39137',
      'US_39139',
      'US_39141',
      'US_39143',
      'US_39145',
      'US_39147',
      'US_39149',
      'US_39151',
      'US_39153',
      'US_39155',
      'US_39157',
      'US_39159',
      'US_39161',
      'US_39163',
      'US_39165',
      'US_39167',
      'US_39169',
      'US_39171',
      'US_39173',
      'US_39175',
    ],
    incompleteVaccinations: 617305,
    completeVaccinations: 7070783,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40: {
    label: 'Oklahoma',
    iso3: null,
    population: '3,956,971',
    casesPastWeek: 2928,
    casesIncreasingPercentage: 1.0003449465332936,
    positiveCasePercentage: 18.8,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_40001',
      'US_40003',
      'US_40005',
      'US_40007',
      'US_40009',
      'US_40011',
      'US_40013',
      'US_40015',
      'US_40017',
      'US_40019',
      'US_40021',
      'US_40023',
      'US_40025',
      'US_40027',
      'US_40029',
      'US_40031',
      'US_40033',
      'US_40035',
      'US_40037',
      'US_40039',
      'US_40041',
      'US_40043',
      'US_40045',
      'US_40047',
      'US_40049',
      'US_40051',
      'US_40053',
      'US_40055',
      'US_40057',
      'US_40059',
      'US_40061',
      'US_40063',
      'US_40065',
      'US_40067',
      'US_40069',
      'US_40071',
      'US_40073',
      'US_40075',
      'US_40077',
      'US_40079',
      'US_40081',
      'US_40083',
      'US_40085',
      'US_40087',
      'US_40089',
      'US_40091',
      'US_40093',
      'US_40095',
      'US_40097',
      'US_40099',
      'US_40101',
      'US_40103',
      'US_40105',
      'US_40107',
      'US_40109',
      'US_40111',
      'US_40113',
      'US_40115',
      'US_40117',
      'US_40119',
      'US_40121',
      'US_40123',
      'US_40125',
      'US_40127',
      'US_40129',
      'US_40131',
      'US_40133',
      'US_40135',
      'US_40137',
      'US_40139',
      'US_40141',
      'US_40143',
      'US_40145',
      'US_40147',
      'US_40149',
      'US_40151',
      'US_40153',
    ],
    incompleteVaccinations: 560317,
    completeVaccinations: 2393713,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_41: {
    label: 'Oregon',
    iso3: null,
    population: '4,217,737',
    casesPastWeek: 2041,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_41001',
      'US_41003',
      'US_41005',
      'US_41007',
      'US_41009',
      'US_41011',
      'US_41013',
      'US_41015',
      'US_41017',
      'US_41019',
      'US_41021',
      'US_41023',
      'US_41025',
      'US_41027',
      'US_41029',
      'US_41031',
      'US_41033',
      'US_41035',
      'US_41037',
      'US_41039',
      'US_41041',
      'US_41043',
      'US_41045',
      'US_41047',
      'US_41049',
      'US_41051',
      'US_41053',
      'US_41055',
      'US_41057',
      'US_41059',
      'US_41061',
      'US_41063',
      'US_41065',
      'US_41067',
      'US_41069',
      'US_41071',
    ],
    incompleteVaccinations: 390657,
    completeVaccinations: 3055514,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42: {
    label: 'Pennsylvania',
    iso3: null,
    population: '12,801,989',
    casesPastWeek: 7041,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_42001',
      'US_42003',
      'US_42005',
      'US_42007',
      'US_42009',
      'US_42011',
      'US_42013',
      'US_42015',
      'US_42017',
      'US_42019',
      'US_42021',
      'US_42023',
      'US_42025',
      'US_42027',
      'US_42029',
      'US_42031',
      'US_42033',
      'US_42035',
      'US_42037',
      'US_42039',
      'US_42041',
      'US_42043',
      'US_42045',
      'US_42047',
      'US_42049',
      'US_42051',
      'US_42053',
      'US_42055',
      'US_42057',
      'US_42059',
      'US_42061',
      'US_42063',
      'US_42065',
      'US_42067',
      'US_42069',
      'US_42071',
      'US_42073',
      'US_42075',
      'US_42077',
      'US_42079',
      'US_42081',
      'US_42083',
      'US_42085',
      'US_42087',
      'US_42089',
      'US_42091',
      'US_42093',
      'US_42095',
      'US_42097',
      'US_42099',
      'US_42101',
      'US_42103',
      'US_42105',
      'US_42107',
      'US_42109',
      'US_42111',
      'US_42113',
      'US_42115',
      'US_42117',
      'US_42119',
      'US_42121',
      'US_42123',
      'US_42125',
      'US_42127',
      'US_42129',
      'US_42131',
      'US_42133',
    ],
    incompleteVaccinations: 2210031,
    completeVaccinations: 9401784,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_44: {
    label: 'Rhode Island',
    iso3: null,
    population: '1,059,361',
    casesPastWeek: 789,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: 'US states',
    subdivisions: ['US_44001', 'US_44003', 'US_44005', 'US_44007', 'US_44009'],
    incompleteVaccinations: 193609,
    completeVaccinations: 929813,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_45: {
    label: 'South Carolina',
    iso3: null,
    population: '5,148,714',
    casesPastWeek: 2837,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_45001',
      'US_45003',
      'US_45005',
      'US_45007',
      'US_45009',
      'US_45011',
      'US_45013',
      'US_45015',
      'US_45017',
      'US_45019',
      'US_45021',
      'US_45023',
      'US_45025',
      'US_45027',
      'US_45029',
      'US_45031',
      'US_45033',
      'US_45035',
      'US_45037',
      'US_45039',
      'US_45041',
      'US_45043',
      'US_45045',
      'US_45047',
      'US_45049',
      'US_45051',
      'US_45053',
      'US_45055',
      'US_45057',
      'US_45059',
      'US_45061',
      'US_45063',
      'US_45065',
      'US_45067',
      'US_45069',
      'US_45071',
      'US_45073',
      'US_45075',
      'US_45077',
      'US_45079',
      'US_45081',
      'US_45083',
      'US_45085',
      'US_45087',
      'US_45089',
      'US_45091',
    ],
    incompleteVaccinations: 572336,
    completeVaccinations: 3084933,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_46: {
    label: 'South Dakota',
    iso3: null,
    population: '884,659',
    casesPastWeek: 641,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_46003',
      'US_46005',
      'US_46007',
      'US_46009',
      'US_46011',
      'US_46013',
      'US_46015',
      'US_46017',
      'US_46019',
      'US_46021',
      'US_46023',
      'US_46025',
      'US_46027',
      'US_46029',
      'US_46031',
      'US_46033',
      'US_46035',
      'US_46037',
      'US_46039',
      'US_46041',
      'US_46043',
      'US_46045',
      'US_46047',
      'US_46049',
      'US_46051',
      'US_46053',
      'US_46055',
      'US_46057',
      'US_46059',
      'US_46061',
      'US_46063',
      'US_46065',
      'US_46067',
      'US_46069',
      'US_46071',
      'US_46073',
      'US_46075',
      'US_46077',
      'US_46079',
      'US_46081',
      'US_46083',
      'US_46085',
      'US_46087',
      'US_46089',
      'US_46091',
      'US_46093',
      'US_46095',
      'US_46097',
      'US_46099',
      'US_46101',
      'US_46102',
      'US_46103',
      'US_46105',
      'US_46107',
      'US_46109',
      'US_46111',
      'US_46115',
      'US_46117',
      'US_46119',
      'US_46121',
      'US_46123',
      'US_46125',
      'US_46127',
      'US_46129',
      'US_46135',
      'US_46137',
    ],
    incompleteVaccinations: 156202,
    completeVaccinations: 586708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47: {
    label: 'Tennessee',
    iso3: null,
    population: '6,829,174',
    casesPastWeek: 4992,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_47001',
      'US_47003',
      'US_47005',
      'US_47007',
      'US_47009',
      'US_47011',
      'US_47013',
      'US_47015',
      'US_47017',
      'US_47019',
      'US_47021',
      'US_47023',
      'US_47025',
      'US_47027',
      'US_47029',
      'US_47031',
      'US_47033',
      'US_47035',
      'US_47037',
      'US_47039',
      'US_47041',
      'US_47043',
      'US_47045',
      'US_47047',
      'US_47049',
      'US_47051',
      'US_47053',
      'US_47055',
      'US_47057',
      'US_47059',
      'US_47061',
      'US_47063',
      'US_47065',
      'US_47067',
      'US_47069',
      'US_47071',
      'US_47073',
      'US_47075',
      'US_47077',
      'US_47079',
      'US_47081',
      'US_47083',
      'US_47085',
      'US_47087',
      'US_47089',
      'US_47091',
      'US_47093',
      'US_47095',
      'US_47097',
      'US_47099',
      'US_47101',
      'US_47103',
      'US_47105',
      'US_47107',
      'US_47109',
      'US_47111',
      'US_47113',
      'US_47115',
      'US_47117',
      'US_47119',
      'US_47121',
      'US_47123',
      'US_47125',
      'US_47127',
      'US_47129',
      'US_47131',
      'US_47133',
      'US_47135',
      'US_47137',
      'US_47139',
      'US_47141',
      'US_47143',
      'US_47145',
      'US_47147',
      'US_47149',
      'US_47151',
      'US_47153',
      'US_47155',
      'US_47157',
      'US_47159',
      'US_47161',
      'US_47163',
      'US_47165',
      'US_47167',
      'US_47169',
      'US_47171',
      'US_47173',
      'US_47175',
      'US_47177',
      'US_47179',
      'US_47181',
      'US_47183',
      'US_47185',
      'US_47187',
      'US_47189',
    ],
    incompleteVaccinations: 557413,
    completeVaccinations: 3843967,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48: {
    label: 'Texas',
    iso3: null,
    population: '28,995,881',
    casesPastWeek: 12490,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_48001',
      'US_48003',
      'US_48005',
      'US_48007',
      'US_48009',
      'US_48011',
      'US_48013',
      'US_48015',
      'US_48017',
      'US_48019',
      'US_48021',
      'US_48023',
      'US_48025',
      'US_48027',
      'US_48029',
      'US_48031',
      'US_48033',
      'US_48035',
      'US_48037',
      'US_48039',
      'US_48041',
      'US_48043',
      'US_48045',
      'US_48047',
      'US_48049',
      'US_48051',
      'US_48053',
      'US_48055',
      'US_48057',
      'US_48059',
      'US_48061',
      'US_48063',
      'US_48065',
      'US_48067',
      'US_48069',
      'US_48071',
      'US_48073',
      'US_48075',
      'US_48077',
      'US_48079',
      'US_48081',
      'US_48083',
      'US_48085',
      'US_48087',
      'US_48089',
      'US_48091',
      'US_48093',
      'US_48095',
      'US_48097',
      'US_48099',
      'US_48101',
      'US_48103',
      'US_48105',
      'US_48107',
      'US_48109',
      'US_48111',
      'US_48113',
      'US_48115',
      'US_48117',
      'US_48119',
      'US_48121',
      'US_48123',
      'US_48125',
      'US_48127',
      'US_48129',
      'US_48131',
      'US_48133',
      'US_48135',
      'US_48137',
      'US_48139',
      'US_48141',
      'US_48143',
      'US_48145',
      'US_48147',
      'US_48149',
      'US_48151',
      'US_48153',
      'US_48155',
      'US_48157',
      'US_48159',
      'US_48161',
      'US_48163',
      'US_48165',
      'US_48167',
      'US_48169',
      'US_48171',
      'US_48173',
      'US_48175',
      'US_48177',
      'US_48179',
      'US_48181',
      'US_48183',
      'US_48185',
      'US_48187',
      'US_48189',
      'US_48191',
      'US_48193',
      'US_48195',
      'US_48197',
      'US_48199',
      'US_48201',
      'US_48203',
      'US_48205',
      'US_48207',
      'US_48209',
      'US_48211',
      'US_48213',
      'US_48215',
      'US_48217',
      'US_48219',
      'US_48221',
      'US_48223',
      'US_48225',
      'US_48227',
      'US_48229',
      'US_48231',
      'US_48233',
      'US_48235',
      'US_48237',
      'US_48239',
      'US_48241',
      'US_48243',
      'US_48245',
      'US_48247',
      'US_48249',
      'US_48251',
      'US_48253',
      'US_48255',
      'US_48257',
      'US_48259',
      'US_48261',
      'US_48263',
      'US_48265',
      'US_48267',
      'US_48269',
      'US_48271',
      'US_48273',
      'US_48275',
      'US_48277',
      'US_48279',
      'US_48281',
      'US_48283',
      'US_48285',
      'US_48287',
      'US_48289',
      'US_48291',
      'US_48293',
      'US_48295',
      'US_48297',
      'US_48299',
      'US_48301',
      'US_48303',
      'US_48305',
      'US_48307',
      'US_48309',
      'US_48311',
      'US_48313',
      'US_48315',
      'US_48317',
      'US_48319',
      'US_48321',
      'US_48323',
      'US_48325',
      'US_48327',
      'US_48329',
      'US_48331',
      'US_48333',
      'US_48335',
      'US_48337',
      'US_48339',
      'US_48341',
      'US_48343',
      'US_48345',
      'US_48347',
      'US_48349',
      'US_48351',
      'US_48353',
      'US_48355',
      'US_48357',
      'US_48359',
      'US_48361',
      'US_48363',
      'US_48365',
      'US_48367',
      'US_48369',
      'US_48371',
      'US_48373',
      'US_48375',
      'US_48377',
      'US_48379',
      'US_48381',
      'US_48383',
      'US_48385',
      'US_48387',
      'US_48389',
      'US_48391',
      'US_48393',
      'US_48395',
      'US_48397',
      'US_48399',
      'US_48401',
      'US_48403',
      'US_48405',
      'US_48407',
      'US_48409',
      'US_48411',
      'US_48413',
      'US_48415',
      'US_48417',
      'US_48419',
      'US_48421',
      'US_48423',
      'US_48425',
      'US_48427',
      'US_48429',
      'US_48431',
      'US_48433',
      'US_48435',
      'US_48437',
      'US_48439',
      'US_48441',
      'US_48443',
      'US_48445',
      'US_48447',
      'US_48449',
      'US_48451',
      'US_48453',
      'US_48455',
      'US_48457',
      'US_48459',
      'US_48461',
      'US_48463',
      'US_48465',
      'US_48467',
      'US_48469',
      'US_48471',
      'US_48473',
      'US_48475',
      'US_48477',
      'US_48479',
      'US_48481',
      'US_48483',
      'US_48485',
      'US_48487',
      'US_48489',
      'US_48491',
      'US_48493',
      'US_48495',
      'US_48497',
      'US_48499',
      'US_48501',
      'US_48503',
      'US_48505',
      'US_48507',
    ],
    incompleteVaccinations: 3828238,
    completeVaccinations: 18349419,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_49: {
    label: 'Utah',
    iso3: null,
    population: '3,205,958',
    casesPastWeek: 1704,
    casesIncreasingPercentage: 3.7758830694275325,
    positiveCasePercentage: 16.1,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_Utah_Bear_River',
      'US_Utah_Central_Utah',
      'US_Utah_Southeast_Utah',
      'US_Utah_Southwest_Utah',
      'US_Utah_TriCounty',
      'US_Utah_Weber_Morgan',
      'US_49011',
      'US_49035',
      'US_49037',
      'US_49043',
      'US_49045',
      'US_49049',
      'US_49051',
    ],
    incompleteVaccinations: 274503,
    completeVaccinations: 2141235,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_50: {
    label: 'Vermont',
    iso3: null,
    population: '623,989',
    casesPastWeek: 1141,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_50001',
      'US_50003',
      'US_50005',
      'US_50007',
      'US_50009',
      'US_50011',
      'US_50013',
      'US_50015',
      'US_50017',
      'US_50019',
      'US_50021',
      'US_50023',
      'US_50025',
      'US_50027',
    ],
    incompleteVaccinations: 86562,
    completeVaccinations: 535103,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_51: {
    label: 'Virginia',
    iso3: null,
    population: '8,535,519',
    casesPastWeek: 4511,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_51001',
      'US_51003',
      'US_51005',
      'US_51007',
      'US_51009',
      'US_51011',
      'US_51013',
      'US_51015',
      'US_51017',
      'US_51019',
      'US_51021',
      'US_51023',
      'US_51025',
      'US_51027',
      'US_51029',
      'US_51031',
      'US_51033',
      'US_51035',
      'US_51036',
      'US_51037',
      'US_51041',
      'US_51043',
      'US_51045',
      'US_51047',
      'US_51049',
      'US_51051',
      'US_51053',
      'US_51057',
      'US_51059',
      'US_51061',
      'US_51063',
      'US_51065',
      'US_51067',
      'US_51069',
      'US_51071',
      'US_51073',
      'US_51075',
      'US_51077',
      'US_51079',
      'US_51081',
      'US_51083',
      'US_51085',
      'US_51087',
      'US_51089',
      'US_51091',
      'US_51093',
      'US_51095',
      'US_51097',
      'US_51099',
      'US_51101',
      'US_51103',
      'US_51105',
      'US_51107',
      'US_51109',
      'US_51111',
      'US_51113',
      'US_51115',
      'US_51117',
      'US_51119',
      'US_51121',
      'US_51125',
      'US_51127',
      'US_51131',
      'US_51133',
      'US_51135',
      'US_51137',
      'US_51139',
      'US_51141',
      'US_51143',
      'US_51145',
      'US_51147',
      'US_51149',
      'US_51153',
      'US_51155',
      'US_51157',
      'US_51159',
      'US_51161',
      'US_51163',
      'US_51165',
      'US_51167',
      'US_51169',
      'US_51171',
      'US_51173',
      'US_51175',
      'US_51177',
      'US_51179',
      'US_51181',
      'US_51183',
      'US_51185',
      'US_51187',
      'US_51191',
      'US_51193',
      'US_51195',
      'US_51197',
      'US_51199',
      'US_51510',
      'US_51520',
      'US_51530',
      'US_51540',
      'US_51550',
      'US_51570',
      'US_51580',
      'US_51590',
      'US_51595',
      'US_51600',
      'US_51610',
      'US_51620',
      'US_51630',
      'US_51640',
      'US_51650',
      'US_51660',
      'US_51670',
      'US_51678',
      'US_51680',
      'US_51683',
      'US_51685',
      'US_51690',
      'US_51700',
      'US_51710',
      'US_51720',
      'US_51730',
      'US_51735',
      'US_51740',
      'US_51750',
      'US_51760',
      'US_51770',
      'US_51775',
      'US_51790',
      'US_51800',
      'US_51810',
      'US_51820',
      'US_51830',
      'US_51840',
    ],
    incompleteVaccinations: 1231957,
    completeVaccinations: 6539579,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_53: {
    label: 'Washington',
    iso3: null,
    population: '7,614,893',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_53001',
      'US_53003',
      'US_53005',
      'US_53007',
      'US_53009',
      'US_53011',
      'US_53013',
      'US_53015',
      'US_53017',
      'US_53019',
      'US_53021',
      'US_53023',
      'US_53025',
      'US_53027',
      'US_53029',
      'US_53031',
      'US_53033',
      'US_53035',
      'US_53037',
      'US_53039',
      'US_53041',
      'US_53043',
      'US_53045',
      'US_53047',
      'US_53049',
      'US_53051',
      'US_53053',
      'US_53055',
      'US_53057',
      'US_53059',
      'US_53061',
      'US_53063',
      'US_53065',
      'US_53067',
      'US_53069',
      'US_53071',
      'US_53073',
      'US_53075',
      'US_53077',
    ],
    incompleteVaccinations: 700294,
    completeVaccinations: 5795079,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_54: {
    label: 'West Virginia',
    iso3: null,
    population: '1,792,147',
    casesPastWeek: 1706,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_54001',
      'US_54003',
      'US_54005',
      'US_54007',
      'US_54009',
      'US_54011',
      'US_54013',
      'US_54015',
      'US_54017',
      'US_54019',
      'US_54021',
      'US_54023',
      'US_54025',
      'US_54027',
      'US_54029',
      'US_54031',
      'US_54033',
      'US_54035',
      'US_54037',
      'US_54039',
      'US_54041',
      'US_54043',
      'US_54045',
      'US_54047',
      'US_54049',
      'US_54051',
      'US_54053',
      'US_54055',
      'US_54057',
      'US_54059',
      'US_54061',
      'US_54063',
      'US_54065',
      'US_54067',
      'US_54069',
      'US_54071',
      'US_54073',
      'US_54075',
      'US_54077',
      'US_54079',
      'US_54081',
      'US_54083',
      'US_54085',
      'US_54087',
      'US_54089',
      'US_54091',
      'US_54093',
      'US_54095',
      'US_54097',
      'US_54099',
      'US_54101',
      'US_54103',
      'US_54105',
      'US_54107',
      'US_54109',
    ],
    incompleteVaccinations: 139970,
    completeVaccinations: 1070043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_55: {
    label: 'Wisconsin',
    iso3: null,
    population: '5,822,434',
    casesPastWeek: 4178,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_55001',
      'US_55003',
      'US_55005',
      'US_55007',
      'US_55009',
      'US_55011',
      'US_55013',
      'US_55015',
      'US_55017',
      'US_55019',
      'US_55021',
      'US_55023',
      'US_55025',
      'US_55027',
      'US_55029',
      'US_55031',
      'US_55033',
      'US_55035',
      'US_55037',
      'US_55039',
      'US_55041',
      'US_55043',
      'US_55045',
      'US_55047',
      'US_55049',
      'US_55051',
      'US_55053',
      'US_55055',
      'US_55057',
      'US_55059',
      'US_55061',
      'US_55063',
      'US_55065',
      'US_55067',
      'US_55069',
      'US_55071',
      'US_55073',
      'US_55075',
      'US_55077',
      'US_55078',
      'US_55079',
      'US_55081',
      'US_55083',
      'US_55085',
      'US_55087',
      'US_55089',
      'US_55091',
      'US_55093',
      'US_55095',
      'US_55097',
      'US_55099',
      'US_55101',
      'US_55103',
      'US_55105',
      'US_55107',
      'US_55109',
      'US_55111',
      'US_55113',
      'US_55115',
      'US_55117',
      'US_55119',
      'US_55121',
      'US_55123',
      'US_55125',
      'US_55127',
      'US_55129',
      'US_55131',
      'US_55133',
      'US_55135',
      'US_55137',
      'US_55139',
      'US_55141',
    ],
    incompleteVaccinations: 404104,
    completeVaccinations: 3972425,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_56: {
    label: 'Wyoming',
    iso3: null,
    population: '578,759',
    casesPastWeek: 226,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_56001',
      'US_56003',
      'US_56005',
      'US_56007',
      'US_56009',
      'US_56011',
      'US_56013',
      'US_56015',
      'US_56017',
      'US_56019',
      'US_56021',
      'US_56023',
      'US_56025',
      'US_56027',
      'US_56029',
      'US_56031',
      'US_56033',
      'US_56035',
      'US_56037',
      'US_56039',
      'US_56041',
      'US_56043',
      'US_56045',
    ],
    incompleteVaccinations: 45745,
    completeVaccinations: 306951,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_60: {
    label: 'American Samoa',
    iso3: null,
    population: '55,641',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: 'US states',
    subdivisions: [],
    incompleteVaccinations: 3728,
    completeVaccinations: 42467,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_66: {
    label: 'Guam',
    iso3: null,
    population: '164,229',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: 'US states',
    subdivisions: [],
    incompleteVaccinations: 14688,
    completeVaccinations: 144432,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_69: {
    label: 'Northern Mariana Islands',
    iso3: null,
    population: '55,144',
    casesPastWeek: 35,
    casesIncreasingPercentage: 34.61538461538463,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: 'US states',
    subdivisions: [],
    incompleteVaccinations: 2758,
    completeVaccinations: 43953,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_72: {
    label: 'Puerto Rico',
    iso3: null,
    population: '3,193,694',
    casesPastWeek: 2349,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: 'US states',
    subdivisions: [
      'US_72001',
      'US_72003',
      'US_72005',
      'US_72007',
      'US_72009',
      'US_72011',
      'US_72013',
      'US_72015',
      'US_72017',
      'US_72019',
      'US_72021',
      'US_72023',
      'US_72025',
      'US_72027',
      'US_72029',
      'US_72031',
      'US_72033',
      'US_72035',
      'US_72037',
      'US_72039',
      'US_72041',
      'US_72043',
      'US_72045',
      'US_72047',
      'US_72049',
      'US_72051',
      'US_72053',
      'US_72054',
      'US_72055',
      'US_72057',
      'US_72059',
      'US_72061',
      'US_72063',
      'US_72065',
      'US_72067',
      'US_72069',
      'US_72071',
      'US_72073',
      'US_72075',
      'US_72077',
      'US_72079',
      'US_72081',
      'US_72083',
      'US_72085',
      'US_72087',
      'US_72089',
      'US_72091',
      'US_72093',
      'US_72095',
      'US_72097',
      'US_72099',
      'US_72101',
      'US_72103',
      'US_72105',
      'US_72107',
      'US_72109',
      'US_72111',
      'US_72113',
      'US_72115',
      'US_72117',
      'US_72119',
      'US_72121',
      'US_72123',
      'US_72125',
      'US_72127',
      'US_72129',
      'US_72131',
      'US_72133',
      'US_72135',
      'US_72137',
      'US_72139',
      'US_72141',
      'US_72143',
      'US_72145',
      'US_72147',
      'US_72149',
      'US_72151',
      'US_72153',
    ],
    incompleteVaccinations: 217400,
    completeVaccinations: 2685604,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_78: {
    label: 'Virgin Islands',
    iso3: null,
    population: '107,268',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: 'US states',
    subdivisions: [],
    incompleteVaccinations: 16098,
    completeVaccinations: 58951,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Afghanistan: {
    label: 'Afghanistan',
    iso3: 'AFG',
    population: '38,928,341',
    casesPastWeek: 111,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.14,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2108365,
    completeVaccinations: 11947403,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Albania: {
    label: 'Albania',
    iso3: 'ALB',
    population: '2,877,800',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 42.63,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 201864,
    completeVaccinations: 1143899,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Algeria: {
    label: 'Algeria',
    iso3: 'DZA',
    population: '43,851,043',
    casesPastWeek: 46,
    casesIncreasingPercentage: 170.58823529411765,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1176020,
    completeVaccinations: 6664111,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Andorra: {
    label: 'Andorra',
    iso3: 'AND',
    population: '77,265',
    casesPastWeek: 15,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 70.49,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 8685,
    completeVaccinations: 49218,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Angola: {
    label: 'Angola',
    iso3: 'AGO',
    population: '32,866,268',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2302467,
    completeVaccinations: 13047313,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Antigua_and_Barbuda: {
    label: 'Antigua and Barbuda',
    iso3: 'ATG',
    population: '97,928',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.5499999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 9644,
    completeVaccinations: 54646,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Argentina: {
    label: 'Argentina',
    iso3: 'ARG',
    population: '45,195,777',
    casesPastWeek: 832,
    casesIncreasingPercentage: 1.8359853121175052,
    positiveCasePercentage: 28.7,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 6221482,
    completeVaccinations: 35255065,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Armenia: {
    label: 'Armenia',
    iso3: 'ARM',
    population: '2,963,234',
    casesPastWeek: 489,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.3,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 169450,
    completeVaccinations: 960219,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Australia: {
    label: 'Australia',
    iso3: 'AUS',
    population: '25,459,700',
    casesPastWeek: 19173,
    casesIncreasingPercentage: 2.732679633499435,
    positiveCasePercentage: 41.68,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Australia_Australian_Capital_Territory',
      'Australia_New_South_Wales',
      'Australia_Northern_Territory',
      'Australia_Queensland',
      'Australia_South_Australia',
      'Australia_Tasmania',
      'Australia_Victoria',
      'Australia_Western_Australia',
    ],
    incompleteVaccinations: 3335505,
    completeVaccinations: 18901193,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Australia_Australian_Capital_Territory: {
    label: 'Australian Capital Territory',
    iso3: null,
    population: '428,100',
    casesPastWeek: 601,
    casesIncreasingPercentage: 22.903885480572605,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Australia_New_South_Wales: {
    label: 'New South Wales',
    iso3: null,
    population: '8,118,000',
    casesPastWeek: 7160,
    casesIncreasingPercentage: 9.513612725604158,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Australia_Northern_Territory: {
    label: 'Northern Territory',
    iso3: null,
    population: '245,600',
    casesPastWeek: 90,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Australia_Queensland: {
    label: 'Queensland',
    iso3: null,
    population: '5,115,500',
    casesPastWeek: 3603,
    casesIncreasingPercentage: 3.297018348623859,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Australia_South_Australia: {
    label: 'South Australia',
    iso3: null,
    population: '1,756,500',
    casesPastWeek: 1704,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Australia_Tasmania: {
    label: 'Tasmania',
    iso3: null,
    population: '535,500',
    casesPastWeek: 633,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Australia_Victoria: {
    label: 'Victoria',
    iso3: null,
    population: '6,629,900',
    casesPastWeek: 2998,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Australia_Western_Australia: {
    label: 'Western Australia',
    iso3: null,
    population: '2,630,600',
    casesPastWeek: 2384,
    casesIncreasingPercentage: 5.068312031732036,
    positiveCasePercentage: 41.68,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Austria: {
    label: 'Austria',
    iso3: 'AUT',
    population: '9,006,400',
    casesPastWeek: 36244,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.09,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1034800,
    completeVaccinations: 5863866,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Azerbaijan: {
    label: 'Azerbaijan',
    iso3: 'AZE',
    population: '10,139,175',
    casesPastWeek: 231,
    casesIncreasingPercentage: 42.59259259259258,
    positiveCasePercentage: 1.7000000000000002,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 813911,
    completeVaccinations: 4612160,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Bahamas: {
    label: 'Bahamas',
    iso3: 'BHS',
    population: '393,248',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 34.86,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 26122,
    completeVaccinations: 148025,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Bahrain: {
    label: 'Bahrain',
    iso3: 'BHR',
    population: '1,701,583',
    casesPastWeek: 2478,
    casesIncreasingPercentage: 3.942953020134232,
    positiveCasePercentage: 27.71,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 186176,
    completeVaccinations: 1054998,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Bangladesh: {
    label: 'Bangladesh',
    iso3: 'BGD',
    population: '164,689,383',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.61,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 22678556,
    completeVaccinations: 128511817,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Barbados: {
    label: 'Barbados',
    iso3: 'BRB',
    population: '287,371',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.12,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 24575,
    completeVaccinations: 139258,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Belarus: {
    label: 'Belarus',
    iso3: 'BLR',
    population: '9,449,321',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.04,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 979342,
    completeVaccinations: 5549602,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Belgium: {
    label: 'Belgium',
    iso3: 'BEL',
    population: '11,492,641',
    casesPastWeek: 10140,
    casesIncreasingPercentage: 8.079300788744415,
    positiveCasePercentage: 26.0,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Belgium_Antwerp',
      'Belgium_Brussels',
      'Belgium_East_Flanders',
      'Belgium_Flemish_Brabant',
      'Belgium_Hainaut',
      'Belgium_Liege',
      'Belgium_Limburg',
      'Belgium_Luxembourg',
      'Belgium_Namur',
      'Belgium_Walloon_Brabant',
      'Belgium_West_Flanders',
    ],
    incompleteVaccinations: 1389855,
    completeVaccinations: 7875844,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Antwerp: {
    label: 'Antwerp',
    iso3: null,
    population: '1,869,730',
    casesPastWeek: 1895,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Brussels: {
    label: 'Brussels',
    iso3: null,
    population: '1,218,255',
    casesPastWeek: 390,
    casesIncreasingPercentage: 8.033240997229907,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_East_Flanders: {
    label: 'East Flanders',
    iso3: null,
    population: '1,525,255',
    casesPastWeek: 2124,
    casesIncreasingPercentage: 15.121951219512187,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Flemish_Brabant: {
    label: 'Flemish Brabant',
    iso3: null,
    population: '1,155,843',
    casesPastWeek: 1097,
    casesIncreasingPercentage: 2.046511627906966,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Hainaut: {
    label: 'Hainaut',
    iso3: null,
    population: '1,346,840',
    casesPastWeek: 623,
    casesIncreasingPercentage: 20.270270270270263,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Liege: {
    label: 'Liege',
    iso3: null,
    population: '1,109,800',
    casesPastWeek: 524,
    casesIncreasingPercentage: 43.56164383561645,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Limburg: {
    label: 'Limburg',
    iso3: null,
    population: '877,370',
    casesPastWeek: 901,
    casesIncreasingPercentage: 26.013986013986013,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Luxembourg: {
    label: 'Luxembourg',
    iso3: null,
    population: '286,752',
    casesPastWeek: 133,
    casesIncreasingPercentage: 22.018348623853214,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Namur: {
    label: 'Namur',
    iso3: null,
    population: '495,832',
    casesPastWeek: 241,
    casesIncreasingPercentage: 28.877005347593588,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_Walloon_Brabant: {
    label: 'Walloon Brabant',
    iso3: null,
    population: '406,019',
    casesPastWeek: 166,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belgium_West_Flanders: {
    label: 'West Flanders',
    iso3: null,
    population: '1,200,945',
    casesPastWeek: 1945,
    casesIncreasingPercentage: 9.02466367713004,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Belize: {
    label: 'Belize',
    iso3: 'BLZ',
    population: '397,621',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.510000000000002,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 37793,
    completeVaccinations: 214159,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  Benin: {
    label: 'Benin',
    iso3: 'BEN',
    population: '12,123,198',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 554578,
    completeVaccinations: 3142612,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Bhutan: {
    label: 'Bhutan',
    iso3: 'BTN',
    population: '771,612',
    casesPastWeek: 7,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 104867,
    completeVaccinations: 594249,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Bolivia: {
    label: 'Bolivia',
    iso3: 'BOL',
    population: '11,673,029',
    casesPastWeek: 931,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.26,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1119402,
    completeVaccinations: 6343279,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Bosnia_and_Herzegovina: {
    label: 'Bosnia and Herzegovina',
    iso3: 'BIH',
    population: '3,280,815',
    casesPastWeek: 93,
    casesIncreasingPercentage: 52.45901639344262,
    positiveCasePercentage: 22.7,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 141509,
    completeVaccinations: 801885,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Botswana: {
    label: 'Botswana',
    iso3: 'BWA',
    population: '2,351,625',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.83,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 289751,
    completeVaccinations: 1641924,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Brazil: {
    label: 'Brazil',
    iso3: 'BRA',
    population: '212,559,409',
    casesPastWeek: 12589,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.481187773994442,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Brazil_Acre',
      'Brazil_Alagoas',
      'Brazil_Amapa',
      'Brazil_Amazonas',
      'Brazil_Bahia',
      'Brazil_Ceara',
      'Brazil_Distrito_Federal',
      'Brazil_Espirito_Santo',
      'Brazil_Goias',
      'Brazil_Maranhao',
      'Brazil_Mato_Grosso',
      'Brazil_Mato_Grosso_do_Sul',
      'Brazil_Minas_Gerais',
      'Brazil_Para',
      'Brazil_Paraiba',
      'Brazil_Parana',
      'Brazil_Pernambuco',
      'Brazil_Piaui',
      'Brazil_Rio_de_Janeiro',
      'Brazil_Rio_Grande_do_Norte',
      'Brazil_Rio_Grande_do_Sul',
      'Brazil_Rondonia',
      'Brazil_Roraima',
      'Brazil_Santa_Catarina',
      'Brazil_Sao_Paulo',
      'Brazil_Sergipe',
      'Brazil_Tocantins',
    ],
    incompleteVaccinations: 28409282,
    completeVaccinations: 160985930,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Brazil_Acre: {
    label: 'Acre',
    iso3: null,
    population: '881,935',
    casesPastWeek: 46,
    casesIncreasingPercentage: 21.052631578947366,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Alagoas: {
    label: 'Alagoas',
    iso3: null,
    population: '3,337,357',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Amapa: {
    label: 'Amapa',
    iso3: null,
    population: '845,731',
    casesPastWeek: 79,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Amazonas: {
    label: 'Amazonas',
    iso3: null,
    population: '4,144,597',
    casesPastWeek: 664,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Bahia: {
    label: 'Bahia',
    iso3: null,
    population: '14,873,064',
    casesPastWeek: 569,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Ceara: {
    label: 'Ceara',
    iso3: null,
    population: '9,132,078',
    casesPastWeek: 379,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Distrito_Federal: {
    label: 'Distrito Federal',
    iso3: null,
    population: '3,015,268',
    casesPastWeek: 423,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Espirito_Santo: {
    label: 'Espirito Santo',
    iso3: null,
    population: '4,018,650',
    casesPastWeek: 580,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Goias: {
    label: 'Goias',
    iso3: null,
    population: '7,018,354',
    casesPastWeek: 1829,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Maranhao: {
    label: 'Maranhao',
    iso3: null,
    population: '7,075,181',
    casesPastWeek: 56,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Mato_Grosso: {
    label: 'Mato Grosso',
    iso3: null,
    population: '3,484,466',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  Brazil_Mato_Grosso_do_Sul: {
    label: 'Mato Grosso do Sul',
    iso3: null,
    population: '2,778,986',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  Brazil_Minas_Gerais: {
    label: 'Minas Gerais',
    iso3: null,
    population: '21,168,791',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Brazil_Para: {
    label: 'Para',
    iso3: null,
    population: '8,602,865',
    casesPastWeek: 685,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Paraiba: {
    label: 'Paraiba',
    iso3: null,
    population: '4,018,127',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Parana: {
    label: 'Parana',
    iso3: null,
    population: '11,433,957',
    casesPastWeek: 634,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Pernambuco: {
    label: 'Pernambuco',
    iso3: null,
    population: '9,557,071',
    casesPastWeek: 1091,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Piaui: {
    label: 'Piaui',
    iso3: null,
    population: '3,273,227',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Brazil_Rio_de_Janeiro: {
    label: 'Rio de Janeiro',
    iso3: null,
    population: '17,264,943',
    casesPastWeek: 3001,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Rio_Grande_do_Norte: {
    label: 'Rio Grande do Norte',
    iso3: null,
    population: '3,506,853',
    casesPastWeek: 52,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Rio_Grande_do_Sul: {
    label: 'Rio Grande do Sul',
    iso3: null,
    population: '11,377,239',
    casesPastWeek: 1166,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Rondonia: {
    label: 'Rondonia',
    iso3: null,
    population: '1,777,225',
    casesPastWeek: 147,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Roraima: {
    label: 'Roraima',
    iso3: null,
    population: '605,761',
    casesPastWeek: 203,
    casesIncreasingPercentage: 50.370370370370374,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Santa_Catarina: {
    label: 'Santa Catarina',
    iso3: null,
    population: '7,164,788',
    casesPastWeek: 888,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Sao_Paulo: {
    label: 'Sao Paulo',
    iso3: null,
    population: '45,919,049',
    casesPastWeek: 5133,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Sergipe: {
    label: 'Sergipe',
    iso3: null,
    population: '2,298,696',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Brazil_Tocantins: {
    label: 'Tocantins',
    iso3: null,
    population: '1,572,866',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  Brunei: {
    label: 'Brunei',
    iso3: 'BRN',
    population: '437,483',
    casesPastWeek: 963,
    casesIncreasingPercentage: 36.40226628895184,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 67633,
    completeVaccinations: 383253,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Bulgaria: {
    label: 'Bulgaria',
    iso3: 'BGR',
    population: '6,948,445',
    casesPastWeek: 598,
    casesIncreasingPercentage: 48.38709677419355,
    positiveCasePercentage: 7.7700000000000005,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 315961,
    completeVaccinations: 1790443,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Burkina_Faso: {
    label: 'Burkina Faso',
    iso3: 'BFA',
    population: '20,903,278',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 679657,
    completeVaccinations: 3851390,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Burma: {
    label: 'Burma',
    iso3: 'MMR',
    population: '54,409,794',
    casesPastWeek: 24,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 0.16,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 5216597,
    completeVaccinations: 29560717,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Burundi: {
    label: 'Burundi',
    iso3: 'BDI',
    population: '11,890,781',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.36,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 4966,
    completeVaccinations: 28138,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Cabo_Verde: {
    label: 'Cabo Verde',
    iso3: 'CPV',
    population: '555,988',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.68,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 53510,
    completeVaccinations: 303224,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  Cambodia: {
    label: 'Cambodia',
    iso3: 'KHM',
    population: '16,718,971',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2290965,
    completeVaccinations: 12982133,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Cameroon: {
    label: 'Cameroon',
    iso3: 'CMR',
    population: '26,545,864',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 514798,
    completeVaccinations: 2917188,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  Canada: {
    label: 'Canada',
    iso3: 'CAN',
    population: '38,246,108',
    casesPastWeek: 9259,
    casesIncreasingPercentage: 5.780875128527363,
    positiveCasePercentage: 8.73,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Canada_Alberta',
      'Canada_British_Columbia',
      'Canada_Manitoba',
      'Canada_New_Brunswick',
      'Canada_Newfoundland_and_Labrador',
      'Canada_Northwest_Territories',
      'Canada_Nova_Scotia',
      'Canada_Ontario',
      'Canada_Prince_Edward_Island',
      'Canada_Quebec',
      'Canada_Saskatchewan',
      'Canada_Yukon',
      'Canada_Nunavut',
    ],
    incompleteVaccinations: 5214479,
    completeVaccinations: 29548715,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Canada_Alberta: {
    label: 'Alberta',
    iso3: null,
    population: '4,442,879',
    casesPastWeek: 322,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.274409700063817,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_Alberta_South',
      'Canada_Alberta_Calgary',
      'Canada_Alberta_Central',
      'Canada_Alberta_Edmonton',
      'Canada_Alberta_North',
    ],
    incompleteVaccinations: 174225,
    completeVaccinations: 3442303,
    unvaccinatedPrevalenceRatio: 1.1765401099087343,
    averageFullyVaccinatedMultiplier: 0.806334596344366,
    updatedAt: 'March 08, 2023',
  },
  Canada_Alberta_South: {
    label: 'South',
    iso3: null,
    population: '308,346',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.274409700063817,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 105348,
    completeVaccinations: 195347,
    unvaccinatedPrevalenceRatio: 1.1398522812128022,
    averageFullyVaccinatedMultiplier: 0.8063343690970427,
    updatedAt: 'March 06, 2023',
  },
  Canada_Alberta_Calgary: {
    label: 'Calgary',
    iso3: null,
    population: '1,726,962',
    casesPastWeek: 70,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.274409700063817,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 854439,
    completeVaccinations: 1421752,
    unvaccinatedPrevalenceRatio: 1.1376137205893304,
    averageFullyVaccinatedMultiplier: 0.8063347194166072,
    updatedAt: 'March 06, 2023',
  },
  Canada_Alberta_Central: {
    label: 'Central',
    iso3: null,
    population: '475,379',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.274409700063817,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 156674,
    completeVaccinations: 290841,
    unvaccinatedPrevalenceRatio: 1.134412592201941,
    averageFullyVaccinatedMultiplier: 0.8063340450624225,
    updatedAt: 'March 06, 2023',
  },
  Canada_Alberta_Edmonton: {
    label: 'Edmonton',
    iso3: null,
    population: '1,477,309',
    casesPastWeek: 91,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.274409700063817,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 677389,
    completeVaccinations: 1156372,
    unvaccinatedPrevalenceRatio: 1.1391150022487098,
    averageFullyVaccinatedMultiplier: 0.8063348126727385,
    updatedAt: 'March 06, 2023',
  },
  Canada_Alberta_North: {
    label: 'North',
    iso3: null,
    population: '454,883',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.274409700063817,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 168377,
    completeVaccinations: 322799,
    unvaccinatedPrevalenceRatio: 1.14583785322201,
    averageFullyVaccinatedMultiplier: 0.8063345921145977,
    updatedAt: 'March 06, 2023',
  },
  Canada_British_Columbia: {
    label: 'British Columbia',
    iso3: null,
    population: '5,214,805',
    casesPastWeek: 406,
    casesIncreasingPercentage: 13.725490196078427,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_British_Columbia_Interior',
      'Canada_British_Columbia_Fraser',
      'Canada_British_Columbia_Vancouver_Coastal',
      'Canada_British_Columbia_Island',
      'Canada_British_Columbia_Northern',
    ],
    incompleteVaccinations: 189136,
    completeVaccinations: 4422459,
    unvaccinatedPrevalenceRatio: 1.194792069766552,
    averageFullyVaccinatedMultiplier: 0.8077558209132069,
    updatedAt: 'March 02, 2023',
  },
  Canada_British_Columbia_Interior: {
    label: 'Interior',
    iso3: null,
    population: '829,666',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 788755,
    completeVaccinations: 671112,
    unvaccinatedPrevalenceRatio: 1.0969441311523696,
    averageFullyVaccinatedMultiplier: 0.8077548903908737,
    updatedAt: 'February 25, 2023',
  },
  Canada_British_Columbia_Fraser: {
    label: 'Fraser',
    iso3: null,
    population: '1,974,706',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1727818,
    completeVaccinations: 1629908,
    unvaccinatedPrevalenceRatio: 1.1029242359294242,
    averageFullyVaccinatedMultiplier: 0.8077554070536497,
    updatedAt: 'February 25, 2023',
  },
  Canada_British_Columbia_Vancouver_Coastal: {
    label: 'Vancouver Coastal',
    iso3: null,
    population: '1,240,517',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1477684,
    completeVaccinations: 1139213,
    unvaccinatedPrevalenceRatio: 1.091333213783785,
    averageFullyVaccinatedMultiplier: 0.8077558805947614,
    updatedAt: 'February 25, 2023',
  },
  Canada_British_Columbia_Island: {
    label: 'Island',
    iso3: null,
    population: '868,946',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1044432,
    completeVaccinations: 735241,
    unvaccinatedPrevalenceRatio: 1.0862745959750404,
    averageFullyVaccinatedMultiplier: 0.8077555522610953,
    updatedAt: 'February 25, 2023',
  },
  Canada_British_Columbia_Northern: {
    label: 'Northern',
    iso3: null,
    population: '300,970',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 203281,
    completeVaccinations: 208440,
    unvaccinatedPrevalenceRatio: 1.1078191847168035,
    averageFullyVaccinatedMultiplier: 0.8077576280944156,
    updatedAt: 'February 25, 2023',
  },
  Canada_Manitoba: {
    label: 'Manitoba',
    iso3: null,
    population: '1,383,765',
    casesPastWeek: 124,
    casesIncreasingPercentage: 31.914893617021267,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_Manitoba_Winnipeg',
      'Canada_Manitoba_Prairie_Mountain',
      'Canada_Manitoba_Interlake_Eastern',
      'Canada_Manitoba_Northern',
      'Canada_Manitoba_Southern',
    ],
    incompleteVaccinations: 47781,
    completeVaccinations: 1112617,
    unvaccinatedPrevalenceRatio: 1.185218399015348,
    averageFullyVaccinatedMultiplier: 0.8056420133792671,
    updatedAt: 'March 06, 2023',
  },
  Canada_Manitoba_Winnipeg: {
    label: 'Winnipeg',
    iso3: null,
    population: '785,200',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 507812,
    completeVaccinations: 671461,
    unvaccinatedPrevalenceRatio: 1.124435409513439,
    averageFullyVaccinatedMultiplier: 0.8056417275165646,
    updatedAt: 'February 25, 2023',
  },
  Canada_Manitoba_Prairie_Mountain: {
    label: 'Prairie Mountain',
    iso3: null,
    population: '172,526',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95305,
    completeVaccinations: 132414,
    unvaccinatedPrevalenceRatio: 1.127416663778635,
    averageFullyVaccinatedMultiplier: 0.8056398870210099,
    updatedAt: 'February 25, 2023',
  },
  Canada_Manitoba_Interlake_Eastern: {
    label: 'Interlake-Eastern',
    iso3: null,
    population: '136,029',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 76750,
    completeVaccinations: 103748,
    unvaccinatedPrevalenceRatio: 1.125762470842117,
    averageFullyVaccinatedMultiplier: 0.8056444461579982,
    updatedAt: 'February 25, 2023',
  },
  Canada_Manitoba_Northern: {
    label: 'Northern',
    iso3: null,
    population: '75,585',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 29186,
    completeVaccinations: 57394,
    unvaccinatedPrevalenceRatio: 1.1478952601922439,
    averageFullyVaccinatedMultiplier: 0.8056417047078092,
    updatedAt: 'February 25, 2023',
  },
  Canada_Manitoba_Southern: {
    label: 'Southern',
    iso3: null,
    population: '214,425',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 88053,
    completeVaccinations: 134673,
    unvaccinatedPrevalenceRatio: 1.1331657780129432,
    averageFullyVaccinatedMultiplier: 0.8056477541897782,
    updatedAt: 'February 25, 2023',
  },
  Canada_New_Brunswick: {
    label: 'New Brunswick',
    iso3: null,
    population: '789,225',
    casesPastWeek: 363,
    casesIncreasingPercentage: 3.7142857142857144,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_New_Brunswick_South_East__Moncton_',
      'Canada_New_Brunswick_South_Central__Saint_John_',
      'Canada_New_Brunswick_Central_West__Fredericton_',
      'Canada_New_Brunswick_North_West__Edmundston_',
      'Canada_New_Brunswick_North_Central__Campbellton_',
      'Canada_New_Brunswick_North_East__Bathurst_',
      'Canada_New_Brunswick_Central_East__Miramichi_',
    ],
    incompleteVaccinations: 39150,
    completeVaccinations: 666606,
    unvaccinatedPrevalenceRatio: 1.1955110678217455,
    averageFullyVaccinatedMultiplier: 0.8063803806146359,
    updatedAt: 'March 06, 2023',
  },
  Canada_New_Brunswick_South_East__Moncton_: {
    label: 'South East (Moncton)',
    iso3: null,
    population: '231,476',
    casesPastWeek: 75,
    casesIncreasingPercentage: 17.1875,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Canada_New_Brunswick_South_Central__Saint_John_: {
    label: 'South Central (Saint John)',
    iso3: null,
    population: '178,110',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Canada_New_Brunswick_Central_West__Fredericton_: {
    label: 'Central West (Fredericton)',
    iso3: null,
    population: '185,853',
    casesPastWeek: 101,
    casesIncreasingPercentage: 3.0612244897959107,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Canada_New_Brunswick_North_West__Edmundston_: {
    label: 'North West (Edmundston)',
    iso3: null,
    population: '47,909',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Canada_New_Brunswick_North_Central__Campbellton_: {
    label: 'North Central (Campbellton)',
    iso3: null,
    population: '24,900',
    casesPastWeek: 13,
    casesIncreasingPercentage: 18.181818181818187,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Canada_New_Brunswick_North_East__Bathurst_: {
    label: 'North East (Bathurst)',
    iso3: null,
    population: '76,696',
    casesPastWeek: 52,
    casesIncreasingPercentage: 23.809523809523814,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Canada_New_Brunswick_Central_East__Miramichi_: {
    label: 'Central East (Miramichi)',
    iso3: null,
    population: '44,281',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Canada_Newfoundland_and_Labrador: {
    label: 'Newfoundland and Labrador',
    iso3: null,
    population: '520,553',
    casesPastWeek: 174,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_Newfoundland_and_Labrador_Eastern',
      'Canada_Newfoundland_and_Labrador_Central',
      'Canada_Newfoundland_and_Labrador_Western',
      'Canada_Newfoundland_and_Labrador_Labrador_Grenfell',
    ],
    incompleteVaccinations: 21066,
    completeVaccinations: 484099,
    unvaccinatedPrevalenceRatio: 1.2202110128947115,
    averageFullyVaccinatedMultiplier: 0.8059405204307384,
    updatedAt: 'March 06, 2023',
  },
  Canada_Newfoundland_and_Labrador_Eastern: {
    label: 'Eastern',
    iso3: null,
    population: '318,149',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Newfoundland_and_Labrador_Central: {
    label: 'Central',
    iso3: null,
    population: '90,402',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Newfoundland_and_Labrador_Western: {
    label: 'Western',
    iso3: null,
    population: '75,930',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Newfoundland_and_Labrador_Labrador_Grenfell: {
    label: 'Labrador-Grenfell',
    iso3: null,
    population: '36,072',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Northwest_Territories: {
    label: 'Northwest Territories',
    iso3: null,
    population: '45,504',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: ['Canada_Northwest_Territories_NWT'],
    incompleteVaccinations: 1455,
    completeVaccinations: 33040,
    unvaccinatedPrevalenceRatio: 1.169888934594817,
    averageFullyVaccinatedMultiplier: 0.8,
    updatedAt: 'February 21, 2023',
  },
  Canada_Northwest_Territories_NWT: {
    label: 'NWT',
    iso3: null,
    population: '45,504',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Nova_Scotia: {
    label: 'Nova Scotia',
    iso3: null,
    population: '992,055',
    casesPastWeek: 301,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_Nova_Scotia_Western',
      'Canada_Nova_Scotia_Northern',
      'Canada_Nova_Scotia_Eastern',
      'Canada_Nova_Scotia_Central',
    ],
    incompleteVaccinations: 37200,
    completeVaccinations: 868291,
    unvaccinatedPrevalenceRatio: 1.2052827822993626,
    averageFullyVaccinatedMultiplier: 0.8054039486761927,
    updatedAt: 'March 06, 2023',
  },
  Canada_Nova_Scotia_Western: {
    label: 'Western',
    iso3: null,
    population: '200,719',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Nova_Scotia_Northern: {
    label: 'Northern',
    iso3: null,
    population: '150,132',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Nova_Scotia_Eastern: {
    label: 'Eastern',
    iso3: null,
    population: '159,934',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Nova_Scotia_Central: {
    label: 'Central',
    iso3: null,
    population: '481,270',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Ontario: {
    label: 'Ontario',
    iso3: null,
    population: '14,826,276',
    casesPastWeek: 4020,
    casesIncreasingPercentage: 3.1298101590559346,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_Ontario_Algoma',
      'Canada_Ontario_Brant',
      'Canada_Ontario_Durham',
      'Canada_Ontario_Grey_Bruce',
      'Canada_Ontario_Haldimand_Norfolk',
      'Canada_Ontario_Haliburton__Kawartha__Pine_Ridge',
      'Canada_Ontario_Halton',
      'Canada_Ontario_Hamilton',
      'Canada_Ontario_Hastings_and_Prince_Edward',
      'Canada_Ontario_Huron_Perth',
      'Canada_Ontario_Chatham_Kent',
      'Canada_Ontario_Kingston__Frontenac_and_Lennox_and_Addington',
      'Canada_Ontario_Lambton',
      'Canada_Ontario_Leeds__Grenville_and_Lanark',
      'Canada_Ontario_Middlesex_London',
      'Canada_Ontario_Niagara',
      'Canada_Ontario_North_Bay_Parry_Sound',
      'Canada_Ontario_Northwestern',
      'Canada_Ontario_Ottawa',
      'Canada_Ontario_Peel',
      'Canada_Ontario_Peterborough',
      'Canada_Ontario_Porcupine',
      'Canada_Ontario_Renfrew',
      'Canada_Ontario_Eastern',
      'Canada_Ontario_Simcoe_Muskoka',
      'Canada_Ontario_Sudbury',
      'Canada_Ontario_Thunder_Bay',
      'Canada_Ontario_Timiskaming',
      'Canada_Ontario_Waterloo',
      'Canada_Ontario_Wellington_Dufferin_Guelph',
      'Canada_Ontario_Windsor_Essex',
      'Canada_Ontario_York',
      'Canada_Ontario_Southwestern',
      'Canada_Ontario_Toronto',
    ],
    incompleteVaccinations: 464122,
    completeVaccinations: 12229361,
    unvaccinatedPrevalenceRatio: 1.1894865975853053,
    averageFullyVaccinatedMultiplier: 0.8068711030772581,
    updatedAt: 'March 07, 2023',
  },
  Canada_Ontario_Algoma: {
    label: 'Algoma',
    iso3: null,
    population: '117,178',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 67112,
    completeVaccinations: 95115,
    unvaccinatedPrevalenceRatio: 1.1276884149647568,
    averageFullyVaccinatedMultiplier: 0.8068758870840561,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Brant: {
    label: 'Brant',
    iso3: null,
    population: '156,506',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 76622,
    completeVaccinations: 121315,
    unvaccinatedPrevalenceRatio: 1.1342574552456048,
    averageFullyVaccinatedMultiplier: 0.8068746651279727,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Durham: {
    label: 'Durham',
    iso3: null,
    population: '727,328',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 380006,
    completeVaccinations: 595408,
    unvaccinatedPrevalenceRatio: 1.133644730556947,
    averageFullyVaccinatedMultiplier: 0.8068702469567087,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Grey_Bruce: {
    label: 'Grey Bruce',
    iso3: null,
    population: '178,618',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 94897,
    completeVaccinations: 131370,
    unvaccinatedPrevalenceRatio: 1.126289224275247,
    averageFullyVaccinatedMultiplier: 0.8068737154601507,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Haldimand_Norfolk: {
    label: 'Haldimand-Norfolk',
    iso3: null,
    population: '122,098',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 58089,
    completeVaccinations: 89458,
    unvaccinatedPrevalenceRatio: 1.1326245490135871,
    averageFullyVaccinatedMultiplier: 0.8068702631402446,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Haliburton__Kawartha__Pine_Ridge: {
    label: 'Haliburton, Kawartha, Pine Ridge',
    iso3: null,
    population: '193,129',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 109098,
    completeVaccinations: 152706,
    unvaccinatedPrevalenceRatio: 1.1269499638417302,
    averageFullyVaccinatedMultiplier: 0.8068707188977512,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Halton: {
    label: 'Halton',
    iso3: null,
    population: '619,069',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 355342,
    completeVaccinations: 521127,
    unvaccinatedPrevalenceRatio: 1.1297265874734477,
    averageFullyVaccinatedMultiplier: 0.8068704941405838,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Hamilton: {
    label: 'Hamilton',
    iso3: null,
    population: '587,192',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 307621,
    completeVaccinations: 470041,
    unvaccinatedPrevalenceRatio: 1.1321591418638373,
    averageFullyVaccinatedMultiplier: 0.8068721664705845,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Hastings_and_Prince_Edward: {
    label: 'Hastings and Prince Edward',
    iso3: null,
    population: '173,947',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 97303,
    completeVaccinations: 134618,
    unvaccinatedPrevalenceRatio: 1.126251074430734,
    averageFullyVaccinatedMultiplier: 0.8068757521282444,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Huron_Perth: {
    label: 'Huron Perth',
    iso3: null,
    population: '147,974',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 82032,
    completeVaccinations: 116093,
    unvaccinatedPrevalenceRatio: 1.1276066566498202,
    averageFullyVaccinatedMultiplier: 0.8068703539403754,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Chatham_Kent: {
    label: 'Chatham-Kent',
    iso3: null,
    population: '107,923',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 54702,
    completeVaccinations: 82343,
    unvaccinatedPrevalenceRatio: 1.1312756929883938,
    averageFullyVaccinatedMultiplier: 0.8068688291658065,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Kingston__Frontenac_and_Lennox_and_Addington: {
    label: 'Kingston, Frontenac and Lennox and Addington',
    iso3: null,
    population: '209,734',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 136699,
    completeVaccinations: 179956,
    unvaccinatedPrevalenceRatio: 1.1232844154508144,
    averageFullyVaccinatedMultiplier: 0.8068750138922848,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Lambton: {
    label: 'Lambton',
    iso3: null,
    population: '132,611',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 68542,
    completeVaccinations: 100928,
    unvaccinatedPrevalenceRatio: 1.1299657283068183,
    averageFullyVaccinatedMultiplier: 0.8068722257450857,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Leeds__Grenville_and_Lanark: {
    label: 'Leeds, Grenville and Lanark',
    iso3: null,
    population: '181,549',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 115076,
    completeVaccinations: 156597,
    unvaccinatedPrevalenceRatio: 1.1252707835429878,
    averageFullyVaccinatedMultiplier: 0.8068673090799952,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Middlesex_London: {
    label: 'Middlesex-London',
    iso3: null,
    population: '515,114',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 285317,
    completeVaccinations: 432698,
    unvaccinatedPrevalenceRatio: 1.1317142906679654,
    averageFullyVaccinatedMultiplier: 0.8068722295920018,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Niagara: {
    label: 'Niagara',
    iso3: null,
    population: '484,840',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 260825,
    completeVaccinations: 389305,
    unvaccinatedPrevalenceRatio: 1.130770996827517,
    averageFullyVaccinatedMultiplier: 0.8068712192240017,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_North_Bay_Parry_Sound: {
    label: 'North Bay Parry Sound',
    iso3: null,
    population: '130,788',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 71818,
    completeVaccinations: 102091,
    unvaccinatedPrevalenceRatio: 1.1278730414029263,
    averageFullyVaccinatedMultiplier: 0.8068683821296686,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Northwestern: {
    label: 'Northwestern',
    iso3: null,
    population: '81,611',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 47374,
    completeVaccinations: 69554,
    unvaccinatedPrevalenceRatio: 1.1297827935379146,
    averageFullyVaccinatedMultiplier: 0.8068838600224286,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Ottawa: {
    label: 'Ottawa',
    iso3: null,
    population: '1,054,800',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 656084,
    completeVaccinations: 897360,
    unvaccinatedPrevalenceRatio: 1.1255715008209324,
    averageFullyVaccinatedMultiplier: 0.8068712668271374,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Peel: {
    label: 'Peel',
    iso3: null,
    population: '1,571,328',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 738525,
    completeVaccinations: 1302897,
    unvaccinatedPrevalenceRatio: 1.1405898440882893,
    averageFullyVaccinatedMultiplier: 0.8068711494461956,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Peterborough: {
    label: 'Peterborough',
    iso3: null,
    population: '147,749',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 86138,
    completeVaccinations: 119415,
    unvaccinatedPrevalenceRatio: 1.1263733553983484,
    averageFullyVaccinatedMultiplier: 0.8068751831846921,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Porcupine: {
    label: 'Porcupine',
    iso3: null,
    population: '84,201',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 42088,
    completeVaccinations: 66853,
    unvaccinatedPrevalenceRatio: 1.134447568468187,
    averageFullyVaccinatedMultiplier: 0.8068747849759921,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Renfrew: {
    label: 'Renfrew',
    iso3: null,
    population: '109,323',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 57647,
    completeVaccinations: 82259,
    unvaccinatedPrevalenceRatio: 1.1280922431865827,
    averageFullyVaccinatedMultiplier: 0.80687827471766,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Eastern: {
    label: 'Eastern',
    iso3: null,
    population: '217,983',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 117657,
    completeVaccinations: 172495,
    unvaccinatedPrevalenceRatio: 1.1297082207461513,
    averageFullyVaccinatedMultiplier: 0.8068697643409954,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Simcoe_Muskoka: {
    label: 'Simcoe Muskoka',
    iso3: null,
    population: '616,474',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 318607,
    completeVaccinations: 481146,
    unvaccinatedPrevalenceRatio: 1.1314644256751978,
    averageFullyVaccinatedMultiplier: 0.806871510934311,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Sudbury: {
    label: 'Sudbury',
    iso3: null,
    population: '205,481',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 111569,
    completeVaccinations: 166356,
    unvaccinatedPrevalenceRatio: 1.1307094879107555,
    averageFullyVaccinatedMultiplier: 0.8068720094255692,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Thunder_Bay: {
    label: 'Thunder Bay',
    iso3: null,
    population: '156,022',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 88350,
    completeVaccinations: 128093,
    unvaccinatedPrevalenceRatio: 1.12904201768342,
    averageFullyVaccinatedMultiplier: 0.8068746926061533,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Timiskaming: {
    label: 'Timiskaming',
    iso3: null,
    population: '34,180',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17797,
    completeVaccinations: 26074,
    unvaccinatedPrevalenceRatio: 1.1296768379039526,
    averageFullyVaccinatedMultiplier: 0.806857405844903,
    updatedAt: 'February 24, 2023',
  },
  Canada_Ontario_Waterloo: {
    label: 'Waterloo',
    iso3: null,
    population: '611,493',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 329021,
    completeVaccinations: 500881,
    unvaccinatedPrevalenceRatio: 1.1319412903199408,
    averageFullyVaccinatedMultiplier: 0.8068702945410188,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Wellington_Dufferin_Guelph: {
    label: 'Wellington-Dufferin-Guelph',
    iso3: null,
    population: '316,676',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 173351,
    completeVaccinations: 255191,
    unvaccinatedPrevalenceRatio: 1.1299514577186447,
    averageFullyVaccinatedMultiplier: 0.8068701482419051,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Windsor_Essex: {
    label: 'Windsor-Essex',
    iso3: null,
    population: '426,246',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 215676,
    completeVaccinations: 347425,
    unvaccinatedPrevalenceRatio: 1.135277407596325,
    averageFullyVaccinatedMultiplier: 0.8068705476002015,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_York: {
    label: 'York',
    iso3: null,
    population: '1,209,914',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 673674,
    completeVaccinations: 1000873,
    unvaccinatedPrevalenceRatio: 1.1304966281833777,
    averageFullyVaccinatedMultiplier: 0.806870601964485,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Southwestern: {
    label: 'Southwestern',
    iso3: null,
    population: '222,904',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 108270,
    completeVaccinations: 167895,
    unvaccinatedPrevalenceRatio: 1.133031098711742,
    averageFullyVaccinatedMultiplier: 0.8068733434587093,
    updatedAt: 'February 25, 2023',
  },
  Canada_Ontario_Toronto: {
    label: 'Toronto',
    iso3: null,
    population: '2,974,293',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1726206,
    completeVaccinations: 2514809,
    unvaccinatedPrevalenceRatio: 1.1293312736846506,
    averageFullyVaccinatedMultiplier: 0.8068712176550983,
    updatedAt: 'February 25, 2023',
  },
  Canada_Prince_Edward_Island: {
    label: 'Prince Edward Island',
    iso3: null,
    population: '164,318',
    casesPastWeek: 67,
    casesIncreasingPercentage: 39.58333333333333,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: ['Canada_Prince_Edward_Island_PEI'],
    incompleteVaccinations: 6799,
    completeVaccinations: 146590,
    unvaccinatedPrevalenceRatio: 1.210392174194879,
    averageFullyVaccinatedMultiplier: 0.8051572412852173,
    updatedAt: 'March 07, 2023',
  },
  Canada_Prince_Edward_Island_PEI: {
    label: 'PEI',
    iso3: null,
    population: '164,318',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 25, 2023',
  },
  Canada_Quebec: {
    label: 'Quebec',
    iso3: null,
    population: '8,604,495',
    casesPastWeek: 3086,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_Quebec_Bas_Saint_Laurent',
      'Canada_Quebec_Saguenay_Lac_Saint_Jean',
      'Canada_Quebec_Capitale_Nationale',
      'Canada_Quebec_Mauricie_et_Centre_du_Qu_bec',
      'Canada_Quebec_Estrie',
      'Canada_Quebec_Montr_al',
      'Canada_Quebec_Outaouais',
      'Canada_Quebec_Abitibi_T_miscamingue',
      'Canada_Quebec_C_te_Nord',
      'Canada_Quebec_Nord_du_Qu_bec',
      'Canada_Quebec_Gasp_sie__les_de_la_Madeleine',
      'Canada_Quebec_Chaudi_re_Appalaches',
      'Canada_Quebec_Laval',
      'Canada_Quebec_Lanaudi_re',
      'Canada_Quebec_Laurentides',
      'Canada_Quebec_Mont_r_gie',
      'Canada_Quebec_Nunavik',
      'Canada_Quebec_Terres_Cries_de_la_Baie_James',
    ],
    incompleteVaccinations: 219806,
    completeVaccinations: 7160015,
    unvaccinatedPrevalenceRatio: 1.1913202278109642,
    averageFullyVaccinatedMultiplier: 0.807005990909237,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Bas_Saint_Laurent: {
    label: 'Bas-Saint-Laurent',
    iso3: null,
    population: '199,097',
    casesPastWeek: 106,
    casesIncreasingPercentage: 11.578947368421044,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 238649,
    completeVaccinations: 170900,
    unvaccinatedPrevalenceRatio: 1.087588895439312,
    averageFullyVaccinatedMultiplier: 0.8070040959625512,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Saguenay_Lac_Saint_Jean: {
    label: 'Saguenay-Lac-Saint-Jean',
    iso3: null,
    population: '279,949',
    casesPastWeek: 90,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 336527,
    completeVaccinations: 241978,
    unvaccinatedPrevalenceRatio: 1.0878141424018202,
    averageFullyVaccinatedMultiplier: 0.8070072485928473,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Capitale_Nationale: {
    label: 'Capitale-Nationale',
    iso3: null,
    population: '761,029',
    casesPastWeek: 346,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 849861,
    completeVaccinations: 659045,
    unvaccinatedPrevalenceRatio: 1.0920537824759682,
    averageFullyVaccinatedMultiplier: 0.8070055914239543,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Mauricie_et_Centre_du_Qu_bec: {
    label: 'Mauricie et Centre-du-Québec',
    iso3: null,
    population: '532,860',
    casesPastWeek: 261,
    casesIncreasingPercentage: 3.1620553359683834,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 559221,
    completeVaccinations: 437504,
    unvaccinatedPrevalenceRatio: 1.0925528066733166,
    averageFullyVaccinatedMultiplier: 0.8070074787887653,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Estrie: {
    label: 'Estrie',
    iso3: null,
    population: '506,560',
    casesPastWeek: 123,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 543659,
    completeVaccinations: 423086,
    unvaccinatedPrevalenceRatio: 1.0922536866224075,
    averageFullyVaccinatedMultiplier: 0.807006140595529,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Montr_al: {
    label: 'Montréal',
    iso3: null,
    population: '2,025,928',
    casesPastWeek: 502,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1846559,
    completeVaccinations: 1657476,
    unvaccinatedPrevalenceRatio: 1.1004609704561843,
    averageFullyVaccinatedMultiplier: 0.8070059536307012,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Outaouais: {
    label: 'Outaouais',
    iso3: null,
    population: '404,265',
    casesPastWeek: 74,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 391620,
    completeVaccinations: 334303,
    unvaccinatedPrevalenceRatio: 1.097548548239805,
    averageFullyVaccinatedMultiplier: 0.8070044241302052,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Abitibi_T_miscamingue: {
    label: 'Abitibi-Témiscamingue',
    iso3: null,
    population: '148,242',
    casesPastWeek: 33,
    casesIncreasingPercentage: 3.125,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 156222,
    completeVaccinations: 122451,
    unvaccinatedPrevalenceRatio: 1.0926596115918616,
    averageFullyVaccinatedMultiplier: 0.8070085176927914,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_C_te_Nord: {
    label: 'Côte-Nord',
    iso3: null,
    population: '90,543',
    casesPastWeek: 75,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95755,
    completeVaccinations: 74973,
    unvaccinatedPrevalenceRatio: 1.0925962664550521,
    averageFullyVaccinatedMultiplier: 0.8070105237885639,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Nord_du_Qu_bec: {
    label: 'Nord-du-Québec',
    iso3: null,
    population: '13,418',
    casesPastWeek: 7,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12655,
    completeVaccinations: 10825,
    unvaccinatedPrevalenceRatio: 1.097657893506615,
    averageFullyVaccinatedMultiplier: 0.8070207852193996,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Gasp_sie__les_de_la_Madeleine: {
    label: 'Gaspésie-Îles-de-la-Madeleine',
    iso3: null,
    population: '92,072',
    casesPastWeek: 54,
    casesIncreasingPercentage: 25.581395348837212,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 119705,
    completeVaccinations: 82577,
    unvaccinatedPrevalenceRatio: 1.0855241621723148,
    averageFullyVaccinatedMultiplier: 0.8070043716773436,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Chaudi_re_Appalaches: {
    label: 'Chaudière-Appalaches',
    iso3: null,
    population: '438,106',
    casesPastWeek: 221,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 432641,
    completeVaccinations: 361926,
    unvaccinatedPrevalenceRatio: 1.0963824499770256,
    averageFullyVaccinatedMultiplier: 0.8070047468266994,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Laval: {
    label: 'Laval',
    iso3: null,
    population: '443,192',
    casesPastWeek: 188,
    casesIncreasingPercentage: 10.588235294117654,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 401732,
    completeVaccinations: 363218,
    unvaccinatedPrevalenceRatio: 1.1008834987644833,
    averageFullyVaccinatedMultiplier: 0.8070057100694349,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Lanaudi_re: {
    label: 'Lanaudière',
    iso3: null,
    population: '535,230',
    casesPastWeek: 192,
    casesIncreasingPercentage: 23.870967741935488,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 560120,
    completeVaccinations: 447343,
    unvaccinatedPrevalenceRatio: 1.0937274732719013,
    averageFullyVaccinatedMultiplier: 0.8070049156910917,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Laurentides: {
    label: 'Laurentides',
    iso3: null,
    population: '647,179',
    casesPastWeek: 198,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 671554,
    completeVaccinations: 543204,
    unvaccinatedPrevalenceRatio: 1.094453484118704,
    averageFullyVaccinatedMultiplier: 0.8070043666836032,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Mont_r_gie: {
    label: 'Montérégie',
    iso3: null,
    population: '1,453,570',
    casesPastWeek: 596,
    casesIncreasingPercentage: 11.819887429643527,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1593932,
    completeVaccinations: 1253240,
    unvaccinatedPrevalenceRatio: 1.0928367668417966,
    averageFullyVaccinatedMultiplier: 0.8070058408604897,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Nunavik: {
    label: 'Nunavik',
    iso3: null,
    population: '14,576',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6061,
    completeVaccinations: 8361,
    unvaccinatedPrevalenceRatio: 1.124431073054077,
    averageFullyVaccinatedMultiplier: 0.8070804927640235,
    updatedAt: 'March 08, 2023',
  },
  Canada_Quebec_Terres_Cries_de_la_Baie_James: {
    label: 'Terres-Cries-de-la-Baie-James',
    iso3: null,
    population: '18,679',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16364,
    completeVaccinations: 15655,
    unvaccinatedPrevalenceRatio: 1.1042176776907957,
    averageFullyVaccinatedMultiplier: 0.8069626317470456,
    updatedAt: 'March 08, 2023',
  },
  Canada_Saskatchewan: {
    label: 'Saskatchewan',
    iso3: null,
    population: '1,179,844',
    casesPastWeek: 396,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: [
      'Canada_Saskatchewan_Far_North_West',
      'Canada_Saskatchewan_Far_North_Central',
      'Canada_Saskatchewan_Far_North_East',
      'Canada_Saskatchewan_North_West',
      'Canada_Saskatchewan_North_Central',
      'Canada_Saskatchewan_North_East',
      'Canada_Saskatchewan_Central_West',
      'Canada_Saskatchewan_Central_East',
      'Canada_Saskatchewan_Saskatoon',
      'Canada_Saskatchewan_Regina',
      'Canada_Saskatchewan_South_West',
      'Canada_Saskatchewan_South_Central',
      'Canada_Saskatchewan_South_East',
    ],
    incompleteVaccinations: 54958,
    completeVaccinations: 920999,
    unvaccinatedPrevalenceRatio: 1.1765473144249812,
    averageFullyVaccinatedMultiplier: 0.8077717782538308,
    updatedAt: 'March 06, 2023',
  },
  Canada_Saskatchewan_Far_North_West: {
    label: 'Far North West',
    iso3: null,
    population: '29,813',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_Far_North_Central: {
    label: 'Far North Central',
    iso3: null,
    population: '2,649',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_Far_North_East: {
    label: 'Far North East',
    iso3: null,
    population: '24,249',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_North_West: {
    label: 'North West',
    iso3: null,
    population: '82,386',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_North_Central: {
    label: 'North Central',
    iso3: null,
    population: '88,991',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_North_East: {
    label: 'North East',
    iso3: null,
    population: '41,560',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_Central_West: {
    label: 'Central West',
    iso3: null,
    population: '36,962',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_Central_East: {
    label: 'Central East',
    iso3: null,
    population: '98,368',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_Saskatoon: {
    label: 'Saskatoon',
    iso3: null,
    population: '338,106',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_Regina: {
    label: 'Regina',
    iso3: null,
    population: '273,351',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_South_West: {
    label: 'South West',
    iso3: null,
    population: '38,670',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_South_Central: {
    label: 'South Central',
    iso3: null,
    population: '60,459',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Saskatchewan_South_East: {
    label: 'South East',
    iso3: null,
    population: '89,294',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Yukon: {
    label: 'Yukon',
    iso3: null,
    population: '42,986',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: ['Canada_Yukon_Yukon'],
    incompleteVaccinations: 1341,
    completeVaccinations: 35969,
    unvaccinatedPrevalenceRatio: 1.20092752975359,
    averageFullyVaccinatedMultiplier: 0.8000500430926631,
    updatedAt: 'February 21, 2023',
  },
  Canada_Yukon_Yukon: {
    label: 'Yukon',
    iso3: null,
    population: '42,986',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Canada_Nunavut: {
    label: 'Nunavut',
    iso3: null,
    population: '39,403',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Canada states',
    subdivisions: ['Canada_Nunavut_Nunavut'],
    incompleteVaccinations: 2725,
    completeVaccinations: 31694,
    unvaccinatedPrevalenceRatio: 1.1917190902492136,
    averageFullyVaccinatedMultiplier: 0.7999936896573484,
    updatedAt: 'February 21, 2023',
  },
  Canada_Nunavut_Nunavut: {
    label: 'Nunavut',
    iso3: null,
    population: '39,403',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Central_African_Republic: {
    label: 'Central African Republic',
    iso3: 'CAF',
    population: '4,829,764',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 343813,
    completeVaccinations: 1948275,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Chad: {
    label: 'Chad',
    iso3: 'TCD',
    population: '16,425,859',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.44,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 936088,
    completeVaccinations: 5304500,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Chile: {
    label: 'Chile',
    iso3: 'CHL',
    population: '19,116,209',
    casesPastWeek: 19241,
    casesIncreasingPercentage: 27.2300469483568,
    positiveCasePercentage: 15.02,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Chile_Antofagasta',
      'Chile_Araucania',
      'Chile_Arica_y_Parinacota',
      'Chile_Atacama',
      'Chile_Aysen',
      'Chile_Biobio',
      'Chile_Coquimbo',
      'Chile_Los_Lagos',
      'Chile_Los_Rios',
      'Chile_Magallanes',
      'Chile_Maule',
      'Chile_Metropolitana',
      'Chile_Nuble',
      'Chile_OHiggins',
      'Chile_Tarapaca',
      'Chile_Valparaiso',
    ],
    incompleteVaccinations: 2713278,
    completeVaccinations: 15375239,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Antofagasta: {
    label: 'Antofagasta',
    iso3: null,
    population: '607,534',
    casesPastWeek: 595,
    casesIncreasingPercentage: 40.330188679245296,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Araucania: {
    label: 'Araucania',
    iso3: null,
    population: '957,224',
    casesPastWeek: 794,
    casesIncreasingPercentage: 24.64678178963893,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Arica_y_Parinacota: {
    label: 'Arica y Parinacota',
    iso3: null,
    population: '226,068',
    casesPastWeek: 71,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Atacama: {
    label: 'Atacama',
    iso3: null,
    population: '288,944',
    casesPastWeek: 409,
    casesIncreasingPercentage: 49.81684981684982,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Aysen: {
    label: 'Aysen',
    iso3: null,
    population: '103,158',
    casesPastWeek: 160,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Biobio: {
    label: 'Biobio',
    iso3: null,
    population: '1,556,805',
    casesPastWeek: 1749,
    casesIncreasingPercentage: 13.130659767141006,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Coquimbo: {
    label: 'Coquimbo',
    iso3: null,
    population: '757,586',
    casesPastWeek: 604,
    casesIncreasingPercentage: 12.686567164179108,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Los_Lagos: {
    label: 'Los Lagos',
    iso3: null,
    population: '828,708',
    casesPastWeek: 893,
    casesIncreasingPercentage: 42.88000000000001,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Los_Rios: {
    label: 'Los Rios',
    iso3: null,
    population: '384,837',
    casesPastWeek: 672,
    casesIncreasingPercentage: 30.739299610894943,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Magallanes: {
    label: 'Magallanes',
    iso3: null,
    population: '166,533',
    casesPastWeek: 152,
    casesIncreasingPercentage: 5.555555555555558,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Maule: {
    label: 'Maule',
    iso3: null,
    population: '1,044,950',
    casesPastWeek: 1069,
    casesIncreasingPercentage: 27.565632458233893,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Metropolitana: {
    label: 'Metropolitana',
    iso3: null,
    population: '7,112,808',
    casesPastWeek: 8126,
    casesIncreasingPercentage: 29.29196499602227,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Nuble: {
    label: 'Nuble',
    iso3: null,
    population: '480,609',
    casesPastWeek: 660,
    casesIncreasingPercentage: 37.214137214137224,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_OHiggins: {
    label: 'OHiggins',
    iso3: null,
    population: '914,555',
    casesPastWeek: 1010,
    casesIncreasingPercentage: 35.752688172043,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Tarapaca: {
    label: 'Tarapaca',
    iso3: null,
    population: '330,558',
    casesPastWeek: 155,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Chile_Valparaiso: {
    label: 'Valparaiso',
    iso3: null,
    population: '1,815,902',
    casesPastWeek: 2122,
    casesIncreasingPercentage: 32.459425717852675,
    positiveCasePercentage: 15.02,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  China: {
    label: 'China',
    iso3: 'CHN',
    population: '1,411,778,724',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: 'Countries',
    subdivisions: [
      'China_Anhui',
      'China_Beijing',
      'China_Chongqing',
      'China_Fujian',
      'China_Gansu',
      'China_Guangdong',
      'China_Guangxi',
      'China_Guizhou',
      'China_Hainan',
      'China_Hebei',
      'China_Heilongjiang',
      'China_Henan',
      'China_Hubei',
      'China_Hunan',
      'China_Inner_Mongolia',
      'China_Jiangsu',
      'China_Jiangxi',
      'China_Jilin',
      'China_Liaoning',
      'China_Ningxia',
      'China_Qinghai',
      'China_Shaanxi',
      'China_Shandong',
      'China_Shanghai',
      'China_Shanxi',
      'China_Sichuan',
      'China_Tianjin',
      'China_Tibet',
      'China_Xinjiang',
      'China_Yunnan',
      'China_Zhejiang',
      'China_Hong_Kong',
      'China_Macau',
    ],
    incompleteVaccinations: 196543800,
    completeVaccinations: 1113748200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 25, 2023',
  },
  China_Anhui: {
    label: 'Anhui',
    iso3: null,
    population: '61,027,171',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Beijing: {
    label: 'Beijing',
    iso3: null,
    population: '21,893,095',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Chongqing: {
    label: 'Chongqing',
    iso3: null,
    population: '32,054,159',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Fujian: {
    label: 'Fujian',
    iso3: null,
    population: '41,540,086',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Gansu: {
    label: 'Gansu',
    iso3: null,
    population: '25,019,831',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Guangdong: {
    label: 'Guangdong',
    iso3: null,
    population: '126,012,510',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Guangxi: {
    label: 'Guangxi',
    iso3: null,
    population: '50,126,804',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Guizhou: {
    label: 'Guizhou',
    iso3: null,
    population: '38,562,148',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Hainan: {
    label: 'Hainan',
    iso3: null,
    population: '10,081,232',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Hebei: {
    label: 'Hebei',
    iso3: null,
    population: '74,610,235',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Heilongjiang: {
    label: 'Heilongjiang',
    iso3: null,
    population: '31,850,088',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Henan: {
    label: 'Henan',
    iso3: null,
    population: '99,365,519',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Hubei: {
    label: 'Hubei',
    iso3: null,
    population: '57,752,557',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Hunan: {
    label: 'Hunan',
    iso3: null,
    population: '66,444,864',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Inner_Mongolia: {
    label: 'Inner Mongolia',
    iso3: null,
    population: '24,049,155',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Jiangsu: {
    label: 'Jiangsu',
    iso3: null,
    population: '84,748,016',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Jiangxi: {
    label: 'Jiangxi',
    iso3: null,
    population: '45,188,635',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Jilin: {
    label: 'Jilin',
    iso3: null,
    population: '24,073,453',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Liaoning: {
    label: 'Liaoning',
    iso3: null,
    population: '42,591,407',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Ningxia: {
    label: 'Ningxia',
    iso3: null,
    population: '7,202,654',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Qinghai: {
    label: 'Qinghai',
    iso3: null,
    population: '5,923,957',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Shaanxi: {
    label: 'Shaanxi',
    iso3: null,
    population: '39,528,999',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Shandong: {
    label: 'Shandong',
    iso3: null,
    population: '101,527,453',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Shanghai: {
    label: 'Shanghai',
    iso3: null,
    population: '24,870,895',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Shanxi: {
    label: 'Shanxi',
    iso3: null,
    population: '34,915,616',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Sichuan: {
    label: 'Sichuan',
    iso3: null,
    population: '83,674,866',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Tianjin: {
    label: 'Tianjin',
    iso3: null,
    population: '13,866,009',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Tibet: {
    label: 'Tibet',
    iso3: null,
    population: '3,648,100',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Xinjiang: {
    label: 'Xinjiang',
    iso3: null,
    population: '25,852,345',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Yunnan: {
    label: 'Yunnan',
    iso3: null,
    population: '47,209,277',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Zhejiang: {
    label: 'Zhejiang',
    iso3: null,
    population: '64,567,588',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Hong_Kong: {
    label: 'Hong Kong',
    iso3: null,
    population: '7,496,988',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  China_Macau: {
    label: 'Macau',
    iso3: null,
    population: '649,342',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.16,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 25, 2023',
  },
  Colombia: {
    label: 'Colombia',
    iso3: 'COL',
    population: '50,882,884',
    casesPastWeek: 861,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Colombia_Amazonas',
      'Colombia_Antioquia',
      'Colombia_Arauca',
      'Colombia_Atlantico',
      'Colombia_Bolivar',
      'Colombia_Boyaca',
      'Colombia_Caldas',
      'Colombia_Capital_District',
      'Colombia_Caqueta',
      'Colombia_Casanare',
      'Colombia_Cauca',
      'Colombia_Cesar',
      'Colombia_Choco',
      'Colombia_Cordoba',
      'Colombia_Cundinamarca',
      'Colombia_Guainia',
      'Colombia_Guaviare',
      'Colombia_Huila',
      'Colombia_La_Guajira',
      'Colombia_Magdalena',
      'Colombia_Meta',
      'Colombia_Narino',
      'Colombia_Norte_de_Santander',
      'Colombia_Putumayo',
      'Colombia_Quindio',
      'Colombia_Risaralda',
      'Colombia_San_Andres_y_Providencia',
      'Colombia_Santander',
      'Colombia_Sucre',
      'Colombia_Tolima',
      'Colombia_Valle_del_Cauca',
      'Colombia_Vaupes',
      'Colombia_Vichada',
    ],
    incompleteVaccinations: 6443920,
    completeVaccinations: 36515548,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Amazonas: {
    label: 'Amazonas',
    iso3: null,
    population: '76,589',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Antioquia: {
    label: 'Antioquia',
    iso3: null,
    population: '6,407,102',
    casesPastWeek: 123,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Arauca: {
    label: 'Arauca',
    iso3: null,
    population: '262,174',
    casesPastWeek: 11,
    casesIncreasingPercentage: 175.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Atlantico: {
    label: 'Atlantico',
    iso3: null,
    population: '2,535,517',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Bolivar: {
    label: 'Bolivar',
    iso3: null,
    population: '2,070,110',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Boyaca: {
    label: 'Boyaca',
    iso3: null,
    population: '1,217,376',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Caldas: {
    label: 'Caldas',
    iso3: null,
    population: '998,255',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Capital_District: {
    label: 'Capital District',
    iso3: null,
    population: '7,412,566',
    casesPastWeek: 345,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Caqueta: {
    label: 'Caqueta',
    iso3: null,
    population: '401,489',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Casanare: {
    label: 'Casanare',
    iso3: null,
    population: '420,504',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Cauca: {
    label: 'Cauca',
    iso3: null,
    population: '1,464,488',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Cesar: {
    label: 'Cesar',
    iso3: null,
    population: '1,200,574',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Choco: {
    label: 'Choco',
    iso3: null,
    population: '534,826',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Colombia_Cordoba: {
    label: 'Cordoba',
    iso3: null,
    population: '1,784,783',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Cundinamarca: {
    label: 'Cundinamarca',
    iso3: null,
    population: '2,919,060',
    casesPastWeek: 51,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Guainia: {
    label: 'Guainia',
    iso3: null,
    population: '48,114',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  Colombia_Guaviare: {
    label: 'Guaviare',
    iso3: null,
    population: '82,767',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Huila: {
    label: 'Huila',
    iso3: null,
    population: '1,100,386',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_La_Guajira: {
    label: 'La Guajira',
    iso3: null,
    population: '880,560',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Colombia_Magdalena: {
    label: 'Magdalena',
    iso3: null,
    population: '1,341,746',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Meta: {
    label: 'Meta',
    iso3: null,
    population: '1,039,722',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Narino: {
    label: 'Narino',
    iso3: null,
    population: '1,630,592',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Norte_de_Santander: {
    label: 'Norte de Santander',
    iso3: null,
    population: '1,491,689',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Putumayo: {
    label: 'Putumayo',
    iso3: null,
    population: '348,182',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Quindio: {
    label: 'Quindio',
    iso3: null,
    population: '539,904',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Risaralda: {
    label: 'Risaralda',
    iso3: null,
    population: '943,401',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_San_Andres_y_Providencia: {
    label: 'San Andres y Providencia',
    iso3: null,
    population: '61,280',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Santander: {
    label: 'Santander',
    iso3: null,
    population: '2,184,837',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Sucre: {
    label: 'Sucre',
    iso3: null,
    population: '904,863',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Tolima: {
    label: 'Tolima',
    iso3: null,
    population: '1,330,187',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Valle_del_Cauca: {
    label: 'Valle del Cauca',
    iso3: null,
    population: '4,475,886',
    casesPastWeek: 81,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Vaupes: {
    label: 'Vaupes',
    iso3: null,
    population: '40,797',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Colombia_Vichada: {
    label: 'Vichada',
    iso3: null,
    population: '107,808',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Comoros: {
    label: 'Comoros',
    iso3: 'COM',
    population: '869,595',
    casesPastWeek: 7,
    casesIncreasingPercentage: 133.33333333333334,
    positiveCasePercentage: 0.02,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 65824,
    completeVaccinations: 373001,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Congo__Brazzaville_: {
    label: 'Congo (Brazzaville)',
    iso3: 'COG',
    population: '5,518,092',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 104364,
    completeVaccinations: 591396,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Congo__Kinshasa_: {
    label: 'Congo (Kinshasa)',
    iso3: 'COD',
    population: '89,561,404',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.68,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1732423,
    completeVaccinations: 9817063,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Costa_Rica: {
    label: 'Costa Rica',
    iso3: 'CRI',
    population: '5,094,114',
    casesPastWeek: 5561,
    casesIncreasingPercentage: 8.84713251125464,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 690776,
    completeVaccinations: 3914396,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Cote_d_Ivoire: {
    label: "Cote d'Ivoire",
    iso3: 'CIV',
    population: '26,378,275',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.1,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2035256,
    completeVaccinations: 11533116,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Croatia: {
    label: 'Croatia',
    iso3: 'HRV',
    population: '4,105,268',
    casesPastWeek: 334,
    casesIncreasingPercentage: 2.4539877300613577,
    positiveCasePercentage: 21.67,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 348189,
    completeVaccinations: 1973068,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Cuba: {
    label: 'Cuba',
    iso3: 'CUB',
    population: '11,326,616',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.790000000000001,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1609381,
    completeVaccinations: 9119827,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Cyprus: {
    label: 'Cyprus',
    iso3: 'CYP',
    population: '1,207,361',
    casesPastWeek: 1592,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.56,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 100645,
    completeVaccinations: 570324,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Czechia: {
    label: 'Czechia',
    iso3: 'CZE',
    population: '10,708,982',
    casesPastWeek: 6113,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1046368,
    completeVaccinations: 5929416,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Denmark: {
    label: 'Denmark',
    iso3: 'DNK',
    population: '5,837,213',
    casesPastWeek: 796,
    casesIncreasingPercentage: 4.461942257217855,
    positiveCasePercentage: 19.400000000000002,
    topLevelGroup: 'Countries',
    subdivisions: ['Denmark_Faroe_Islands', 'Denmark_Greenland'],
    incompleteVaccinations: 719537,
    completeVaccinations: 4077374,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Denmark_Faroe_Islands: {
    label: 'Faroe Islands',
    iso3: null,
    population: '48,865',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Denmark_Greenland: {
    label: 'Greenland',
    iso3: null,
    population: '56,772',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Djibouti: {
    label: 'Djibouti',
    iso3: 'DJI',
    population: '988,002',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 55041,
    completeVaccinations: 311899,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Dominica: {
    label: 'Dominica',
    iso3: 'DMA',
    population: '71,991',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 4949,
    completeVaccinations: 28046,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Dominican_Republic: {
    label: 'Dominican Republic',
    iso3: 'DOM',
    population: '10,847,904',
    casesPastWeek: 85,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.24,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1096592,
    completeVaccinations: 6214020,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Ecuador: {
    label: 'Ecuador',
    iso3: 'ECU',
    population: '17,643,060',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.1,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2299634,
    completeVaccinations: 13031259,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Egypt: {
    label: 'Egypt',
    iso3: 'EGY',
    population: '102,334,403',
    casesPastWeek: 61,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 8273302,
    completeVaccinations: 46882044,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  El_Salvador: {
    label: 'El Salvador',
    iso3: 'SLV',
    population: '6,486,201',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 697890,
    completeVaccinations: 3954707,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Equatorial_Guinea: {
    label: 'Equatorial Guinea',
    iso3: 'GNQ',
    population: '1,402,985',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.5699999999999998,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 40516,
    completeVaccinations: 229593,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Eritrea: {
    label: 'Eritrea',
    iso3: 'ERI',
    population: '3,546,427',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Estonia: {
    label: 'Estonia',
    iso3: 'EST',
    population: '1,326,539',
    casesPastWeek: 305,
    casesIncreasingPercentage: 10.507246376811597,
    positiveCasePercentage: 11.83,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 130373,
    completeVaccinations: 738783,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Eswatini: {
    label: 'Eswatini',
    iso3: 'SWZ',
    population: '1,160,164',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.34,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 76745,
    completeVaccinations: 434887,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 05, 2023',
  },
  Ethiopia: {
    label: 'Ethiopia',
    iso3: 'ETH',
    population: '114,963,583',
    casesPastWeek: 116,
    casesIncreasingPercentage: 39.75903614457832,
    positiveCasePercentage: 13.25,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 6568689,
    completeVaccinations: 37222574,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Fiji: {
    label: 'Fiji',
    iso3: 'FJI',
    population: '896,444',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.14939309056956115,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 106779,
    completeVaccinations: 605080,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Finland: {
    label: 'Finland',
    iso3: 'FIN',
    population: '5,540,718',
    casesPastWeek: 807,
    casesIncreasingPercentage: 37.71331058020478,
    positiveCasePercentage: 33.1,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 681964,
    completeVaccinations: 3864463,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  France: {
    label: 'France',
    iso3: 'FRA',
    population: '65,249,843',
    casesPastWeek: 28407,
    casesIncreasingPercentage: 8.39056776556777,
    positiveCasePercentage: 22.7,
    topLevelGroup: 'Countries',
    subdivisions: [
      'France_French_Guiana',
      'France_French_Polynesia',
      'France_Guadeloupe',
      'France_Martinique',
      'France_Mayotte',
      'France_New_Caledonia',
      'France_Reunion',
      'France_Saint_Barthelemy',
      'France_Saint_Pierre_and_Miquelon',
      'France_St_Martin',
      'France_Wallis_and_Futuna',
    ],
    incompleteVaccinations: 8198943,
    completeVaccinations: 46460679,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  France_French_Guiana: {
    label: 'French Guiana',
    iso3: null,
    population: '298,682',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  France_French_Polynesia: {
    label: 'French Polynesia',
    iso3: null,
    population: '280,904',
    casesPastWeek: 98,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  France_Guadeloupe: {
    label: 'Guadeloupe',
    iso3: null,
    population: '400,127',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  France_Martinique: {
    label: 'Martinique',
    iso3: null,
    population: '375,265',
    casesPastWeek: 145,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  France_Mayotte: {
    label: 'Mayotte',
    iso3: null,
    population: '272,813',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  France_New_Caledonia: {
    label: 'New Caledonia',
    iso3: null,
    population: '285,491',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  France_Reunion: {
    label: 'Reunion',
    iso3: null,
    population: '895,308',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  France_Saint_Barthelemy: {
    label: 'Saint Barthelemy',
    iso3: null,
    population: '9,885',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  France_Saint_Pierre_and_Miquelon: {
    label: 'Saint Pierre and Miquelon',
    iso3: null,
    population: '5,795',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  France_St_Martin: {
    label: 'St Martin',
    iso3: null,
    population: '38,659',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  France_Wallis_and_Futuna: {
    label: 'Wallis and Futuna',
    iso3: null,
    population: '15,289',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Gabon: {
    label: 'Gabon',
    iso3: 'GAB',
    population: '2,225,728',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.8899999999999997,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 46687,
    completeVaccinations: 264557,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Gambia: {
    label: 'Gambia',
    iso3: 'GMB',
    population: '2,416,664',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.05,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 140220,
    completeVaccinations: 794579,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Georgia: {
    label: 'Georgia',
    iso3: 'GEO',
    population: '3,989,175',
    casesPastWeek: 1939,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.06,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 248176,
    completeVaccinations: 1406328,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany: {
    label: 'Germany',
    iso3: 'DEU',
    population: '83,155,031',
    casesPastWeek: 51277,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Germany_Baden_Wurttemberg',
      'Germany_Bayern',
      'Germany_Berlin',
      'Germany_Brandenburg',
      'Germany_Bremen',
      'Germany_Hamburg',
      'Germany_Hessen',
      'Germany_Mecklenburg_Vorpommern',
      'Germany_Niedersachsen',
      'Germany_Nordrhein_Westfalen',
      'Germany_Rheinland_Pfalz',
      'Germany_Saarland',
      'Germany_Sachsen',
      'Germany_Sachsen_Anhalt',
      'Germany_Schleswig_Holstein',
      'Germany_Thuringen',
    ],
    incompleteVaccinations: 9730958,
    completeVaccinations: 55142098,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Baden_Wurttemberg: {
    label: 'Baden-Wurttemberg',
    iso3: null,
    population: '11,103,043',
    casesPastWeek: 6520,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Bayern: {
    label: 'Bayern',
    iso3: null,
    population: '13,140,183',
    casesPastWeek: 7662,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Berlin: {
    label: 'Berlin',
    iso3: null,
    population: '3,664,088',
    casesPastWeek: 1322,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Germany_Brandenburg: {
    label: 'Brandenburg',
    iso3: null,
    population: '2,531,071',
    casesPastWeek: 1190,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Bremen: {
    label: 'Bremen',
    iso3: null,
    population: '680,130',
    casesPastWeek: 363,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Hamburg: {
    label: 'Hamburg',
    iso3: null,
    population: '1,852,478',
    casesPastWeek: 535,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Hessen: {
    label: 'Hessen',
    iso3: null,
    population: '6,293,154',
    casesPastWeek: 4415,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Mecklenburg_Vorpommern: {
    label: 'Mecklenburg-Vorpommern',
    iso3: null,
    population: '1,610,774',
    casesPastWeek: 486,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Germany_Niedersachsen: {
    label: 'Niedersachsen',
    iso3: null,
    population: '8,003,421',
    casesPastWeek: 4215,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Nordrhein_Westfalen: {
    label: 'Nordrhein-Westfalen',
    iso3: null,
    population: '17,925,570',
    casesPastWeek: 15503,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Rheinland_Pfalz: {
    label: 'Rheinland-Pfalz',
    iso3: null,
    population: '4,098,391',
    casesPastWeek: 3064,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Saarland: {
    label: 'Saarland',
    iso3: null,
    population: '983,991',
    casesPastWeek: 1128,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Sachsen: {
    label: 'Sachsen',
    iso3: null,
    population: '4,056,941',
    casesPastWeek: 1853,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Sachsen_Anhalt: {
    label: 'Sachsen-Anhalt',
    iso3: null,
    population: '2,180,684',
    casesPastWeek: 1147,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Schleswig_Holstein: {
    label: 'Schleswig-Holstein',
    iso3: null,
    population: '2,910,875',
    casesPastWeek: 922,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Germany_Thuringen: {
    label: 'Thuringen',
    iso3: null,
    population: '2,120,237',
    casesPastWeek: 952,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Ghana: {
    label: 'Ghana',
    iso3: 'GHA',
    population: '31,072,945',
    casesPastWeek: 57,
    casesIncreasingPercentage: 1040.0,
    positiveCasePercentage: 26.32,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1927967,
    completeVaccinations: 10925146,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Greece: {
    label: 'Greece',
    iso3: 'GRC',
    population: '10,423,056',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.27,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1190113,
    completeVaccinations: 6743975,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Grenada: {
    label: 'Grenada',
    iso3: 'GRD',
    population: '112,519',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.72,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 6627,
    completeVaccinations: 37553,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Guatemala: {
    label: 'Guatemala',
    iso3: 'GTM',
    population: '17,915,567',
    casesPastWeek: 1271,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.11,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1336848,
    completeVaccinations: 7575472,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Guinea: {
    label: 'Guinea',
    iso3: 'GIN',
    population: '13,132,792',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1149248,
    completeVaccinations: 6512405,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Guinea_Bissau: {
    label: 'Guinea-Bissau',
    iso3: 'GNB',
    population: '1,967,998',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.4699999999999998,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 81638,
    completeVaccinations: 462618,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Guyana: {
    label: 'Guyana',
    iso3: 'GUY',
    population: '786,559',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.879999999999995,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 74293,
    completeVaccinations: 420992,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Haiti: {
    label: 'Haiti',
    iso3: 'HTI',
    population: '11,402,533',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.63,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 60980,
    completeVaccinations: 345554,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Holy_See: {
    label: 'Holy See',
    iso3: 'VAT',
    population: '809',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Honduras: {
    label: 'Honduras',
    iso3: 'HND',
    population: '9,904,608',
    casesPastWeek: 134,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 978285,
    completeVaccinations: 5543616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Hungary: {
    label: 'Hungary',
    iso3: 'HUN',
    population: '9,660,350',
    casesPastWeek: 878,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.189999999999998,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 963122,
    completeVaccinations: 5457691,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Iceland: {
    label: 'Iceland',
    iso3: 'ISL',
    population: '341,250',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.3664596273291925,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 46466,
    completeVaccinations: 263304,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  India: {
    label: 'India',
    iso3: 'IND',
    population: '1,380,004,385',
    casesPastWeek: 2193,
    casesIncreasingPercentage: 53.463960811756486,
    positiveCasePercentage: 2.78,
    topLevelGroup: 'Countries',
    subdivisions: [
      'India_Andaman_and_Nicobar_Islands',
      'India_Andhra_Pradesh',
      'India_Arunachal_Pradesh',
      'India_Assam',
      'India_Bihar',
      'India_Chandigarh',
      'India_Chhattisgarh',
      'India_Dadra_and_Nagar_Haveli_and_Daman_and_Diu',
      'India_Delhi',
      'India_Goa',
      'India_Gujarat',
      'India_Haryana',
      'India_Himachal_Pradesh',
      'India_Jammu_and_Kashmir',
      'India_Jharkhand',
      'India_Karnataka',
      'India_Kerala',
      'India_Ladakh',
      'India_Madhya_Pradesh',
      'India_Maharashtra',
      'India_Manipur',
      'India_Meghalaya',
      'India_Mizoram',
      'India_Nagaland',
      'India_Odisha',
      'India_Puducherry',
      'India_Punjab',
      'India_Rajasthan',
      'India_Sikkim',
      'India_Tamil_Nadu',
      'India_Telangana',
      'India_Tripura',
      'India_Uttar_Pradesh',
      'India_Uttarakhand',
      'India_West_Bengal',
      'India_Lakshadweep',
    ],
    incompleteVaccinations: 154106931,
    completeVaccinations: 873272609,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Andaman_and_Nicobar_Islands: {
    label: 'Andaman and Nicobar Islands',
    iso3: null,
    population: '417,036',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Andhra_Pradesh: {
    label: 'Andhra Pradesh',
    iso3: null,
    population: '53,903,393',
    casesPastWeek: 4,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  India_Arunachal_Pradesh: {
    label: 'Arunachal Pradesh',
    iso3: null,
    population: '1,570,458',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Assam: {
    label: 'Assam',
    iso3: null,
    population: '35,607,039',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Bihar: {
    label: 'Bihar',
    iso3: null,
    population: '124,799,926',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  India_Chandigarh: {
    label: 'Chandigarh',
    iso3: null,
    population: '1,158,473',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Chhattisgarh: {
    label: 'Chhattisgarh',
    iso3: null,
    population: '29,436,231',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Dadra_and_Nagar_Haveli_and_Daman_and_Diu: {
    label: 'Dadra and Nagar Haveli and Daman and Diu',
    iso3: null,
    population: '615,724',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Delhi: {
    label: 'Delhi',
    iso3: null,
    population: '18,710,922',
    casesPastWeek: 76,
    casesIncreasingPercentage: 49.01960784313726,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Goa: {
    label: 'Goa',
    iso3: null,
    population: '1,586,250',
    casesPastWeek: 16,
    casesIncreasingPercentage: 128.57142857142856,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  India_Gujarat: {
    label: 'Gujarat',
    iso3: null,
    population: '63,872,399',
    casesPastWeek: 115,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Haryana: {
    label: 'Haryana',
    iso3: null,
    population: '28,204,692',
    casesPastWeek: 26,
    casesIncreasingPercentage: 8.333333333333325,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Himachal_Pradesh: {
    label: 'Himachal Pradesh',
    iso3: null,
    population: '7,451,955',
    casesPastWeek: 44,
    casesIncreasingPercentage: 62.962962962962955,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  India_Jammu_and_Kashmir: {
    label: 'Jammu and Kashmir',
    iso3: null,
    population: '13,606,320',
    casesPastWeek: 17,
    casesIncreasingPercentage: 41.66666666666667,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Jharkhand: {
    label: 'Jharkhand',
    iso3: null,
    population: '38,593,948',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  India_Karnataka: {
    label: 'Karnataka',
    iso3: null,
    population: '67,562,686',
    casesPastWeek: 539,
    casesIncreasingPercentage: 38.56041131105399,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Kerala: {
    label: 'Kerala',
    iso3: null,
    population: '35,699,443',
    casesPastWeek: 445,
    casesIncreasingPercentage: 31.268436578171087,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Ladakh: {
    label: 'Ladakh',
    iso3: null,
    population: '274,289',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 05, 2023',
  },
  India_Madhya_Pradesh: {
    label: 'Madhya Pradesh',
    iso3: null,
    population: '85,358,965',
    casesPastWeek: 20,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  India_Maharashtra: {
    label: 'Maharashtra',
    iso3: null,
    population: '123,144,223',
    casesPastWeek: 377,
    casesIncreasingPercentage: 89.44723618090453,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Manipur: {
    label: 'Manipur',
    iso3: null,
    population: '3,091,545',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Meghalaya: {
    label: 'Meghalaya',
    iso3: null,
    population: '3,366,710',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Mizoram: {
    label: 'Mizoram',
    iso3: null,
    population: '1,239,244',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  India_Nagaland: {
    label: 'Nagaland',
    iso3: null,
    population: '2,249,695',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Odisha: {
    label: 'Odisha',
    iso3: null,
    population: '46,356,334',
    casesPastWeek: 12,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Puducherry: {
    label: 'Puducherry',
    iso3: null,
    population: '1,413,542',
    casesPastWeek: 41,
    casesIncreasingPercentage: 2.499999999999991,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Punjab: {
    label: 'Punjab',
    iso3: null,
    population: '30,141,373',
    casesPastWeek: 47,
    casesIncreasingPercentage: 38.23529411764706,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Rajasthan: {
    label: 'Rajasthan',
    iso3: null,
    population: '81,032,689',
    casesPastWeek: 24,
    casesIncreasingPercentage: 166.66666666666666,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Sikkim: {
    label: 'Sikkim',
    iso3: null,
    population: '690,251',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 25, 2023',
  },
  India_Tamil_Nadu: {
    label: 'Tamil Nadu',
    iso3: null,
    population: '77,841,267',
    casesPastWeek: 186,
    casesIncreasingPercentage: 82.35294117647058,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Telangana: {
    label: 'Telangana',
    iso3: null,
    population: '39,362,732',
    casesPastWeek: 136,
    casesIncreasingPercentage: 29.52380952380953,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Tripura: {
    label: 'Tripura',
    iso3: null,
    population: '4,169,794',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  India_Uttar_Pradesh: {
    label: 'Uttar Pradesh',
    iso3: null,
    population: '237,882,725',
    casesPastWeek: 13,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Uttarakhand: {
    label: 'Uttarakhand',
    iso3: null,
    population: '11,250,858',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  India_West_Bengal: {
    label: 'West Bengal',
    iso3: null,
    population: '99,609,303',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  India_Lakshadweep: {
    label: 'Lakshadweep',
    iso3: null,
    population: '64,429',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.78,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Indonesia: {
    label: 'Indonesia',
    iso3: 'IDN',
    population: '273,523,621',
    casesPastWeek: 1620,
    casesIncreasingPercentage: 9.459459459459453,
    positiveCasePercentage: 8.37,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 30548630,
    completeVaccinations: 173108905,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Iran: {
    label: 'Iran',
    iso3: 'IRN',
    population: '83,992,953',
    casesPastWeek: 3093,
    casesIncreasingPercentage: 108.70445344129553,
    positiveCasePercentage: 0.7799999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 9773965,
    completeVaccinations: 55385802,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Iraq: {
    label: 'Iraq',
    iso3: 'IRQ',
    population: '40,222,503',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.9199999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1699939,
    completeVaccinations: 9632986,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ireland: {
    label: 'Ireland',
    iso3: 'IRL',
    population: '4,937,796',
    casesPastWeek: 652,
    casesIncreasingPercentage: 7.590759075907583,
    positiveCasePercentage: 32.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 616088,
    completeVaccinations: 3491165,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Israel: {
    label: 'Israel',
    iso3: 'ISR',
    population: '8,655,541',
    casesPastWeek: 3533,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1058320,
    completeVaccinations: 5997146,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Italy: {
    label: 'Italy',
    iso3: 'ITA',
    population: '60,461,828',
    casesPastWeek: 26658,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Italy_Abruzzo',
      'Italy_Basilicata',
      'Italy_Calabria',
      'Italy_Campania',
      'Italy_Emilia_Romagna',
      'Italy_Friuli_Venezia_Giulia',
      'Italy_Lazio',
      'Italy_Liguria',
      'Italy_Lombardia',
      'Italy_Marche',
      'Italy_Molise',
      'Italy_P_A__Bolzano',
      'Italy_P_A__Trento',
      'Italy_Piemonte',
      'Italy_Puglia',
      'Italy_Sardegna',
      'Italy_Sicilia',
      'Italy_Toscana',
      'Italy_Umbria',
      'Italy_Valle_d_Aosta',
      'Italy_Veneto',
    ],
    incompleteVaccinations: 7632870,
    completeVaccinations: 43252932,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Abruzzo: {
    label: 'Abruzzo',
    iso3: null,
    population: '1,311,580',
    casesPastWeek: 689,
    casesIncreasingPercentage: 9.713375796178347,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Basilicata: {
    label: 'Basilicata',
    iso3: null,
    population: '562,869',
    casesPastWeek: 93,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Calabria: {
    label: 'Calabria',
    iso3: null,
    population: '1,947,131',
    casesPastWeek: 648,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Campania: {
    label: 'Campania',
    iso3: null,
    population: '5,801,692',
    casesPastWeek: 2011,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Emilia_Romagna: {
    label: 'Emilia-Romagna',
    iso3: null,
    population: '4,459,477',
    casesPastWeek: 1922,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Friuli_Venezia_Giulia: {
    label: 'Friuli Venezia Giulia',
    iso3: null,
    population: '1,215,220',
    casesPastWeek: 534,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Lazio: {
    label: 'Lazio',
    iso3: null,
    population: '5,879,082',
    casesPastWeek: 5212,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Liguria: {
    label: 'Liguria',
    iso3: null,
    population: '1,550,640',
    casesPastWeek: 443,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Lombardia: {
    label: 'Lombardia',
    iso3: null,
    population: '10,060,574',
    casesPastWeek: 3936,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Marche: {
    label: 'Marche',
    iso3: null,
    population: '1,525,271',
    casesPastWeek: 505,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Molise: {
    label: 'Molise',
    iso3: null,
    population: '305,617',
    casesPastWeek: 63,
    casesIncreasingPercentage: 5.000000000000004,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_P_A__Bolzano: {
    label: 'P.A. Bolzano',
    iso3: null,
    population: '532,318',
    casesPastWeek: 394,
    casesIncreasingPercentage: 7.650273224043724,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_P_A__Trento: {
    label: 'P.A. Trento',
    iso3: null,
    population: '541,418',
    casesPastWeek: 215,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Piemonte: {
    label: 'Piemonte',
    iso3: null,
    population: '4,356,406',
    casesPastWeek: 1183,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Puglia: {
    label: 'Puglia',
    iso3: null,
    population: '4,029,053',
    casesPastWeek: 1216,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Sardegna: {
    label: 'Sardegna',
    iso3: null,
    population: '1,639,591',
    casesPastWeek: 540,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Sicilia: {
    label: 'Sicilia',
    iso3: null,
    population: '4,999,891',
    casesPastWeek: 1376,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Toscana: {
    label: 'Toscana',
    iso3: null,
    population: '3,729,641',
    casesPastWeek: 1580,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Umbria: {
    label: 'Umbria',
    iso3: null,
    population: '882,015',
    casesPastWeek: 599,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Valle_d_Aosta: {
    label: "Valle d'Aosta",
    iso3: null,
    population: '125,666',
    casesPastWeek: 64,
    casesIncreasingPercentage: 236.84210526315786,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Italy_Veneto: {
    label: 'Veneto',
    iso3: null,
    population: '4,905,854',
    casesPastWeek: 3435,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.53,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Jamaica: {
    label: 'Jamaica',
    iso3: 'JAM',
    population: '2,961,161',
    casesPastWeek: 91,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.64,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 127676,
    completeVaccinations: 723499,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan: {
    label: 'Japan',
    iso3: 'JPN',
    population: '126,476,458',
    casesPastWeek: 69424,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Japan_Aichi',
      'Japan_Akita',
      'Japan_Aomori',
      'Japan_Chiba',
      'Japan_Ehime',
      'Japan_Fukui',
      'Japan_Fukuoka',
      'Japan_Fukushima',
      'Japan_Gifu',
      'Japan_Gunma',
      'Japan_Hiroshima',
      'Japan_Hokkaido',
      'Japan_Hyogo',
      'Japan_Ibaraki',
      'Japan_Ishikawa',
      'Japan_Iwate',
      'Japan_Kagawa',
      'Japan_Kagoshima',
      'Japan_Kanagawa',
      'Japan_Kochi',
      'Japan_Kumamoto',
      'Japan_Kyoto',
      'Japan_Mie',
      'Japan_Miyagi',
      'Japan_Miyazaki',
      'Japan_Nagano',
      'Japan_Nagasaki',
      'Japan_Nara',
      'Japan_Niigata',
      'Japan_Oita',
      'Japan_Okayama',
      'Japan_Okinawa',
      'Japan_Osaka',
      'Japan_Saga',
      'Japan_Saitama',
      'Japan_Shiga',
      'Japan_Shimane',
      'Japan_Shizuoka',
      'Japan_Tochigi',
      'Japan_Tokushima',
      'Japan_Tokyo',
      'Japan_Tottori',
      'Japan_Toyama',
      'Japan_Wakayama',
      'Japan_Yamagata',
      'Japan_Yamaguchi',
      'Japan_Yamanashi',
    ],
    incompleteVaccinations: 15701392,
    completeVaccinations: 88974556,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Aichi: {
    label: 'Aichi',
    iso3: null,
    population: '7,552,239',
    casesPastWeek: 3804,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Akita: {
    label: 'Akita',
    iso3: null,
    population: '966,490',
    casesPastWeek: 646,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Aomori: {
    label: 'Aomori',
    iso3: null,
    population: '1,246,371',
    casesPastWeek: 542,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Chiba: {
    label: 'Chiba',
    iso3: null,
    population: '6,259,382',
    casesPastWeek: 2405,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Ehime: {
    label: 'Ehime',
    iso3: null,
    population: '1,339,215',
    casesPastWeek: 1313,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Fukui: {
    label: 'Fukui',
    iso3: null,
    population: '767,937',
    casesPastWeek: 436,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Fukuoka: {
    label: 'Fukuoka',
    iso3: null,
    population: '5,103,679',
    casesPastWeek: 2915,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Fukushima: {
    label: 'Fukushima',
    iso3: null,
    population: '1,845,519',
    casesPastWeek: 1691,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Gifu: {
    label: 'Gifu',
    iso3: null,
    population: '1,986,587',
    casesPastWeek: 1655,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Gunma: {
    label: 'Gunma',
    iso3: null,
    population: '1,942,456',
    casesPastWeek: 1216,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Hiroshima: {
    label: 'Hiroshima',
    iso3: null,
    population: '2,804,177',
    casesPastWeek: 2085,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Hokkaido: {
    label: 'Hokkaido',
    iso3: null,
    population: '5,250,049',
    casesPastWeek: 2617,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Hyogo: {
    label: 'Hyogo',
    iso3: null,
    population: '5,466,190',
    casesPastWeek: 2649,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Ibaraki: {
    label: 'Ibaraki',
    iso3: null,
    population: '2,860,307',
    casesPastWeek: 1285,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Ishikawa: {
    label: 'Ishikawa',
    iso3: null,
    population: '1,137,649',
    casesPastWeek: 653,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Iwate: {
    label: 'Iwate',
    iso3: null,
    population: '1,226,816',
    casesPastWeek: 633,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Kagawa: {
    label: 'Kagawa',
    iso3: null,
    population: '956,347',
    casesPastWeek: 656,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Kagoshima: {
    label: 'Kagoshima',
    iso3: null,
    population: '1,602,273',
    casesPastWeek: 1084,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Kanagawa: {
    label: 'Kanagawa',
    iso3: null,
    population: '9,198,268',
    casesPastWeek: 3329,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Kochi: {
    label: 'Kochi',
    iso3: null,
    population: '698,029',
    casesPastWeek: 527,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Kumamoto: {
    label: 'Kumamoto',
    iso3: null,
    population: '1,747,567',
    casesPastWeek: 980,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Kyoto: {
    label: 'Kyoto',
    iso3: null,
    population: '2,582,957',
    casesPastWeek: 1171,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Mie: {
    label: 'Mie',
    iso3: null,
    population: '1,780,882',
    casesPastWeek: 1383,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Miyagi: {
    label: 'Miyagi',
    iso3: null,
    population: '2,306,365',
    casesPastWeek: 1722,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Miyazaki: {
    label: 'Miyazaki',
    iso3: null,
    population: '1,073,301',
    casesPastWeek: 706,
    casesIncreasingPercentage: 5.530642750373693,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Nagano: {
    label: 'Nagano',
    iso3: null,
    population: '2,048,790',
    casesPastWeek: 2017,
    casesIncreasingPercentage: 3.1186094069529657,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Nagasaki: {
    label: 'Nagasaki',
    iso3: null,
    population: '1,326,524',
    casesPastWeek: 791,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Nara: {
    label: 'Nara',
    iso3: null,
    population: '1,330,123',
    casesPastWeek: 612,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Niigata: {
    label: 'Niigata',
    iso3: null,
    population: '2,223,106',
    casesPastWeek: 1796,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Oita: {
    label: 'Oita',
    iso3: null,
    population: '1,135,434',
    casesPastWeek: 756,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Okayama: {
    label: 'Okayama',
    iso3: null,
    population: '1,889,586',
    casesPastWeek: 986,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Okinawa: {
    label: 'Okinawa',
    iso3: null,
    population: '1,453,168',
    casesPastWeek: 544,
    casesIncreasingPercentage: 5.631067961165059,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Osaka: {
    label: 'Osaka',
    iso3: null,
    population: '8,809,363',
    casesPastWeek: 4036,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Saga: {
    label: 'Saga',
    iso3: null,
    population: '814,711',
    casesPastWeek: 582,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Saitama: {
    label: 'Saitama',
    iso3: null,
    population: '7,349,693',
    casesPastWeek: 3003,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Shiga: {
    label: 'Shiga',
    iso3: null,
    population: '1,413,943',
    casesPastWeek: 749,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Shimane: {
    label: 'Shimane',
    iso3: null,
    population: '674,346',
    casesPastWeek: 812,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Shizuoka: {
    label: 'Shizuoka',
    iso3: null,
    population: '3,643,528',
    casesPastWeek: 2240,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Tochigi: {
    label: 'Tochigi',
    iso3: null,
    population: '1,933,990',
    casesPastWeek: 1357,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Tokushima: {
    label: 'Tokushima',
    iso3: null,
    population: '727,977',
    casesPastWeek: 759,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Tokyo: {
    label: 'Tokyo',
    iso3: null,
    population: '13,920,663',
    casesPastWeek: 5438,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Tottori: {
    label: 'Tottori',
    iso3: null,
    population: '555,558',
    casesPastWeek: 759,
    casesIncreasingPercentage: 7.507082152974509,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Toyama: {
    label: 'Toyama',
    iso3: null,
    population: '1,043,502',
    casesPastWeek: 643,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Wakayama: {
    label: 'Wakayama',
    iso3: null,
    population: '924,933',
    casesPastWeek: 608,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Yamagata: {
    label: 'Yamagata',
    iso3: null,
    population: '1,077,666',
    casesPastWeek: 952,
    casesIncreasingPercentage: 11.214953271028039,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Yamaguchi: {
    label: 'Yamaguchi',
    iso3: null,
    population: '1,358,336',
    casesPastWeek: 1158,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Japan_Yamanashi: {
    label: 'Yamanashi',
    iso3: null,
    population: '810,956',
    casesPastWeek: 723,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.829999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Jordan: {
    label: 'Jordan',
    iso3: 'JOR',
    population: '10,203,140',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.71,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 723237,
    completeVaccinations: 4098342,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Kazakhstan: {
    label: 'Kazakhstan',
    iso3: 'KAZ',
    population: '18,776,707',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1855873,
    completeVaccinations: 10516611,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  Kenya: {
    label: 'Kenya',
    iso3: 'KEN',
    population: '53,771,300',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.07,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2147556,
    completeVaccinations: 12169483,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Kiribati: {
    label: 'Kiribati',
    iso3: 'KIR',
    population: '117,606',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 15135,
    completeVaccinations: 85765,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Korea__North: {
    label: 'Korea, North',
    iso3: 'PRK',
    population: '25,778,815',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Kosovo: {
    label: 'Kosovo',
    iso3: 'XKS',
    population: '1,810,366',
    casesPastWeek: 183,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 136029,
    completeVaccinations: 770829,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Kuwait: {
    label: 'Kuwait',
    iso3: 'KWT',
    population: '4,270,563',
    casesPastWeek: 404,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.6500000000000001,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 518463,
    completeVaccinations: 2937960,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Kyrgyzstan: {
    label: 'Kyrgyzstan',
    iso3: 'KGZ',
    population: '6,524,191',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 247973,
    completeVaccinations: 1405179,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Laos: {
    label: 'Laos',
    iso3: 'LAO',
    population: '7,275,556',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 10.620000000000001,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 943574,
    completeVaccinations: 5346918,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Latvia: {
    label: 'Latvia',
    iso3: 'LVA',
    population: '1,886,202',
    casesPastWeek: 197,
    casesIncreasingPercentage: 34.01360544217687,
    positiveCasePercentage: 12.379999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 201928,
    completeVaccinations: 1144256,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Lebanon: {
    label: 'Lebanon',
    iso3: 'LBN',
    population: '6,825,442',
    casesPastWeek: 586,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.5699502023031435,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 411034,
    completeVaccinations: 2329193,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Lesotho: {
    label: 'Lesotho',
    iso3: 'LSO',
    population: '2,142,252',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.72,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 151222,
    completeVaccinations: 856923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Liberia: {
    label: 'Liberia',
    iso3: 'LBR',
    population: '5,057,677',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 585570,
    completeVaccinations: 3318232,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Libya: {
    label: 'Libya',
    iso3: 'LBY',
    population: '6,871,287',
    casesPastWeek: 13,
    casesIncreasingPercentage: 333.3333333333333,
    positiveCasePercentage: 24.779999999999998,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 347449,
    completeVaccinations: 1968878,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Liechtenstein: {
    label: 'Liechtenstein',
    iso3: 'LIE',
    population: '38,137',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 34.4,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 4015,
    completeVaccinations: 22751,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Lithuania: {
    label: 'Lithuania',
    iso3: 'LTU',
    population: '2,722,291',
    casesPastWeek: 2285,
    casesIncreasingPercentage: 7.025761124121788,
    positiveCasePercentage: 21.2,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 293534,
    completeVaccinations: 1663358,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Luxembourg: {
    label: 'Luxembourg',
    iso3: 'LUX',
    population: '625,976',
    casesPastWeek: 766,
    casesIncreasingPercentage: 16.768292682926834,
    positiveCasePercentage: 37.6,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 72291,
    completeVaccinations: 409646,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Madagascar: {
    label: 'Madagascar',
    iso3: 'MDG',
    population: '27,691,019',
    casesPastWeek: 24,
    casesIncreasingPercentage: 84.61538461538463,
    positiveCasePercentage: 5.47,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 349768,
    completeVaccinations: 1982016,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 05, 2023',
  },
  Malawi: {
    label: 'Malawi',
    iso3: 'MWI',
    population: '19,129,955',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5600000000000005,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 742543,
    completeVaccinations: 4207745,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia: {
    label: 'Malaysia',
    iso3: 'MYS',
    population: '32,365,998',
    casesPastWeek: 1431,
    casesIncreasingPercentage: 0.703729767769179,
    positiveCasePercentage: 5.55,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Malaysia_Johor',
      'Malaysia_Kedah',
      'Malaysia_Kelantan',
      'Malaysia_Melaka',
      'Malaysia_Negeri_Sembilan',
      'Malaysia_Pahang',
      'Malaysia_Perak',
      'Malaysia_Perlis',
      'Malaysia_Pulau_Pinang',
      'Malaysia_Sabah',
      'Malaysia_Sarawak',
      'Malaysia_Selangor',
      'Malaysia_Terengganu',
      'Malaysia_W_P__Kuala_Lumpur',
      'Malaysia_W_P__Labuan',
      'Malaysia_W_P__Putrajaya',
    ],
    incompleteVaccinations: 4219658,
    completeVaccinations: 23911392,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Johor: {
    label: 'Johor',
    iso3: null,
    population: '3,768,200',
    casesPastWeek: 87,
    casesIncreasingPercentage: 17.567567567567565,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Kedah: {
    label: 'Kedah',
    iso3: null,
    population: '2,185,900',
    casesPastWeek: 55,
    casesIncreasingPercentage: 37.5,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Kelantan: {
    label: 'Kelantan',
    iso3: null,
    population: '1,892,200',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Melaka: {
    label: 'Melaka',
    iso3: null,
    population: '932,700',
    casesPastWeek: 85,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Negeri_Sembilan: {
    label: 'Negeri Sembilan',
    iso3: null,
    population: '1,132,100',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Pahang: {
    label: 'Pahang',
    iso3: null,
    population: '1,677,100',
    casesPastWeek: 31,
    casesIncreasingPercentage: 29.166666666666675,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Perak: {
    label: 'Perak',
    iso3: null,
    population: '2,514,300',
    casesPastWeek: 64,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Perlis: {
    label: 'Perlis',
    iso3: null,
    population: '254,600',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  Malaysia_Pulau_Pinang: {
    label: 'Pulau Pinang',
    iso3: null,
    population: '1,777,600',
    casesPastWeek: 39,
    casesIncreasingPercentage: 18.181818181818187,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Sabah: {
    label: 'Sabah',
    iso3: null,
    population: '3,904,700',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Sarawak: {
    label: 'Sarawak',
    iso3: null,
    population: '2,818,100',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Selangor: {
    label: 'Selangor',
    iso3: null,
    population: '6,541,900',
    casesPastWeek: 445,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_Terengganu: {
    label: 'Terengganu',
    iso3: null,
    population: '1,250,100',
    casesPastWeek: 28,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Malaysia_W_P__Kuala_Lumpur: {
    label: 'W.P. Kuala Lumpur',
    iso3: null,
    population: '1,778,400',
    casesPastWeek: 444,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malaysia_W_P__Labuan: {
    label: 'W.P. Labuan',
    iso3: null,
    population: '99,400',
    casesPastWeek: 11,
    casesIncreasingPercentage: 120.00000000000001,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Malaysia_W_P__Putrajaya: {
    label: 'W.P. Putrajaya',
    iso3: null,
    population: '105,400',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.55,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Maldives: {
    label: 'Maldives',
    iso3: 'MDV',
    population: '540,542',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 59884,
    completeVaccinations: 339341,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Mali: {
    label: 'Mali',
    iso3: 'MLI',
    population: '20,250,834',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.35000000000000003,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 541994,
    completeVaccinations: 3071298,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Malta: {
    label: 'Malta',
    iso3: 'MLT',
    population: '441,539',
    casesPastWeek: 133,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.34,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 71808,
    completeVaccinations: 406913,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Marshall_Islands: {
    label: 'Marshall Islands',
    iso3: 'MHL',
    population: '58,413',
    casesPastWeek: 31,
    casesIncreasingPercentage: 55.00000000000001,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 6844,
    completeVaccinations: 38783,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mauritania: {
    label: 'Mauritania',
    iso3: 'MRT',
    population: '4,649,660',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.4200000000000002,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 315041,
    completeVaccinations: 1785230,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Mauritius: {
    label: 'Mauritius',
    iso3: 'MUS',
    population: '1,271,767',
    casesPastWeek: 602,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 168566,
    completeVaccinations: 955207,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico: {
    label: 'Mexico',
    iso3: 'MEX',
    population: '127,792,286',
    casesPastWeek: 19775,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Mexico_Aguascalientes',
      'Mexico_Baja_California',
      'Mexico_Baja_California_Sur',
      'Mexico_Campeche',
      'Mexico_Chiapas',
      'Mexico_Chihuahua',
      'Mexico_Ciudad_de_Mexico',
      'Mexico_Coahuila',
      'Mexico_Colima',
      'Mexico_Durango',
      'Mexico_Guanajuato',
      'Mexico_Guerrero',
      'Mexico_Hidalgo',
      'Mexico_Jalisco',
      'Mexico_Mexico',
      'Mexico_Michoacan',
      'Mexico_Morelos',
      'Mexico_Nayarit',
      'Mexico_Nuevo_Leon',
      'Mexico_Oaxaca',
      'Mexico_Puebla',
      'Mexico_Queretaro',
      'Mexico_Quintana_Roo',
      'Mexico_San_Luis_Potosi',
      'Mexico_Sinaloa',
      'Mexico_Sonora',
      'Mexico_Tabasco',
      'Mexico_Tamaulipas',
      'Mexico_Tlaxcala',
      'Mexico_Veracruz',
      'Mexico_Yucatan',
      'Mexico_Zacatecas',
    ],
    incompleteVaccinations: 14860650,
    completeVaccinations: 84210351,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Aguascalientes: {
    label: 'Aguascalientes',
    iso3: null,
    population: '1,434,635',
    casesPastWeek: 91,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Baja_California: {
    label: 'Baja California',
    iso3: null,
    population: '3,634,868',
    casesPastWeek: 572,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Baja_California_Sur: {
    label: 'Baja California Sur',
    iso3: null,
    population: '804,708',
    casesPastWeek: 348,
    casesIncreasingPercentage: 12.987012987012992,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Campeche: {
    label: 'Campeche',
    iso3: null,
    population: '1,000,617',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Chiapas: {
    label: 'Chiapas',
    iso3: null,
    population: '5,730,367',
    casesPastWeek: 193,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Chihuahua: {
    label: 'Chihuahua',
    iso3: null,
    population: '3,801,487',
    casesPastWeek: 184,
    casesIncreasingPercentage: 1.098901098901095,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Ciudad_de_Mexico: {
    label: 'Ciudad de Mexico',
    iso3: null,
    population: '9,018,645',
    casesPastWeek: 6201,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Coahuila: {
    label: 'Coahuila',
    iso3: null,
    population: '3,218,720',
    casesPastWeek: 418,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Colima: {
    label: 'Colima',
    iso3: null,
    population: '785,153',
    casesPastWeek: 263,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Durango: {
    label: 'Durango',
    iso3: null,
    population: '1,868,996',
    casesPastWeek: 77,
    casesIncreasingPercentage: 8.450704225352123,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Guanajuato: {
    label: 'Guanajuato',
    iso3: null,
    population: '6,228,175',
    casesPastWeek: 938,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Guerrero: {
    label: 'Guerrero',
    iso3: null,
    population: '3,657,048',
    casesPastWeek: 121,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Hidalgo: {
    label: 'Hidalgo',
    iso3: null,
    population: '3,086,414',
    casesPastWeek: 531,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Jalisco: {
    label: 'Jalisco',
    iso3: null,
    population: '8,409,693',
    casesPastWeek: 422,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Mexico: {
    label: 'Mexico',
    iso3: null,
    population: '17,427,790',
    casesPastWeek: 2727,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Michoacan: {
    label: 'Michoacan',
    iso3: null,
    population: '4,825,401',
    casesPastWeek: 601,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Morelos: {
    label: 'Morelos',
    iso3: null,
    population: '2,044,058',
    casesPastWeek: 310,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Nayarit: {
    label: 'Nayarit',
    iso3: null,
    population: '1,288,571',
    casesPastWeek: 203,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Nuevo_Leon: {
    label: 'Nuevo Leon',
    iso3: null,
    population: '5,610,153',
    casesPastWeek: 1769,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Oaxaca: {
    label: 'Oaxaca',
    iso3: null,
    population: '4,143,593',
    casesPastWeek: 352,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Puebla: {
    label: 'Puebla',
    iso3: null,
    population: '6,604,451',
    casesPastWeek: 798,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Queretaro: {
    label: 'Queretaro',
    iso3: null,
    population: '2,279,637',
    casesPastWeek: 374,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Quintana_Roo: {
    label: 'Quintana Roo',
    iso3: null,
    population: '1,723,259',
    casesPastWeek: 96,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_San_Luis_Potosi: {
    label: 'San Luis Potosi',
    iso3: null,
    population: '2,866,142',
    casesPastWeek: 429,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Sinaloa: {
    label: 'Sinaloa',
    iso3: null,
    population: '3,156,674',
    casesPastWeek: 302,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Sonora: {
    label: 'Sonora',
    iso3: null,
    population: '3,074,745',
    casesPastWeek: 233,
    casesIncreasingPercentage: 0.4310344827586299,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Tabasco: {
    label: 'Tabasco',
    iso3: null,
    population: '2,572,287',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Tamaulipas: {
    label: 'Tamaulipas',
    iso3: null,
    population: '3,650,602',
    casesPastWeek: 149,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Tlaxcala: {
    label: 'Tlaxcala',
    iso3: null,
    population: '1,380,011',
    casesPastWeek: 337,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Veracruz: {
    label: 'Veracruz',
    iso3: null,
    population: '8,539,862',
    casesPastWeek: 519,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Yucatan: {
    label: 'Yucatan',
    iso3: null,
    population: '2,259,098',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Mexico_Zacatecas: {
    label: 'Zacatecas',
    iso3: null,
    population: '1,666,426',
    casesPastWeek: 126,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 48.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Micronesia: {
    label: 'Micronesia',
    iso3: 'FSM',
    population: '113,815',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 12851,
    completeVaccinations: 72822,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Moldova: {
    label: 'Moldova',
    iso3: 'MDA',
    population: '4,027,690',
    casesPastWeek: 3690,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.07,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 166006,
    completeVaccinations: 940699,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Monaco: {
    label: 'Monaco',
    iso3: 'MCO',
    population: '39,244',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 4331,
    completeVaccinations: 24544,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Mongolia: {
    label: 'Mongolia',
    iso3: 'MNG',
    population: '3,278,292',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 23.53,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 342586,
    completeVaccinations: 1941320,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Montenegro: {
    label: 'Montenegro',
    iso3: 'MNE',
    population: '628,062',
    casesPastWeek: 391,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 43917,
    completeVaccinations: 248866,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Morocco: {
    label: 'Morocco',
    iso3: 'MAR',
    population: '36,910,558',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.07,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3752872,
    completeVaccinations: 21266274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Mozambique: {
    label: 'Mozambique',
    iso3: 'MOZ',
    population: '31,255,435',
    casesPastWeek: 116,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.89,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2796223,
    completeVaccinations: 15845262,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Namibia: {
    label: 'Namibia',
    iso3: 'NAM',
    population: '2,540,916',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.79,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 91454,
    completeVaccinations: 518241,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  Nauru: {
    label: 'Nauru',
    iso3: 'NRU',
    population: '10,834',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1812,
    completeVaccinations: 10265,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Nepal: {
    label: 'Nepal',
    iso3: 'NPL',
    population: '29,136,808',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.7000000000000001,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 4157724,
    completeVaccinations: 23560434,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Netherlands: {
    label: 'Netherlands',
    iso3: 'NLD',
    population: '17,134,873',
    casesPastWeek: 3824,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Netherlands_Drenthe',
      'Netherlands_Flevoland',
      'Netherlands_Friesland',
      'Netherlands_Gelderland',
      'Netherlands_Groningen',
      'Netherlands_Limburg',
      'Netherlands_Noord_Brabant',
      'Netherlands_Noord_Holland',
      'Netherlands_Overijssel',
      'Netherlands_Utrecht',
      'Netherlands_Zeeland',
      'Netherlands_Zuid_Holland',
      'Netherlands_Aruba',
      'Netherlands_Curacao',
      'Netherlands_Sint_Maarten',
      'Netherlands_Bonaire__Sint_Eustatius_and_Saba',
    ],
    incompleteVaccinations: 1916530,
    completeVaccinations: 10860338,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Drenthe: {
    label: 'Drenthe',
    iso3: null,
    population: '493,682',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Flevoland: {
    label: 'Flevoland',
    iso3: null,
    population: '423,021',
    casesPastWeek: 64,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Friesland: {
    label: 'Friesland',
    iso3: null,
    population: '649,957',
    casesPastWeek: 87,
    casesIncreasingPercentage: 61.111111111111114,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Gelderland: {
    label: 'Gelderland',
    iso3: null,
    population: '2,085,952',
    casesPastWeek: 704,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Groningen: {
    label: 'Groningen',
    iso3: null,
    population: '585,866',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Limburg: {
    label: 'Limburg',
    iso3: null,
    population: '1,117,201',
    casesPastWeek: 390,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Noord_Brabant: {
    label: 'Noord-Brabant',
    iso3: null,
    population: '2,562,955',
    casesPastWeek: 763,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Noord_Holland: {
    label: 'Noord-Holland',
    iso3: null,
    population: '2,879,527',
    casesPastWeek: 512,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Overijssel: {
    label: 'Overijssel',
    iso3: null,
    population: '1,162,406',
    casesPastWeek: 243,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Utrecht: {
    label: 'Utrecht',
    iso3: null,
    population: '1,354,834',
    casesPastWeek: 246,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Zeeland: {
    label: 'Zeeland',
    iso3: null,
    population: '383,488',
    casesPastWeek: 92,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Zuid_Holland: {
    label: 'Zuid-Holland',
    iso3: null,
    population: '3,708,696',
    casesPastWeek: 587,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Netherlands_Aruba: {
    label: 'Aruba',
    iso3: null,
    population: '106,766',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Netherlands_Curacao: {
    label: 'Curacao',
    iso3: null,
    population: '164,100',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Netherlands_Sint_Maarten: {
    label: 'Sint Maarten',
    iso3: null,
    population: '42,882',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Netherlands_Bonaire__Sint_Eustatius_and_Saba: {
    label: 'Bonaire, Sint Eustatius and Saba',
    iso3: null,
    population: '26,221',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 68.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  New_Zealand: {
    label: 'New Zealand',
    iso3: 'NZL',
    population: '4,822,233',
    casesPastWeek: 11439,
    casesIncreasingPercentage: 26.021813374462923,
    positiveCasePercentage: 52.026197298403595,
    topLevelGroup: 'Countries',
    subdivisions: ['New_Zealand_Cook_Islands', 'New_Zealand_Niue'],
    incompleteVaccinations: 645161,
    completeVaccinations: 3655915,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 05, 2023',
  },
  New_Zealand_Cook_Islands: {
    label: 'Cook Islands',
    iso3: null,
    population: '17,459',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  New_Zealand_Niue: {
    label: 'Niue',
    iso3: null,
    population: '1,650',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Nicaragua: {
    label: 'Nicaragua',
    iso3: 'NIC',
    population: '6,624,554',
    casesPastWeek: 17,
    casesIncreasingPercentage: 54.54545454545454,
    positiveCasePercentage: 1.7399999999999998,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 934843,
    completeVaccinations: 5297445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Niger: {
    label: 'Niger',
    iso3: 'NER',
    population: '24,206,636',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.15,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 918671,
    completeVaccinations: 5205803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Nigeria: {
    label: 'Nigeria',
    iso3: 'NGA',
    population: '206,139,587',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.49,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 11887252,
    completeVaccinations: 67361095,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  North_Macedonia: {
    label: 'North Macedonia',
    iso3: 'MKD',
    population: '2,083,380',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.430000000000001,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 128172,
    completeVaccinations: 726307,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  Norway: {
    label: 'Norway',
    iso3: 'NOR',
    population: '5,421,242',
    casesPastWeek: 366,
    casesIncreasingPercentage: 14.375000000000004,
    positiveCasePercentage: 32.2,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 652049,
    completeVaccinations: 3694946,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Oman: {
    label: 'Oman',
    iso3: 'OMN',
    population: '5,106,622',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.6,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 488605,
    completeVaccinations: 2768760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Pakistan: {
    label: 'Pakistan',
    iso3: 'PAK',
    population: '220,892,331',
    casesPastWeek: 308,
    casesIncreasingPercentage: 4.761904761904767,
    positiveCasePercentage: 1.66,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Pakistan_Azad_Jammu_and_Kashmir',
      'Pakistan_Balochistan',
      'Pakistan_Gilgit_Baltistan',
      'Pakistan_Islamabad',
      'Pakistan_Khyber_Pakhtunkhwa',
      'Pakistan_Punjab',
      'Pakistan_Sindh',
    ],
    incompleteVaccinations: 24332958,
    completeVaccinations: 137886759,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Pakistan_Azad_Jammu_and_Kashmir: {
    label: 'Azad Jammu and Kashmir',
    iso3: null,
    population: '4,045,366',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.66,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  Pakistan_Balochistan: {
    label: 'Balochistan',
    iso3: null,
    population: '12,344,408',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.66,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Pakistan_Gilgit_Baltistan: {
    label: 'Gilgit-Baltistan',
    iso3: null,
    population: '1,013,584',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.66,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Pakistan_Islamabad: {
    label: 'Islamabad',
    iso3: null,
    population: '2,006,572',
    casesPastWeek: 46,
    casesIncreasingPercentage: 27.777777777777768,
    positiveCasePercentage: 1.66,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Pakistan_Khyber_Pakhtunkhwa: {
    label: 'Khyber Pakhtunkhwa',
    iso3: null,
    population: '30,523,371',
    casesPastWeek: 23,
    casesIncreasingPercentage: 129.99999999999997,
    positiveCasePercentage: 1.66,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Pakistan_Punjab: {
    label: 'Punjab',
    iso3: null,
    population: '110,012,442',
    casesPastWeek: 187,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.66,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Pakistan_Sindh: {
    label: 'Sindh',
    iso3: null,
    population: '47,886,051',
    casesPastWeek: 52,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.66,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Palau: {
    label: 'Palau',
    iso3: 'PLW',
    population: '18,008',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 3.45,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3116,
    completeVaccinations: 17656,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Panama: {
    label: 'Panama',
    iso3: 'PAN',
    population: '4,314,768',
    casesPastWeek: 458,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.35,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 528979,
    completeVaccinations: 2997550,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Papua_New_Guinea: {
    label: 'Papua New Guinea',
    iso3: 'PNG',
    population: '8,947,027',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 56374,
    completeVaccinations: 319455,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Paraguay: {
    label: 'Paraguay',
    iso3: 'PRY',
    population: '7,132,530',
    casesPastWeek: 74,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.54,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 598117,
    completeVaccinations: 3389331,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Peru: {
    label: 'Peru',
    iso3: 'PER',
    population: '32,971,846',
    casesPastWeek: 2784,
    casesIncreasingPercentage: 84.00528750826173,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Peru_Amazonas',
      'Peru_Ancash',
      'Peru_Apurimac',
      'Peru_Arequipa',
      'Peru_Ayacucho',
      'Peru_Cajamarca',
      'Peru_Callao',
      'Peru_Cusco',
      'Peru_Huancavelica',
      'Peru_Huanuco',
      'Peru_Ica',
      'Peru_Junin',
      'Peru_La_Libertad',
      'Peru_Lambayeque',
      'Peru_Lima',
      'Peru_Loreto',
      'Peru_Madre_de_Dios',
      'Peru_Moquegua',
      'Peru_Pasco',
      'Peru_Piura',
      'Peru_Puno',
      'Peru_San_Martin',
      'Peru_Tacna',
      'Peru_Tumbes',
      'Peru_Ucayali',
    ],
    incompleteVaccinations: 4551958,
    completeVaccinations: 25794426,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Peru_Amazonas: {
    label: 'Amazonas',
    iso3: null,
    population: '426,800',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Ancash: {
    label: 'Ancash',
    iso3: null,
    population: '1,180,600',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Apurimac: {
    label: 'Apurimac',
    iso3: null,
    population: '430,700',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Arequipa: {
    label: 'Arequipa',
    iso3: null,
    population: '1,497,400',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Ayacucho: {
    label: 'Ayacucho',
    iso3: null,
    population: '668,200',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Cajamarca: {
    label: 'Cajamarca',
    iso3: null,
    population: '1,453,700',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Callao: {
    label: 'Callao',
    iso3: null,
    population: '1,129,900',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Cusco: {
    label: 'Cusco',
    iso3: null,
    population: '1,357,100',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Huancavelica: {
    label: 'Huancavelica',
    iso3: null,
    population: '365,300',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Huanuco: {
    label: 'Huanuco',
    iso3: null,
    population: '760,300',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Ica: {
    label: 'Ica',
    iso3: null,
    population: '975,200',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Junin: {
    label: 'Junin',
    iso3: null,
    population: '1,361,500',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_La_Libertad: {
    label: 'La Libertad',
    iso3: null,
    population: '2,016,800',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Lambayeque: {
    label: 'Lambayeque',
    iso3: null,
    population: '1,310,800',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Lima: {
    label: 'Lima',
    iso3: null,
    population: '10,628,500',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Loreto: {
    label: 'Loreto',
    iso3: null,
    population: '1,027,600',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Madre_de_Dios: {
    label: 'Madre de Dios',
    iso3: null,
    population: '173,800',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Moquegua: {
    label: 'Moquegua',
    iso3: null,
    population: '192,700',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Pasco: {
    label: 'Pasco',
    iso3: null,
    population: '271,900',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Piura: {
    label: 'Piura',
    iso3: null,
    population: '2,048,000',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Puno: {
    label: 'Puno',
    iso3: null,
    population: '1,238,000',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_San_Martin: {
    label: 'San Martin',
    iso3: null,
    population: '899,600',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Tacna: {
    label: 'Tacna',
    iso3: null,
    population: '371,000',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Tumbes: {
    label: 'Tumbes',
    iso3: null,
    population: '251,500',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Peru_Ucayali: {
    label: 'Ucayali',
    iso3: null,
    population: '589,100',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Philippines: {
    label: 'Philippines',
    iso3: 'PHL',
    population: '109,581,085',
    casesPastWeek: 884,
    casesIncreasingPercentage: 33.73676248108926,
    positiveCasePercentage: 0.9299999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 11770899,
    completeVaccinations: 66701760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Poland: {
    label: 'Poland',
    iso3: 'POL',
    population: '37,846,605',
    casesPastWeek: 19034,
    casesIncreasingPercentage: 21.483278018892005,
    positiveCasePercentage: 5.91,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3442340,
    completeVaccinations: 19506590,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Portugal: {
    label: 'Portugal',
    iso3: 'PRT',
    population: '10,196,707',
    casesPastWeek: 2389,
    casesIncreasingPercentage: 73.61918604651163,
    positiveCasePercentage: 50.629999999999995,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1467109,
    completeVaccinations: 8313618,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Qatar: {
    label: 'Qatar',
    iso3: 'QAT',
    population: '2,881,060',
    casesPastWeek: 711,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 0.7092481570520813,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 427766,
    completeVaccinations: 2424010,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Romania: {
    label: 'Romania',
    iso3: 'ROU',
    population: '19,237,682',
    casesPastWeek: 5704,
    casesIncreasingPercentage: 9.460756092880441,
    positiveCasePercentage: 5.65,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1227982,
    completeVaccinations: 6958564,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  Russia: {
    label: 'Russia',
    iso3: 'RUS',
    population: '145,934,460',
    casesPastWeek: 89375,
    casesIncreasingPercentage: 9.903960846521809,
    positiveCasePercentage: 2.83,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Russia_Adygea_Republic',
      'Russia_Altai_Krai',
      'Russia_Altai_Republic',
      'Russia_Amur_Oblast',
      'Russia_Arkhangelsk_Oblast',
      'Russia_Astrakhan_Oblast',
      'Russia_Bashkortostan_Republic',
      'Russia_Belgorod_Oblast',
      'Russia_Bryansk_Oblast',
      'Russia_Buryatia_Republic',
      'Russia_Chechen_Republic',
      'Russia_Chelyabinsk_Oblast',
      'Russia_Chukotka_Autonomous_Okrug',
      'Russia_Chuvashia_Republic',
      'Russia_Dagestan_Republic',
      'Russia_Ingushetia_Republic',
      'Russia_Irkutsk_Oblast',
      'Russia_Ivanovo_Oblast',
      'Russia_Jewish_Autonomous_Okrug',
      'Russia_Kabardino_Balkarian_Republic',
      'Russia_Kaliningrad_Oblast',
      'Russia_Kalmykia_Republic',
      'Russia_Kaluga_Oblast',
      'Russia_Kamchatka_Krai',
      'Russia_Karachay_Cherkess_Republic',
      'Russia_Karelia_Republic',
      'Russia_Kemerovo_Oblast',
      'Russia_Khabarovsk_Krai',
      'Russia_Khakassia_Republic',
      'Russia_Khanty_Mansi_Autonomous_Okrug',
      'Russia_Kirov_Oblast',
      'Russia_Komi_Republic',
      'Russia_Kostroma_Oblast',
      'Russia_Krasnodar_Krai',
      'Russia_Krasnoyarsk_Krai',
      'Russia_Kurgan_Oblast',
      'Russia_Kursk_Oblast',
      'Russia_Leningrad_Oblast',
      'Russia_Lipetsk_Oblast',
      'Russia_Magadan_Oblast',
      'Russia_Mari_El_Republic',
      'Russia_Mordovia_Republic',
      'Russia_Moscow',
      'Russia_Moscow_Oblast',
      'Russia_Murmansk_Oblast',
      'Russia_Nenets_Autonomous_Okrug',
      'Russia_Nizhny_Novgorod_Oblast',
      'Russia_North_Ossetia___Alania_Republic',
      'Russia_Novgorod_Oblast',
      'Russia_Novosibirsk_Oblast',
      'Russia_Omsk_Oblast',
      'Russia_Orel_Oblast',
      'Russia_Orenburg_Oblast',
      'Russia_Penza_Oblast',
      'Russia_Perm_Krai',
      'Russia_Primorsky_Krai',
      'Russia_Pskov_Oblast',
      'Russia_Rostov_Oblast',
      'Russia_Ryazan_Oblast',
      'Russia_Saint_Petersburg',
      'Russia_Sakha__Yakutiya__Republic',
      'Russia_Sakhalin_Oblast',
      'Russia_Samara_Oblast',
      'Russia_Saratov_Oblast',
      'Russia_Smolensk_Oblast',
      'Russia_Stavropol_Krai',
      'Russia_Sverdlovsk_Oblast',
      'Russia_Tambov_Oblast',
      'Russia_Tatarstan_Republic',
      'Russia_Tomsk_Oblast',
      'Russia_Tula_Oblast',
      'Russia_Tver_Oblast',
      'Russia_Tyumen_Oblast',
      'Russia_Tyva_Republic',
      'Russia_Udmurt_Republic',
      'Russia_Ulyanovsk_Oblast',
      'Russia_Vladimir_Oblast',
      'Russia_Volgograd_Oblast',
      'Russia_Vologda_Oblast',
      'Russia_Voronezh_Oblast',
      'Russia_Yamalo_Nenets_Autonomous_Okrug',
      'Russia_Yaroslavl_Oblast',
      'Russia_Zabaykalsky_Krai',
    ],
    incompleteVaccinations: 13261174,
    completeVaccinations: 75146652,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Adygea_Republic: {
    label: 'Adygea Republic',
    iso3: null,
    population: '453,376',
    casesPastWeek: 92,
    casesIncreasingPercentage: 48.38709677419355,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Altai_Krai: {
    label: 'Altai Krai',
    iso3: null,
    population: '2,350,080',
    casesPastWeek: 719,
    casesIncreasingPercentage: 6.20384047267355,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Altai_Republic: {
    label: 'Altai Republic',
    iso3: null,
    population: '218,063',
    casesPastWeek: 40,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Amur_Oblast: {
    label: 'Amur Oblast',
    iso3: null,
    population: '798,424',
    casesPastWeek: 380,
    casesIncreasingPercentage: 9.5100864553314,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Arkhangelsk_Oblast: {
    label: 'Arkhangelsk Oblast',
    iso3: null,
    population: '1,155,028',
    casesPastWeek: 1013,
    casesIncreasingPercentage: 38.57729138166894,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Astrakhan_Oblast: {
    label: 'Astrakhan Oblast',
    iso3: null,
    population: '1,017,514',
    casesPastWeek: 240,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Bashkortostan_Republic: {
    label: 'Bashkortostan Republic',
    iso3: null,
    population: '4,063,293',
    casesPastWeek: 1489,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Belgorod_Oblast: {
    label: 'Belgorod Oblast',
    iso3: null,
    population: '1,549,876',
    casesPastWeek: 731,
    casesIncreasingPercentage: 1.2465373961218829,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Bryansk_Oblast: {
    label: 'Bryansk Oblast',
    iso3: null,
    population: '1,210,982',
    casesPastWeek: 666,
    casesIncreasingPercentage: 26.857142857142847,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Buryatia_Republic: {
    label: 'Buryatia Republic',
    iso3: null,
    population: '984,511',
    casesPastWeek: 143,
    casesIncreasingPercentage: 6.7164179104477695,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Chechen_Republic: {
    label: 'Chechen Republic',
    iso3: null,
    population: '1,436,981',
    casesPastWeek: 13,
    casesIncreasingPercentage: 62.5,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Chelyabinsk_Oblast: {
    label: 'Chelyabinsk Oblast',
    iso3: null,
    population: '3,493,036',
    casesPastWeek: 1581,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Chukotka_Autonomous_Okrug: {
    label: 'Chukotka Autonomous Okrug',
    iso3: null,
    population: '49,348',
    casesPastWeek: 46,
    casesIncreasingPercentage: 35.29411764705883,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Chuvashia_Republic: {
    label: 'Chuvashia Republic',
    iso3: null,
    population: '1,231,117',
    casesPastWeek: 381,
    casesIncreasingPercentage: 32.29166666666667,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Dagestan_Republic: {
    label: 'Dagestan Republic',
    iso3: null,
    population: '3,063,885',
    casesPastWeek: 118,
    casesIncreasingPercentage: 38.8235294117647,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Ingushetia_Republic: {
    label: 'Ingushetia Republic',
    iso3: null,
    population: '488,043',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Irkutsk_Oblast: {
    label: 'Irkutsk Oblast',
    iso3: null,
    population: '2,404,195',
    casesPastWeek: 1005,
    casesIncreasingPercentage: 28.681177976952622,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Ivanovo_Oblast: {
    label: 'Ivanovo Oblast',
    iso3: null,
    population: '1,014,646',
    casesPastWeek: 604,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Jewish_Autonomous_Okrug: {
    label: 'Jewish Autonomous Okrug',
    iso3: null,
    population: '162,014',
    casesPastWeek: 46,
    casesIncreasingPercentage: 76.92307692307692,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kabardino_Balkarian_Republic: {
    label: 'Kabardino-Balkarian Republic',
    iso3: null,
    population: '865,828',
    casesPastWeek: 194,
    casesIncreasingPercentage: 23.566878980891715,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kaliningrad_Oblast: {
    label: 'Kaliningrad Oblast',
    iso3: null,
    population: '994,599',
    casesPastWeek: 1123,
    casesIncreasingPercentage: 6.749049429657794,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kalmykia_Republic: {
    label: 'Kalmykia Republic',
    iso3: null,
    population: '275,413',
    casesPastWeek: 82,
    casesIncreasingPercentage: 5.128205128205132,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kaluga_Oblast: {
    label: 'Kaluga Oblast',
    iso3: null,
    population: '1,012,056',
    casesPastWeek: 566,
    casesIncreasingPercentage: 31.62790697674418,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kamchatka_Krai: {
    label: 'Kamchatka Krai',
    iso3: null,
    population: '315,557',
    casesPastWeek: 251,
    casesIncreasingPercentage: 4.583333333333339,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Karachay_Cherkess_Republic: {
    label: 'Karachay-Cherkess Republic',
    iso3: null,
    population: '466,305',
    casesPastWeek: 171,
    casesIncreasingPercentage: 62.857142857142854,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Karelia_Republic: {
    label: 'Karelia Republic',
    iso3: null,
    population: '622,484',
    casesPastWeek: 1018,
    casesIncreasingPercentage: 102.38568588469184,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kemerovo_Oblast: {
    label: 'Kemerovo Oblast',
    iso3: null,
    population: '2,694,877',
    casesPastWeek: 1093,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Khabarovsk_Krai: {
    label: 'Khabarovsk Krai',
    iso3: null,
    population: '1,328,302',
    casesPastWeek: 402,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Khakassia_Republic: {
    label: 'Khakassia Republic',
    iso3: null,
    population: '537,513',
    casesPastWeek: 242,
    casesIncreasingPercentage: 63.51351351351351,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Khanty_Mansi_Autonomous_Okrug: {
    label: 'Khanty-Mansi Autonomous Okrug',
    iso3: null,
    population: '1,532,243',
    casesPastWeek: 1149,
    casesIncreasingPercentage: 2.7728085867620766,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kirov_Oblast: {
    label: 'Kirov Oblast',
    iso3: null,
    population: '1,283,238',
    casesPastWeek: 258,
    casesIncreasingPercentage: 23.444976076555022,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Komi_Republic: {
    label: 'Komi Republic',
    iso3: null,
    population: '840,873',
    casesPastWeek: 647,
    casesIncreasingPercentage: 31.77189409368635,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kostroma_Oblast: {
    label: 'Kostroma Oblast',
    iso3: null,
    population: '643,324',
    casesPastWeek: 167,
    casesIncreasingPercentage: 24.626865671641784,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Krasnodar_Krai: {
    label: 'Krasnodar Krai',
    iso3: null,
    population: '5,603,420',
    casesPastWeek: 1696,
    casesIncreasingPercentage: 41.333333333333336,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Krasnoyarsk_Krai: {
    label: 'Krasnoyarsk Krai',
    iso3: null,
    population: '2,876,497',
    casesPastWeek: 843,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kurgan_Oblast: {
    label: 'Kurgan Oblast',
    iso3: null,
    population: '845,537',
    casesPastWeek: 452,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Kursk_Oblast: {
    label: 'Kursk Oblast',
    iso3: null,
    population: '1,115,237',
    casesPastWeek: 567,
    casesIncreasingPercentage: 38.29268292682926,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Leningrad_Oblast: {
    label: 'Leningrad Oblast',
    iso3: null,
    population: '1,813,816',
    casesPastWeek: 1542,
    casesIncreasingPercentage: 58.47893114080165,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Lipetsk_Oblast: {
    label: 'Lipetsk Oblast',
    iso3: null,
    population: '1,150,201',
    casesPastWeek: 749,
    casesIncreasingPercentage: 21.197411003236244,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Magadan_Oblast: {
    label: 'Magadan Oblast',
    iso3: null,
    population: '144,091',
    casesPastWeek: 93,
    casesIncreasingPercentage: 9.411764705882364,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Mari_El_Republic: {
    label: 'Mari El Republic',
    iso3: null,
    population: '682,333',
    casesPastWeek: 101,
    casesIncreasingPercentage: 8.602150537634401,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Mordovia_Republic: {
    label: 'Mordovia Republic',
    iso3: null,
    population: '805,056',
    casesPastWeek: 559,
    casesIncreasingPercentage: 2.946593001841613,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Moscow: {
    label: 'Moscow',
    iso3: null,
    population: '12,506,468',
    casesPastWeek: 15074,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Moscow_Oblast: {
    label: 'Moscow Oblast',
    iso3: null,
    population: '7,503,385',
    casesPastWeek: 1901,
    casesIncreasingPercentage: 27.583892617449667,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Murmansk_Oblast: {
    label: 'Murmansk Oblast',
    iso3: null,
    population: '753,557',
    casesPastWeek: 337,
    casesIncreasingPercentage: 10.130718954248374,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Nenets_Autonomous_Okrug: {
    label: 'Nenets Autonomous Okrug',
    iso3: null,
    population: '43,997',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Nizhny_Novgorod_Oblast: {
    label: 'Nizhny Novgorod Oblast',
    iso3: null,
    population: '3,234,752',
    casesPastWeek: 2311,
    casesIncreasingPercentage: 35.4630715123095,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_North_Ossetia___Alania_Republic: {
    label: 'North Ossetia - Alania Republic',
    iso3: null,
    population: '701,765',
    casesPastWeek: 90,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Novgorod_Oblast: {
    label: 'Novgorod Oblast',
    iso3: null,
    population: '606,476',
    casesPastWeek: 353,
    casesIncreasingPercentage: 16.11842105263157,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Novosibirsk_Oblast: {
    label: 'Novosibirsk Oblast',
    iso3: null,
    population: '2,788,849',
    casesPastWeek: 745,
    casesIncreasingPercentage: 2.900552486187835,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Omsk_Oblast: {
    label: 'Omsk Oblast',
    iso3: null,
    population: '1,960,081',
    casesPastWeek: 1227,
    casesIncreasingPercentage: 4.692832764505117,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Orel_Oblast: {
    label: 'Orel Oblast',
    iso3: null,
    population: '747,247',
    casesPastWeek: 486,
    casesIncreasingPercentage: 51.87500000000001,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Orenburg_Oblast: {
    label: 'Orenburg Oblast',
    iso3: null,
    population: '1,977,720',
    casesPastWeek: 751,
    casesIncreasingPercentage: 63.616557734204804,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Penza_Oblast: {
    label: 'Penza Oblast',
    iso3: null,
    population: '1,331,655',
    casesPastWeek: 873,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Perm_Krai: {
    label: 'Perm Krai',
    iso3: null,
    population: '2,623,122',
    casesPastWeek: 1225,
    casesIncreasingPercentage: 24.23935091277891,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Primorsky_Krai: {
    label: 'Primorsky Krai',
    iso3: null,
    population: '1,913,037',
    casesPastWeek: 635,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Pskov_Oblast: {
    label: 'Pskov Oblast',
    iso3: null,
    population: '636,546',
    casesPastWeek: 335,
    casesIncreasingPercentage: 61.057692307692314,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Rostov_Oblast: {
    label: 'Rostov Oblast',
    iso3: null,
    population: '4,220,452',
    casesPastWeek: 1939,
    casesIncreasingPercentage: 57.89902280130293,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Ryazan_Oblast: {
    label: 'Ryazan Oblast',
    iso3: null,
    population: '1,121,474',
    casesPastWeek: 655,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Saint_Petersburg: {
    label: 'Saint Petersburg',
    iso3: null,
    population: '5,351,935',
    casesPastWeek: 9542,
    casesIncreasingPercentage: 16.52216387837342,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Sakha__Yakutiya__Republic: {
    label: 'Sakha (Yakutiya) Republic',
    iso3: null,
    population: '964,330',
    casesPastWeek: 572,
    casesIncreasingPercentage: 62.962962962962955,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Sakhalin_Oblast: {
    label: 'Sakhalin Oblast',
    iso3: null,
    population: '490,181',
    casesPastWeek: 110,
    casesIncreasingPercentage: 139.1304347826087,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Samara_Oblast: {
    label: 'Samara Oblast',
    iso3: null,
    population: '3,193,514',
    casesPastWeek: 4139,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Saratov_Oblast: {
    label: 'Saratov Oblast',
    iso3: null,
    population: '2,462,950',
    casesPastWeek: 1904,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Smolensk_Oblast: {
    label: 'Smolensk Oblast',
    iso3: null,
    population: '949,348',
    casesPastWeek: 237,
    casesIncreasingPercentage: 38.596491228070185,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Stavropol_Krai: {
    label: 'Stavropol Krai',
    iso3: null,
    population: '2,800,674',
    casesPastWeek: 1693,
    casesIncreasingPercentage: 39.112571898110104,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Sverdlovsk_Oblast: {
    label: 'Sverdlovsk Oblast',
    iso3: null,
    population: '4,325,256',
    casesPastWeek: 2508,
    casesIncreasingPercentage: 24.466501240694782,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Tambov_Oblast: {
    label: 'Tambov Oblast',
    iso3: null,
    population: '1,033,552',
    casesPastWeek: 833,
    casesIncreasingPercentage: 42.881646655231556,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Tatarstan_Republic: {
    label: 'Tatarstan Republic',
    iso3: null,
    population: '3,894,284',
    casesPastWeek: 1140,
    casesIncreasingPercentage: 10.144927536231885,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Tomsk_Oblast: {
    label: 'Tomsk Oblast',
    iso3: null,
    population: '1,078,280',
    casesPastWeek: 1044,
    casesIncreasingPercentage: 30.663329161451824,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Tula_Oblast: {
    label: 'Tula Oblast',
    iso3: null,
    population: '1,491,855',
    casesPastWeek: 544,
    casesIncreasingPercentage: 30.455635491606724,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Tver_Oblast: {
    label: 'Tver Oblast',
    iso3: null,
    population: '1,283,873',
    casesPastWeek: 1327,
    casesIncreasingPercentage: 3.4294621979735096,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Tyumen_Oblast: {
    label: 'Tyumen Oblast',
    iso3: null,
    population: '3,692,400',
    casesPastWeek: 2370,
    casesIncreasingPercentage: 10.541044776119413,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Tyva_Republic: {
    label: 'Tyva Republic',
    iso3: null,
    population: '321,722',
    casesPastWeek: 182,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Udmurt_Republic: {
    label: 'Udmurt Republic',
    iso3: null,
    population: '1,513,044',
    casesPastWeek: 876,
    casesIncreasingPercentage: 15.873015873015884,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Ulyanovsk_Oblast: {
    label: 'Ulyanovsk Oblast',
    iso3: null,
    population: '1,246,618',
    casesPastWeek: 371,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Vladimir_Oblast: {
    label: 'Vladimir Oblast',
    iso3: null,
    population: '1,378,337',
    casesPastWeek: 567,
    casesIncreasingPercentage: 6.578947368421062,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Volgograd_Oblast: {
    label: 'Volgograd Oblast',
    iso3: null,
    population: '2,521,276',
    casesPastWeek: 958,
    casesIncreasingPercentage: 24.577373211963582,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Vologda_Oblast: {
    label: 'Vologda Oblast',
    iso3: null,
    population: '1,176,689',
    casesPastWeek: 1405,
    casesIncreasingPercentage: 31.924882629107977,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Voronezh_Oblast: {
    label: 'Voronezh Oblast',
    iso3: null,
    population: '2,333,768',
    casesPastWeek: 3012,
    casesIncreasingPercentage: 21.256038647343,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Yamalo_Nenets_Autonomous_Okrug: {
    label: 'Yamalo-Nenets Autonomous Okrug',
    iso3: null,
    population: '538,547',
    casesPastWeek: 431,
    casesIncreasingPercentage: 11.36950904392764,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Yaroslavl_Oblast: {
    label: 'Yaroslavl Oblast',
    iso3: null,
    population: '1,265,684',
    casesPastWeek: 795,
    casesIncreasingPercentage: 3.112840466926059,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Russia_Zabaykalsky_Krai: {
    label: 'Zabaykalsky Krai',
    iso3: null,
    population: '1,072,806',
    casesPastWeek: 583,
    casesIncreasingPercentage: 64.68926553672316,
    positiveCasePercentage: 2.83,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Rwanda: {
    label: 'Rwanda',
    iso3: 'RWA',
    population: '12,952,209',
    casesPastWeek: 14,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 0.77,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1585947,
    completeVaccinations: 8987034,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Saint_Kitts_and_Nevis: {
    label: 'Saint Kitts and Nevis',
    iso3: 'KNA',
    population: '53,192',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.2,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 5069,
    completeVaccinations: 28725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Saint_Lucia: {
    label: 'Saint Lucia',
    iso3: 'LCA',
    population: '183,629',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.879999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 9021,
    completeVaccinations: 51119,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Saint_Vincent_and_the_Grenadines: {
    label: 'Saint Vincent and the Grenadines',
    iso3: 'VCT',
    population: '110,947',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.57,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 5625,
    completeVaccinations: 31877,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Samoa: {
    label: 'Samoa',
    iso3: 'WSM',
    population: '196,130',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 28706,
    completeVaccinations: 162665,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  San_Marino: {
    label: 'San Marino',
    iso3: 'SMR',
    population: '33,938',
    casesPastWeek: 33,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3954,
    completeVaccinations: 22403,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Sao_Tome_and_Principe: {
    label: 'Sao Tome and Principe',
    iso3: 'STP',
    population: '219,161',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.91,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 19135,
    completeVaccinations: 108429,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Saudi_Arabia: {
    label: 'Saudi Arabia',
    iso3: 'SAU',
    population: '34,813,867',
    casesPastWeek: 490,
    casesIncreasingPercentage: 6.753812636165568,
    positiveCasePercentage: 3.47,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 4051314,
    completeVaccinations: 22957447,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Senegal: {
    label: 'Senegal',
    iso3: 'SEN',
    population: '16,743,930',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 0.33999999999999997,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 402704,
    completeVaccinations: 2281992,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Serbia: {
    label: 'Serbia',
    iso3: 'SRB',
    population: '8,737,370',
    casesPastWeek: 6706,
    casesIncreasingPercentage: 44.900605012964554,
    positiveCasePercentage: 5.94,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 509508,
    completeVaccinations: 2887212,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Seychelles: {
    label: 'Seychelles',
    iso3: 'SYC',
    population: '98,340',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 12874,
    completeVaccinations: 72951,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Sierra_Leone: {
    label: 'Sierra Leone',
    iso3: 'SLE',
    population: '7,976,985',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.11,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 705443,
    completeVaccinations: 3997509,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Singapore: {
    label: 'Singapore',
    iso3: 'SGP',
    population: '5,850,343',
    casesPastWeek: 4426,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.27,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 774083,
    completeVaccinations: 4386468,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Slovakia: {
    label: 'Slovakia',
    iso3: 'SVK',
    population: '5,434,712',
    casesPastWeek: 1741,
    casesIncreasingPercentage: 27.82672540381792,
    positiveCasePercentage: 13.3,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 425874,
    completeVaccinations: 2413286,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Slovenia: {
    label: 'Slovenia',
    iso3: 'SVN',
    population: '2,078,932',
    casesPastWeek: 2523,
    casesIncreasingPercentage: 9.505208333333325,
    positiveCasePercentage: 12.8,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 189870,
    completeVaccinations: 1075932,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Solomon_Islands: {
    label: 'Solomon Islands',
    iso3: 'SLB',
    population: '652,858',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 51573,
    completeVaccinations: 292248,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Somalia: {
    label: 'Somalia',
    iso3: 'SOM',
    population: '15,893,219',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.14,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1159476,
    completeVaccinations: 6570366,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  South_Africa: {
    label: 'South Africa',
    iso3: 'ZAF',
    population: '59,308,690',
    casesPastWeek: 2778,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.26,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3595634,
    completeVaccinations: 20375257,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  South_Korea: {
    label: 'South Korea',
    iso3: 'KOR',
    population: '51,269,183',
    casesPastWeek: 71614,
    casesIncreasingPercentage: 12.122872665215834,
    positiveCasePercentage: 6.87,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 6733278,
    completeVaccinations: 38155244,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  South_Sudan: {
    label: 'South Sudan',
    iso3: 'SSD',
    population: '11,193,729',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.31,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 468488,
    completeVaccinations: 2654767,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Spain: {
    label: 'Spain',
    iso3: 'ESP',
    population: '46,754,783',
    casesPastWeek: 7093,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Spain_Andalusia',
      'Spain_Aragon',
      'Spain_Asturias',
      'Spain_Baleares',
      'Spain_Canarias',
      'Spain_Cantabria',
      'Spain_Castilla___La_Mancha',
      'Spain_Castilla_y_Leon',
      'Spain_Catalonia',
      'Spain_Ceuta',
      'Spain_C__Valenciana',
      'Spain_Extremadura',
      'Spain_Galicia',
      'Spain_Madrid',
      'Spain_Melilla',
      'Spain_Murcia',
      'Spain_Navarra',
      'Spain_Pais_Vasco',
      'Spain_La_Rioja',
    ],
    incompleteVaccinations: 6201509,
    completeVaccinations: 35141887,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Andalusia: {
    label: 'Andalusia',
    iso3: null,
    population: '8,427,405',
    casesPastWeek: 779,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Aragon: {
    label: 'Aragon',
    iso3: null,
    population: '1,320,586',
    casesPastWeek: 133,
    casesIncreasingPercentage: 60.24096385542168,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Asturias: {
    label: 'Asturias',
    iso3: null,
    population: '1,022,205',
    casesPastWeek: 177,
    casesIncreasingPercentage: 27.338129496402885,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Baleares: {
    label: 'Baleares',
    iso3: null,
    population: '1,188,220',
    casesPastWeek: 116,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Canarias: {
    label: 'Canarias',
    iso3: null,
    population: '2,206,901',
    casesPastWeek: 373,
    casesIncreasingPercentage: 34.65703971119134,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Cantabria: {
    label: 'Cantabria',
    iso3: null,
    population: '581,641',
    casesPastWeek: 45,
    casesIncreasingPercentage: 15.384615384615374,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Castilla___La_Mancha: {
    label: 'Castilla - La Mancha',
    iso3: null,
    population: '2,034,877',
    casesPastWeek: 569,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Castilla_y_Leon: {
    label: 'Castilla y Leon',
    iso3: null,
    population: '2,407,733',
    casesPastWeek: 812,
    casesIncreasingPercentage: 26.677067082683315,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Catalonia: {
    label: 'Catalonia',
    iso3: null,
    population: '7,566,431',
    casesPastWeek: 584,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Ceuta: {
    label: 'Ceuta',
    iso3: null,
    population: '84,829',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_C__Valenciana: {
    label: 'C. Valenciana',
    iso3: null,
    population: '4,974,969',
    casesPastWeek: 583,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Extremadura: {
    label: 'Extremadura',
    iso3: null,
    population: '1,065,424',
    casesPastWeek: 124,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Galicia: {
    label: 'Galicia',
    iso3: null,
    population: '2,700,441',
    casesPastWeek: 821,
    casesIncreasingPercentage: 52.319109461966605,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Madrid: {
    label: 'Madrid',
    iso3: null,
    population: '6,641,649',
    casesPastWeek: 1537,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Melilla: {
    label: 'Melilla',
    iso3: null,
    population: '84,689',
    casesPastWeek: 7,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Murcia: {
    label: 'Murcia',
    iso3: null,
    population: '1,487,663',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Navarra: {
    label: 'Navarra',
    iso3: null,
    population: '649,946',
    casesPastWeek: 76,
    casesIncreasingPercentage: 35.71428571428572,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_Pais_Vasco: {
    label: 'Pais Vasco',
    iso3: null,
    population: '2,177,880',
    casesPastWeek: 271,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Spain_La_Rioja: {
    label: 'La Rioja',
    iso3: null,
    population: '313,571',
    casesPastWeek: 13,
    casesIncreasingPercentage: 30.000000000000004,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  Sri_Lanka: {
    label: 'Sri Lanka',
    iso3: 'LKA',
    population: '21,413,250',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.83,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2571564,
    completeVaccinations: 14572197,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Sudan: {
    label: 'Sudan',
    iso3: 'SDN',
    population: '43,849,269',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1894284,
    completeVaccinations: 10734277,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Suriname: {
    label: 'Suriname',
    iso3: 'SUR',
    population: '586,634',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 49.1,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 40173,
    completeVaccinations: 227647,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  Sweden: {
    label: 'Sweden',
    iso3: 'SWE',
    population: '10,099,270',
    casesPastWeek: 708,
    casesIncreasingPercentage: 5.988023952095811,
    positiveCasePercentage: 11.37,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Sweden_Blekinge',
      'Sweden_Dalarna',
      'Sweden_Gavleborg',
      'Sweden_Gotland',
      'Sweden_Halland',
      'Sweden_Jamtland_Harjedalen',
      'Sweden_Jonkoping',
      'Sweden_Kalmar',
      'Sweden_Kronoberg',
      'Sweden_Norrbotten',
      'Sweden_Orebro',
      'Sweden_Ostergotland',
      'Sweden_Skane',
      'Sweden_Sormland',
      'Sweden_Stockholm',
      'Sweden_Uppsala',
      'Sweden_Varmland',
      'Sweden_Vasterbotten',
      'Sweden_Vasternorrland',
      'Sweden_Vastmanland',
      'Sweden_Vastra_Gotaland',
    ],
    incompleteVaccinations: 1172839,
    completeVaccinations: 6646086,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Blekinge: {
    label: 'Blekinge',
    iso3: null,
    population: '159,606',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Dalarna: {
    label: 'Dalarna',
    iso3: null,
    population: '287,966',
    casesPastWeek: 14,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Gavleborg: {
    label: 'Gavleborg',
    iso3: null,
    population: '287,382',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Gotland: {
    label: 'Gotland',
    iso3: null,
    population: '59,686',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  Sweden_Halland: {
    label: 'Halland',
    iso3: null,
    population: '333,848',
    casesPastWeek: 57,
    casesIncreasingPercentage: 29.54545454545454,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Jamtland_Harjedalen: {
    label: 'Jamtland Harjedalen',
    iso3: null,
    population: '130,810',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Jonkoping: {
    label: 'Jonkoping',
    iso3: null,
    population: '363,599',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Kalmar: {
    label: 'Kalmar',
    iso3: null,
    population: '245,446',
    casesPastWeek: 28,
    casesIncreasingPercentage: 27.27272727272727,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Kronoberg: {
    label: 'Kronoberg',
    iso3: null,
    population: '201,469',
    casesPastWeek: 26,
    casesIncreasingPercentage: 23.809523809523814,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Norrbotten: {
    label: 'Norrbotten',
    iso3: null,
    population: '250,093',
    casesPastWeek: 21,
    casesIncreasingPercentage: 110.00000000000001,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Orebro: {
    label: 'Orebro',
    iso3: null,
    population: '304,805',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Ostergotland: {
    label: 'Ostergotland',
    iso3: null,
    population: '465,495',
    casesPastWeek: 44,
    casesIncreasingPercentage: 18.918918918918926,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Skane: {
    label: 'Skane',
    iso3: null,
    population: '1,377,827',
    casesPastWeek: 88,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Sormland: {
    label: 'Sormland',
    iso3: null,
    population: '297,540',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Stockholm: {
    label: 'Stockholm',
    iso3: null,
    population: '2,377,081',
    casesPastWeek: 169,
    casesIncreasingPercentage: 36.29032258064515,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Uppsala: {
    label: 'Uppsala',
    iso3: null,
    population: '383,713',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Varmland: {
    label: 'Varmland',
    iso3: null,
    population: '282,414',
    casesPastWeek: 13,
    casesIncreasingPercentage: 18.181818181818187,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Vasterbotten: {
    label: 'Vasterbotten',
    iso3: null,
    population: '271,736',
    casesPastWeek: 23,
    casesIncreasingPercentage: 21.052631578947366,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Vasternorrland: {
    label: 'Vasternorrland',
    iso3: null,
    population: '245,347',
    casesPastWeek: 13,
    casesIncreasingPercentage: 30.000000000000004,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Vastmanland: {
    label: 'Vastmanland',
    iso3: null,
    population: '275,845',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Sweden_Vastra_Gotaland: {
    label: 'Vastra Gotaland',
    iso3: null,
    population: '1,725,881',
    casesPastWeek: 91,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.37,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  Switzerland: {
    label: 'Switzerland',
    iso3: 'CHE',
    population: '8,654,618',
    casesPastWeek: 1472,
    casesIncreasingPercentage: 6.666666666666665,
    positiveCasePercentage: 40.699999999999996,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 914442,
    completeVaccinations: 5181835,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Syria: {
    label: 'Syria',
    iso3: 'SYR',
    population: '17,500,657',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.52,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 481470,
    completeVaccinations: 2728327,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  Taiwan_: {
    label: 'Taiwan*',
    iso3: 'TWN',
    population: '23,816,775',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 78.38000000000001,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3280795,
    completeVaccinations: 18591173,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Tajikistan: {
    label: 'Tajikistan',
    iso3: 'TJK',
    population: '9,537,642',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 796795,
    completeVaccinations: 4515172,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Tanzania: {
    label: 'Tanzania',
    iso3: 'TZA',
    population: '59,734,213',
    casesPastWeek: 60,
    casesIncreasingPercentage: 160.8695652173913,
    positiveCasePercentage: 1.6099999999999999,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 5085465,
    completeVaccinations: 28817633,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Thailand: {
    label: 'Thailand',
    iso3: 'THA',
    population: '69,799,978',
    casesPastWeek: 147,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.46,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 8638199,
    completeVaccinations: 48949792,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 05, 2023',
  },
  Timor_Leste: {
    label: 'Timor-Leste',
    iso3: 'TLS',
    population: '1,318,442',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.38999999999999996,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 132453,
    completeVaccinations: 750565,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Togo: {
    label: 'Togo',
    iso3: 'TGO',
    population: '8,278,737',
    casesPastWeek: 19,
    casesIncreasingPercentage: 89.99999999999999,
    positiveCasePercentage: 2.35,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 336668,
    completeVaccinations: 1907788,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Tonga: {
    label: 'Tonga',
    iso3: 'TON',
    population: '105,697',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 13092,
    completeVaccinations: 74186,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Trinidad_and_Tobago: {
    label: 'Trinidad and Tobago',
    iso3: 'TTO',
    population: '1,399,491',
    casesPastWeek: 622,
    casesIncreasingPercentage: 11.270125223613592,
    positiveCasePercentage: 22.2,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 113160,
    completeVaccinations: 641239,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Tunisia: {
    label: 'Tunisia',
    iso3: 'TUN',
    population: '11,818,618',
    casesPastWeek: 164,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.58,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1082694,
    completeVaccinations: 6135264,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Turkey: {
    label: 'Turkey',
    iso3: 'TUR',
    population: '84,339,067',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.77,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 8691158,
    completeVaccinations: 49249893,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Tuvalu: {
    label: 'Tuvalu',
    iso3: 'TUV',
    population: '11,792',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1440,
    completeVaccinations: 8163,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US: {
    label: 'United States (all)',
    iso3: 'USA',
    population: '329,466,283',
    casesPastWeek: 221286,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 40433117,
    completeVaccinations: 229120999,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72001: {
    label: 'Adjuntas',
    iso3: null,
    population: '17,363',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 690,
    completeVaccinations: 13588,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72003: {
    label: 'Aguada',
    iso3: null,
    population: '36,694',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2173,
    completeVaccinations: 29276,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72005: {
    label: 'Aguadilla',
    iso3: null,
    population: '50,265',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3324,
    completeVaccinations: 42349,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72007: {
    label: 'Aguas Buenas',
    iso3: null,
    population: '24,814',
    casesPastWeek: 22,
    casesIncreasingPercentage: 144.44444444444446,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1030,
    completeVaccinations: 19312,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72009: {
    label: 'Aibonito',
    iso3: null,
    population: '22,108',
    casesPastWeek: 20,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 57.099999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 845,
    completeVaccinations: 20633,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72011: {
    label: 'Anasco',
    iso3: null,
    population: '26,161',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1228,
    completeVaccinations: 20201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72013: {
    label: 'Arecibo',
    iso3: null,
    population: '81,966',
    casesPastWeek: 61,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72015: {
    label: 'Arroyo',
    iso3: null,
    population: '17,238',
    casesPastWeek: 18,
    casesIncreasingPercentage: 5.882352941176472,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 861,
    completeVaccinations: 13403,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72017: {
    label: 'Barceloneta',
    iso3: null,
    population: '23,727',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1113,
    completeVaccinations: 18785,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72019: {
    label: 'Barranquitas',
    iso3: null,
    population: '27,725',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1126,
    completeVaccinations: 23052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72021: {
    label: 'Bayamon',
    iso3: null,
    population: '169,269',
    casesPastWeek: 112,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10100,
    completeVaccinations: 158481,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72023: {
    label: 'Cabo Rojo',
    iso3: null,
    population: '47,515',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1951,
    completeVaccinations: 35666,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72025: {
    label: 'Caguas',
    iso3: null,
    population: '124,606',
    casesPastWeek: 111,
    casesIncreasingPercentage: 46.05263157894737,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7090,
    completeVaccinations: 105693,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72027: {
    label: 'Camuy',
    iso3: null,
    population: '30,504',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1423,
    completeVaccinations: 24426,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72029: {
    label: 'Canovanas',
    iso3: null,
    population: '44,674',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2411,
    completeVaccinations: 36160,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72031: {
    label: 'Carolina',
    iso3: null,
    population: '146,984',
    casesPastWeek: 95,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10674,
    completeVaccinations: 122311,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72033: {
    label: 'Catano',
    iso3: null,
    population: '23,121',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1392,
    completeVaccinations: 17488,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72035: {
    label: 'Cayey',
    iso3: null,
    population: '42,409',
    casesPastWeek: 27,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2023,
    completeVaccinations: 36648,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72037: {
    label: 'Ceiba',
    iso3: null,
    population: '10,904',
    casesPastWeek: 11,
    casesIncreasingPercentage: 266.66666666666663,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 401,
    completeVaccinations: 8851,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72039: {
    label: 'Ciales',
    iso3: null,
    population: '15,808',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 29.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 901,
    completeVaccinations: 13961,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72041: {
    label: 'Cidra',
    iso3: null,
    population: '38,307',
    casesPastWeek: 43,
    casesIncreasingPercentage: 186.66666666666666,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1666,
    completeVaccinations: 30958,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72043: {
    label: 'Coamo',
    iso3: null,
    population: '38,336',
    casesPastWeek: 24,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1899,
    completeVaccinations: 28320,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72045: {
    label: 'Comerio',
    iso3: null,
    population: '18,648',
    casesPastWeek: 26,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1051,
    completeVaccinations: 14037,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72047: {
    label: 'Corozal',
    iso3: null,
    population: '32,293',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1732,
    completeVaccinations: 27079,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72049: {
    label: 'Culebra',
    iso3: null,
    population: '1,714',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_72051: {
    label: 'Dorado',
    iso3: null,
    population: '36,141',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3918,
    completeVaccinations: 30778,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72053: {
    label: 'Fajardo',
    iso3: null,
    population: '29,454',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1853,
    completeVaccinations: 24760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72054: {
    label: 'Florida',
    iso3: null,
    population: '11,317',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 593,
    completeVaccinations: 10370,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72055: {
    label: 'Guanica',
    iso3: null,
    population: '15,383',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 895,
    completeVaccinations: 11644,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72057: {
    label: 'Guayama',
    iso3: null,
    population: '39,465',
    casesPastWeek: 34,
    casesIncreasingPercentage: 54.54545454545454,
    positiveCasePercentage: 26.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2019,
    completeVaccinations: 29635,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72059: {
    label: 'Guayanilla',
    iso3: null,
    population: '17,623',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 973,
    completeVaccinations: 14785,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72061: {
    label: 'Guaynabo',
    iso3: null,
    population: '83,728',
    casesPastWeek: 64,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7143,
    completeVaccinations: 77264,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72063: {
    label: 'Gurabo',
    iso3: null,
    population: '47,093',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1891,
    completeVaccinations: 31642,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72065: {
    label: 'Hatillo',
    iso3: null,
    population: '39,218',
    casesPastWeek: 29,
    casesIncreasingPercentage: 20.833333333333325,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1252,
    completeVaccinations: 27880,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72067: {
    label: 'Hormigueros',
    iso3: null,
    population: '15,518',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 622,
    completeVaccinations: 12742,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_72069: {
    label: 'Humacao',
    iso3: null,
    population: '50,653',
    casesPastWeek: 48,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2917,
    completeVaccinations: 41999,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72071: {
    label: 'Isabela',
    iso3: null,
    population: '40,423',
    casesPastWeek: 30,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2074,
    completeVaccinations: 31458,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72073: {
    label: 'Jayuya',
    iso3: null,
    population: '13,891',
    casesPastWeek: 18,
    casesIncreasingPercentage: 28.57142857142858,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 597,
    completeVaccinations: 11734,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72075: {
    label: 'Juana Diaz',
    iso3: null,
    population: '44,679',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 36.199999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2014,
    completeVaccinations: 34842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72077: {
    label: 'Juncos',
    iso3: null,
    population: '38,155',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1564,
    completeVaccinations: 28954,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72079: {
    label: 'Lajas',
    iso3: null,
    population: '22,010',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 936,
    completeVaccinations: 17643,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72081: {
    label: 'Lares',
    iso3: null,
    population: '24,276',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1798,
    completeVaccinations: 23367,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72083: {
    label: 'Las Marias',
    iso3: null,
    population: '7,927',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 343,
    completeVaccinations: 6974,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_72085: {
    label: 'Las Piedras',
    iso3: null,
    population: '37,007',
    casesPastWeek: 41,
    casesIncreasingPercentage: 70.83333333333333,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1531,
    completeVaccinations: 27751,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72087: {
    label: 'Loiza',
    iso3: null,
    population: '24,553',
    casesPastWeek: 13,
    casesIncreasingPercentage: 18.181818181818187,
    positiveCasePercentage: 20.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1550,
    completeVaccinations: 15068,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_72089: {
    label: 'Luquillo',
    iso3: null,
    population: '17,665',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1151,
    completeVaccinations: 12862,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72091: {
    label: 'Manati',
    iso3: null,
    population: '37,287',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1781,
    completeVaccinations: 31673,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72093: {
    label: 'Maricao',
    iso3: null,
    population: '5,430',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 206,
    completeVaccinations: 3931,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_72095: {
    label: 'Maunabo',
    iso3: null,
    population: '10,321',
    casesPastWeek: 8,
    casesIncreasingPercentage: 166.66666666666666,
    positiveCasePercentage: 27.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 573,
    completeVaccinations: 8167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72097: {
    label: 'Mayaguez',
    iso3: null,
    population: '71,530',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3446,
    completeVaccinations: 59674,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72099: {
    label: 'Moca',
    iso3: null,
    population: '34,891',
    casesPastWeek: 40,
    casesIncreasingPercentage: 17.647058823529417,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1685,
    completeVaccinations: 27856,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72101: {
    label: 'Morovis',
    iso3: null,
    population: '30,335',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1529,
    completeVaccinations: 23173,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72103: {
    label: 'Naguabo',
    iso3: null,
    population: '25,761',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1176,
    completeVaccinations: 16933,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72105: {
    label: 'Naranjito',
    iso3: null,
    population: '27,349',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1445,
    completeVaccinations: 23423,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72107: {
    label: 'Orocovis',
    iso3: null,
    population: '20,220',
    casesPastWeek: 10,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1013,
    completeVaccinations: 17626,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72109: {
    label: 'Patillas',
    iso3: null,
    population: '16,211',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1008,
    completeVaccinations: 13565,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72111: {
    label: 'Penuelas',
    iso3: null,
    population: '19,249',
    casesPastWeek: 14,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1093,
    completeVaccinations: 16809,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_72113: {
    label: 'Ponce',
    iso3: null,
    population: '131,881',
    casesPastWeek: 78,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8350,
    completeVaccinations: 119180,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72115: {
    label: 'Quebradillas',
    iso3: null,
    population: '22,918',
    casesPastWeek: 19,
    casesIncreasingPercentage: 5.555555555555558,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1211,
    completeVaccinations: 19581,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72117: {
    label: 'Rincon',
    iso3: null,
    population: '13,656',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 737,
    completeVaccinations: 10789,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72119: {
    label: 'Rio Grande',
    iso3: null,
    population: '48,025',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2785,
    completeVaccinations: 35987,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72121: {
    label: 'Sabana Grande',
    iso3: null,
    population: '21,712',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1016,
    completeVaccinations: 18440,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72123: {
    label: 'Salinas',
    iso3: null,
    population: '27,128',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1292,
    completeVaccinations: 21160,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72125: {
    label: 'San German',
    iso3: null,
    population: '30,227',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1168,
    completeVaccinations: 24033,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72127: {
    label: 'San Juan',
    iso3: null,
    population: '318,441',
    casesPastWeek: 160,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 41366,
    completeVaccinations: 276574,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72129: {
    label: 'San Lorenzo',
    iso3: null,
    population: '35,989',
    casesPastWeek: 34,
    casesIncreasingPercentage: 9.677419354838701,
    positiveCasePercentage: 16.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1597,
    completeVaccinations: 29018,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72131: {
    label: 'San Sebastian',
    iso3: null,
    population: '35,528',
    casesPastWeek: 50,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1662,
    completeVaccinations: 31460,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72133: {
    label: 'Santa Isabel',
    iso3: null,
    population: '21,209',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1009,
    completeVaccinations: 16069,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72135: {
    label: 'Toa Alta',
    iso3: null,
    population: '72,025',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3468,
    completeVaccinations: 50640,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72137: {
    label: 'Toa Baja',
    iso3: null,
    population: '74,271',
    casesPastWeek: 66,
    casesIncreasingPercentage: 22.222222222222232,
    positiveCasePercentage: 14.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4522,
    completeVaccinations: 58614,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72139: {
    label: 'Trujillo Alto',
    iso3: null,
    population: '63,674',
    casesPastWeek: 50,
    casesIncreasingPercentage: 8.695652173913038,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3636,
    completeVaccinations: 45530,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72141: {
    label: 'Utuado',
    iso3: null,
    population: '27,395',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1172,
    completeVaccinations: 21147,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72143: {
    label: 'Vega Alta',
    iso3: null,
    population: '36,061',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 29.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2337,
    completeVaccinations: 25789,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72145: {
    label: 'Vega Baja',
    iso3: null,
    population: '50,023',
    casesPastWeek: 50,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3359,
    completeVaccinations: 42941,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72147: {
    label: 'Vieques',
    iso3: null,
    population: '8,386',
    casesPastWeek: 4,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 427,
    completeVaccinations: 6499,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72149: {
    label: 'Villalba',
    iso3: null,
    population: '21,372',
    casesPastWeek: 28,
    casesIncreasingPercentage: 86.66666666666667,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1102,
    completeVaccinations: 19655,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72151: {
    label: 'Yabucoa',
    iso3: null,
    population: '32,282',
    casesPastWeek: 29,
    casesIncreasingPercentage: 52.63157894736843,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1820,
    completeVaccinations: 24890,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_72153: {
    label: 'Yauco',
    iso3: null,
    population: '33,575',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1872,
    completeVaccinations: 29675,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01001: {
    label: 'Autauga',
    iso3: null,
    population: '55,869',
    casesPastWeek: 31,
    casesIncreasingPercentage: 14.814814814814813,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6664,
    completeVaccinations: 25705,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01003: {
    label: 'Baldwin',
    iso3: null,
    population: '223,234',
    casesPastWeek: 93,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 30213,
    completeVaccinations: 119068,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01005: {
    label: 'Barbour',
    iso3: null,
    population: '24,686',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2548,
    completeVaccinations: 11692,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01007: {
    label: 'Bibb',
    iso3: null,
    population: '22,394',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1650,
    completeVaccinations: 8162,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01009: {
    label: 'Blount',
    iso3: null,
    population: '57,826',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3897,
    completeVaccinations: 18993,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01011: {
    label: 'Bullock',
    iso3: null,
    population: '10,101',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1551,
    completeVaccinations: 5729,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01013: {
    label: 'Butler',
    iso3: null,
    population: '19,448',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2018,
    completeVaccinations: 7954,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01015: {
    label: 'Calhoun',
    iso3: null,
    population: '113,605',
    casesPastWeek: 83,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11533,
    completeVaccinations: 55544,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01017: {
    label: 'Chambers',
    iso3: null,
    population: '33,254',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2081,
    completeVaccinations: 11012,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01019: {
    label: 'Cherokee',
    iso3: null,
    population: '26,196',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1814,
    completeVaccinations: 8478,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01021: {
    label: 'Chilton',
    iso3: null,
    population: '44,428',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3563,
    completeVaccinations: 16692,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01023: {
    label: 'Choctaw',
    iso3: null,
    population: '12,589',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2063,
    completeVaccinations: 8631,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01025: {
    label: 'Clarke',
    iso3: null,
    population: '23,622',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2813,
    completeVaccinations: 12377,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01027: {
    label: 'Clay',
    iso3: null,
    population: '13,235',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1152,
    completeVaccinations: 5772,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01029: {
    label: 'Cleburne',
    iso3: null,
    population: '14,910',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 865,
    completeVaccinations: 4411,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01031: {
    label: 'Coffee',
    iso3: null,
    population: '52,342',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6768,
    completeVaccinations: 25593,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01033: {
    label: 'Colbert',
    iso3: null,
    population: '55,241',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5087,
    completeVaccinations: 27353,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01035: {
    label: 'Conecuh',
    iso3: null,
    population: '12,067',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1592,
    completeVaccinations: 5465,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01037: {
    label: 'Coosa',
    iso3: null,
    population: '10,663',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 874,
    completeVaccinations: 4475,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01039: {
    label: 'Covington',
    iso3: null,
    population: '37,049',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3149,
    completeVaccinations: 14497,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01041: {
    label: 'Crenshaw',
    iso3: null,
    population: '13,772',
    casesPastWeek: 5,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1035,
    completeVaccinations: 4913,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01043: {
    label: 'Cullman',
    iso3: null,
    population: '83,768',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5957,
    completeVaccinations: 32902,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01045: {
    label: 'Dale',
    iso3: null,
    population: '49,172',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4919,
    completeVaccinations: 24364,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01047: {
    label: 'Dallas',
    iso3: null,
    population: '37,196',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5101,
    completeVaccinations: 17575,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01049: {
    label: 'DeKalb',
    iso3: null,
    population: '71,513',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5695,
    completeVaccinations: 25172,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01051: {
    label: 'Elmore',
    iso3: null,
    population: '81,209',
    casesPastWeek: 47,
    casesIncreasingPercentage: 4.444444444444451,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9022,
    completeVaccinations: 39252,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01053: {
    label: 'Escambia',
    iso3: null,
    population: '36,633',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3737,
    completeVaccinations: 13912,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01055: {
    label: 'Etowah',
    iso3: null,
    population: '102,268',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8576,
    completeVaccinations: 43402,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01057: {
    label: 'Fayette',
    iso3: null,
    population: '16,302',
    casesPastWeek: 11,
    casesIncreasingPercentage: 120.00000000000001,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 963,
    completeVaccinations: 5901,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01059: {
    label: 'Franklin',
    iso3: null,
    population: '31,362',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3132,
    completeVaccinations: 14210,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01061: {
    label: 'Geneva',
    iso3: null,
    population: '26,271',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2257,
    completeVaccinations: 10852,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01063: {
    label: 'Greene',
    iso3: null,
    population: '8,111',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1053,
    completeVaccinations: 4290,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01065: {
    label: 'Hale',
    iso3: null,
    population: '14,651',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1669,
    completeVaccinations: 9262,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01067: {
    label: 'Henry',
    iso3: null,
    population: '17,205',
    casesPastWeek: 15,
    casesIncreasingPercentage: 7.14285714285714,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1599,
    completeVaccinations: 8026,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01069: {
    label: 'Houston',
    iso3: null,
    population: '105,882',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9028,
    completeVaccinations: 47354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01071: {
    label: 'Jackson',
    iso3: null,
    population: '51,626',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5635,
    completeVaccinations: 26431,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01073: {
    label: 'Jefferson',
    iso3: null,
    population: '658,573',
    casesPastWeek: 407,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 91024,
    completeVaccinations: 403477,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01075: {
    label: 'Lamar',
    iso3: null,
    population: '13,805',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1009,
    completeVaccinations: 6280,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01077: {
    label: 'Lauderdale',
    iso3: null,
    population: '92,729',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10209,
    completeVaccinations: 44904,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01079: {
    label: 'Lawrence',
    iso3: null,
    population: '32,924',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2714,
    completeVaccinations: 15878,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01081: {
    label: 'Lee',
    iso3: null,
    population: '164,542',
    casesPastWeek: 70,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13430,
    completeVaccinations: 69288,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01083: {
    label: 'Limestone',
    iso3: null,
    population: '98,915',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7895,
    completeVaccinations: 39896,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01085: {
    label: 'Lowndes',
    iso3: null,
    population: '9,726',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 1.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1375,
    completeVaccinations: 5204,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01087: {
    label: 'Macon',
    iso3: null,
    population: '18,068',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2246,
    completeVaccinations: 9158,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01089: {
    label: 'Madison',
    iso3: null,
    population: '372,909',
    casesPastWeek: 169,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 54665,
    completeVaccinations: 244274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01091: {
    label: 'Marengo',
    iso3: null,
    population: '18,863',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2467,
    completeVaccinations: 10538,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01093: {
    label: 'Marion',
    iso3: null,
    population: '29,709',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1861,
    completeVaccinations: 11144,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01095: {
    label: 'Marshall',
    iso3: null,
    population: '96,774',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9721,
    completeVaccinations: 45884,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01097: {
    label: 'Mobile',
    iso3: null,
    population: '413,210',
    casesPastWeek: 179,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 45596,
    completeVaccinations: 215307,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01099: {
    label: 'Monroe',
    iso3: null,
    population: '20,733',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2357,
    completeVaccinations: 9328,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01101: {
    label: 'Montgomery',
    iso3: null,
    population: '226,486',
    casesPastWeek: 122,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31770,
    completeVaccinations: 124283,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01103: {
    label: 'Morgan',
    iso3: null,
    population: '119,679',
    casesPastWeek: 82,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9517,
    completeVaccinations: 53842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01105: {
    label: 'Perry',
    iso3: null,
    population: '8,923',
    casesPastWeek: 10,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 1.4000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 887,
    completeVaccinations: 4488,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01107: {
    label: 'Pickens',
    iso3: null,
    population: '19,930',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2366,
    completeVaccinations: 10500,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01109: {
    label: 'Pike',
    iso3: null,
    population: '33,114',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3120,
    completeVaccinations: 14518,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01111: {
    label: 'Randolph',
    iso3: null,
    population: '22,722',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.8000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1693,
    completeVaccinations: 7791,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01113: {
    label: 'Russell',
    iso3: null,
    population: '57,961',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8206,
    completeVaccinations: 25746,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01115: {
    label: 'St. Clair',
    iso3: null,
    population: '89,512',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6820,
    completeVaccinations: 36233,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01117: {
    label: 'Shelby',
    iso3: null,
    population: '217,702',
    casesPastWeek: 107,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19005,
    completeVaccinations: 82205,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01119: {
    label: 'Sumter',
    iso3: null,
    population: '12,427',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2104,
    completeVaccinations: 6748,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01121: {
    label: 'Talladega',
    iso3: null,
    population: '79,978',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6358,
    completeVaccinations: 30224,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01123: {
    label: 'Tallapoosa',
    iso3: null,
    population: '40,367',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2931,
    completeVaccinations: 16054,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01125: {
    label: 'Tuscaloosa',
    iso3: null,
    population: '209,355',
    casesPastWeek: 147,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19656,
    completeVaccinations: 95130,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01127: {
    label: 'Walker',
    iso3: null,
    population: '63,521',
    casesPastWeek: 54,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5832,
    completeVaccinations: 31030,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01129: {
    label: 'Washington',
    iso3: null,
    population: '16,326',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 1.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1643,
    completeVaccinations: 7703,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01131: {
    label: 'Wilcox',
    iso3: null,
    population: '10,373',
    casesPastWeek: 9,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1416,
    completeVaccinations: 5750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_01133: {
    label: 'Winston',
    iso3: null,
    population: '23,629',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1063,
    completeVaccinations: 5334,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_02013: {
    label: 'Aleutians East',
    iso3: null,
    population: '3,337',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 402,
    completeVaccinations: 2498,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02016: {
    label: 'Aleutians West',
    iso3: null,
    population: '5,634',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 776,
    completeVaccinations: 3619,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_02020: {
    label: 'Anchorage',
    iso3: null,
    population: '288,000',
    casesPastWeek: 138,
    casesIncreasingPercentage: 2.9850746268656803,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25381,
    completeVaccinations: 204553,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02050: {
    label: 'Bethel',
    iso3: null,
    population: '18,386',
    casesPastWeek: 93,
    casesIncreasingPercentage: 106.66666666666669,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 984,
    completeVaccinations: 12676,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02060: {
    label: 'Bristol Bay',
    iso3: null,
    population: '836',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_02066: {
    label: 'Copper River',
    iso3: null,
    population: '2,699',
    casesPastWeek: 10,
    casesIncreasingPercentage: 900.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02063: {
    label: 'Chugach',
    iso3: null,
    population: '6,751',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02068: {
    label: 'Denali',
    iso3: null,
    population: '2,097',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 214,
    completeVaccinations: 1167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_02070: {
    label: 'Dillingham',
    iso3: null,
    population: '4,916',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 295,
    completeVaccinations: 2631,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02090: {
    label: 'Fairbanks North Star',
    iso3: null,
    population: '96,849',
    casesPastWeek: 81,
    casesIncreasingPercentage: 52.830188679245296,
    positiveCasePercentage: 14.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7073,
    completeVaccinations: 62079,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02100: {
    label: 'Haines',
    iso3: null,
    population: '2,530',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 148,
    completeVaccinations: 1811,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_02110: {
    label: 'Juneau',
    iso3: null,
    population: '31,974',
    casesPastWeek: 29,
    casesIncreasingPercentage: 3.571428571428581,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2436,
    completeVaccinations: 25538,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02122: {
    label: 'Kenai Peninsula',
    iso3: null,
    population: '58,708',
    casesPastWeek: 40,
    casesIncreasingPercentage: 5.263157894736836,
    positiveCasePercentage: 14.499999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3343,
    completeVaccinations: 29441,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02130: {
    label: 'Ketchikan Gateway',
    iso3: null,
    population: '13,901',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 950,
    completeVaccinations: 9390,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02150: {
    label: 'Kodiak Island',
    iso3: null,
    population: '12,998',
    casesPastWeek: 16,
    casesIncreasingPercentage: 23.076923076923084,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 999,
    completeVaccinations: 8733,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02158: {
    label: 'Kusilvak',
    iso3: null,
    population: '8,314',
    casesPastWeek: 27,
    casesIncreasingPercentage: 107.69230769230771,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 456,
    completeVaccinations: 5744,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02164: {
    label: 'Bristol Bay plus Lake and Peninsula',
    iso3: null,
    population: '2,428',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 88,
    completeVaccinations: 970,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_02170: {
    label: 'Matanuska-Susitna',
    iso3: null,
    population: '108,317',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6162,
    completeVaccinations: 46043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02180: {
    label: 'Nome',
    iso3: null,
    population: '10,004',
    casesPastWeek: 11,
    casesIncreasingPercentage: 22.222222222222232,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 419,
    completeVaccinations: 7173,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02185: {
    label: 'North Slope',
    iso3: null,
    population: '9,832',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 323,
    completeVaccinations: 3964,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02188: {
    label: 'Northwest Arctic',
    iso3: null,
    population: '7,621',
    casesPastWeek: 28,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 22.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 375,
    completeVaccinations: 4808,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02195: {
    label: 'Petersburg',
    iso3: null,
    population: '3,266',
    casesPastWeek: 8,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 227,
    completeVaccinations: 2568,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02198: {
    label: 'Prince of Wales-Hyder',
    iso3: null,
    population: '6,203',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 56.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 342,
    completeVaccinations: 3390,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02220: {
    label: 'Sitka',
    iso3: null,
    population: '8,493',
    casesPastWeek: 8,
    casesIncreasingPercentage: 60.00000000000001,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 550,
    completeVaccinations: 6906,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02230: {
    label: 'Skagway',
    iso3: null,
    population: '1,183',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95,
    completeVaccinations: 882,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_02240: {
    label: 'Southeast Fairbanks',
    iso3: null,
    population: '6,893',
    casesPastWeek: 11,
    casesIncreasingPercentage: 266.66666666666663,
    positiveCasePercentage: 35.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 326,
    completeVaccinations: 2963,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02261: {
    label: 'Valdez-Cordova',
    iso3: null,
    population: '9,202',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 523,
    completeVaccinations: 5967,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_02275: {
    label: 'Wrangell',
    iso3: null,
    population: '2,502',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 79,
    completeVaccinations: 1519,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02282: {
    label: 'Yakutat',
    iso3: null,
    population: '604',
    casesPastWeek: 6,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20,
    completeVaccinations: 462,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_02290: {
    label: 'Yukon-Koyukuk',
    iso3: null,
    population: '5,230',
    casesPastWeek: 9,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 42.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 341,
    completeVaccinations: 4148,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_04001: {
    label: 'Apache',
    iso3: null,
    population: '71,887',
    casesPastWeek: 230,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16095,
    completeVaccinations: 79994,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04003: {
    label: 'Cochise',
    iso3: null,
    population: '125,922',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19469,
    completeVaccinations: 90022,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04005: {
    label: 'Coconino',
    iso3: null,
    population: '143,476',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18474,
    completeVaccinations: 119116,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04007: {
    label: 'Gila',
    iso3: null,
    population: '54,018',
    casesPastWeek: 30,
    casesIncreasingPercentage: 76.47058823529412,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4729,
    completeVaccinations: 33843,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04009: {
    label: 'Graham',
    iso3: null,
    population: '38,837',
    casesPastWeek: 13,
    casesIncreasingPercentage: 30.000000000000004,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3121,
    completeVaccinations: 26369,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04011: {
    label: 'Greenlee',
    iso3: null,
    population: '9,498',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 472,
    completeVaccinations: 4345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04012: {
    label: 'La Paz',
    iso3: null,
    population: '21,108',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2716,
    completeVaccinations: 10609,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04013: {
    label: 'Maricopa',
    iso3: null,
    population: '4,485,414',
    casesPastWeek: 1313,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 406194,
    completeVaccinations: 2762626,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04015: {
    label: 'Mohave',
    iso3: null,
    population: '212,181',
    casesPastWeek: 106,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17623,
    completeVaccinations: 95094,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04017: {
    label: 'Navajo',
    iso3: null,
    population: '110,924',
    casesPastWeek: 172,
    casesIncreasingPercentage: 14.666666666666671,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18752,
    completeVaccinations: 94817,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04019: {
    label: 'Pima',
    iso3: null,
    population: '1,047,279',
    casesPastWeek: 678,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 163390,
    completeVaccinations: 785935,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04021: {
    label: 'Pinal',
    iso3: null,
    population: '462,789',
    casesPastWeek: 271,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 37409,
    completeVaccinations: 268669,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04023: {
    label: 'Santa Cruz',
    iso3: null,
    population: '46,498',
    casesPastWeek: 31,
    casesIncreasingPercentage: 158.33333333333334,
    positiveCasePercentage: 28.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04025: {
    label: 'Yavapai',
    iso3: null,
    population: '235,099',
    casesPastWeek: 123,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18882,
    completeVaccinations: 121946,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_04027: {
    label: 'Yuma',
    iso3: null,
    population: '213,787',
    casesPastWeek: 131,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.200000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 78145,
    completeVaccinations: 163861,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05001: {
    label: 'Arkansas',
    iso3: null,
    population: '17,486',
    casesPastWeek: 9,
    casesIncreasingPercentage: 800.0,
    positiveCasePercentage: 4.1000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1742,
    completeVaccinations: 10215,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05003: {
    label: 'Ashley',
    iso3: null,
    population: '19,657',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1765,
    completeVaccinations: 10148,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  US_05005: {
    label: 'Baxter',
    iso3: null,
    population: '41,932',
    casesPastWeek: 28,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3747,
    completeVaccinations: 20091,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05007: {
    label: 'Benton',
    iso3: null,
    population: '279,141',
    casesPastWeek: 122,
    casesIncreasingPercentage: 60.52631578947369,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 35667,
    completeVaccinations: 161883,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05009: {
    label: 'Boone',
    iso3: null,
    population: '37,432',
    casesPastWeek: 24,
    casesIncreasingPercentage: 84.61538461538463,
    positiveCasePercentage: 4.1000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2833,
    completeVaccinations: 14888,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05011: {
    label: 'Bradley',
    iso3: null,
    population: '10,763',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1151,
    completeVaccinations: 6354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05013: {
    label: 'Calhoun',
    iso3: null,
    population: '5,189',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 316,
    completeVaccinations: 2068,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05015: {
    label: 'Carroll',
    iso3: null,
    population: '28,380',
    casesPastWeek: 13,
    casesIncreasingPercentage: 85.71428571428572,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3042,
    completeVaccinations: 14372,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05017: {
    label: 'Chicot',
    iso3: null,
    population: '10,118',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1198,
    completeVaccinations: 5714,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_05019: {
    label: 'Clark',
    iso3: null,
    population: '22,320',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1830,
    completeVaccinations: 11389,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05021: {
    label: 'Clay',
    iso3: null,
    population: '14,551',
    casesPastWeek: 12,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 971,
    completeVaccinations: 6907,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05023: {
    label: 'Cleburne',
    iso3: null,
    population: '24,919',
    casesPastWeek: 31,
    casesIncreasingPercentage: 933.3333333333334,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1971,
    completeVaccinations: 11612,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05025: {
    label: 'Cleveland',
    iso3: null,
    population: '7,956',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 698,
    completeVaccinations: 4386,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05027: {
    label: 'Columbia',
    iso3: null,
    population: '23,457',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2762,
    completeVaccinations: 11991,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05029: {
    label: 'Conway',
    iso3: null,
    population: '20,846',
    casesPastWeek: 17,
    casesIncreasingPercentage: 6.25,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1842,
    completeVaccinations: 11694,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05031: {
    label: 'Craighead',
    iso3: null,
    population: '110,332',
    casesPastWeek: 111,
    casesIncreasingPercentage: 12.12121212121211,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9755,
    completeVaccinations: 54650,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05033: {
    label: 'Crawford',
    iso3: null,
    population: '63,257',
    casesPastWeek: 32,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5216,
    completeVaccinations: 30100,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05035: {
    label: 'Crittenden',
    iso3: null,
    population: '47,955',
    casesPastWeek: 20,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5288,
    completeVaccinations: 23056,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05037: {
    label: 'Cross',
    iso3: null,
    population: '16,419',
    casesPastWeek: 7,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1420,
    completeVaccinations: 8716,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05039: {
    label: 'Dallas',
    iso3: null,
    population: '7,009',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 647,
    completeVaccinations: 4081,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  US_05041: {
    label: 'Desha',
    iso3: null,
    population: '11,361',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1383,
    completeVaccinations: 7140,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05043: {
    label: 'Drew',
    iso3: null,
    population: '18,219',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1567,
    completeVaccinations: 9066,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05045: {
    label: 'Faulkner',
    iso3: null,
    population: '126,007',
    casesPastWeek: 83,
    casesIncreasingPercentage: 50.90909090909091,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11455,
    completeVaccinations: 67354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05047: {
    label: 'Franklin',
    iso3: null,
    population: '17,715',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1105,
    completeVaccinations: 8861,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05049: {
    label: 'Fulton',
    iso3: null,
    population: '12,477',
    casesPastWeek: 7,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 838,
    completeVaccinations: 4497,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05051: {
    label: 'Garland',
    iso3: null,
    population: '99,386',
    casesPastWeek: 56,
    casesIncreasingPercentage: 30.232558139534895,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9038,
    completeVaccinations: 47948,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05053: {
    label: 'Grant',
    iso3: null,
    population: '18,265',
    casesPastWeek: 12,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1416,
    completeVaccinations: 8798,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05055: {
    label: 'Greene',
    iso3: null,
    population: '45,325',
    casesPastWeek: 64,
    casesIncreasingPercentage: 52.38095238095237,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3220,
    completeVaccinations: 20576,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05057: {
    label: 'Hempstead',
    iso3: null,
    population: '21,532',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2334,
    completeVaccinations: 9250,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05059: {
    label: 'Hot Spring',
    iso3: null,
    population: '33,771',
    casesPastWeek: 17,
    casesIncreasingPercentage: 41.66666666666667,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2204,
    completeVaccinations: 15191,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05061: {
    label: 'Howard',
    iso3: null,
    population: '13,202',
    casesPastWeek: 5,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1356,
    completeVaccinations: 7503,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05063: {
    label: 'Independence',
    iso3: null,
    population: '37,825',
    casesPastWeek: 29,
    casesIncreasingPercentage: 61.111111111111114,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3062,
    completeVaccinations: 17150,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05065: {
    label: 'Izard',
    iso3: null,
    population: '13,629',
    casesPastWeek: 5,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 907,
    completeVaccinations: 5974,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_05067: {
    label: 'Jackson',
    iso3: null,
    population: '16,719',
    casesPastWeek: 16,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 50.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1144,
    completeVaccinations: 6864,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05069: {
    label: 'Jefferson',
    iso3: null,
    population: '66,824',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8802,
    completeVaccinations: 32657,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05071: {
    label: 'Johnson',
    iso3: null,
    population: '26,578',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 1.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2635,
    completeVaccinations: 13679,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05073: {
    label: 'Lafayette',
    iso3: null,
    population: '6,624',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 577,
    completeVaccinations: 2906,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_05075: {
    label: 'Lawrence',
    iso3: null,
    population: '16,406',
    casesPastWeek: 14,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1440,
    completeVaccinations: 8004,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05077: {
    label: 'Lee',
    iso3: null,
    population: '8,857',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 770,
    completeVaccinations: 3696,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_05079: {
    label: 'Lincoln',
    iso3: null,
    population: '13,024',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1011,
    completeVaccinations: 4819,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05081: {
    label: 'Little River',
    iso3: null,
    population: '12,259',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1291,
    completeVaccinations: 5263,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05083: {
    label: 'Logan',
    iso3: null,
    population: '21,466',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1607,
    completeVaccinations: 10597,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05085: {
    label: 'Lonoke',
    iso3: null,
    population: '73,309',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6495,
    completeVaccinations: 36673,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05087: {
    label: 'Madison',
    iso3: null,
    population: '16,576',
    casesPastWeek: 10,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1327,
    completeVaccinations: 7613,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05089: {
    label: 'Marion',
    iso3: null,
    population: '16,694',
    casesPastWeek: 13,
    casesIncreasingPercentage: 225.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1143,
    completeVaccinations: 6245,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05091: {
    label: 'Miller',
    iso3: null,
    population: '43,257',
    casesPastWeek: 14,
    casesIncreasingPercentage: 27.27272727272727,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4856,
    completeVaccinations: 14687,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05093: {
    label: 'Mississippi',
    iso3: null,
    population: '40,651',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3353,
    completeVaccinations: 17497,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05095: {
    label: 'Monroe',
    iso3: null,
    population: '6,701',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 948,
    completeVaccinations: 4039,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  US_05097: {
    label: 'Montgomery',
    iso3: null,
    population: '8,986',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 679,
    completeVaccinations: 3460,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05099: {
    label: 'Nevada',
    iso3: null,
    population: '8,252',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1047,
    completeVaccinations: 3952,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 05, 2023',
  },
  US_05101: {
    label: 'Newton',
    iso3: null,
    population: '7,753',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 613,
    completeVaccinations: 2766,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05103: {
    label: 'Ouachita',
    iso3: null,
    population: '23,382',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2023,
    completeVaccinations: 13060,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05105: {
    label: 'Perry',
    iso3: null,
    population: '10,455',
    casesPastWeek: 10,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 981,
    completeVaccinations: 5649,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05107: {
    label: 'Phillips',
    iso3: null,
    population: '17,782',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1750,
    completeVaccinations: 9290,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 04, 2023',
  },
  US_05109: {
    label: 'Pike',
    iso3: null,
    population: '10,718',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1116,
    completeVaccinations: 4683,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05111: {
    label: 'Poinsett',
    iso3: null,
    population: '23,528',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1975,
    completeVaccinations: 11229,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05113: {
    label: 'Polk',
    iso3: null,
    population: '19,964',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1805,
    completeVaccinations: 7598,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05115: {
    label: 'Pope',
    iso3: null,
    population: '64,072',
    casesPastWeek: 15,
    casesIncreasingPercentage: 87.5,
    positiveCasePercentage: 2.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5542,
    completeVaccinations: 33051,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05117: {
    label: 'Prairie',
    iso3: null,
    population: '8,062',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 789,
    completeVaccinations: 3718,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05119: {
    label: 'Pulaski',
    iso3: null,
    population: '391,911',
    casesPastWeek: 233,
    casesIncreasingPercentage: 65.24822695035462,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 46993,
    completeVaccinations: 247163,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05121: {
    label: 'Randolph',
    iso3: null,
    population: '17,958',
    casesPastWeek: 26,
    casesIncreasingPercentage: 225.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1789,
    completeVaccinations: 7891,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05123: {
    label: 'St. Francis',
    iso3: null,
    population: '24,994',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2403,
    completeVaccinations: 13081,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05125: {
    label: 'Saline',
    iso3: null,
    population: '122,437',
    casesPastWeek: 83,
    casesIncreasingPercentage: 27.692307692307683,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11160,
    completeVaccinations: 66808,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05127: {
    label: 'Scott',
    iso3: null,
    population: '10,281',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1001,
    completeVaccinations: 5233,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_05129: {
    label: 'Searcy',
    iso3: null,
    population: '7,881',
    casesPastWeek: 9,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 571,
    completeVaccinations: 3192,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05131: {
    label: 'Sebastian',
    iso3: null,
    population: '127,827',
    casesPastWeek: 47,
    casesIncreasingPercentage: 74.07407407407408,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14222,
    completeVaccinations: 67746,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05133: {
    label: 'Sevier',
    iso3: null,
    population: '17,007',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1440,
    completeVaccinations: 8225,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05135: {
    label: 'Sharp',
    iso3: null,
    population: '17,442',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1408,
    completeVaccinations: 7380,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05137: {
    label: 'Stone',
    iso3: null,
    population: '12,506',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 734,
    completeVaccinations: 4887,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_05139: {
    label: 'Union',
    iso3: null,
    population: '38,682',
    casesPastWeek: 22,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 29.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5413,
    completeVaccinations: 20027,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05141: {
    label: 'Van Buren',
    iso3: null,
    population: '16,545',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 971,
    completeVaccinations: 7285,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05143: {
    label: 'Washington',
    iso3: null,
    population: '239,187',
    casesPastWeek: 191,
    casesIncreasingPercentage: 76.85185185185186,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 30009,
    completeVaccinations: 144487,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05145: {
    label: 'White',
    iso3: null,
    population: '78,753',
    casesPastWeek: 55,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6387,
    completeVaccinations: 36072,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_05147: {
    label: 'Woodruff',
    iso3: null,
    population: '6,320',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 548,
    completeVaccinations: 3672,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_05149: {
    label: 'Yell',
    iso3: null,
    population: '21,341',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1947,
    completeVaccinations: 10995,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_06001: {
    label: 'Alameda',
    iso3: null,
    population: '1,671,329',
    casesPastWeek: 162,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 120830,
    completeVaccinations: 1410721,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06003: {
    label: 'Alpine',
    iso3: null,
    population: '1,129',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 72,
    completeVaccinations: 616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_06005: {
    label: 'Amador',
    iso3: null,
    population: '39,752',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.5000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1861,
    completeVaccinations: 22258,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06007: {
    label: 'Butte',
    iso3: null,
    population: '219,186',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11283,
    completeVaccinations: 118927,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06009: {
    label: 'Calaveras',
    iso3: null,
    population: '45,905',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1957,
    completeVaccinations: 24777,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06011: {
    label: 'Colusa',
    iso3: null,
    population: '21,547',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1276,
    completeVaccinations: 13256,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06013: {
    label: 'Contra Costa',
    iso3: null,
    population: '1,153,526',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 65076,
    completeVaccinations: 975046,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06015: {
    label: 'Del Norte',
    iso3: null,
    population: '27,812',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.5000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1747,
    completeVaccinations: 13631,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06017: {
    label: 'El Dorado',
    iso3: null,
    population: '192,843',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11894,
    completeVaccinations: 119935,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06019: {
    label: 'Fresno',
    iso3: null,
    population: '999,101',
    casesPastWeek: 193,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 67103,
    completeVaccinations: 629241,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06021: {
    label: 'Glenn',
    iso3: null,
    population: '28,393',
    casesPastWeek: 10,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1504,
    completeVaccinations: 15987,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06023: {
    label: 'Humboldt',
    iso3: null,
    population: '135,558',
    casesPastWeek: 69,
    casesIncreasingPercentage: 245.00000000000003,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8874,
    completeVaccinations: 92347,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06025: {
    label: 'Imperial',
    iso3: null,
    population: '181,215',
    casesPastWeek: 56,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 84348,
    completeVaccinations: 181043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06027: {
    label: 'Inyo',
    iso3: null,
    population: '18,039',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1118,
    completeVaccinations: 11704,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06029: {
    label: 'Kern',
    iso3: null,
    population: '900,202',
    casesPastWeek: 566,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 57418,
    completeVaccinations: 495800,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_06031: {
    label: 'Kings',
    iso3: null,
    population: '152,940',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9585,
    completeVaccinations: 71226,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06033: {
    label: 'Lake',
    iso3: null,
    population: '64,386',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4475,
    completeVaccinations: 36047,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_06035: {
    label: 'Lassen',
    iso3: null,
    population: '30,573',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1024,
    completeVaccinations: 8967,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_06037: {
    label: 'Los Angeles',
    iso3: null,
    population: '10,039,107',
    casesPastWeek: 7584,
    casesIncreasingPercentage: 16.748768472906406,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 850385,
    completeVaccinations: 7542100,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06039: {
    label: 'Madera',
    iso3: null,
    population: '157,327',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10054,
    completeVaccinations: 88147,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06041: {
    label: 'Marin',
    iso3: null,
    population: '258,826',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19598,
    completeVaccinations: 233445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06043: {
    label: 'Mariposa',
    iso3: null,
    population: '17,203',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1519,
    completeVaccinations: 9196,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06045: {
    label: 'Mendocino',
    iso3: null,
    population: '86,749',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7347,
    completeVaccinations: 60575,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06047: {
    label: 'Merced',
    iso3: null,
    population: '277,680',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21356,
    completeVaccinations: 159157,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06049: {
    label: 'Modoc',
    iso3: null,
    population: '8,841',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 276,
    completeVaccinations: 3748,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06051: {
    label: 'Mono',
    iso3: null,
    population: '14,444',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1038,
    completeVaccinations: 9167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_06053: {
    label: 'Monterey',
    iso3: null,
    population: '434,061',
    casesPastWeek: 220,
    casesIncreasingPercentage: 15.18324607329844,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 38041,
    completeVaccinations: 324917,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06055: {
    label: 'Napa',
    iso3: null,
    population: '137,744',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12201,
    completeVaccinations: 111402,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06057: {
    label: 'Nevada',
    iso3: null,
    population: '99,755',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7351,
    completeVaccinations: 64492,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06059: {
    label: 'Orange',
    iso3: null,
    population: '3,175,692',
    casesPastWeek: 2002,
    casesIncreasingPercentage: 3.676851372345924,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 226438,
    completeVaccinations: 2368886,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06061: {
    label: 'Placer',
    iso3: null,
    population: '398,329',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20542,
    completeVaccinations: 274126,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06063: {
    label: 'Plumas',
    iso3: null,
    population: '18,807',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 998,
    completeVaccinations: 10481,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_06065: {
    label: 'Riverside',
    iso3: null,
    population: '2,470,546',
    casesPastWeek: 199,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 170116,
    completeVaccinations: 1492274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06067: {
    label: 'Sacramento',
    iso3: null,
    population: '1,552,058',
    casesPastWeek: 167,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 93532,
    completeVaccinations: 1080974,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06069: {
    label: 'San Benito',
    iso3: null,
    population: '62,808',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4325,
    completeVaccinations: 46604,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06071: {
    label: 'San Bernardino',
    iso3: null,
    population: '2,180,085',
    casesPastWeek: 119,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 127786,
    completeVaccinations: 1273982,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06073: {
    label: 'San Diego',
    iso3: null,
    population: '3,338,330',
    casesPastWeek: 2144,
    casesIncreasingPercentage: 3.7252056119980725,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 390966,
    completeVaccinations: 2556475,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06075: {
    label: 'San Francisco',
    iso3: null,
    population: '881,549',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 79824,
    completeVaccinations: 758407,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06077: {
    label: 'San Joaquin',
    iso3: null,
    population: '762,148',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 53597,
    completeVaccinations: 512795,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06079: {
    label: 'San Luis Obispo',
    iso3: null,
    population: '283,111',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18592,
    completeVaccinations: 185525,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06081: {
    label: 'San Mateo',
    iso3: null,
    population: '766,573',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 61202,
    completeVaccinations: 670049,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06083: {
    label: 'Santa Barbara',
    iso3: null,
    population: '446,499',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 38981,
    completeVaccinations: 317596,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06085: {
    label: 'Santa Clara',
    iso3: null,
    population: '1,927,852',
    casesPastWeek: 72,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 145531,
    completeVaccinations: 1687030,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06087: {
    label: 'Santa Cruz',
    iso3: null,
    population: '273,213',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18713,
    completeVaccinations: 212228,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06089: {
    label: 'Shasta',
    iso3: null,
    population: '180,080',
    casesPastWeek: 8300,
    casesIncreasingPercentage: 75354.54545454546,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10529,
    completeVaccinations: 85429,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_06091: {
    label: 'Sierra',
    iso3: null,
    population: '3,005',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 124,
    completeVaccinations: 1581,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_06093: {
    label: 'Siskiyou',
    iso3: null,
    population: '43,539',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2778,
    completeVaccinations: 21728,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06095: {
    label: 'Solano',
    iso3: null,
    population: '447,643',
    casesPastWeek: 52,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36638,
    completeVaccinations: 311590,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06097: {
    label: 'Sonoma',
    iso3: null,
    population: '494,336',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 30414,
    completeVaccinations: 398354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06099: {
    label: 'Stanislaus',
    iso3: null,
    population: '550,660',
    casesPastWeek: 320,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 32215,
    completeVaccinations: 334047,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06101: {
    label: 'Sutter',
    iso3: null,
    population: '96,971',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5314,
    completeVaccinations: 59413,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_06103: {
    label: 'Tehama',
    iso3: null,
    population: '65,084',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3029,
    completeVaccinations: 28611,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06105: {
    label: 'Trinity',
    iso3: null,
    population: '12,285',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 861,
    completeVaccinations: 6041,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06107: {
    label: 'Tulare',
    iso3: null,
    population: '466,195',
    casesPastWeek: 125,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31033,
    completeVaccinations: 260455,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06109: {
    label: 'Tuolumne',
    iso3: null,
    population: '54,478',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2411,
    completeVaccinations: 28937,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06111: {
    label: 'Ventura',
    iso3: null,
    population: '846,006',
    casesPastWeek: 49,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 56573,
    completeVaccinations: 624463,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06113: {
    label: 'Yolo',
    iso3: null,
    population: '220,500',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16872,
    completeVaccinations: 161357,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_06115: {
    label: 'Yuba',
    iso3: null,
    population: '78,668',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4117,
    completeVaccinations: 39955,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08001: {
    label: 'Adams',
    iso3: null,
    population: '517,421',
    casesPastWeek: 308,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 33112,
    completeVaccinations: 370266,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08003: {
    label: 'Alamosa',
    iso3: null,
    population: '16,233',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1159,
    completeVaccinations: 9490,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08005: {
    label: 'Arapahoe',
    iso3: null,
    population: '656,590',
    casesPastWeek: 333,
    casesIncreasingPercentage: 18.50533807829182,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 45043,
    completeVaccinations: 469331,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08007: {
    label: 'Archuleta',
    iso3: null,
    population: '14,029',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 989,
    completeVaccinations: 8381,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08009: {
    label: 'Baca',
    iso3: null,
    population: '3,581',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 66,
    completeVaccinations: 1455,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08011: {
    label: 'Bent',
    iso3: null,
    population: '5,577',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 66,
    completeVaccinations: 1537,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08013: {
    label: 'Boulder',
    iso3: null,
    population: '326,196',
    casesPastWeek: 131,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26962,
    completeVaccinations: 269501,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08014: {
    label: 'Broomfield',
    iso3: null,
    population: '70,465',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3834,
    completeVaccinations: 58998,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08015: {
    label: 'Chaffee',
    iso3: null,
    population: '20,356',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1213,
    completeVaccinations: 13485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08017: {
    label: 'Cheyenne',
    iso3: null,
    population: '1,831',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10,
    completeVaccinations: 634,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08019: {
    label: 'Clear Creek',
    iso3: null,
    population: '9,700',
    casesPastWeek: 4,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 614,
    completeVaccinations: 4947,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08021: {
    label: 'Conejos',
    iso3: null,
    population: '8,205',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 388,
    completeVaccinations: 4208,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_08023: {
    label: 'Costilla',
    iso3: null,
    population: '3,887',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 18.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 152,
    completeVaccinations: 2413,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08025: {
    label: 'Crowley',
    iso3: null,
    population: '6,061',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 92,
    completeVaccinations: 1357,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08027: {
    label: 'Custer',
    iso3: null,
    population: '5,068',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 248,
    completeVaccinations: 2608,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08029: {
    label: 'Delta',
    iso3: null,
    population: '31,162',
    casesPastWeek: 19,
    casesIncreasingPercentage: 89.99999999999999,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1559,
    completeVaccinations: 15388,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08031: {
    label: 'Denver',
    iso3: null,
    population: '727,211',
    casesPastWeek: 423,
    casesIncreasingPercentage: 6.281407035175879,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 63669,
    completeVaccinations: 582731,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08033: {
    label: 'Dolores',
    iso3: null,
    population: '2,055',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 147,
    completeVaccinations: 889,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_08035: {
    label: 'Douglas',
    iso3: null,
    population: '351,154',
    casesPastWeek: 121,
    casesIncreasingPercentage: 163.0434782608696,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26494,
    completeVaccinations: 269834,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08037: {
    label: 'Eagle',
    iso3: null,
    population: '55,127',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11519,
    completeVaccinations: 47352,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08039: {
    label: 'Elbert',
    iso3: null,
    population: '26,729',
    casesPastWeek: 12,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1019,
    completeVaccinations: 10436,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08041: {
    label: 'El Paso',
    iso3: null,
    population: '720,403',
    casesPastWeek: 420,
    casesIncreasingPercentage: 21.387283236994215,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 75638,
    completeVaccinations: 490651,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08043: {
    label: 'Fremont',
    iso3: null,
    population: '47,839',
    casesPastWeek: 34,
    casesIncreasingPercentage: 61.904761904761905,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2743,
    completeVaccinations: 23078,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08045: {
    label: 'Garfield',
    iso3: null,
    population: '60,061',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4312,
    completeVaccinations: 40185,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08047: {
    label: 'Gilpin',
    iso3: null,
    population: '6,243',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 492,
    completeVaccinations: 3750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08049: {
    label: 'Grand',
    iso3: null,
    population: '15,734',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1732,
    completeVaccinations: 10275,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08051: {
    label: 'Gunnison',
    iso3: null,
    population: '17,462',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1481,
    completeVaccinations: 12550,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08053: {
    label: 'Hinsdale',
    iso3: null,
    population: '820',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21,
    completeVaccinations: 568,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08055: {
    label: 'Huerfano',
    iso3: null,
    population: '6,897',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 432,
    completeVaccinations: 3872,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08057: {
    label: 'Jackson',
    iso3: null,
    population: '1,392',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36,
    completeVaccinations: 663,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_08059: {
    label: 'Jefferson',
    iso3: null,
    population: '582,881',
    casesPastWeek: 257,
    casesIncreasingPercentage: 47.70114942528736,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 43250,
    completeVaccinations: 471813,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08061: {
    label: 'Kiowa',
    iso3: null,
    population: '1,406',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21,
    completeVaccinations: 536,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08063: {
    label: 'Kit Carson',
    iso3: null,
    population: '7,097',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 230,
    completeVaccinations: 2853,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_08065: {
    label: 'Lake',
    iso3: null,
    population: '8,127',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 742,
    completeVaccinations: 5039,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08067: {
    label: 'La Plata',
    iso3: null,
    population: '56,221',
    casesPastWeek: 30,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5094,
    completeVaccinations: 41235,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08069: {
    label: 'Larimer',
    iso3: null,
    population: '356,899',
    casesPastWeek: 244,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28284,
    completeVaccinations: 254096,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08071: {
    label: 'Las Animas',
    iso3: null,
    population: '14,506',
    casesPastWeek: 11,
    casesIncreasingPercentage: 83.33333333333333,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 779,
    completeVaccinations: 9199,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08073: {
    label: 'Lincoln',
    iso3: null,
    population: '5,701',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 92,
    completeVaccinations: 2121,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_08075: {
    label: 'Logan',
    iso3: null,
    population: '22,409',
    casesPastWeek: 14,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 551,
    completeVaccinations: 8819,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08077: {
    label: 'Mesa',
    iso3: null,
    population: '154,210',
    casesPastWeek: 96,
    casesIncreasingPercentage: 28.000000000000004,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8615,
    completeVaccinations: 80118,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08079: {
    label: 'Mineral',
    iso3: null,
    population: '769',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 49,
    completeVaccinations: 674,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08081: {
    label: 'Moffat',
    iso3: null,
    population: '13,283',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 731,
    completeVaccinations: 5992,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08083: {
    label: 'Montezuma',
    iso3: null,
    population: '26,183',
    casesPastWeek: 23,
    casesIncreasingPercentage: 129.99999999999997,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3302,
    completeVaccinations: 18926,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08085: {
    label: 'Montrose',
    iso3: null,
    population: '42,758',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2479,
    completeVaccinations: 21929,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08087: {
    label: 'Morgan',
    iso3: null,
    population: '29,068',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1706,
    completeVaccinations: 14500,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08089: {
    label: 'Otero',
    iso3: null,
    population: '18,278',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 681,
    completeVaccinations: 9279,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08091: {
    label: 'Ouray',
    iso3: null,
    population: '4,952',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 382,
    completeVaccinations: 3447,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_08093: {
    label: 'Park',
    iso3: null,
    population: '18,845',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 860,
    completeVaccinations: 9283,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08095: {
    label: 'Phillips',
    iso3: null,
    population: '4,265',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 272,
    completeVaccinations: 2297,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08097: {
    label: 'Pitkin',
    iso3: null,
    population: '17,767',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4008,
    completeVaccinations: 15455,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08099: {
    label: 'Prowers',
    iso3: null,
    population: '12,172',
    casesPastWeek: 12,
    casesIncreasingPercentage: 1100.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 530,
    completeVaccinations: 5400,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08101: {
    label: 'Pueblo',
    iso3: null,
    population: '168,424',
    casesPastWeek: 107,
    casesIncreasingPercentage: 2.8846153846153744,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10004,
    completeVaccinations: 99387,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08103: {
    label: 'Rio Blanco',
    iso3: null,
    population: '6,324',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 391,
    completeVaccinations: 2519,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08105: {
    label: 'Rio Grande',
    iso3: null,
    population: '11,267',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 774,
    completeVaccinations: 7406,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08107: {
    label: 'Routt',
    iso3: null,
    population: '25,638',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2441,
    completeVaccinations: 20236,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08109: {
    label: 'Saguache',
    iso3: null,
    population: '6,824',
    casesPastWeek: 6,
    casesIncreasingPercentage: 500.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 245,
    completeVaccinations: 2529,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08111: {
    label: 'San Juan',
    iso3: null,
    population: '728',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 126,
    completeVaccinations: 717,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_08113: {
    label: 'San Miguel',
    iso3: null,
    population: '8,179',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1189,
    completeVaccinations: 7080,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08115: {
    label: 'Sedgwick',
    iso3: null,
    population: '2,248',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 100,
    completeVaccinations: 1216,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_08117: {
    label: 'Summit',
    iso3: null,
    population: '31,011',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4917,
    completeVaccinations: 27295,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08119: {
    label: 'Teller',
    iso3: null,
    population: '25,388',
    casesPastWeek: 6,
    casesIncreasingPercentage: 500.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 745,
    completeVaccinations: 14226,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08121: {
    label: 'Washington',
    iso3: null,
    population: '4,908',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 180,
    completeVaccinations: 1587,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_08123: {
    label: 'Weld',
    iso3: null,
    population: '324,492',
    casesPastWeek: 275,
    casesIncreasingPercentage: 40.3061224489796,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16010,
    completeVaccinations: 206535,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_08125: {
    label: 'Yuma',
    iso3: null,
    population: '10,019',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 543,
    completeVaccinations: 4548,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_09001: {
    label: 'Fairfield',
    iso3: null,
    population: '943,332',
    casesPastWeek: 220,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 221467,
    completeVaccinations: 813616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09003: {
    label: 'Hartford',
    iso3: null,
    population: '891,720',
    casesPastWeek: 289,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 130266,
    completeVaccinations: 706681,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09005: {
    label: 'Litchfield',
    iso3: null,
    population: '180,333',
    casesPastWeek: 56,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25726,
    completeVaccinations: 141585,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09007: {
    label: 'Middlesex',
    iso3: null,
    population: '162,436',
    casesPastWeek: 45,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28104,
    completeVaccinations: 141236,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09009: {
    label: 'New Haven',
    iso3: null,
    population: '854,757',
    casesPastWeek: 315,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 177145,
    completeVaccinations: 702652,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09011: {
    label: 'New London',
    iso3: null,
    population: '265,206',
    casesPastWeek: 108,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 46085,
    completeVaccinations: 219174,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09013: {
    label: 'Tolland',
    iso3: null,
    population: '150,721',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18286,
    completeVaccinations: 104921,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_09015: {
    label: 'Windham',
    iso3: null,
    population: '116,782',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13936,
    completeVaccinations: 77763,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_10001: {
    label: 'Kent',
    iso3: null,
    population: '180,786',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17645,
    completeVaccinations: 106868,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_10003: {
    label: 'New Castle',
    iso3: null,
    population: '558,753',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 87800,
    completeVaccinations: 410236,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_10005: {
    label: 'Sussex',
    iso3: null,
    population: '234,225',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31674,
    completeVaccinations: 170461,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_11001: {
    label: 'District of Columbia',
    iso3: null,
    population: '705,749',
    casesPastWeek: 210,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_12001: {
    label: 'Alachua',
    iso3: null,
    population: '269,043',
    casesPastWeek: 735,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27795,
    completeVaccinations: 186108,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12003: {
    label: 'Baker',
    iso3: null,
    population: '29,210',
    casesPastWeek: 77,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2344,
    completeVaccinations: 10943,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12005: {
    label: 'Bay',
    iso3: null,
    population: '174,705',
    casesPastWeek: 385,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17087,
    completeVaccinations: 96945,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12007: {
    label: 'Bradford',
    iso3: null,
    population: '28,201',
    casesPastWeek: 84,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2149,
    completeVaccinations: 13245,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12009: {
    label: 'Brevard',
    iso3: null,
    population: '601,942',
    casesPastWeek: 1216,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 70646,
    completeVaccinations: 403660,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12011: {
    label: 'Broward',
    iso3: null,
    population: '1,952,778',
    casesPastWeek: 5496,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 284759,
    completeVaccinations: 1436454,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12013: {
    label: 'Calhoun',
    iso3: null,
    population: '14,105',
    casesPastWeek: 45,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 970,
    completeVaccinations: 5019,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12015: {
    label: 'Charlotte',
    iso3: null,
    population: '188,910',
    casesPastWeek: 519,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25715,
    completeVaccinations: 128523,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12017: {
    label: 'Citrus',
    iso3: null,
    population: '149,657',
    casesPastWeek: 291,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11923,
    completeVaccinations: 87006,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12019: {
    label: 'Clay',
    iso3: null,
    population: '219,252',
    casesPastWeek: 556,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 23419,
    completeVaccinations: 122984,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12021: {
    label: 'Collier',
    iso3: null,
    population: '384,902',
    casesPastWeek: 815,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 49483,
    completeVaccinations: 282472,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12023: {
    label: 'Columbia',
    iso3: null,
    population: '71,686',
    casesPastWeek: 187,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5145,
    completeVaccinations: 31102,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12027: {
    label: 'DeSoto',
    iso3: null,
    population: '38,001',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4497,
    completeVaccinations: 18540,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12029: {
    label: 'Dixie',
    iso3: null,
    population: '16,826',
    casesPastWeek: 33,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1599,
    completeVaccinations: 5976,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12031: {
    label: 'Duval',
    iso3: null,
    population: '957,755',
    casesPastWeek: 2266,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 96912,
    completeVaccinations: 604720,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12033: {
    label: 'Escambia',
    iso3: null,
    population: '318,316',
    casesPastWeek: 598,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 33120,
    completeVaccinations: 184029,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12035: {
    label: 'Flagler',
    iso3: null,
    population: '115,081',
    casesPastWeek: 183,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10991,
    completeVaccinations: 75747,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12037: {
    label: 'Franklin',
    iso3: null,
    population: '12,125',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 740,
    completeVaccinations: 6012,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12039: {
    label: 'Gadsden',
    iso3: null,
    population: '45,660',
    casesPastWeek: 213,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3637,
    completeVaccinations: 27281,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12041: {
    label: 'Gilchrist',
    iso3: null,
    population: '18,582',
    casesPastWeek: 60,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1118,
    completeVaccinations: 7006,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12043: {
    label: 'Glades',
    iso3: null,
    population: '13,811',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 978,
    completeVaccinations: 4832,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12045: {
    label: 'Gulf',
    iso3: null,
    population: '13,639',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1037,
    completeVaccinations: 7375,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12047: {
    label: 'Hamilton',
    iso3: null,
    population: '14,428',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 958,
    completeVaccinations: 5210,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12049: {
    label: 'Hardee',
    iso3: null,
    population: '26,937',
    casesPastWeek: 73,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.200000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1907,
    completeVaccinations: 11853,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12051: {
    label: 'Hendry',
    iso3: null,
    population: '42,022',
    casesPastWeek: 118,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5173,
    completeVaccinations: 21780,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12053: {
    label: 'Hernando',
    iso3: null,
    population: '193,920',
    casesPastWeek: 320,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14067,
    completeVaccinations: 109915,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12055: {
    label: 'Highlands',
    iso3: null,
    population: '106,221',
    casesPastWeek: 190,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13218,
    completeVaccinations: 57673,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12057: {
    label: 'Hillsborough',
    iso3: null,
    population: '1,471,968',
    casesPastWeek: 3392,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 179019,
    completeVaccinations: 966011,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12059: {
    label: 'Holmes',
    iso3: null,
    population: '19,617',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1235,
    completeVaccinations: 5942,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12061: {
    label: 'Indian River',
    iso3: null,
    population: '159,923',
    casesPastWeek: 320,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20764,
    completeVaccinations: 111465,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12063: {
    label: 'Jackson',
    iso3: null,
    population: '46,414',
    casesPastWeek: 227,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3983,
    completeVaccinations: 20750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12065: {
    label: 'Jefferson',
    iso3: null,
    population: '14,246',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1726,
    completeVaccinations: 8683,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12067: {
    label: 'Lafayette',
    iso3: null,
    population: '8,422',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 416,
    completeVaccinations: 3403,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12069: {
    label: 'Lake',
    iso3: null,
    population: '367,118',
    casesPastWeek: 717,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 35929,
    completeVaccinations: 250207,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12071: {
    label: 'Lee',
    iso3: null,
    population: '770,577',
    casesPastWeek: 1871,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 84162,
    completeVaccinations: 497621,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12073: {
    label: 'Leon',
    iso3: null,
    population: '293,582',
    casesPastWeek: 1044,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26141,
    completeVaccinations: 173063,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12075: {
    label: 'Levy',
    iso3: null,
    population: '41,503',
    casesPastWeek: 118,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3327,
    completeVaccinations: 20201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12077: {
    label: 'Liberty',
    iso3: null,
    population: '8,354',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 402,
    completeVaccinations: 2902,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12079: {
    label: 'Madison',
    iso3: null,
    population: '18,493',
    casesPastWeek: 45,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1574,
    completeVaccinations: 8981,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12081: {
    label: 'Manatee',
    iso3: null,
    population: '403,253',
    casesPastWeek: 782,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 41430,
    completeVaccinations: 259240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12083: {
    label: 'Marion',
    iso3: null,
    population: '365,579',
    casesPastWeek: 789,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28394,
    completeVaccinations: 214683,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12085: {
    label: 'Martin',
    iso3: null,
    population: '161,000',
    casesPastWeek: 335,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16583,
    completeVaccinations: 105824,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12086: {
    label: 'Miami-Dade',
    iso3: null,
    population: '2,716,940',
    casesPastWeek: 13795,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 615372,
    completeVaccinations: 2401120,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12087: {
    label: 'Monroe',
    iso3: null,
    population: '74,228',
    casesPastWeek: 146,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12450,
    completeVaccinations: 60355,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12089: {
    label: 'Nassau',
    iso3: null,
    population: '88,625',
    casesPastWeek: 196,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7855,
    completeVaccinations: 53159,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12091: {
    label: 'Okaloosa',
    iso3: null,
    population: '210,738',
    casesPastWeek: 415,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27505,
    completeVaccinations: 140214,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12093: {
    label: 'Okeechobee',
    iso3: null,
    population: '42,168',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3442,
    completeVaccinations: 20166,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12095: {
    label: 'Orange',
    iso3: null,
    population: '1,393,452',
    casesPastWeek: 2608,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 204282,
    completeVaccinations: 1002445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12097: {
    label: 'Osceola',
    iso3: null,
    population: '375,751',
    casesPastWeek: 917,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 66798,
    completeVaccinations: 284648,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12099: {
    label: 'Palm Beach',
    iso3: null,
    population: '1,496,770',
    casesPastWeek: 3929,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 166229,
    completeVaccinations: 1028483,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12101: {
    label: 'Pasco',
    iso3: null,
    population: '553,947',
    casesPastWeek: 1297,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 50159,
    completeVaccinations: 339154,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12103: {
    label: 'Pinellas',
    iso3: null,
    population: '974,996',
    casesPastWeek: 2156,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 93078,
    completeVaccinations: 653482,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12105: {
    label: 'Polk',
    iso3: null,
    population: '724,777',
    casesPastWeek: 2185,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 69617,
    completeVaccinations: 430460,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12107: {
    label: 'Putnam',
    iso3: null,
    population: '74,521',
    casesPastWeek: 108,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5788,
    completeVaccinations: 32282,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12109: {
    label: 'St. Johns',
    iso3: null,
    population: '264,672',
    casesPastWeek: 476,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22104,
    completeVaccinations: 192284,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12111: {
    label: 'St. Lucie',
    iso3: null,
    population: '328,297',
    casesPastWeek: 744,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31575,
    completeVaccinations: 198872,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12113: {
    label: 'Santa Rosa',
    iso3: null,
    population: '184,313',
    casesPastWeek: 499,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21177,
    completeVaccinations: 103244,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12115: {
    label: 'Sarasota',
    iso3: null,
    population: '433,742',
    casesPastWeek: 978,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 56360,
    completeVaccinations: 314978,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12117: {
    label: 'Seminole',
    iso3: null,
    population: '471,826',
    casesPastWeek: 847,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 34201,
    completeVaccinations: 301817,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12119: {
    label: 'Sumter',
    iso3: null,
    population: '132,420',
    casesPastWeek: 265,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13646,
    completeVaccinations: 110363,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12121: {
    label: 'Suwannee',
    iso3: null,
    population: '44,417',
    casesPastWeek: 166,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3187,
    completeVaccinations: 17643,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12123: {
    label: 'Taylor',
    iso3: null,
    population: '21,569',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1654,
    completeVaccinations: 9020,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12125: {
    label: 'Union',
    iso3: null,
    population: '15,237',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2378,
    completeVaccinations: 5622,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12127: {
    label: 'Volusia',
    iso3: null,
    population: '553,284',
    casesPastWeek: 1113,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 48190,
    completeVaccinations: 340152,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12129: {
    label: 'Wakulla',
    iso3: null,
    population: '33,739',
    casesPastWeek: 86,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3061,
    completeVaccinations: 15549,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12131: {
    label: 'Walton',
    iso3: null,
    population: '74,071',
    casesPastWeek: 124,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7413,
    completeVaccinations: 37884,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_12133: {
    label: 'Washington',
    iso3: null,
    population: '25,473',
    casesPastWeek: 61,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1772,
    completeVaccinations: 8514,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_13001: {
    label: 'Appling',
    iso3: null,
    population: '18,386',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 959,
    completeVaccinations: 6799,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13003: {
    label: 'Atkinson',
    iso3: null,
    population: '8,165',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 633,
    completeVaccinations: 3361,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13005: {
    label: 'Bacon',
    iso3: null,
    population: '11,164',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 500,
    completeVaccinations: 3518,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13007: {
    label: 'Baker',
    iso3: null,
    population: '3,038',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 344,
    completeVaccinations: 1609,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13009: {
    label: 'Baldwin',
    iso3: null,
    population: '44,890',
    casesPastWeek: 29,
    casesIncreasingPercentage: 314.28571428571433,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2503,
    completeVaccinations: 20608,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13011: {
    label: 'Banks',
    iso3: null,
    population: '19,234',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 819,
    completeVaccinations: 6050,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13013: {
    label: 'Barrow',
    iso3: null,
    population: '83,240',
    casesPastWeek: 30,
    casesIncreasingPercentage: 275.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4371,
    completeVaccinations: 34651,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13015: {
    label: 'Bartow',
    iso3: null,
    population: '107,738',
    casesPastWeek: 42,
    casesIncreasingPercentage: 10.526315789473696,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5521,
    completeVaccinations: 41493,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13017: {
    label: 'Ben Hill',
    iso3: null,
    population: '16,700',
    casesPastWeek: 7,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 965,
    completeVaccinations: 6670,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13019: {
    label: 'Berrien',
    iso3: null,
    population: '19,397',
    casesPastWeek: 9,
    casesIncreasingPercentage: 28.57142857142858,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1171,
    completeVaccinations: 6813,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13021: {
    label: 'Bibb',
    iso3: null,
    population: '153,159',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11142,
    completeVaccinations: 73331,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13023: {
    label: 'Bleckley',
    iso3: null,
    population: '12,873',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 779,
    completeVaccinations: 4851,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13025: {
    label: 'Brantley',
    iso3: null,
    population: '19,109',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 918,
    completeVaccinations: 5122,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13027: {
    label: 'Brooks',
    iso3: null,
    population: '15,457',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 2.8000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1252,
    completeVaccinations: 7694,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13029: {
    label: 'Bryan',
    iso3: null,
    population: '39,627',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3792,
    completeVaccinations: 22234,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13031: {
    label: 'Bulloch',
    iso3: null,
    population: '79,608',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3934,
    completeVaccinations: 28268,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13033: {
    label: 'Burke',
    iso3: null,
    population: '22,383',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2388,
    completeVaccinations: 13259,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13035: {
    label: 'Butts',
    iso3: null,
    population: '24,936',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1373,
    completeVaccinations: 8856,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13037: {
    label: 'Calhoun',
    iso3: null,
    population: '6,189',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 391,
    completeVaccinations: 3096,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13039: {
    label: 'Camden',
    iso3: null,
    population: '54,666',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5613,
    completeVaccinations: 31788,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13043: {
    label: 'Candler',
    iso3: null,
    population: '10,803',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 614,
    completeVaccinations: 4720,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13045: {
    label: 'Carroll',
    iso3: null,
    population: '119,992',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6864,
    completeVaccinations: 47302,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13047: {
    label: 'Catoosa',
    iso3: null,
    population: '67,580',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4985,
    completeVaccinations: 31223,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13049: {
    label: 'Charlton',
    iso3: null,
    population: '13,392',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 617,
    completeVaccinations: 3512,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13051: {
    label: 'Chatham',
    iso3: null,
    population: '289,430',
    casesPastWeek: 64,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24417,
    completeVaccinations: 169610,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13053: {
    label: 'Chattahoochee',
    iso3: null,
    population: '10,907',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13055: {
    label: 'Chattooga',
    iso3: null,
    population: '24,789',
    casesPastWeek: 7,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1222,
    completeVaccinations: 9746,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13057: {
    label: 'Cherokee',
    iso3: null,
    population: '258,773',
    casesPastWeek: 50,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15495,
    completeVaccinations: 133072,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13059: {
    label: 'Clarke',
    iso3: null,
    population: '128,331',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6863,
    completeVaccinations: 62463,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13061: {
    label: 'Clay',
    iso3: null,
    population: '2,834',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 293,
    completeVaccinations: 2017,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13063: {
    label: 'Clayton',
    iso3: null,
    population: '292,256',
    casesPastWeek: 56,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21490,
    completeVaccinations: 134201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13065: {
    label: 'Clinch',
    iso3: null,
    population: '6,618',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 459,
    completeVaccinations: 2743,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13067: {
    label: 'Cobb',
    iso3: null,
    population: '760,141',
    casesPastWeek: 198,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 61786,
    completeVaccinations: 461307,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13069: {
    label: 'Coffee',
    iso3: null,
    population: '43,273',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2825,
    completeVaccinations: 16668,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13071: {
    label: 'Colquitt',
    iso3: null,
    population: '45,600',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2970,
    completeVaccinations: 18742,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13073: {
    label: 'Columbia',
    iso3: null,
    population: '156,714',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15527,
    completeVaccinations: 83042,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13075: {
    label: 'Cook',
    iso3: null,
    population: '17,270',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1225,
    completeVaccinations: 7311,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13077: {
    label: 'Coweta',
    iso3: null,
    population: '148,509',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9186,
    completeVaccinations: 68559,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13079: {
    label: 'Crawford',
    iso3: null,
    population: '12,404',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 995,
    completeVaccinations: 5274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13081: {
    label: 'Crisp',
    iso3: null,
    population: '22,372',
    casesPastWeek: 12,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1682,
    completeVaccinations: 10115,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13083: {
    label: 'Dade',
    iso3: null,
    population: '16,116',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1707,
    completeVaccinations: 7834,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13085: {
    label: 'Dawson',
    iso3: null,
    population: '26,108',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1182,
    completeVaccinations: 10780,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13087: {
    label: 'Decatur',
    iso3: null,
    population: '26,404',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2141,
    completeVaccinations: 13353,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13089: {
    label: 'DeKalb',
    iso3: null,
    population: '759,297',
    casesPastWeek: 201,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 64232,
    completeVaccinations: 452113,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13091: {
    label: 'Dodge',
    iso3: null,
    population: '20,605',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1125,
    completeVaccinations: 7539,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13093: {
    label: 'Dooly',
    iso3: null,
    population: '13,390',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 702,
    completeVaccinations: 5157,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13095: {
    label: 'Dougherty',
    iso3: null,
    population: '87,956',
    casesPastWeek: 52,
    casesIncreasingPercentage: 136.36363636363637,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6944,
    completeVaccinations: 45051,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13097: {
    label: 'Douglas',
    iso3: null,
    population: '146,343',
    casesPastWeek: 45,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10341,
    completeVaccinations: 70619,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13099: {
    label: 'Early',
    iso3: null,
    population: '10,190',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 584,
    completeVaccinations: 5119,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13101: {
    label: 'Echols',
    iso3: null,
    population: '4,006',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 299,
    completeVaccinations: 1272,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13103: {
    label: 'Effingham',
    iso3: null,
    population: '64,296',
    casesPastWeek: 22,
    casesIncreasingPercentage: 4.761904761904767,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3549,
    completeVaccinations: 25624,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13105: {
    label: 'Elbert',
    iso3: null,
    population: '19,194',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1007,
    completeVaccinations: 8384,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13107: {
    label: 'Emanuel',
    iso3: null,
    population: '22,646',
    casesPastWeek: 7,
    casesIncreasingPercentage: 600.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1365,
    completeVaccinations: 9393,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13109: {
    label: 'Evans',
    iso3: null,
    population: '10,654',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 678,
    completeVaccinations: 4429,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13111: {
    label: 'Fannin',
    iso3: null,
    population: '26,188',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1394,
    completeVaccinations: 11442,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13113: {
    label: 'Fayette',
    iso3: null,
    population: '114,421',
    casesPastWeek: 56,
    casesIncreasingPercentage: 36.585365853658544,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8299,
    completeVaccinations: 73973,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13115: {
    label: 'Floyd',
    iso3: null,
    population: '98,498',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5207,
    completeVaccinations: 43410,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13117: {
    label: 'Forsyth',
    iso3: null,
    population: '244,252',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14255,
    completeVaccinations: 134311,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13119: {
    label: 'Franklin',
    iso3: null,
    population: '23,349',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1035,
    completeVaccinations: 8708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13121: {
    label: 'Fulton',
    iso3: null,
    population: '1,063,937',
    casesPastWeek: 287,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 85470,
    completeVaccinations: 628368,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13123: {
    label: 'Gilmer',
    iso3: null,
    population: '31,369',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1522,
    completeVaccinations: 13263,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13125: {
    label: 'Glascock',
    iso3: null,
    population: '2,971',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 131,
    completeVaccinations: 1006,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13127: {
    label: 'Glynn',
    iso3: null,
    population: '85,292',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6420,
    completeVaccinations: 45287,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13129: {
    label: 'Gordon',
    iso3: null,
    population: '57,963',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2934,
    completeVaccinations: 22466,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13131: {
    label: 'Grady',
    iso3: null,
    population: '24,633',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1470,
    completeVaccinations: 11137,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13133: {
    label: 'Greene',
    iso3: null,
    population: '18,324',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1298,
    completeVaccinations: 10380,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13135: {
    label: 'Gwinnett',
    iso3: null,
    population: '936,250',
    casesPastWeek: 285,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 68886,
    completeVaccinations: 534694,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13137: {
    label: 'Habersham',
    iso3: null,
    population: '45,328',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2468,
    completeVaccinations: 18724,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13139: {
    label: 'Hall',
    iso3: null,
    population: '204,441',
    casesPastWeek: 120,
    casesIncreasingPercentage: 114.28571428571428,
    positiveCasePercentage: 6.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12823,
    completeVaccinations: 95536,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13141: {
    label: 'Hancock',
    iso3: null,
    population: '8,457',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 489,
    completeVaccinations: 4234,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13143: {
    label: 'Haralson',
    iso3: null,
    population: '29,792',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1272,
    completeVaccinations: 10240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13145: {
    label: 'Harris',
    iso3: null,
    population: '35,236',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2855,
    completeVaccinations: 17766,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13147: {
    label: 'Hart',
    iso3: null,
    population: '26,205',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1793,
    completeVaccinations: 10351,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13149: {
    label: 'Heard',
    iso3: null,
    population: '11,923',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 495,
    completeVaccinations: 3505,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13151: {
    label: 'Henry',
    iso3: null,
    population: '234,561',
    casesPastWeek: 98,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15119,
    completeVaccinations: 108114,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13153: {
    label: 'Houston',
    iso3: null,
    population: '157,863',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15485,
    completeVaccinations: 82956,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13155: {
    label: 'Irwin',
    iso3: null,
    population: '9,416',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 513,
    completeVaccinations: 3546,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13157: {
    label: 'Jackson',
    iso3: null,
    population: '72,977',
    casesPastWeek: 44,
    casesIncreasingPercentage: 69.23076923076923,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3810,
    completeVaccinations: 31833,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13159: {
    label: 'Jasper',
    iso3: null,
    population: '14,219',
    casesPastWeek: 25,
    casesIncreasingPercentage: 212.5,
    positiveCasePercentage: 4.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 739,
    completeVaccinations: 5140,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13161: {
    label: 'Jeff Davis',
    iso3: null,
    population: '15,115',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 740,
    completeVaccinations: 4835,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13163: {
    label: 'Jefferson',
    iso3: null,
    population: '15,362',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1082,
    completeVaccinations: 8529,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13165: {
    label: 'Jenkins',
    iso3: null,
    population: '8,676',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 472,
    completeVaccinations: 3384,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13167: {
    label: 'Johnson',
    iso3: null,
    population: '9,643',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 485,
    completeVaccinations: 3498,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13169: {
    label: 'Jones',
    iso3: null,
    population: '28,735',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1626,
    completeVaccinations: 12992,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13171: {
    label: 'Lamar',
    iso3: null,
    population: '19,077',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 3.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 884,
    completeVaccinations: 6833,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13173: {
    label: 'Lanier',
    iso3: null,
    population: '10,423',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 483,
    completeVaccinations: 2926,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13175: {
    label: 'Laurens',
    iso3: null,
    population: '47,546',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3813,
    completeVaccinations: 22884,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13177: {
    label: 'Lee',
    iso3: null,
    population: '29,992',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1933,
    completeVaccinations: 14363,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13179: {
    label: 'Liberty',
    iso3: null,
    population: '61,435',
    casesPastWeek: 25,
    casesIncreasingPercentage: 19.047619047619047,
    positiveCasePercentage: 31.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6451,
    completeVaccinations: 42452,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13181: {
    label: 'Lincoln',
    iso3: null,
    population: '7,921',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 510,
    completeVaccinations: 3775,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13183: {
    label: 'Long',
    iso3: null,
    population: '19,559',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 839,
    completeVaccinations: 4410,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13185: {
    label: 'Lowndes',
    iso3: null,
    population: '117,406',
    casesPastWeek: 33,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7392,
    completeVaccinations: 48658,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13187: {
    label: 'Lumpkin',
    iso3: null,
    population: '33,610',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1440,
    completeVaccinations: 11995,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13189: {
    label: 'McDuffie',
    iso3: null,
    population: '21,312',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1323,
    completeVaccinations: 9868,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13191: {
    label: 'McIntosh',
    iso3: null,
    population: '14,378',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 724,
    completeVaccinations: 6133,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13193: {
    label: 'Macon',
    iso3: null,
    population: '12,947',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 789,
    completeVaccinations: 5778,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13195: {
    label: 'Madison',
    iso3: null,
    population: '29,880',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1518,
    completeVaccinations: 13308,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13197: {
    label: 'Marion',
    iso3: null,
    population: '8,359',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 697,
    completeVaccinations: 3606,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13199: {
    label: 'Meriwether',
    iso3: null,
    population: '21,167',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1154,
    completeVaccinations: 9115,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13201: {
    label: 'Miller',
    iso3: null,
    population: '5,718',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 312,
    completeVaccinations: 3195,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13205: {
    label: 'Mitchell',
    iso3: null,
    population: '21,863',
    casesPastWeek: 16,
    casesIncreasingPercentage: 128.57142857142856,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1248,
    completeVaccinations: 10803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13207: {
    label: 'Monroe',
    iso3: null,
    population: '27,578',
    casesPastWeek: 9,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1529,
    completeVaccinations: 11980,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13209: {
    label: 'Montgomery',
    iso3: null,
    population: '9,172',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 417,
    completeVaccinations: 3396,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13211: {
    label: 'Morgan',
    iso3: null,
    population: '19,276',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 879,
    completeVaccinations: 8998,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13213: {
    label: 'Murray',
    iso3: null,
    population: '40,096',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1986,
    completeVaccinations: 15323,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13215: {
    label: 'Muscogee',
    iso3: null,
    population: '195,769',
    casesPastWeek: 71,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17238,
    completeVaccinations: 92137,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13217: {
    label: 'Newton',
    iso3: null,
    population: '111,744',
    casesPastWeek: 61,
    casesIncreasingPercentage: 10.909090909090914,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7084,
    completeVaccinations: 48955,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13219: {
    label: 'Oconee',
    iso3: null,
    population: '40,280',
    casesPastWeek: 13,
    casesIncreasingPercentage: 18.181818181818187,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2053,
    completeVaccinations: 23735,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13221: {
    label: 'Oglethorpe',
    iso3: null,
    population: '15,259',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 742,
    completeVaccinations: 6644,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13223: {
    label: 'Paulding',
    iso3: null,
    population: '168,667',
    casesPastWeek: 46,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8191,
    completeVaccinations: 65289,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13225: {
    label: 'Peach',
    iso3: null,
    population: '27,546',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1859,
    completeVaccinations: 12939,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13227: {
    label: 'Pickens',
    iso3: null,
    population: '32,591',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1643,
    completeVaccinations: 13927,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13229: {
    label: 'Pierce',
    iso3: null,
    population: '19,465',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1048,
    completeVaccinations: 6719,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13231: {
    label: 'Pike',
    iso3: null,
    population: '18,962',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 623,
    completeVaccinations: 6343,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13233: {
    label: 'Polk',
    iso3: null,
    population: '42,613',
    casesPastWeek: 13,
    casesIncreasingPercentage: 8.333333333333325,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2251,
    completeVaccinations: 17166,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13235: {
    label: 'Pulaski',
    iso3: null,
    population: '11,137',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 423,
    completeVaccinations: 4280,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13237: {
    label: 'Putnam',
    iso3: null,
    population: '22,119',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1193,
    completeVaccinations: 9865,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13239: {
    label: 'Quitman',
    iso3: null,
    population: '2,299',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 148,
    completeVaccinations: 808,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13241: {
    label: 'Rabun',
    iso3: null,
    population: '17,137',
    casesPastWeek: 9,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1025,
    completeVaccinations: 8193,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13243: {
    label: 'Randolph',
    iso3: null,
    population: '6,778',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 477,
    completeVaccinations: 3290,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13245: {
    label: 'Richmond',
    iso3: null,
    population: '202,518',
    casesPastWeek: 66,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16328,
    completeVaccinations: 104772,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13247: {
    label: 'Rockdale',
    iso3: null,
    population: '90,896',
    casesPastWeek: 34,
    casesIncreasingPercentage: 41.66666666666667,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6402,
    completeVaccinations: 47910,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13249: {
    label: 'Schley',
    iso3: null,
    population: '5,257',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 255,
    completeVaccinations: 1894,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13251: {
    label: 'Screven',
    iso3: null,
    population: '13,966',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 816,
    completeVaccinations: 6941,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13253: {
    label: 'Seminole',
    iso3: null,
    population: '8,090',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 444,
    completeVaccinations: 3805,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13255: {
    label: 'Spalding',
    iso3: null,
    population: '66,703',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3227,
    completeVaccinations: 27906,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13257: {
    label: 'Stephens',
    iso3: null,
    population: '25,925',
    casesPastWeek: 9,
    casesIncreasingPercentage: 350.0,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1360,
    completeVaccinations: 11101,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13259: {
    label: 'Stewart',
    iso3: null,
    population: '6,621',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 302,
    completeVaccinations: 2225,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13261: {
    label: 'Sumter',
    iso3: null,
    population: '29,524',
    casesPastWeek: 14,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2332,
    completeVaccinations: 15958,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13263: {
    label: 'Talbot',
    iso3: null,
    population: '6,195',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 392,
    completeVaccinations: 3089,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13265: {
    label: 'Taliaferro',
    iso3: null,
    population: '1,537',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 81,
    completeVaccinations: 799,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13267: {
    label: 'Tattnall',
    iso3: null,
    population: '25,286',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1206,
    completeVaccinations: 8573,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13269: {
    label: 'Taylor',
    iso3: null,
    population: '8,020',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 538,
    completeVaccinations: 3767,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13271: {
    label: 'Telfair',
    iso3: null,
    population: '15,860',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 670,
    completeVaccinations: 4802,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13273: {
    label: 'Terrell',
    iso3: null,
    population: '8,531',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 675,
    completeVaccinations: 5104,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13275: {
    label: 'Thomas',
    iso3: null,
    population: '44,451',
    casesPastWeek: 30,
    casesIncreasingPercentage: 57.89473684210527,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2989,
    completeVaccinations: 22411,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13277: {
    label: 'Tift',
    iso3: null,
    population: '40,644',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2828,
    completeVaccinations: 17110,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13279: {
    label: 'Toombs',
    iso3: null,
    population: '26,830',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1462,
    completeVaccinations: 11559,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13281: {
    label: 'Towns',
    iso3: null,
    population: '12,037',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 764,
    completeVaccinations: 6092,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13283: {
    label: 'Treutlen',
    iso3: null,
    population: '6,901',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 369,
    completeVaccinations: 2493,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13285: {
    label: 'Troup',
    iso3: null,
    population: '69,922',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4121,
    completeVaccinations: 26813,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13287: {
    label: 'Turner',
    iso3: null,
    population: '7,985',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 587,
    completeVaccinations: 4112,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13289: {
    label: 'Twiggs',
    iso3: null,
    population: '8,120',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 803,
    completeVaccinations: 4519,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13291: {
    label: 'Union',
    iso3: null,
    population: '24,511',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1327,
    completeVaccinations: 11572,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13293: {
    label: 'Upson',
    iso3: null,
    population: '26,320',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1381,
    completeVaccinations: 12268,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13295: {
    label: 'Walker',
    iso3: null,
    population: '69,761',
    casesPastWeek: 31,
    casesIncreasingPercentage: 3.3333333333333437,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5613,
    completeVaccinations: 30282,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13297: {
    label: 'Walton',
    iso3: null,
    population: '94,593',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4956,
    completeVaccinations: 40509,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13299: {
    label: 'Ware',
    iso3: null,
    population: '35,734',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2278,
    completeVaccinations: 14198,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13301: {
    label: 'Warren',
    iso3: null,
    population: '5,254',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 326,
    completeVaccinations: 2607,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_13303: {
    label: 'Washington',
    iso3: null,
    population: '20,374',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1181,
    completeVaccinations: 9240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13305: {
    label: 'Wayne',
    iso3: null,
    population: '29,927',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1705,
    completeVaccinations: 12972,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13307: {
    label: 'Webster',
    iso3: null,
    population: '2,607',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 143,
    completeVaccinations: 1171,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13309: {
    label: 'Wheeler',
    iso3: null,
    population: '7,855',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 257,
    completeVaccinations: 2144,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13311: {
    label: 'White',
    iso3: null,
    population: '30,798',
    casesPastWeek: 51,
    casesIncreasingPercentage: 5000.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1101,
    completeVaccinations: 11364,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13313: {
    label: 'Whitfield',
    iso3: null,
    population: '104,628',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5922,
    completeVaccinations: 46842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13315: {
    label: 'Wilcox',
    iso3: null,
    population: '8,635',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 470,
    completeVaccinations: 3245,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13317: {
    label: 'Wilkes',
    iso3: null,
    population: '9,777',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 455,
    completeVaccinations: 4872,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_13319: {
    label: 'Wilkinson',
    iso3: null,
    population: '8,954',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 526,
    completeVaccinations: 4710,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_13321: {
    label: 'Worth',
    iso3: null,
    population: '20,247',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1140,
    completeVaccinations: 8623,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_15001: {
    label: 'Hawaii',
    iso3: null,
    population: '201,513',
    casesPastWeek: 65,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13962,
    completeVaccinations: 135630,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_15003: {
    label: 'Honolulu',
    iso3: null,
    population: '974,563',
    casesPastWeek: 345,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 78785,
    completeVaccinations: 787855,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_15007: {
    label: 'Kauai',
    iso3: null,
    population: '72,293',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4306,
    completeVaccinations: 51672,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_15009: {
    label: 'Maui',
    iso3: null,
    population: '167,417',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13283,
    completeVaccinations: 116230,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16001: {
    label: 'Ada',
    iso3: null,
    population: '481,587',
    casesPastWeek: 344,
    casesIncreasingPercentage: 4.2424242424242475,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36871,
    completeVaccinations: 324402,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16003: {
    label: 'Adams',
    iso3: null,
    population: '4,294',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 179,
    completeVaccinations: 1954,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16005: {
    label: 'Bannock',
    iso3: null,
    population: '87,808',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6090,
    completeVaccinations: 48293,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16007: {
    label: 'Bear Lake',
    iso3: null,
    population: '6,125',
    casesPastWeek: 14,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 331,
    completeVaccinations: 2960,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16009: {
    label: 'Benewah',
    iso3: null,
    population: '9,298',
    casesPastWeek: 28,
    casesIncreasingPercentage: 1300.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 907,
    completeVaccinations: 5188,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16011: {
    label: 'Bingham',
    iso3: null,
    population: '46,811',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 32.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2769,
    completeVaccinations: 23542,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16013: {
    label: 'Blaine',
    iso3: null,
    population: '23,021',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 29.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3032,
    completeVaccinations: 19736,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16015: {
    label: 'Boise',
    iso3: null,
    population: '7,831',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 518,
    completeVaccinations: 2726,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16017: {
    label: 'Bonner',
    iso3: null,
    population: '45,739',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2989,
    completeVaccinations: 20539,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16019: {
    label: 'Bonneville',
    iso3: null,
    population: '119,062',
    casesPastWeek: 99,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 35.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7795,
    completeVaccinations: 66100,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16021: {
    label: 'Boundary',
    iso3: null,
    population: '12,245',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 482,
    completeVaccinations: 4458,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16023: {
    label: 'Butte',
    iso3: null,
    population: '2,597',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 144,
    completeVaccinations: 1310,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_16025: {
    label: 'Camas',
    iso3: null,
    population: '1,106',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 46,
    completeVaccinations: 491,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16027: {
    label: 'Canyon',
    iso3: null,
    population: '229,849',
    casesPastWeek: 200,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14329,
    completeVaccinations: 111405,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16029: {
    label: 'Caribou',
    iso3: null,
    population: '7,155',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 254,
    completeVaccinations: 2977,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16031: {
    label: 'Cassia',
    iso3: null,
    population: '24,030',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.299999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1674,
    completeVaccinations: 10805,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16033: {
    label: 'Clark',
    iso3: null,
    population: '845',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 57,
    completeVaccinations: 368,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16035: {
    label: 'Clearwater',
    iso3: null,
    population: '8,756',
    casesPastWeek: 10,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 503,
    completeVaccinations: 3894,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16037: {
    label: 'Custer',
    iso3: null,
    population: '4,315',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 216,
    completeVaccinations: 2104,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16039: {
    label: 'Elmore',
    iso3: null,
    population: '27,511',
    casesPastWeek: 54,
    casesIncreasingPercentage: 14.893617021276606,
    positiveCasePercentage: 25.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2555,
    completeVaccinations: 16659,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16041: {
    label: 'Franklin',
    iso3: null,
    population: '13,876',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 992,
    completeVaccinations: 6479,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16043: {
    label: 'Fremont',
    iso3: null,
    population: '13,099',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 877,
    completeVaccinations: 6172,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_16045: {
    label: 'Gem',
    iso3: null,
    population: '18,112',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1055,
    completeVaccinations: 7526,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16047: {
    label: 'Gooding',
    iso3: null,
    population: '15,179',
    casesPastWeek: 16,
    casesIncreasingPercentage: 433.3333333333333,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 966,
    completeVaccinations: 6981,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16049: {
    label: 'Idaho',
    iso3: null,
    population: '16,667',
    casesPastWeek: 17,
    casesIncreasingPercentage: 1600.0,
    positiveCasePercentage: 80.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 671,
    completeVaccinations: 5306,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16051: {
    label: 'Jefferson',
    iso3: null,
    population: '29,871',
    casesPastWeek: 17,
    casesIncreasingPercentage: 21.42857142857142,
    positiveCasePercentage: 31.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1632,
    completeVaccinations: 13300,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16053: {
    label: 'Jerome',
    iso3: null,
    population: '24,412',
    casesPastWeek: 17,
    casesIncreasingPercentage: 13.33333333333333,
    positiveCasePercentage: 14.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1493,
    completeVaccinations: 11776,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16055: {
    label: 'Kootenai',
    iso3: null,
    population: '165,697',
    casesPastWeek: 78,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11960,
    completeVaccinations: 79921,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16057: {
    label: 'Latah',
    iso3: null,
    population: '40,108',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 29.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3527,
    completeVaccinations: 25220,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16059: {
    label: 'Lemhi',
    iso3: null,
    population: '8,027',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 58.099999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 470,
    completeVaccinations: 3959,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16061: {
    label: 'Lewis',
    iso3: null,
    population: '3,838',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 60.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 310,
    completeVaccinations: 2098,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16063: {
    label: 'Lincoln',
    iso3: null,
    population: '5,366',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 277,
    completeVaccinations: 2305,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16065: {
    label: 'Madison',
    iso3: null,
    population: '39,907',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 58.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4223,
    completeVaccinations: 22698,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16067: {
    label: 'Minidoka',
    iso3: null,
    population: '21,039',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1278,
    completeVaccinations: 9445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16069: {
    label: 'Nez Perce',
    iso3: null,
    population: '40,408',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3849,
    completeVaccinations: 24303,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16071: {
    label: 'Oneida',
    iso3: null,
    population: '4,531',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 289,
    completeVaccinations: 2183,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16073: {
    label: 'Owyhee',
    iso3: null,
    population: '11,823',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 665,
    completeVaccinations: 4349,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16075: {
    label: 'Payette',
    iso3: null,
    population: '23,951',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.800000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1300,
    completeVaccinations: 7950,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16077: {
    label: 'Power',
    iso3: null,
    population: '7,681',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 427,
    completeVaccinations: 4066,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16079: {
    label: 'Shoshone',
    iso3: null,
    population: '12,882',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 611,
    completeVaccinations: 5708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_16081: {
    label: 'Teton',
    iso3: null,
    population: '12,142',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1707,
    completeVaccinations: 8037,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_16083: {
    label: 'Twin Falls',
    iso3: null,
    population: '86,878',
    casesPastWeek: 103,
    casesIncreasingPercentage: 11.956521739130444,
    positiveCasePercentage: 19.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4589,
    completeVaccinations: 45091,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16085: {
    label: 'Valley',
    iso3: null,
    population: '11,392',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 982,
    completeVaccinations: 7385,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_16087: {
    label: 'Washington',
    iso3: null,
    population: '10,194',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 503,
    completeVaccinations: 4200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_17001: {
    label: 'Adams',
    iso3: null,
    population: '65,435',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3085,
    completeVaccinations: 35063,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17003: {
    label: 'Alexander',
    iso3: null,
    population: '5,761',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 398,
    completeVaccinations: 2062,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17005: {
    label: 'Bond',
    iso3: null,
    population: '16,426',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 668,
    completeVaccinations: 9354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17007: {
    label: 'Boone',
    iso3: null,
    population: '53,544',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2708,
    completeVaccinations: 33826,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17009: {
    label: 'Brown',
    iso3: null,
    population: '6,578',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 290,
    completeVaccinations: 3576,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17011: {
    label: 'Bureau',
    iso3: null,
    population: '32,628',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1898,
    completeVaccinations: 19212,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17013: {
    label: 'Calhoun',
    iso3: null,
    population: '4,739',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 210,
    completeVaccinations: 2533,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17015: {
    label: 'Carroll',
    iso3: null,
    population: '14,305',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1019,
    completeVaccinations: 9728,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17017: {
    label: 'Cass',
    iso3: null,
    population: '12,147',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 650,
    completeVaccinations: 7684,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17019: {
    label: 'Champaign',
    iso3: null,
    population: '209,689',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16365,
    completeVaccinations: 143233,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17021: {
    label: 'Christian',
    iso3: null,
    population: '32,304',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1466,
    completeVaccinations: 16258,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17023: {
    label: 'Clark',
    iso3: null,
    population: '15,441',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1304,
    completeVaccinations: 8092,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17025: {
    label: 'Clay',
    iso3: null,
    population: '13,184',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 334,
    completeVaccinations: 5172,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17027: {
    label: 'Clinton',
    iso3: null,
    population: '37,562',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2045,
    completeVaccinations: 22965,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17029: {
    label: 'Coles',
    iso3: null,
    population: '50,621',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1974,
    completeVaccinations: 24054,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17031: {
    label: 'Cook',
    iso3: null,
    population: '5,150,233',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 459074,
    completeVaccinations: 3876105,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17033: {
    label: 'Crawford',
    iso3: null,
    population: '18,667',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 985,
    completeVaccinations: 9831,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17035: {
    label: 'Cumberland',
    iso3: null,
    population: '10,766',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 331,
    completeVaccinations: 4335,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17037: {
    label: 'DeKalb',
    iso3: null,
    population: '104,897',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.1000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4497,
    completeVaccinations: 60848,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17039: {
    label: 'De Witt',
    iso3: null,
    population: '15,638',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 558,
    completeVaccinations: 8484,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17041: {
    label: 'Douglas',
    iso3: null,
    population: '19,465',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 762,
    completeVaccinations: 9860,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17043: {
    label: 'DuPage',
    iso3: null,
    population: '922,921',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 58521,
    completeVaccinations: 735240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17045: {
    label: 'Edgar',
    iso3: null,
    population: '17,161',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 900,
    completeVaccinations: 8748,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17047: {
    label: 'Edwards',
    iso3: null,
    population: '6,395',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 209,
    completeVaccinations: 2684,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17049: {
    label: 'Effingham',
    iso3: null,
    population: '34,008',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1390,
    completeVaccinations: 17088,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17051: {
    label: 'Fayette',
    iso3: null,
    population: '21,336',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 703,
    completeVaccinations: 7901,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17053: {
    label: 'Ford',
    iso3: null,
    population: '12,961',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 467,
    completeVaccinations: 7681,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17055: {
    label: 'Franklin',
    iso3: null,
    population: '38,469',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1706,
    completeVaccinations: 17829,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17057: {
    label: 'Fulton',
    iso3: null,
    population: '34,340',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1235,
    completeVaccinations: 20135,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17059: {
    label: 'Gallatin',
    iso3: null,
    population: '4,828',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 330,
    completeVaccinations: 2774,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17061: {
    label: 'Greene',
    iso3: null,
    population: '12,969',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 329,
    completeVaccinations: 5808,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17063: {
    label: 'Grundy',
    iso3: null,
    population: '51,054',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2491,
    completeVaccinations: 30364,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17065: {
    label: 'Hamilton',
    iso3: null,
    population: '8,116',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 45.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 281,
    completeVaccinations: 3296,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17067: {
    label: 'Hancock',
    iso3: null,
    population: '17,708',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.299999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1730,
    completeVaccinations: 9089,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17069: {
    label: 'Hardin',
    iso3: null,
    population: '3,821',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 154,
    completeVaccinations: 1671,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17071: {
    label: 'Henderson',
    iso3: null,
    population: '6,646',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 496,
    completeVaccinations: 3496,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_17073: {
    label: 'Henry',
    iso3: null,
    population: '48,913',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2837,
    completeVaccinations: 29900,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17075: {
    label: 'Iroquois',
    iso3: null,
    population: '27,114',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 977,
    completeVaccinations: 14280,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17077: {
    label: 'Jackson',
    iso3: null,
    population: '56,750',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3278,
    completeVaccinations: 30574,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17079: {
    label: 'Jasper',
    iso3: null,
    population: '9,610',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 337,
    completeVaccinations: 4098,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17081: {
    label: 'Jefferson',
    iso3: null,
    population: '37,684',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1735,
    completeVaccinations: 18289,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17083: {
    label: 'Jersey',
    iso3: null,
    population: '21,773',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 883,
    completeVaccinations: 12497,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17085: {
    label: 'Jo Daviess',
    iso3: null,
    population: '21,235',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2191,
    completeVaccinations: 17512,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17087: {
    label: 'Johnson',
    iso3: null,
    population: '12,417',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.8999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 719,
    completeVaccinations: 6769,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17089: {
    label: 'Kane',
    iso3: null,
    population: '532,403',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 30699,
    completeVaccinations: 368324,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17091: {
    label: 'Kankakee',
    iso3: null,
    population: '109,862',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4555,
    completeVaccinations: 59413,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17093: {
    label: 'Kendall',
    iso3: null,
    population: '128,990',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6877,
    completeVaccinations: 92614,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17095: {
    label: 'Knox',
    iso3: null,
    population: '49,699',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2444,
    completeVaccinations: 30175,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17097: {
    label: 'Lake',
    iso3: null,
    population: '696,535',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 66167,
    completeVaccinations: 581201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17099: {
    label: 'LaSalle',
    iso3: null,
    population: '108,669',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5060,
    completeVaccinations: 65181,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17101: {
    label: 'Lawrence',
    iso3: null,
    population: '15,678',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 970,
    completeVaccinations: 6618,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17103: {
    label: 'Lee',
    iso3: null,
    population: '34,096',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.5000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1420,
    completeVaccinations: 20808,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17105: {
    label: 'Livingston',
    iso3: null,
    population: '35,648',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1294,
    completeVaccinations: 18481,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17107: {
    label: 'Logan',
    iso3: null,
    population: '28,618',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 992,
    completeVaccinations: 15664,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17109: {
    label: 'McDonough',
    iso3: null,
    population: '29,682',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1648,
    completeVaccinations: 14822,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17111: {
    label: 'McHenry',
    iso3: null,
    population: '307,774',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17157,
    completeVaccinations: 214124,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17113: {
    label: 'McLean',
    iso3: null,
    population: '171,517',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9200,
    completeVaccinations: 113758,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17115: {
    label: 'Macon',
    iso3: null,
    population: '104,009',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4386,
    completeVaccinations: 57100,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17117: {
    label: 'Macoupin',
    iso3: null,
    population: '44,926',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1948,
    completeVaccinations: 25220,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17119: {
    label: 'Madison',
    iso3: null,
    population: '262,966',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17999,
    completeVaccinations: 169884,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17121: {
    label: 'Marion',
    iso3: null,
    population: '37,205',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1276,
    completeVaccinations: 17555,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17123: {
    label: 'Marshall',
    iso3: null,
    population: '11,438',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 479,
    completeVaccinations: 6725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17125: {
    label: 'Mason',
    iso3: null,
    population: '13,359',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 393,
    completeVaccinations: 7381,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17127: {
    label: 'Massac',
    iso3: null,
    population: '13,772',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 765,
    completeVaccinations: 7051,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17129: {
    label: 'Menard',
    iso3: null,
    population: '12,196',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 482,
    completeVaccinations: 7143,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17131: {
    label: 'Mercer',
    iso3: null,
    population: '15,437',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1002,
    completeVaccinations: 9714,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17133: {
    label: 'Monroe',
    iso3: null,
    population: '34,637',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.9000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2026,
    completeVaccinations: 22345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17135: {
    label: 'Montgomery',
    iso3: null,
    population: '28,414',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1336,
    completeVaccinations: 14937,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17137: {
    label: 'Morgan',
    iso3: null,
    population: '33,658',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1410,
    completeVaccinations: 18889,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17139: {
    label: 'Moultrie',
    iso3: null,
    population: '14,501',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 400,
    completeVaccinations: 6555,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17141: {
    label: 'Ogle',
    iso3: null,
    population: '50,643',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2264,
    completeVaccinations: 30089,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17143: {
    label: 'Peoria',
    iso3: null,
    population: '179,179',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9702,
    completeVaccinations: 113736,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17145: {
    label: 'Perry',
    iso3: null,
    population: '20,916',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1027,
    completeVaccinations: 10814,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17147: {
    label: 'Piatt',
    iso3: null,
    population: '16,344',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 695,
    completeVaccinations: 10537,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17149: {
    label: 'Pike',
    iso3: null,
    population: '15,561',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 570,
    completeVaccinations: 6518,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17151: {
    label: 'Pope',
    iso3: null,
    population: '4,177',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 162,
    completeVaccinations: 1640,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17153: {
    label: 'Pulaski',
    iso3: null,
    population: '5,335',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 356,
    completeVaccinations: 2461,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17155: {
    label: 'Putnam',
    iso3: null,
    population: '5,739',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 253,
    completeVaccinations: 3382,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17157: {
    label: 'Randolph',
    iso3: null,
    population: '31,782',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1352,
    completeVaccinations: 16949,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17159: {
    label: 'Richland',
    iso3: null,
    population: '15,513',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 495,
    completeVaccinations: 7684,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17161: {
    label: 'Rock Island',
    iso3: null,
    population: '141,879',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16999,
    completeVaccinations: 87935,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17163: {
    label: 'St. Clair',
    iso3: null,
    population: '259,686',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22394,
    completeVaccinations: 170811,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17165: {
    label: 'Saline',
    iso3: null,
    population: '23,491',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1220,
    completeVaccinations: 11759,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17167: {
    label: 'Sangamon',
    iso3: null,
    population: '194,672',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10748,
    completeVaccinations: 130915,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17169: {
    label: 'Schuyler',
    iso3: null,
    population: '6,768',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 187,
    completeVaccinations: 3857,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17171: {
    label: 'Scott',
    iso3: null,
    population: '4,951',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 116,
    completeVaccinations: 2114,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17173: {
    label: 'Shelby',
    iso3: null,
    population: '21,634',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 534,
    completeVaccinations: 9272,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17175: {
    label: 'Stark',
    iso3: null,
    population: '5,342',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 183,
    completeVaccinations: 2923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17177: {
    label: 'Stephenson',
    iso3: null,
    population: '44,498',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2365,
    completeVaccinations: 27946,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17179: {
    label: 'Tazewell',
    iso3: null,
    population: '131,803',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4849,
    completeVaccinations: 80083,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17181: {
    label: 'Union',
    iso3: null,
    population: '16,653',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 845,
    completeVaccinations: 9792,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17183: {
    label: 'Vermilion',
    iso3: null,
    population: '75,758',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3617,
    completeVaccinations: 35687,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17185: {
    label: 'Wabash',
    iso3: null,
    population: '11,520',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 727,
    completeVaccinations: 5868,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17187: {
    label: 'Warren',
    iso3: null,
    population: '16,844',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 664,
    completeVaccinations: 8567,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17189: {
    label: 'Washington',
    iso3: null,
    population: '13,887',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 479,
    completeVaccinations: 8307,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17191: {
    label: 'Wayne',
    iso3: null,
    population: '16,215',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 527,
    completeVaccinations: 6520,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17193: {
    label: 'White',
    iso3: null,
    population: '13,537',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 790,
    completeVaccinations: 7332,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17195: {
    label: 'Whiteside',
    iso3: null,
    population: '55,175',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3295,
    completeVaccinations: 32766,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17197: {
    label: 'Will',
    iso3: null,
    population: '690,743',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 41402,
    completeVaccinations: 483601,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17199: {
    label: 'Williamson',
    iso3: null,
    population: '66,597',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3627,
    completeVaccinations: 38321,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17201: {
    label: 'Winnebago',
    iso3: null,
    population: '282,572',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15371,
    completeVaccinations: 170043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_17203: {
    label: 'Woodford',
    iso3: null,
    population: '38,459',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1080,
    completeVaccinations: 21534,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_18001: {
    label: 'Adams',
    iso3: null,
    population: '35,777',
    casesPastWeek: 21,
    casesIncreasingPercentage: 5.000000000000004,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1093,
    completeVaccinations: 12374,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18003: {
    label: 'Allen',
    iso3: null,
    population: '379,299',
    casesPastWeek: 237,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21634,
    completeVaccinations: 209031,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18005: {
    label: 'Bartholomew',
    iso3: null,
    population: '83,779',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5039,
    completeVaccinations: 54028,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18007: {
    label: 'Benton',
    iso3: null,
    population: '8,748',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 258,
    completeVaccinations: 3850,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18009: {
    label: 'Blackford',
    iso3: null,
    population: '11,758',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 434,
    completeVaccinations: 5870,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18011: {
    label: 'Boone',
    iso3: null,
    population: '67,843',
    casesPastWeek: 13,
    casesIncreasingPercentage: 8.333333333333325,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4604,
    completeVaccinations: 50581,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18013: {
    label: 'Brown',
    iso3: null,
    population: '15,092',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 966,
    completeVaccinations: 8094,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18015: {
    label: 'Carroll',
    iso3: null,
    population: '20,257',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 737,
    completeVaccinations: 7838,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18017: {
    label: 'Cass',
    iso3: null,
    population: '37,689',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2097,
    completeVaccinations: 18209,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18019: {
    label: 'Clark',
    iso3: null,
    population: '118,302',
    casesPastWeek: 118,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11731,
    completeVaccinations: 75567,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18021: {
    label: 'Clay',
    iso3: null,
    population: '26,225',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1444,
    completeVaccinations: 15536,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18023: {
    label: 'Clinton',
    iso3: null,
    population: '32,399',
    casesPastWeek: 68,
    casesIncreasingPercentage: 36.00000000000001,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1626,
    completeVaccinations: 16439,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18025: {
    label: 'Crawford',
    iso3: null,
    population: '10,577',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 430,
    completeVaccinations: 4121,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_18027: {
    label: 'Daviess',
    iso3: null,
    population: '33,351',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1059,
    completeVaccinations: 11429,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18029: {
    label: 'Dearborn',
    iso3: null,
    population: '49,458',
    casesPastWeek: 30,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4257,
    completeVaccinations: 32408,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18031: {
    label: 'Decatur',
    iso3: null,
    population: '26,559',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1187,
    completeVaccinations: 13335,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18033: {
    label: 'DeKalb',
    iso3: null,
    population: '43,475',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1696,
    completeVaccinations: 19140,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18035: {
    label: 'Delaware',
    iso3: null,
    population: '114,135',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5554,
    completeVaccinations: 58016,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18037: {
    label: 'Dubois',
    iso3: null,
    population: '42,736',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1903,
    completeVaccinations: 25472,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18039: {
    label: 'Elkhart',
    iso3: null,
    population: '206,341',
    casesPastWeek: 88,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11843,
    completeVaccinations: 93633,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18041: {
    label: 'Fayette',
    iso3: null,
    population: '23,102',
    casesPastWeek: 29,
    casesIncreasingPercentage: 20.833333333333325,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 886,
    completeVaccinations: 9790,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18043: {
    label: 'Floyd',
    iso3: null,
    population: '78,522',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5412,
    completeVaccinations: 46702,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18045: {
    label: 'Fountain',
    iso3: null,
    population: '16,346',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 873,
    completeVaccinations: 7986,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18047: {
    label: 'Franklin',
    iso3: null,
    population: '22,758',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1168,
    completeVaccinations: 9146,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18049: {
    label: 'Fulton',
    iso3: null,
    population: '19,974',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 849,
    completeVaccinations: 8346,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18051: {
    label: 'Gibson',
    iso3: null,
    population: '33,659',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1681,
    completeVaccinations: 16256,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18053: {
    label: 'Grant',
    iso3: null,
    population: '65,769',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3089,
    completeVaccinations: 28856,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18055: {
    label: 'Greene',
    iso3: null,
    population: '31,922',
    casesPastWeek: 32,
    casesIncreasingPercentage: 10.344827586206895,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1522,
    completeVaccinations: 13802,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18057: {
    label: 'Hamilton',
    iso3: null,
    population: '338,011',
    casesPastWeek: 150,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24572,
    completeVaccinations: 253825,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18059: {
    label: 'Hancock',
    iso3: null,
    population: '78,168',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4284,
    completeVaccinations: 55351,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18061: {
    label: 'Harrison',
    iso3: null,
    population: '40,515',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2530,
    completeVaccinations: 21648,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18063: {
    label: 'Hendricks',
    iso3: null,
    population: '170,311',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12473,
    completeVaccinations: 129321,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18065: {
    label: 'Henry',
    iso3: null,
    population: '47,972',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2137,
    completeVaccinations: 26152,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18067: {
    label: 'Howard',
    iso3: null,
    population: '82,544',
    casesPastWeek: 44,
    casesIncreasingPercentage: 18.918918918918926,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3938,
    completeVaccinations: 41115,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18069: {
    label: 'Huntington',
    iso3: null,
    population: '36,520',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1654,
    completeVaccinations: 19183,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18071: {
    label: 'Jackson',
    iso3: null,
    population: '44,231',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2570,
    completeVaccinations: 24702,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18073: {
    label: 'Jasper',
    iso3: null,
    population: '33,562',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1664,
    completeVaccinations: 16744,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18075: {
    label: 'Jay',
    iso3: null,
    population: '20,436',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 915,
    completeVaccinations: 8310,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18077: {
    label: 'Jefferson',
    iso3: null,
    population: '32,308',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1714,
    completeVaccinations: 18867,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18079: {
    label: 'Jennings',
    iso3: null,
    population: '27,735',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1268,
    completeVaccinations: 11826,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18081: {
    label: 'Johnson',
    iso3: null,
    population: '158,167',
    casesPastWeek: 72,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12112,
    completeVaccinations: 98030,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18083: {
    label: 'Knox',
    iso3: null,
    population: '36,594',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1668,
    completeVaccinations: 18704,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18085: {
    label: 'Kosciusko',
    iso3: null,
    population: '79,456',
    casesPastWeek: 52,
    casesIncreasingPercentage: 18.181818181818187,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3432,
    completeVaccinations: 33753,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18087: {
    label: 'LaGrange',
    iso3: null,
    population: '39,614',
    casesPastWeek: 9,
    casesIncreasingPercentage: 125.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 889,
    completeVaccinations: 9261,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18089: {
    label: 'Lake',
    iso3: null,
    population: '485,493',
    casesPastWeek: 250,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 35309,
    completeVaccinations: 290931,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18091: {
    label: 'LaPorte',
    iso3: null,
    population: '109,888',
    casesPastWeek: 48,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6603,
    completeVaccinations: 64490,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18093: {
    label: 'Lawrence',
    iso3: null,
    population: '45,370',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2267,
    completeVaccinations: 23346,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18095: {
    label: 'Madison',
    iso3: null,
    population: '129,569',
    casesPastWeek: 79,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6434,
    completeVaccinations: 71236,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18097: {
    label: 'Marion',
    iso3: null,
    population: '964,582',
    casesPastWeek: 442,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 79225,
    completeVaccinations: 576699,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18099: {
    label: 'Marshall',
    iso3: null,
    population: '46,258',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2258,
    completeVaccinations: 21632,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18101: {
    label: 'Martin',
    iso3: null,
    population: '10,255',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 376,
    completeVaccinations: 4798,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18103: {
    label: 'Miami',
    iso3: null,
    population: '35,516',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1406,
    completeVaccinations: 15012,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18105: {
    label: 'Monroe',
    iso3: null,
    population: '148,431',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10784,
    completeVaccinations: 91842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18107: {
    label: 'Montgomery',
    iso3: null,
    population: '38,338',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1754,
    completeVaccinations: 18341,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18109: {
    label: 'Morgan',
    iso3: null,
    population: '70,489',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3663,
    completeVaccinations: 39888,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18111: {
    label: 'Newton',
    iso3: null,
    population: '13,984',
    casesPastWeek: 21,
    casesIncreasingPercentage: 110.00000000000001,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 514,
    completeVaccinations: 4740,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18113: {
    label: 'Noble',
    iso3: null,
    population: '47,744',
    casesPastWeek: 21,
    casesIncreasingPercentage: 31.25,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2001,
    completeVaccinations: 19202,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18115: {
    label: 'Ohio',
    iso3: null,
    population: '5,875',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 439,
    completeVaccinations: 4624,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18117: {
    label: 'Orange',
    iso3: null,
    population: '19,646',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1090,
    completeVaccinations: 11397,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18119: {
    label: 'Owen',
    iso3: null,
    population: '20,799',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 861,
    completeVaccinations: 9215,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18121: {
    label: 'Parke',
    iso3: null,
    population: '16,937',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 647,
    completeVaccinations: 7094,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18123: {
    label: 'Perry',
    iso3: null,
    population: '19,169',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1003,
    completeVaccinations: 11391,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18125: {
    label: 'Pike',
    iso3: null,
    population: '12,389',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 623,
    completeVaccinations: 7454,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18127: {
    label: 'Porter',
    iso3: null,
    population: '170,389',
    casesPastWeek: 84,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9640,
    completeVaccinations: 102050,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18129: {
    label: 'Posey',
    iso3: null,
    population: '25,427',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1066,
    completeVaccinations: 12551,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18131: {
    label: 'Pulaski',
    iso3: null,
    population: '12,353',
    casesPastWeek: 6,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 550,
    completeVaccinations: 5831,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18133: {
    label: 'Putnam',
    iso3: null,
    population: '37,576',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1531,
    completeVaccinations: 16725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18135: {
    label: 'Randolph',
    iso3: null,
    population: '24,665',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1406,
    completeVaccinations: 11821,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18137: {
    label: 'Ripley',
    iso3: null,
    population: '28,324',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1338,
    completeVaccinations: 14274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18139: {
    label: 'Rush',
    iso3: null,
    population: '16,581',
    casesPastWeek: 14,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 629,
    completeVaccinations: 8092,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18141: {
    label: 'St. Joseph',
    iso3: null,
    population: '271,826',
    casesPastWeek: 128,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20314,
    completeVaccinations: 167585,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18143: {
    label: 'Scott',
    iso3: null,
    population: '23,873',
    casesPastWeek: 21,
    casesIncreasingPercentage: 5.000000000000004,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1136,
    completeVaccinations: 10897,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18145: {
    label: 'Shelby',
    iso3: null,
    population: '44,729',
    casesPastWeek: 33,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2673,
    completeVaccinations: 27299,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18147: {
    label: 'Spencer',
    iso3: null,
    population: '20,277',
    casesPastWeek: 9,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 947,
    completeVaccinations: 9485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18149: {
    label: 'Starke',
    iso3: null,
    population: '22,995',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 865,
    completeVaccinations: 8933,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18151: {
    label: 'Steuben',
    iso3: null,
    population: '34,594',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1621,
    completeVaccinations: 17106,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18153: {
    label: 'Sullivan',
    iso3: null,
    population: '20,669',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 918,
    completeVaccinations: 10695,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18155: {
    label: 'Switzerland',
    iso3: null,
    population: '10,751',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 337,
    completeVaccinations: 3475,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_18157: {
    label: 'Tippecanoe',
    iso3: null,
    population: '195,732',
    casesPastWeek: 78,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13954,
    completeVaccinations: 117558,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18159: {
    label: 'Tipton',
    iso3: null,
    population: '15,148',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 587,
    completeVaccinations: 7139,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18161: {
    label: 'Union',
    iso3: null,
    population: '7,054',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 399,
    completeVaccinations: 3766,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18163: {
    label: 'Vanderburgh',
    iso3: null,
    population: '181,451',
    casesPastWeek: 79,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10327,
    completeVaccinations: 109430,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18165: {
    label: 'Vermillion',
    iso3: null,
    population: '15,498',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 863,
    completeVaccinations: 7615,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18167: {
    label: 'Vigo',
    iso3: null,
    population: '107,038',
    casesPastWeek: 53,
    casesIncreasingPercentage: 3.9215686274509887,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5414,
    completeVaccinations: 55952,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18169: {
    label: 'Wabash',
    iso3: null,
    population: '30,996',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1323,
    completeVaccinations: 13365,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18171: {
    label: 'Warren',
    iso3: null,
    population: '8,265',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 433,
    completeVaccinations: 3968,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_18173: {
    label: 'Warrick',
    iso3: null,
    population: '62,998',
    casesPastWeek: 35,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3877,
    completeVaccinations: 41214,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18175: {
    label: 'Washington',
    iso3: null,
    population: '28,036',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1229,
    completeVaccinations: 11736,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18177: {
    label: 'Wayne',
    iso3: null,
    population: '65,884',
    casesPastWeek: 71,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3584,
    completeVaccinations: 34553,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18179: {
    label: 'Wells',
    iso3: null,
    population: '28,296',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1182,
    completeVaccinations: 12310,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18181: {
    label: 'White',
    iso3: null,
    population: '24,102',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1217,
    completeVaccinations: 13724,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_18183: {
    label: 'Whitley',
    iso3: null,
    population: '33,964',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1285,
    completeVaccinations: 16603,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19001: {
    label: 'Adair',
    iso3: null,
    population: '7,152',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 281,
    completeVaccinations: 3619,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19003: {
    label: 'Adams',
    iso3: null,
    population: '3,602',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 136,
    completeVaccinations: 2076,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_19005: {
    label: 'Allamakee',
    iso3: null,
    population: '13,687',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 850,
    completeVaccinations: 7691,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19007: {
    label: 'Appanoose',
    iso3: null,
    population: '12,426',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 470,
    completeVaccinations: 6073,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19009: {
    label: 'Audubon',
    iso3: null,
    population: '5,496',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 119,
    completeVaccinations: 3287,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19011: {
    label: 'Benton',
    iso3: null,
    population: '25,645',
    casesPastWeek: 19,
    casesIncreasingPercentage: 72.72727272727273,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 881,
    completeVaccinations: 15590,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19013: {
    label: 'Black Hawk',
    iso3: null,
    population: '131,228',
    casesPastWeek: 88,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6909,
    completeVaccinations: 82257,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19015: {
    label: 'Boone',
    iso3: null,
    population: '26,234',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1166,
    completeVaccinations: 17449,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19017: {
    label: 'Bremer',
    iso3: null,
    population: '25,062',
    casesPastWeek: 21,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 896,
    completeVaccinations: 15502,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19019: {
    label: 'Buchanan',
    iso3: null,
    population: '21,175',
    casesPastWeek: 18,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 712,
    completeVaccinations: 11707,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19021: {
    label: 'Buena Vista',
    iso3: null,
    population: '19,620',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1280,
    completeVaccinations: 14041,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19023: {
    label: 'Butler',
    iso3: null,
    population: '14,439',
    casesPastWeek: 10,
    casesIncreasingPercentage: 42.85714285714286,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 445,
    completeVaccinations: 8485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19025: {
    label: 'Calhoun',
    iso3: null,
    population: '9,668',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 333,
    completeVaccinations: 5919,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19027: {
    label: 'Carroll',
    iso3: null,
    population: '20,165',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 662,
    completeVaccinations: 12738,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19029: {
    label: 'Cass',
    iso3: null,
    population: '12,836',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 521,
    completeVaccinations: 7680,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19031: {
    label: 'Cedar',
    iso3: null,
    population: '18,627',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 774,
    completeVaccinations: 11546,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19033: {
    label: 'Cerro Gordo',
    iso3: null,
    population: '42,450',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2087,
    completeVaccinations: 27745,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19035: {
    label: 'Cherokee',
    iso3: null,
    population: '11,235',
    casesPastWeek: 7,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 448,
    completeVaccinations: 6155,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19037: {
    label: 'Chickasaw',
    iso3: null,
    population: '11,933',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 386,
    completeVaccinations: 6730,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19039: {
    label: 'Clarke',
    iso3: null,
    population: '9,395',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 426,
    completeVaccinations: 5304,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19041: {
    label: 'Clay',
    iso3: null,
    population: '16,016',
    casesPastWeek: 21,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 701,
    completeVaccinations: 8607,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19043: {
    label: 'Clayton',
    iso3: null,
    population: '17,549',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 710,
    completeVaccinations: 8716,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19045: {
    label: 'Clinton',
    iso3: null,
    population: '46,429',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2493,
    completeVaccinations: 26312,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19047: {
    label: 'Crawford',
    iso3: null,
    population: '16,820',
    casesPastWeek: 36,
    casesIncreasingPercentage: 350.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 761,
    completeVaccinations: 9367,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19049: {
    label: 'Dallas',
    iso3: null,
    population: '93,453',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5376,
    completeVaccinations: 65048,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19051: {
    label: 'Davis',
    iso3: null,
    population: '9,000',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 219,
    completeVaccinations: 3409,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19053: {
    label: 'Decatur',
    iso3: null,
    population: '7,870',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 343,
    completeVaccinations: 3356,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19055: {
    label: 'Delaware',
    iso3: null,
    population: '17,011',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 488,
    completeVaccinations: 9590,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19057: {
    label: 'Des Moines',
    iso3: null,
    population: '38,967',
    casesPastWeek: 30,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1828,
    completeVaccinations: 20760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19059: {
    label: 'Dickinson',
    iso3: null,
    population: '17,258',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 949,
    completeVaccinations: 10623,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19061: {
    label: 'Dubuque',
    iso3: null,
    population: '97,311',
    casesPastWeek: 60,
    casesIncreasingPercentage: 15.384615384615374,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4578,
    completeVaccinations: 64928,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19063: {
    label: 'Emmet',
    iso3: null,
    population: '9,208',
    casesPastWeek: 16,
    casesIncreasingPercentage: 220.00000000000003,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 432,
    completeVaccinations: 4995,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19065: {
    label: 'Fayette',
    iso3: null,
    population: '19,650',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 698,
    completeVaccinations: 10997,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19067: {
    label: 'Floyd',
    iso3: null,
    population: '15,642',
    casesPastWeek: 4,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 549,
    completeVaccinations: 8535,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19069: {
    label: 'Franklin',
    iso3: null,
    population: '10,070',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 372,
    completeVaccinations: 5654,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19071: {
    label: 'Fremont',
    iso3: null,
    population: '6,960',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 462,
    completeVaccinations: 3840,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19073: {
    label: 'Greene',
    iso3: null,
    population: '8,888',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 317,
    completeVaccinations: 5328,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_19075: {
    label: 'Grundy',
    iso3: null,
    population: '12,232',
    casesPastWeek: 9,
    casesIncreasingPercentage: 125.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 421,
    completeVaccinations: 7803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19077: {
    label: 'Guthrie',
    iso3: null,
    population: '10,689',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 411,
    completeVaccinations: 6438,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19079: {
    label: 'Hamilton',
    iso3: null,
    population: '14,773',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 686,
    completeVaccinations: 9667,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19081: {
    label: 'Hancock',
    iso3: null,
    population: '10,630',
    casesPastWeek: 6,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 327,
    completeVaccinations: 5442,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19083: {
    label: 'Hardin',
    iso3: null,
    population: '16,846',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 595,
    completeVaccinations: 9614,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19085: {
    label: 'Harrison',
    iso3: null,
    population: '14,049',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1056,
    completeVaccinations: 7623,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19087: {
    label: 'Henry',
    iso3: null,
    population: '19,954',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 836,
    completeVaccinations: 11228,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19089: {
    label: 'Howard',
    iso3: null,
    population: '9,158',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 378,
    completeVaccinations: 4927,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19091: {
    label: 'Humboldt',
    iso3: null,
    population: '9,558',
    casesPastWeek: 10,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 306,
    completeVaccinations: 4902,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19093: {
    label: 'Ida',
    iso3: null,
    population: '6,860',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 272,
    completeVaccinations: 3442,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19095: {
    label: 'Iowa',
    iso3: null,
    population: '16,184',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 673,
    completeVaccinations: 10423,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19097: {
    label: 'Jackson',
    iso3: null,
    population: '19,439',
    casesPastWeek: 14,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 563,
    completeVaccinations: 10560,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19099: {
    label: 'Jasper',
    iso3: null,
    population: '37,185',
    casesPastWeek: 27,
    casesIncreasingPercentage: 17.391304347826097,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1613,
    completeVaccinations: 22360,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19101: {
    label: 'Jefferson',
    iso3: null,
    population: '18,295',
    casesPastWeek: 48,
    casesIncreasingPercentage: 220.00000000000003,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 844,
    completeVaccinations: 9118,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19103: {
    label: 'Johnson',
    iso3: null,
    population: '151,140',
    casesPastWeek: 87,
    casesIncreasingPercentage: 26.086956521739136,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13090,
    completeVaccinations: 116188,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19105: {
    label: 'Jones',
    iso3: null,
    population: '20,681',
    casesPastWeek: 9,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 744,
    completeVaccinations: 12445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19107: {
    label: 'Keokuk',
    iso3: null,
    population: '10,246',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 325,
    completeVaccinations: 4898,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19109: {
    label: 'Kossuth',
    iso3: null,
    population: '14,813',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 601,
    completeVaccinations: 7911,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19111: {
    label: 'Lee',
    iso3: null,
    population: '33,657',
    casesPastWeek: 26,
    casesIncreasingPercentage: 36.8421052631579,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1702,
    completeVaccinations: 17892,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19113: {
    label: 'Linn',
    iso3: null,
    population: '226,706',
    casesPastWeek: 131,
    casesIncreasingPercentage: 10.084033613445387,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13464,
    completeVaccinations: 156847,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19115: {
    label: 'Louisa',
    iso3: null,
    population: '11,035',
    casesPastWeek: 4,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 484,
    completeVaccinations: 6040,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19117: {
    label: 'Lucas',
    iso3: null,
    population: '8,600',
    casesPastWeek: 7,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 266,
    completeVaccinations: 4022,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19119: {
    label: 'Lyon',
    iso3: null,
    population: '11,755',
    casesPastWeek: 8,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 740,
    completeVaccinations: 5194,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19121: {
    label: 'Madison',
    iso3: null,
    population: '16,338',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 669,
    completeVaccinations: 9062,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19123: {
    label: 'Mahaska',
    iso3: null,
    population: '22,095',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 819,
    completeVaccinations: 10070,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19125: {
    label: 'Marion',
    iso3: null,
    population: '33,253',
    casesPastWeek: 26,
    casesIncreasingPercentage: 30.000000000000004,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1349,
    completeVaccinations: 18145,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19127: {
    label: 'Marshall',
    iso3: null,
    population: '39,369',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2550,
    completeVaccinations: 27097,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19129: {
    label: 'Mills',
    iso3: null,
    population: '15,109',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1371,
    completeVaccinations: 8961,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19131: {
    label: 'Mitchell',
    iso3: null,
    population: '10,586',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 293,
    completeVaccinations: 5300,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19133: {
    label: 'Monona',
    iso3: null,
    population: '8,615',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 327,
    completeVaccinations: 4811,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19135: {
    label: 'Monroe',
    iso3: null,
    population: '7,707',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 226,
    completeVaccinations: 3647,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19137: {
    label: 'Montgomery',
    iso3: null,
    population: '9,917',
    casesPastWeek: 8,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 573,
    completeVaccinations: 5858,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19139: {
    label: 'Muscatine',
    iso3: null,
    population: '42,664',
    casesPastWeek: 27,
    casesIncreasingPercentage: 58.823529411764696,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2335,
    completeVaccinations: 26808,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19141: {
    label: "O'Brien",
    iso3: null,
    population: '13,753',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 445,
    completeVaccinations: 7123,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19143: {
    label: 'Osceola',
    iso3: null,
    population: '5,958',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 190,
    completeVaccinations: 2767,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19145: {
    label: 'Page',
    iso3: null,
    population: '15,107',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 743,
    completeVaccinations: 8314,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19147: {
    label: 'Palo Alto',
    iso3: null,
    population: '8,886',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 311,
    completeVaccinations: 4658,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19149: {
    label: 'Plymouth',
    iso3: null,
    population: '25,177',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1003,
    completeVaccinations: 13653,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19151: {
    label: 'Pocahontas',
    iso3: null,
    population: '6,619',
    casesPastWeek: 5,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 262,
    completeVaccinations: 3572,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19153: {
    label: 'Polk',
    iso3: null,
    population: '490,161',
    casesPastWeek: 247,
    casesIncreasingPercentage: 0.8163265306122547,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 33734,
    completeVaccinations: 345844,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19155: {
    label: 'Pottawattamie',
    iso3: null,
    population: '93,206',
    casesPastWeek: 42,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8133,
    completeVaccinations: 56689,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19157: {
    label: 'Poweshiek',
    iso3: null,
    population: '18,504',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1184,
    completeVaccinations: 11303,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19159: {
    label: 'Ringgold',
    iso3: null,
    population: '4,894',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 158,
    completeVaccinations: 2659,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19161: {
    label: 'Sac',
    iso3: null,
    population: '9,721',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 333,
    completeVaccinations: 5269,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19163: {
    label: 'Scott',
    iso3: null,
    population: '172,943',
    casesPastWeek: 170,
    casesIncreasingPercentage: 65.04854368932038,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17310,
    completeVaccinations: 112091,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19165: {
    label: 'Shelby',
    iso3: null,
    population: '11,454',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 490,
    completeVaccinations: 6986,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19167: {
    label: 'Sioux',
    iso3: null,
    population: '34,855',
    casesPastWeek: 35,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1184,
    completeVaccinations: 14984,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19169: {
    label: 'Story',
    iso3: null,
    population: '97,117',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5704,
    completeVaccinations: 63897,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19171: {
    label: 'Tama',
    iso3: null,
    population: '16,854',
    casesPastWeek: 14,
    casesIncreasingPercentage: 27.27272727272727,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 830,
    completeVaccinations: 10889,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19173: {
    label: 'Taylor',
    iso3: null,
    population: '6,121',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 198,
    completeVaccinations: 2949,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19175: {
    label: 'Union',
    iso3: null,
    population: '12,241',
    casesPastWeek: 14,
    casesIncreasingPercentage: 27.27272727272727,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 485,
    completeVaccinations: 6456,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19177: {
    label: 'Van Buren',
    iso3: null,
    population: '7,044',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 196,
    completeVaccinations: 3032,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19179: {
    label: 'Wapello',
    iso3: null,
    population: '34,969',
    casesPastWeek: 14,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1571,
    completeVaccinations: 18255,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19181: {
    label: 'Warren',
    iso3: null,
    population: '51,466',
    casesPastWeek: 19,
    casesIncreasingPercentage: 11.764705882352944,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2494,
    completeVaccinations: 31803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19183: {
    label: 'Washington',
    iso3: null,
    population: '21,965',
    casesPastWeek: 13,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1072,
    completeVaccinations: 12913,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19185: {
    label: 'Wayne',
    iso3: null,
    population: '6,441',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 189,
    completeVaccinations: 2782,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19187: {
    label: 'Webster',
    iso3: null,
    population: '35,904',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1745,
    completeVaccinations: 21271,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19189: {
    label: 'Winnebago',
    iso3: null,
    population: '10,354',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 432,
    completeVaccinations: 5585,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19191: {
    label: 'Winneshiek',
    iso3: null,
    population: '19,991',
    casesPastWeek: 11,
    casesIncreasingPercentage: 37.5,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 899,
    completeVaccinations: 13008,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19193: {
    label: 'Woodbury',
    iso3: null,
    population: '103,107',
    casesPastWeek: 103,
    casesIncreasingPercentage: 11.956521739130444,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7474,
    completeVaccinations: 60996,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19195: {
    label: 'Worth',
    iso3: null,
    population: '7,381',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 266,
    completeVaccinations: 3922,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_19197: {
    label: 'Wright',
    iso3: null,
    population: '12,562',
    casesPastWeek: 11,
    casesIncreasingPercentage: 37.5,
    positiveCasePercentage: null,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 562,
    completeVaccinations: 7431,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20001: {
    label: 'Allen',
    iso3: null,
    population: '12,369',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 587,
    completeVaccinations: 6020,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20003: {
    label: 'Anderson',
    iso3: null,
    population: '7,858',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 29.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 353,
    completeVaccinations: 3549,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20005: {
    label: 'Atchison',
    iso3: null,
    population: '16,073',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 38.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1004,
    completeVaccinations: 7868,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20007: {
    label: 'Barber',
    iso3: null,
    population: '4,427',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 242,
    completeVaccinations: 2173,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20009: {
    label: 'Barton',
    iso3: null,
    population: '25,779',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1100,
    completeVaccinations: 11605,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20011: {
    label: 'Bourbon',
    iso3: null,
    population: '14,534',
    casesPastWeek: 20,
    casesIncreasingPercentage: 42.85714285714286,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 555,
    completeVaccinations: 5584,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20013: {
    label: 'Brown',
    iso3: null,
    population: '9,564',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 603,
    completeVaccinations: 6079,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20015: {
    label: 'Butler',
    iso3: null,
    population: '66,911',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4291,
    completeVaccinations: 34210,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20017: {
    label: 'Chase',
    iso3: null,
    population: '2,648',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 89,
    completeVaccinations: 1278,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20019: {
    label: 'Chautauqua',
    iso3: null,
    population: '3,250',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 232,
    completeVaccinations: 1396,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20021: {
    label: 'Cherokee',
    iso3: null,
    population: '19,939',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1790,
    completeVaccinations: 9676,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20023: {
    label: 'Cheyenne',
    iso3: null,
    population: '2,657',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 77,
    completeVaccinations: 1247,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20025: {
    label: 'Clark',
    iso3: null,
    population: '1,994',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 70,
    completeVaccinations: 956,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20027: {
    label: 'Clay',
    iso3: null,
    population: '8,002',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 432,
    completeVaccinations: 5060,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20029: {
    label: 'Cloud',
    iso3: null,
    population: '8,786',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 466,
    completeVaccinations: 4369,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20031: {
    label: 'Coffey',
    iso3: null,
    population: '8,179',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 461,
    completeVaccinations: 4505,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20033: {
    label: 'Comanche',
    iso3: null,
    population: '1,700',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 65,
    completeVaccinations: 803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20035: {
    label: 'Cowley',
    iso3: null,
    population: '34,908',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2128,
    completeVaccinations: 17694,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20037: {
    label: 'Crawford',
    iso3: null,
    population: '38,818',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.499999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2264,
    completeVaccinations: 19021,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20039: {
    label: 'Decatur',
    iso3: null,
    population: '2,827',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 75,
    completeVaccinations: 1180,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20041: {
    label: 'Dickinson',
    iso3: null,
    population: '18,466',
    casesPastWeek: 18,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1665,
    completeVaccinations: 11170,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20043: {
    label: 'Doniphan',
    iso3: null,
    population: '7,600',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 522,
    completeVaccinations: 3841,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20045: {
    label: 'Douglas',
    iso3: null,
    population: '122,259',
    casesPastWeek: 84,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11965,
    completeVaccinations: 80652,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20047: {
    label: 'Edwards',
    iso3: null,
    population: '2,798',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 85,
    completeVaccinations: 1397,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_20049: {
    label: 'Elk',
    iso3: null,
    population: '2,530',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 93,
    completeVaccinations: 902,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20051: {
    label: 'Ellis',
    iso3: null,
    population: '28,553',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1635,
    completeVaccinations: 14957,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20053: {
    label: 'Ellsworth',
    iso3: null,
    population: '6,102',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 105,
    completeVaccinations: 3591,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20055: {
    label: 'Finney',
    iso3: null,
    population: '36,467',
    casesPastWeek: 40,
    casesIncreasingPercentage: 48.14814814814814,
    positiveCasePercentage: 19.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4018,
    completeVaccinations: 19312,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_20057: {
    label: 'Ford',
    iso3: null,
    population: '33,619',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2222,
    completeVaccinations: 16244,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20059: {
    label: 'Franklin',
    iso3: null,
    population: '25,544',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1511,
    completeVaccinations: 13849,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20061: {
    label: 'Geary',
    iso3: null,
    population: '31,670',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20063: {
    label: 'Gove',
    iso3: null,
    population: '2,636',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 69.19999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 137,
    completeVaccinations: 1299,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20065: {
    label: 'Graham',
    iso3: null,
    population: '2,482',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 60,
    completeVaccinations: 1256,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20067: {
    label: 'Grant',
    iso3: null,
    population: '7,150',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 349,
    completeVaccinations: 3508,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20069: {
    label: 'Gray',
    iso3: null,
    population: '5,988',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 322,
    completeVaccinations: 2831,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20071: {
    label: 'Greeley',
    iso3: null,
    population: '1,232',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 46,
    completeVaccinations: 655,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_20073: {
    label: 'Greenwood',
    iso3: null,
    population: '5,982',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 241,
    completeVaccinations: 3103,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20075: {
    label: 'Hamilton',
    iso3: null,
    population: '2,539',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 150,
    completeVaccinations: 1094,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_20077: {
    label: 'Harper',
    iso3: null,
    population: '5,436',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 202,
    completeVaccinations: 2754,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20079: {
    label: 'Harvey',
    iso3: null,
    population: '34,429',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2000,
    completeVaccinations: 17053,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20081: {
    label: 'Haskell',
    iso3: null,
    population: '3,968',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 197,
    completeVaccinations: 1620,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20083: {
    label: 'Hodgeman',
    iso3: null,
    population: '1,794',
    casesPastWeek: 12,
    casesIncreasingPercentage: 1100.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 67,
    completeVaccinations: 845,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20085: {
    label: 'Jackson',
    iso3: null,
    population: '13,171',
    casesPastWeek: 15,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 761,
    completeVaccinations: 8760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20087: {
    label: 'Jefferson',
    iso3: null,
    population: '19,043',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.200000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1126,
    completeVaccinations: 10795,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20089: {
    label: 'Jewell',
    iso3: null,
    population: '2,879',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 106,
    completeVaccinations: 1383,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20091: {
    label: 'Johnson',
    iso3: null,
    population: '602,401',
    casesPastWeek: 296,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 97907,
    completeVaccinations: 498666,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20093: {
    label: 'Kearny',
    iso3: null,
    population: '3,838',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 295,
    completeVaccinations: 2154,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20095: {
    label: 'Kingman',
    iso3: null,
    population: '7,152',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 318,
    completeVaccinations: 3507,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20097: {
    label: 'Kiowa',
    iso3: null,
    population: '2,475',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 106,
    completeVaccinations: 1312,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20099: {
    label: 'Labette',
    iso3: null,
    population: '19,618',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1043,
    completeVaccinations: 10106,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20101: {
    label: 'Lane',
    iso3: null,
    population: '1,535',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 50,
    completeVaccinations: 670,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_20103: {
    label: 'Leavenworth',
    iso3: null,
    population: '81,758',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10269,
    completeVaccinations: 51230,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20105: {
    label: 'Lincoln',
    iso3: null,
    population: '2,962',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 101,
    completeVaccinations: 1337,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20107: {
    label: 'Linn',
    iso3: null,
    population: '9,703',
    casesPastWeek: 18,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 357,
    completeVaccinations: 3599,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20109: {
    label: 'Logan',
    iso3: null,
    population: '2,794',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 104,
    completeVaccinations: 1344,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20111: {
    label: 'Lyon',
    iso3: null,
    population: '33,195',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2042,
    completeVaccinations: 19813,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20113: {
    label: 'McPherson',
    iso3: null,
    population: '28,542',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 44.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1537,
    completeVaccinations: 16430,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20115: {
    label: 'Marion',
    iso3: null,
    population: '11,884',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 638,
    completeVaccinations: 6153,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20117: {
    label: 'Marshall',
    iso3: null,
    population: '9,707',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 514,
    completeVaccinations: 6434,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20119: {
    label: 'Meade',
    iso3: null,
    population: '4,033',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 187,
    completeVaccinations: 1741,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20121: {
    label: 'Miami',
    iso3: null,
    population: '34,237',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2088,
    completeVaccinations: 17216,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20123: {
    label: 'Mitchell',
    iso3: null,
    population: '5,979',
    casesPastWeek: 4,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 140,
    completeVaccinations: 3240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20125: {
    label: 'Montgomery',
    iso3: null,
    population: '31,829',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2177,
    completeVaccinations: 15194,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20127: {
    label: 'Morris',
    iso3: null,
    population: '5,620',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 263,
    completeVaccinations: 3235,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20129: {
    label: 'Morton',
    iso3: null,
    population: '2,587',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 156,
    completeVaccinations: 1116,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20131: {
    label: 'Nemaha',
    iso3: null,
    population: '10,231',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 301,
    completeVaccinations: 5723,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20133: {
    label: 'Neosho',
    iso3: null,
    population: '16,007',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 881,
    completeVaccinations: 4811,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20135: {
    label: 'Ness',
    iso3: null,
    population: '2,750',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 149,
    completeVaccinations: 1489,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20137: {
    label: 'Norton',
    iso3: null,
    population: '5,361',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 51,
    completeVaccinations: 2922,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20139: {
    label: 'Osage',
    iso3: null,
    population: '15,949',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 835,
    completeVaccinations: 8432,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20141: {
    label: 'Osborne',
    iso3: null,
    population: '3,421',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 103,
    completeVaccinations: 1469,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20143: {
    label: 'Ottawa',
    iso3: null,
    population: '5,704',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 296,
    completeVaccinations: 2703,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20145: {
    label: 'Pawnee',
    iso3: null,
    population: '6,414',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 157,
    completeVaccinations: 3623,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20147: {
    label: 'Phillips',
    iso3: null,
    population: '5,234',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 166,
    completeVaccinations: 2351,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20149: {
    label: 'Pottawatomie',
    iso3: null,
    population: '24,383',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1113,
    completeVaccinations: 10703,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20151: {
    label: 'Pratt',
    iso3: null,
    population: '9,164',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 549,
    completeVaccinations: 4737,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20153: {
    label: 'Rawlins',
    iso3: null,
    population: '2,530',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 39.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 70,
    completeVaccinations: 1119,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20155: {
    label: 'Reno',
    iso3: null,
    population: '61,998',
    casesPastWeek: 88,
    casesIncreasingPercentage: 6.024096385542177,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3180,
    completeVaccinations: 31989,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20157: {
    label: 'Republic',
    iso3: null,
    population: '4,636',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 160,
    completeVaccinations: 2415,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_20159: {
    label: 'Rice',
    iso3: null,
    population: '9,537',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 533,
    completeVaccinations: 4337,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20161: {
    label: 'Riley',
    iso3: null,
    population: '74,232',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3760,
    completeVaccinations: 30815,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20163: {
    label: 'Rooks',
    iso3: null,
    population: '4,920',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 153,
    completeVaccinations: 2444,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20165: {
    label: 'Rush',
    iso3: null,
    population: '3,036',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 146,
    completeVaccinations: 1592,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20167: {
    label: 'Russell',
    iso3: null,
    population: '6,856',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 294,
    completeVaccinations: 3059,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20169: {
    label: 'Saline',
    iso3: null,
    population: '54,224',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2933,
    completeVaccinations: 28299,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20171: {
    label: 'Scott',
    iso3: null,
    population: '4,823',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 190,
    completeVaccinations: 2413,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20173: {
    label: 'Sedgwick',
    iso3: null,
    population: '516,042',
    casesPastWeek: 259,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 48871,
    completeVaccinations: 299121,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20175: {
    label: 'Seward',
    iso3: null,
    population: '21,428',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3390,
    completeVaccinations: 10642,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20177: {
    label: 'Shawnee',
    iso3: null,
    population: '176,875',
    casesPastWeek: 163,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14546,
    completeVaccinations: 114201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20179: {
    label: 'Sheridan',
    iso3: null,
    population: '2,521',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 78,
    completeVaccinations: 938,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20181: {
    label: 'Sherman',
    iso3: null,
    population: '5,917',
    casesPastWeek: 6,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 244,
    completeVaccinations: 2715,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20183: {
    label: 'Smith',
    iso3: null,
    population: '3,583',
    casesPastWeek: 10,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 129,
    completeVaccinations: 1645,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20185: {
    label: 'Stafford',
    iso3: null,
    population: '4,156',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 186,
    completeVaccinations: 1998,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20187: {
    label: 'Stanton',
    iso3: null,
    population: '2,006',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 106,
    completeVaccinations: 1053,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20189: {
    label: 'Stevens',
    iso3: null,
    population: '5,485',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 305,
    completeVaccinations: 2249,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20191: {
    label: 'Sumner',
    iso3: null,
    population: '22,836',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1129,
    completeVaccinations: 10390,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20193: {
    label: 'Thomas',
    iso3: null,
    population: '7,777',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 278,
    completeVaccinations: 3332,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20195: {
    label: 'Trego',
    iso3: null,
    population: '2,803',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 41,
    completeVaccinations: 1120,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20197: {
    label: 'Wabaunsee',
    iso3: null,
    population: '6,931',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 260,
    completeVaccinations: 3443,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20199: {
    label: 'Wallace',
    iso3: null,
    population: '1,518',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24,
    completeVaccinations: 590,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_20201: {
    label: 'Washington',
    iso3: null,
    population: '5,406',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 232,
    completeVaccinations: 3014,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20203: {
    label: 'Wichita',
    iso3: null,
    population: '2,119',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 75,
    completeVaccinations: 1109,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_20205: {
    label: 'Wilson',
    iso3: null,
    population: '8,525',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 325,
    completeVaccinations: 3923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_20207: {
    label: 'Woodson',
    iso3: null,
    population: '3,138',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 145,
    completeVaccinations: 1375,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_20209: {
    label: 'Wyandotte',
    iso3: null,
    population: '165,429',
    casesPastWeek: 73,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22542,
    completeVaccinations: 102787,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21001: {
    label: 'Adair',
    iso3: null,
    population: '19,202',
    casesPastWeek: 46,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 21.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 956,
    completeVaccinations: 7771,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21003: {
    label: 'Allen',
    iso3: null,
    population: '21,315',
    casesPastWeek: 31,
    casesIncreasingPercentage: 82.35294117647058,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1052,
    completeVaccinations: 8689,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21005: {
    label: 'Anderson',
    iso3: null,
    population: '22,747',
    casesPastWeek: 25,
    casesIncreasingPercentage: 4.166666666666674,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1646,
    completeVaccinations: 13347,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21007: {
    label: 'Ballard',
    iso3: null,
    population: '7,888',
    casesPastWeek: 9,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 316,
    completeVaccinations: 3146,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21009: {
    label: 'Barren',
    iso3: null,
    population: '44,249',
    casesPastWeek: 89,
    casesIncreasingPercentage: 36.92307692307693,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2456,
    completeVaccinations: 21133,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21011: {
    label: 'Bath',
    iso3: null,
    population: '12,500',
    casesPastWeek: 19,
    casesIncreasingPercentage: 26.66666666666666,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 748,
    completeVaccinations: 6945,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21013: {
    label: 'Bell',
    iso3: null,
    population: '26,032',
    casesPastWeek: 36,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 14.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1883,
    completeVaccinations: 12326,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21015: {
    label: 'Boone',
    iso3: null,
    population: '133,581',
    casesPastWeek: 181,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12295,
    completeVaccinations: 86642,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21017: {
    label: 'Bourbon',
    iso3: null,
    population: '19,788',
    casesPastWeek: 15,
    casesIncreasingPercentage: 15.384615384615374,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1755,
    completeVaccinations: 11929,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21019: {
    label: 'Boyd',
    iso3: null,
    population: '46,718',
    casesPastWeek: 91,
    casesIncreasingPercentage: 4.597701149425282,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3771,
    completeVaccinations: 24025,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21021: {
    label: 'Boyle',
    iso3: null,
    population: '30,060',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2504,
    completeVaccinations: 17795,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21023: {
    label: 'Bracken',
    iso3: null,
    population: '8,303',
    casesPastWeek: 9,
    casesIncreasingPercentage: 125.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 416,
    completeVaccinations: 4056,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21025: {
    label: 'Breathitt',
    iso3: null,
    population: '12,630',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 838,
    completeVaccinations: 6830,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21027: {
    label: 'Breckinridge',
    iso3: null,
    population: '20,477',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1746,
    completeVaccinations: 10500,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21029: {
    label: 'Bullitt',
    iso3: null,
    population: '81,676',
    casesPastWeek: 88,
    casesIncreasingPercentage: 22.222222222222232,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5161,
    completeVaccinations: 38338,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21031: {
    label: 'Butler',
    iso3: null,
    population: '12,879',
    casesPastWeek: 23,
    casesIncreasingPercentage: 109.09090909090908,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 573,
    completeVaccinations: 6043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21033: {
    label: 'Caldwell',
    iso3: null,
    population: '12,747',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 901,
    completeVaccinations: 6826,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21035: {
    label: 'Calloway',
    iso3: null,
    population: '39,001',
    casesPastWeek: 37,
    casesIncreasingPercentage: 85.00000000000001,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2796,
    completeVaccinations: 18721,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21037: {
    label: 'Campbell',
    iso3: null,
    population: '93,584',
    casesPastWeek: 87,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9689,
    completeVaccinations: 62756,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21039: {
    label: 'Carlisle',
    iso3: null,
    population: '4,760',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 211,
    completeVaccinations: 2006,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21041: {
    label: 'Carroll',
    iso3: null,
    population: '10,631',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 686,
    completeVaccinations: 5136,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21043: {
    label: 'Carter',
    iso3: null,
    population: '26,797',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1870,
    completeVaccinations: 13623,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21045: {
    label: 'Casey',
    iso3: null,
    population: '16,159',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 760,
    completeVaccinations: 6062,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21047: {
    label: 'Christian',
    iso3: null,
    population: '70,461',
    casesPastWeek: 61,
    casesIncreasingPercentage: 17.307692307692314,
    positiveCasePercentage: 2.8000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4795,
    completeVaccinations: 26999,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21049: {
    label: 'Clark',
    iso3: null,
    population: '36,263',
    casesPastWeek: 64,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2725,
    completeVaccinations: 20513,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21051: {
    label: 'Clay',
    iso3: null,
    population: '19,901',
    casesPastWeek: 14,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1128,
    completeVaccinations: 9198,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21053: {
    label: 'Clinton',
    iso3: null,
    population: '10,218',
    casesPastWeek: 29,
    casesIncreasingPercentage: 38.095238095238095,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 675,
    completeVaccinations: 4360,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21055: {
    label: 'Crittenden',
    iso3: null,
    population: '8,806',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 431,
    completeVaccinations: 3714,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21057: {
    label: 'Cumberland',
    iso3: null,
    population: '6,614',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 266,
    completeVaccinations: 3059,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21059: {
    label: 'Daviess',
    iso3: null,
    population: '101,511',
    casesPastWeek: 105,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6373,
    completeVaccinations: 56773,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21061: {
    label: 'Edmonson',
    iso3: null,
    population: '12,150',
    casesPastWeek: 11,
    casesIncreasingPercentage: 57.14285714285714,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 476,
    completeVaccinations: 4302,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21063: {
    label: 'Elliott',
    iso3: null,
    population: '7,517',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 305,
    completeVaccinations: 2759,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21065: {
    label: 'Estill',
    iso3: null,
    population: '14,106',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 779,
    completeVaccinations: 7612,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21067: {
    label: 'Fayette',
    iso3: null,
    population: '323,152',
    casesPastWeek: 334,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 39231,
    completeVaccinations: 225629,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21069: {
    label: 'Fleming',
    iso3: null,
    population: '14,581',
    casesPastWeek: 11,
    casesIncreasingPercentage: 37.5,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 775,
    completeVaccinations: 7753,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21071: {
    label: 'Floyd',
    iso3: null,
    population: '35,589',
    casesPastWeek: 45,
    casesIncreasingPercentage: 15.384615384615374,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2343,
    completeVaccinations: 20838,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21073: {
    label: 'Franklin',
    iso3: null,
    population: '50,991',
    casesPastWeek: 74,
    casesIncreasingPercentage: 85.00000000000001,
    positiveCasePercentage: 4.1000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3820,
    completeVaccinations: 32550,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21075: {
    label: 'Fulton',
    iso3: null,
    population: '5,969',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 723,
    completeVaccinations: 3663,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21077: {
    label: 'Gallatin',
    iso3: null,
    population: '8,869',
    casesPastWeek: 10,
    casesIncreasingPercentage: 42.85714285714286,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 360,
    completeVaccinations: 3732,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21079: {
    label: 'Garrard',
    iso3: null,
    population: '17,666',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1083,
    completeVaccinations: 8763,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21081: {
    label: 'Grant',
    iso3: null,
    population: '25,069',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1190,
    completeVaccinations: 12683,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21083: {
    label: 'Graves',
    iso3: null,
    population: '37,266',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2388,
    completeVaccinations: 17629,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21085: {
    label: 'Grayson',
    iso3: null,
    population: '26,427',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1596,
    completeVaccinations: 14152,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21087: {
    label: 'Green',
    iso3: null,
    population: '10,941',
    casesPastWeek: 22,
    casesIncreasingPercentage: 175.0,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 441,
    completeVaccinations: 4895,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21089: {
    label: 'Greenup',
    iso3: null,
    population: '35,098',
    casesPastWeek: 72,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3341,
    completeVaccinations: 19151,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21091: {
    label: 'Hancock',
    iso3: null,
    population: '8,722',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 734,
    completeVaccinations: 5339,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21093: {
    label: 'Hardin',
    iso3: null,
    population: '110,958',
    casesPastWeek: 99,
    casesIncreasingPercentage: 23.750000000000004,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13663,
    completeVaccinations: 75536,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21095: {
    label: 'Harlan',
    iso3: null,
    population: '26,010',
    casesPastWeek: 39,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1552,
    completeVaccinations: 12418,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21097: {
    label: 'Harrison',
    iso3: null,
    population: '18,886',
    casesPastWeek: 13,
    casesIncreasingPercentage: 8.333333333333325,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1098,
    completeVaccinations: 9754,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21099: {
    label: 'Hart',
    iso3: null,
    population: '19,035',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 831,
    completeVaccinations: 7014,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21101: {
    label: 'Henderson',
    iso3: null,
    population: '45,210',
    casesPastWeek: 37,
    casesIncreasingPercentage: 19.354838709677423,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3807,
    completeVaccinations: 24429,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21103: {
    label: 'Henry',
    iso3: null,
    population: '16,126',
    casesPastWeek: 20,
    casesIncreasingPercentage: 53.846153846153854,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1150,
    completeVaccinations: 8923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21105: {
    label: 'Hickman',
    iso3: null,
    population: '4,380',
    casesPastWeek: 9,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 1.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 216,
    completeVaccinations: 1694,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21107: {
    label: 'Hopkins',
    iso3: null,
    population: '44,686',
    casesPastWeek: 70,
    casesIncreasingPercentage: 12.903225806451623,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2511,
    completeVaccinations: 23933,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21109: {
    label: 'Jackson',
    iso3: null,
    population: '13,329',
    casesPastWeek: 14,
    casesIncreasingPercentage: 27.27272727272727,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 548,
    completeVaccinations: 5040,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21111: {
    label: 'Jefferson',
    iso3: null,
    population: '766,757',
    casesPastWeek: 962,
    casesIncreasingPercentage: 16.464891041162222,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 92003,
    completeVaccinations: 514899,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21113: {
    label: 'Jessamine',
    iso3: null,
    population: '54,115',
    casesPastWeek: 51,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4545,
    completeVaccinations: 30244,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21115: {
    label: 'Johnson',
    iso3: null,
    population: '22,188',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1472,
    completeVaccinations: 11437,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21117: {
    label: 'Kenton',
    iso3: null,
    population: '166,998',
    casesPastWeek: 131,
    casesIncreasingPercentage: 129.82456140350877,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15499,
    completeVaccinations: 105818,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21119: {
    label: 'Knott',
    iso3: null,
    population: '14,806',
    casesPastWeek: 24,
    casesIncreasingPercentage: 242.85714285714283,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 743,
    completeVaccinations: 7276,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21121: {
    label: 'Knox',
    iso3: null,
    population: '31,145',
    casesPastWeek: 24,
    casesIncreasingPercentage: 26.315789473684205,
    positiveCasePercentage: 2.9000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1305,
    completeVaccinations: 11728,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21123: {
    label: 'Larue',
    iso3: null,
    population: '14,398',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 597,
    completeVaccinations: 6485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21125: {
    label: 'Laurel',
    iso3: null,
    population: '60,813',
    casesPastWeek: 83,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3077,
    completeVaccinations: 25252,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21127: {
    label: 'Lawrence',
    iso3: null,
    population: '15,317',
    casesPastWeek: 12,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1045,
    completeVaccinations: 7636,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21129: {
    label: 'Lee',
    iso3: null,
    population: '7,403',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 338,
    completeVaccinations: 3783,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21131: {
    label: 'Leslie',
    iso3: null,
    population: '9,877',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 731,
    completeVaccinations: 5310,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21133: {
    label: 'Letcher',
    iso3: null,
    population: '21,553',
    casesPastWeek: 15,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1075,
    completeVaccinations: 11698,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21135: {
    label: 'Lewis',
    iso3: null,
    population: '13,275',
    casesPastWeek: 23,
    casesIncreasingPercentage: 9.523809523809534,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 656,
    completeVaccinations: 5425,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21137: {
    label: 'Lincoln',
    iso3: null,
    population: '24,549',
    casesPastWeek: 32,
    casesIncreasingPercentage: 39.13043478260869,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1178,
    completeVaccinations: 10389,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21139: {
    label: 'Livingston',
    iso3: null,
    population: '9,194',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 593,
    completeVaccinations: 4659,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21141: {
    label: 'Logan',
    iso3: null,
    population: '27,102',
    casesPastWeek: 38,
    casesIncreasingPercentage: 35.71428571428572,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1820,
    completeVaccinations: 14284,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21143: {
    label: 'Lyon',
    iso3: null,
    population: '8,210',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 609,
    completeVaccinations: 4937,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21145: {
    label: 'McCracken',
    iso3: null,
    population: '65,418',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5634,
    completeVaccinations: 37883,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21147: {
    label: 'McCreary',
    iso3: null,
    population: '17,231',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 901,
    completeVaccinations: 6945,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21149: {
    label: 'McLean',
    iso3: null,
    population: '9,207',
    casesPastWeek: 10,
    casesIncreasingPercentage: 900.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 405,
    completeVaccinations: 4870,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21151: {
    label: 'Madison',
    iso3: null,
    population: '92,987',
    casesPastWeek: 112,
    casesIncreasingPercentage: 9.80392156862746,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5845,
    completeVaccinations: 46544,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21153: {
    label: 'Magoffin',
    iso3: null,
    population: '12,161',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 2.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 712,
    completeVaccinations: 6362,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21155: {
    label: 'Marion',
    iso3: null,
    population: '19,273',
    casesPastWeek: 21,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1082,
    completeVaccinations: 9987,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21157: {
    label: 'Marshall',
    iso3: null,
    population: '31,100',
    casesPastWeek: 18,
    casesIncreasingPercentage: 38.46153846153846,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2188,
    completeVaccinations: 17141,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21159: {
    label: 'Martin',
    iso3: null,
    population: '11,195',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 742,
    completeVaccinations: 6226,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21161: {
    label: 'Mason',
    iso3: null,
    population: '17,070',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1065,
    completeVaccinations: 8983,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21163: {
    label: 'Meade',
    iso3: null,
    population: '28,572',
    casesPastWeek: 35,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1907,
    completeVaccinations: 11687,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21165: {
    label: 'Menifee',
    iso3: null,
    population: '6,489',
    casesPastWeek: 12,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 336,
    completeVaccinations: 2987,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21167: {
    label: 'Mercer',
    iso3: null,
    population: '21,933',
    casesPastWeek: 27,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 4.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1418,
    completeVaccinations: 12345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21169: {
    label: 'Metcalfe',
    iso3: null,
    population: '10,071',
    casesPastWeek: 19,
    casesIncreasingPercentage: 72.72727272727273,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 413,
    completeVaccinations: 4197,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21171: {
    label: 'Monroe',
    iso3: null,
    population: '10,650',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 552,
    completeVaccinations: 5041,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21173: {
    label: 'Montgomery',
    iso3: null,
    population: '28,157',
    casesPastWeek: 40,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1584,
    completeVaccinations: 13592,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21175: {
    label: 'Morgan',
    iso3: null,
    population: '13,309',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.1999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 659,
    completeVaccinations: 6599,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21177: {
    label: 'Muhlenberg',
    iso3: null,
    population: '30,622',
    casesPastWeek: 41,
    casesIncreasingPercentage: 173.33333333333334,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1673,
    completeVaccinations: 16204,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21179: {
    label: 'Nelson',
    iso3: null,
    population: '46,233',
    casesPastWeek: 58,
    casesIncreasingPercentage: 31.818181818181813,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3351,
    completeVaccinations: 24987,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21181: {
    label: 'Nicholas',
    iso3: null,
    population: '7,269',
    casesPastWeek: 9,
    casesIncreasingPercentage: 350.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 348,
    completeVaccinations: 3940,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21183: {
    label: 'Ohio',
    iso3: null,
    population: '23,994',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1236,
    completeVaccinations: 9880,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21185: {
    label: 'Oldham',
    iso3: null,
    population: '66,799',
    casesPastWeek: 88,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5061,
    completeVaccinations: 39802,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21187: {
    label: 'Owen',
    iso3: null,
    population: '10,901',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 460,
    completeVaccinations: 4243,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21189: {
    label: 'Owsley',
    iso3: null,
    population: '4,415',
    casesPastWeek: 4,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 207,
    completeVaccinations: 2154,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21191: {
    label: 'Pendleton',
    iso3: null,
    population: '14,590',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 671,
    completeVaccinations: 6645,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21193: {
    label: 'Perry',
    iso3: null,
    population: '25,758',
    casesPastWeek: 46,
    casesIncreasingPercentage: 17.948717948717952,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2204,
    completeVaccinations: 16398,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21195: {
    label: 'Pike',
    iso3: null,
    population: '57,876',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3842,
    completeVaccinations: 31314,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21197: {
    label: 'Powell',
    iso3: null,
    population: '12,359',
    casesPastWeek: 30,
    casesIncreasingPercentage: 3.4482758620689724,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 792,
    completeVaccinations: 6700,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21199: {
    label: 'Pulaski',
    iso3: null,
    population: '64,979',
    casesPastWeek: 48,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 4.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4194,
    completeVaccinations: 31104,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21201: {
    label: 'Robertson',
    iso3: null,
    population: '2,108',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 58,
    completeVaccinations: 841,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21203: {
    label: 'Rockcastle',
    iso3: null,
    population: '16,695',
    casesPastWeek: 28,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 797,
    completeVaccinations: 6956,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21205: {
    label: 'Rowan',
    iso3: null,
    population: '24,460',
    casesPastWeek: 43,
    casesIncreasingPercentage: 38.70967741935485,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1581,
    completeVaccinations: 13210,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21207: {
    label: 'Russell',
    iso3: null,
    population: '17,923',
    casesPastWeek: 45,
    casesIncreasingPercentage: 55.172413793103445,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 918,
    completeVaccinations: 8464,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21209: {
    label: 'Scott',
    iso3: null,
    population: '57,004',
    casesPastWeek: 61,
    casesIncreasingPercentage: 29.78723404255319,
    positiveCasePercentage: 4.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4527,
    completeVaccinations: 32940,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21211: {
    label: 'Shelby',
    iso3: null,
    population: '49,024',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3303,
    completeVaccinations: 23904,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21213: {
    label: 'Simpson',
    iso3: null,
    population: '18,572',
    casesPastWeek: 34,
    casesIncreasingPercentage: 6.25,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1359,
    completeVaccinations: 9758,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21215: {
    label: 'Spencer',
    iso3: null,
    population: '19,351',
    casesPastWeek: 19,
    casesIncreasingPercentage: 5.555555555555558,
    positiveCasePercentage: 14.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 919,
    completeVaccinations: 6052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21217: {
    label: 'Taylor',
    iso3: null,
    population: '25,769',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1602,
    completeVaccinations: 12752,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21219: {
    label: 'Todd',
    iso3: null,
    population: '12,294',
    casesPastWeek: 14,
    casesIncreasingPercentage: 366.6666666666667,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 811,
    completeVaccinations: 5418,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21221: {
    label: 'Trigg',
    iso3: null,
    population: '14,651',
    casesPastWeek: 34,
    casesIncreasingPercentage: 78.94736842105263,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 884,
    completeVaccinations: 7137,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21223: {
    label: 'Trimble',
    iso3: null,
    population: '8,471',
    casesPastWeek: 10,
    casesIncreasingPercentage: 42.85714285714286,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 703,
    completeVaccinations: 3875,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21225: {
    label: 'Union',
    iso3: null,
    population: '14,381',
    casesPastWeek: 82,
    casesIncreasingPercentage: 485.71428571428567,
    positiveCasePercentage: 23.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 875,
    completeVaccinations: 6309,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21227: {
    label: 'Warren',
    iso3: null,
    population: '132,896',
    casesPastWeek: 202,
    casesIncreasingPercentage: 23.17073170731707,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8016,
    completeVaccinations: 53569,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21229: {
    label: 'Washington',
    iso3: null,
    population: '12,095',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 782,
    completeVaccinations: 6386,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21231: {
    label: 'Wayne',
    iso3: null,
    population: '20,333',
    casesPastWeek: 35,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 2.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1233,
    completeVaccinations: 8903,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21233: {
    label: 'Webster',
    iso3: null,
    population: '12,942',
    casesPastWeek: 21,
    casesIncreasingPercentage: 90.90909090909092,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 835,
    completeVaccinations: 6181,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21235: {
    label: 'Whitley',
    iso3: null,
    population: '36,264',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2160,
    completeVaccinations: 16616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21237: {
    label: 'Wolfe',
    iso3: null,
    population: '7,157',
    casesPastWeek: 14,
    casesIncreasingPercentage: 55.55555555555556,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 459,
    completeVaccinations: 3948,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_21239: {
    label: 'Woodford',
    iso3: null,
    population: '26,734',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2684,
    completeVaccinations: 18430,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22001: {
    label: 'Acadia',
    iso3: null,
    population: '62,045',
    casesPastWeek: 28,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3890,
    completeVaccinations: 33587,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22003: {
    label: 'Allen',
    iso3: null,
    population: '25,627',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1344,
    completeVaccinations: 9628,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22005: {
    label: 'Ascension',
    iso3: null,
    population: '126,604',
    casesPastWeek: 84,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7513,
    completeVaccinations: 66053,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22007: {
    label: 'Assumption',
    iso3: null,
    population: '21,891',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1235,
    completeVaccinations: 9804,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22009: {
    label: 'Avoyelles',
    iso3: null,
    population: '40,144',
    casesPastWeek: 37,
    casesIncreasingPercentage: 2.777777777777768,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2859,
    completeVaccinations: 19762,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22011: {
    label: 'Beauregard',
    iso3: null,
    population: '37,497',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2400,
    completeVaccinations: 13899,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22013: {
    label: 'Bienville',
    iso3: null,
    population: '13,241',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 916,
    completeVaccinations: 6883,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22015: {
    label: 'Bossier',
    iso3: null,
    population: '127,039',
    casesPastWeek: 138,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11341,
    completeVaccinations: 66406,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22017: {
    label: 'Caddo',
    iso3: null,
    population: '240,204',
    casesPastWeek: 201,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19962,
    completeVaccinations: 120084,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22019: {
    label: 'Calcasieu',
    iso3: null,
    population: '203,436',
    casesPastWeek: 133,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14180,
    completeVaccinations: 88925,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22021: {
    label: 'Caldwell',
    iso3: null,
    population: '9,918',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 431,
    completeVaccinations: 3961,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22023: {
    label: 'Cameron',
    iso3: null,
    population: '6,973',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 163,
    completeVaccinations: 1285,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_22025: {
    label: 'Catahoula',
    iso3: null,
    population: '9,494',
    casesPastWeek: 8,
    casesIncreasingPercentage: 700.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 672,
    completeVaccinations: 3978,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22027: {
    label: 'Claiborne',
    iso3: null,
    population: '15,670',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 664,
    completeVaccinations: 6556,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22029: {
    label: 'Concordia',
    iso3: null,
    population: '19,259',
    casesPastWeek: 21,
    casesIncreasingPercentage: 5.000000000000004,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1905,
    completeVaccinations: 8823,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22031: {
    label: 'De Soto',
    iso3: null,
    population: '27,463',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1622,
    completeVaccinations: 12061,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22033: {
    label: 'East Baton Rouge',
    iso3: null,
    population: '440,059',
    casesPastWeek: 360,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 32859,
    completeVaccinations: 253481,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22035: {
    label: 'East Carroll',
    iso3: null,
    population: '6,861',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 465,
    completeVaccinations: 3277,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22037: {
    label: 'East Feliciana',
    iso3: null,
    population: '19,135',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 786,
    completeVaccinations: 8058,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22039: {
    label: 'Evangeline',
    iso3: null,
    population: '33,395',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1594,
    completeVaccinations: 13251,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22041: {
    label: 'Franklin',
    iso3: null,
    population: '20,015',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1025,
    completeVaccinations: 7631,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22043: {
    label: 'Grant',
    iso3: null,
    population: '22,389',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1229,
    completeVaccinations: 9923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22045: {
    label: 'Iberia',
    iso3: null,
    population: '69,830',
    casesPastWeek: 48,
    casesIncreasingPercentage: 6.666666666666665,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4599,
    completeVaccinations: 33786,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22047: {
    label: 'Iberville',
    iso3: null,
    population: '32,511',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2157,
    completeVaccinations: 19129,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22049: {
    label: 'Jackson',
    iso3: null,
    population: '15,744',
    casesPastWeek: 17,
    casesIncreasingPercentage: 6.25,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 789,
    completeVaccinations: 7008,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22051: {
    label: 'Jefferson',
    iso3: null,
    population: '432,493',
    casesPastWeek: 191,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 41746,
    completeVaccinations: 290858,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22053: {
    label: 'Jefferson Davis',
    iso3: null,
    population: '31,368',
    casesPastWeek: 14,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1372,
    completeVaccinations: 11848,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22055: {
    label: 'Lafayette',
    iso3: null,
    population: '244,390',
    casesPastWeek: 139,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15894,
    completeVaccinations: 126117,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22057: {
    label: 'Lafourche',
    iso3: null,
    population: '97,614',
    casesPastWeek: 138,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5981,
    completeVaccinations: 45091,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22059: {
    label: 'LaSalle',
    iso3: null,
    population: '14,892',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1073,
    completeVaccinations: 7015,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22061: {
    label: 'Lincoln',
    iso3: null,
    population: '46,742',
    casesPastWeek: 62,
    casesIncreasingPercentage: 210.0,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2871,
    completeVaccinations: 20872,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22063: {
    label: 'Livingston',
    iso3: null,
    population: '140,789',
    casesPastWeek: 61,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7259,
    completeVaccinations: 57658,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22065: {
    label: 'Madison',
    iso3: null,
    population: '10,951',
    casesPastWeek: 7,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1075,
    completeVaccinations: 5229,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22067: {
    label: 'Morehouse',
    iso3: null,
    population: '24,874',
    casesPastWeek: 19,
    casesIncreasingPercentage: 11.764705882352944,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1977,
    completeVaccinations: 11752,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22069: {
    label: 'Natchitoches',
    iso3: null,
    population: '38,158',
    casesPastWeek: 17,
    casesIncreasingPercentage: 41.66666666666667,
    positiveCasePercentage: 3.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2120,
    completeVaccinations: 17098,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22071: {
    label: 'Orleans',
    iso3: null,
    population: '390,144',
    casesPastWeek: 272,
    casesIncreasingPercentage: 30.76923076923077,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 46952,
    completeVaccinations: 282702,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22073: {
    label: 'Ouachita',
    iso3: null,
    population: '153,279',
    casesPastWeek: 134,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10421,
    completeVaccinations: 71535,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22075: {
    label: 'Plaquemines',
    iso3: null,
    population: '23,197',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2338,
    completeVaccinations: 16494,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22077: {
    label: 'Pointe Coupee',
    iso3: null,
    population: '21,730',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1210,
    completeVaccinations: 12318,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22079: {
    label: 'Rapides',
    iso3: null,
    population: '129,648',
    casesPastWeek: 102,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10419,
    completeVaccinations: 66874,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22081: {
    label: 'Red River',
    iso3: null,
    population: '8,442',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 420,
    completeVaccinations: 3209,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22083: {
    label: 'Richland',
    iso3: null,
    population: '20,122',
    casesPastWeek: 37,
    casesIncreasingPercentage: 27.586206896551737,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1010,
    completeVaccinations: 8866,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22085: {
    label: 'Sabine',
    iso3: null,
    population: '23,884',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1549,
    completeVaccinations: 9505,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22087: {
    label: 'St. Bernard',
    iso3: null,
    population: '47,244',
    casesPastWeek: 36,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3235,
    completeVaccinations: 23893,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22089: {
    label: 'St. Charles',
    iso3: null,
    population: '53,100',
    casesPastWeek: 51,
    casesIncreasingPercentage: 131.81818181818184,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3815,
    completeVaccinations: 30874,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22091: {
    label: 'St. Helena',
    iso3: null,
    population: '10,132',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 397,
    completeVaccinations: 3273,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22093: {
    label: 'St. James',
    iso3: null,
    population: '21,096',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2090,
    completeVaccinations: 11180,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22095: {
    label: 'St. John the Baptist',
    iso3: null,
    population: '42,837',
    casesPastWeek: 36,
    casesIncreasingPercentage: 24.13793103448276,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4110,
    completeVaccinations: 26449,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22097: {
    label: 'St. Landry',
    iso3: null,
    population: '82,124',
    casesPastWeek: 66,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4732,
    completeVaccinations: 35501,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22099: {
    label: 'St. Martin',
    iso3: null,
    population: '53,431',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2509,
    completeVaccinations: 22049,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22101: {
    label: 'St. Mary',
    iso3: null,
    population: '49,348',
    casesPastWeek: 45,
    casesIncreasingPercentage: 40.625,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3225,
    completeVaccinations: 22818,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22103: {
    label: 'St. Tammany',
    iso3: null,
    population: '260,419',
    casesPastWeek: 119,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17759,
    completeVaccinations: 151879,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22105: {
    label: 'Tangipahoa',
    iso3: null,
    population: '134,758',
    casesPastWeek: 46,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8502,
    completeVaccinations: 64618,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22107: {
    label: 'Tensas',
    iso3: null,
    population: '4,334',
    casesPastWeek: 5,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 474,
    completeVaccinations: 2794,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22109: {
    label: 'Terrebonne',
    iso3: null,
    population: '110,461',
    casesPastWeek: 92,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8266,
    completeVaccinations: 51683,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22111: {
    label: 'Union',
    iso3: null,
    population: '22,108',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1199,
    completeVaccinations: 10334,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22113: {
    label: 'Vermilion',
    iso3: null,
    population: '59,511',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3059,
    completeVaccinations: 23397,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22115: {
    label: 'Vernon',
    iso3: null,
    population: '47,429',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4378,
    completeVaccinations: 28609,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22117: {
    label: 'Washington',
    iso3: null,
    population: '46,194',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2570,
    completeVaccinations: 19912,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22119: {
    label: 'Webster',
    iso3: null,
    population: '38,340',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2136,
    completeVaccinations: 16960,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22121: {
    label: 'West Baton Rouge',
    iso3: null,
    population: '26,465',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1587,
    completeVaccinations: 14570,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22123: {
    label: 'West Carroll',
    iso3: null,
    population: '10,830',
    casesPastWeek: 14,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 512,
    completeVaccinations: 4507,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22125: {
    label: 'West Feliciana',
    iso3: null,
    population: '15,568',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1089,
    completeVaccinations: 14094,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_22127: {
    label: 'Winn',
    iso3: null,
    population: '13,904',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 57.099999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 857,
    completeVaccinations: 6810,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_23001: {
    label: 'Androscoggin',
    iso3: null,
    population: '108,277',
    casesPastWeek: 119,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11621,
    completeVaccinations: 79302,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23003: {
    label: 'Aroostook',
    iso3: null,
    population: '67,055',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4572,
    completeVaccinations: 48049,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23005: {
    label: 'Cumberland',
    iso3: null,
    population: '295,003',
    casesPastWeek: 264,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 45334,
    completeVaccinations: 274128,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23007: {
    label: 'Franklin',
    iso3: null,
    population: '30,199',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3017,
    completeVaccinations: 20280,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23009: {
    label: 'Hancock',
    iso3: null,
    population: '54,987',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6514,
    completeVaccinations: 44468,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23011: {
    label: 'Kennebec',
    iso3: null,
    population: '122,302',
    casesPastWeek: 135,
    casesIncreasingPercentage: 77.63157894736842,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15277,
    completeVaccinations: 92129,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23013: {
    label: 'Knox',
    iso3: null,
    population: '39,772',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5023,
    completeVaccinations: 34994,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23015: {
    label: 'Lincoln',
    iso3: null,
    population: '34,634',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3419,
    completeVaccinations: 29376,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23017: {
    label: 'Oxford',
    iso3: null,
    population: '57,975',
    casesPastWeek: 93,
    casesIncreasingPercentage: 20.779220779220786,
    positiveCasePercentage: 16.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6876,
    completeVaccinations: 41315,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23019: {
    label: 'Penobscot',
    iso3: null,
    population: '152,148',
    casesPastWeek: 257,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16855,
    completeVaccinations: 113134,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23021: {
    label: 'Piscataquis',
    iso3: null,
    population: '16,785',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 966,
    completeVaccinations: 10805,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23023: {
    label: 'Sagadahoc',
    iso3: null,
    population: '35,856',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3766,
    completeVaccinations: 30108,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23025: {
    label: 'Somerset',
    iso3: null,
    population: '50,484',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3915,
    completeVaccinations: 32603,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23027: {
    label: 'Waldo',
    iso3: null,
    population: '39,715',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4441,
    completeVaccinations: 29634,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23029: {
    label: 'Washington',
    iso3: null,
    population: '31,379',
    casesPastWeek: 23,
    casesIncreasingPercentage: 21.052631578947366,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3389,
    completeVaccinations: 23796,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_23031: {
    label: 'York',
    iso3: null,
    population: '207,641',
    casesPastWeek: 177,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31801,
    completeVaccinations: 173560,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_24001: {
    label: 'Allegany',
    iso3: null,
    population: '70,416',
    casesPastWeek: 59,
    casesIncreasingPercentage: 68.57142857142857,
    positiveCasePercentage: 4.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4978,
    completeVaccinations: 40519,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24003: {
    label: 'Anne Arundel',
    iso3: null,
    population: '579,234',
    casesPastWeek: 238,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 60059,
    completeVaccinations: 467742,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24005: {
    label: 'Baltimore',
    iso3: null,
    population: '827,370',
    casesPastWeek: 328,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 67244,
    completeVaccinations: 628485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24009: {
    label: 'Calvert',
    iso3: null,
    population: '92,525',
    casesPastWeek: 27,
    casesIncreasingPercentage: 28.57142857142858,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8151,
    completeVaccinations: 68231,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24011: {
    label: 'Caroline',
    iso3: null,
    population: '33,406',
    casesPastWeek: 13,
    casesIncreasingPercentage: 8.333333333333325,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1831,
    completeVaccinations: 19722,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24013: {
    label: 'Carroll',
    iso3: null,
    population: '168,447',
    casesPastWeek: 63,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12607,
    completeVaccinations: 125950,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24015: {
    label: 'Cecil',
    iso3: null,
    population: '102,855',
    casesPastWeek: 44,
    casesIncreasingPercentage: 25.71428571428571,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13102,
    completeVaccinations: 63505,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24017: {
    label: 'Charles',
    iso3: null,
    population: '163,257',
    casesPastWeek: 58,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17582,
    completeVaccinations: 121050,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24019: {
    label: 'Dorchester',
    iso3: null,
    population: '31,929',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2098,
    completeVaccinations: 19730,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24021: {
    label: 'Frederick',
    iso3: null,
    population: '259,547',
    casesPastWeek: 114,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25435,
    completeVaccinations: 214066,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24023: {
    label: 'Garrett',
    iso3: null,
    population: '29,014',
    casesPastWeek: 20,
    casesIncreasingPercentage: 5.263157894736836,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2165,
    completeVaccinations: 15380,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24025: {
    label: 'Harford',
    iso3: null,
    population: '255,441',
    casesPastWeek: 103,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20942,
    completeVaccinations: 185993,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24027: {
    label: 'Howard',
    iso3: null,
    population: '325,690',
    casesPastWeek: 135,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 34379,
    completeVaccinations: 294984,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24029: {
    label: 'Kent',
    iso3: null,
    population: '19,422',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1884,
    completeVaccinations: 13293,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24031: {
    label: 'Montgomery',
    iso3: null,
    population: '1,050,688',
    casesPastWeek: 517,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 176141,
    completeVaccinations: 984471,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24033: {
    label: "Prince George's",
    iso3: null,
    population: '909,327',
    casesPastWeek: 429,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 136420,
    completeVaccinations: 721351,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24035: {
    label: "Queen Anne's",
    iso3: null,
    population: '50,381',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3996,
    completeVaccinations: 35303,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24037: {
    label: "St. Mary's",
    iso3: null,
    population: '113,510',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10024,
    completeVaccinations: 78158,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24039: {
    label: 'Somerset',
    iso3: null,
    population: '25,616',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1763,
    completeVaccinations: 13336,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24041: {
    label: 'Talbot',
    iso3: null,
    population: '37,181',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3448,
    completeVaccinations: 28522,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24043: {
    label: 'Washington',
    iso3: null,
    population: '151,049',
    casesPastWeek: 49,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10728,
    completeVaccinations: 93697,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24045: {
    label: 'Wicomico',
    iso3: null,
    population: '103,609',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7482,
    completeVaccinations: 60254,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24047: {
    label: 'Worcester',
    iso3: null,
    population: '52,276',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5678,
    completeVaccinations: 39190,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_24510: {
    label: 'Baltimore City',
    iso3: null,
    population: '593,490',
    casesPastWeek: 328,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 57906,
    completeVaccinations: 403422,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_Massachusetts_Dukes_and_Nantucket: {
    label: 'Dukes and Nantucket',
    iso3: null,
    population: '28,731',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25001: {
    label: 'Barnstable',
    iso3: null,
    population: '212,990',
    casesPastWeek: 124,
    casesIncreasingPercentage: 3.3333333333333437,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25003: {
    label: 'Berkshire',
    iso3: null,
    population: '124,944',
    casesPastWeek: 110,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21834,
    completeVaccinations: 79777,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25005: {
    label: 'Bristol',
    iso3: null,
    population: '565,217',
    casesPastWeek: 278,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 93799,
    completeVaccinations: 398195,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25009: {
    label: 'Essex',
    iso3: null,
    population: '789,034',
    casesPastWeek: 364,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 142356,
    completeVaccinations: 621242,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25011: {
    label: 'Franklin',
    iso3: null,
    population: '70,180',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14269,
    completeVaccinations: 51837,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25013: {
    label: 'Hampden',
    iso3: null,
    population: '466,372',
    casesPastWeek: 291,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 81793,
    completeVaccinations: 327110,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25015: {
    label: 'Hampshire',
    iso3: null,
    population: '160,830',
    casesPastWeek: 56,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28717,
    completeVaccinations: 116005,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25017: {
    label: 'Middlesex',
    iso3: null,
    population: '1,611,699',
    casesPastWeek: 766,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 336182,
    completeVaccinations: 1377606,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25021: {
    label: 'Norfolk',
    iso3: null,
    population: '706,775',
    casesPastWeek: 310,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 122484,
    completeVaccinations: 598750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25023: {
    label: 'Plymouth',
    iso3: null,
    population: '521,202',
    casesPastWeek: 252,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 80514,
    completeVaccinations: 392226,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25025: {
    label: 'Suffolk',
    iso3: null,
    population: '803,907',
    casesPastWeek: 372,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 176782,
    completeVaccinations: 628542,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_25027: {
    label: 'Worcester',
    iso3: null,
    population: '830,622',
    casesPastWeek: 412,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 157747,
    completeVaccinations: 620317,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_26001: {
    label: 'Alcona',
    iso3: null,
    population: '10,405',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95,
    completeVaccinations: 6200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26003: {
    label: 'Alger',
    iso3: null,
    population: '9,108',
    casesPastWeek: 4,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 383,
    completeVaccinations: 5834,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26005: {
    label: 'Allegan',
    iso3: null,
    population: '118,081',
    casesPastWeek: 50,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25,
    completeVaccinations: 63446,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26007: {
    label: 'Alpena',
    iso3: null,
    population: '28,405',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 69,
    completeVaccinations: 16192,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26009: {
    label: 'Antrim',
    iso3: null,
    population: '23,324',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 720,
    completeVaccinations: 13916,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26011: {
    label: 'Arenac',
    iso3: null,
    population: '14,883',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 275,
    completeVaccinations: 7761,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26013: {
    label: 'Baraga',
    iso3: null,
    population: '8,209',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 509,
    completeVaccinations: 5163,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26015: {
    label: 'Barry',
    iso3: null,
    population: '61,550',
    casesPastWeek: 52,
    casesIncreasingPercentage: 160.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 253,
    completeVaccinations: 31236,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26017: {
    label: 'Bay',
    iso3: null,
    population: '103,126',
    casesPastWeek: 54,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1343,
    completeVaccinations: 60281,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26019: {
    label: 'Benzie',
    iso3: null,
    population: '17,766',
    casesPastWeek: 9,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 20.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 550,
    completeVaccinations: 11432,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26021: {
    label: 'Berrien',
    iso3: null,
    population: '153,401',
    casesPastWeek: 64,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7372,
    completeVaccinations: 89656,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26023: {
    label: 'Branch',
    iso3: null,
    population: '43,517',
    casesPastWeek: 38,
    casesIncreasingPercentage: 8.571428571428562,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 847,
    completeVaccinations: 20266,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26025: {
    label: 'Calhoun',
    iso3: null,
    population: '134,159',
    casesPastWeek: 123,
    casesIncreasingPercentage: 9.82142857142858,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 403,
    completeVaccinations: 72223,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26027: {
    label: 'Cass',
    iso3: null,
    population: '51,787',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3132,
    completeVaccinations: 23605,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26029: {
    label: 'Charlevoix',
    iso3: null,
    population: '26,143',
    casesPastWeek: 8,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 435,
    completeVaccinations: 17286,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26031: {
    label: 'Cheboygan',
    iso3: null,
    population: '25,276',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 411,
    completeVaccinations: 14615,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26033: {
    label: 'Chippewa',
    iso3: null,
    population: '37,349',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1863,
    completeVaccinations: 26786,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26035: {
    label: 'Clare',
    iso3: null,
    population: '30,950',
    casesPastWeek: 34,
    casesIncreasingPercentage: 9.677419354838701,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 292,
    completeVaccinations: 14637,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26037: {
    label: 'Clinton',
    iso3: null,
    population: '79,595',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 263,
    completeVaccinations: 47304,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26039: {
    label: 'Crawford',
    iso3: null,
    population: '14,029',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 111,
    completeVaccinations: 7367,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26041: {
    label: 'Delta',
    iso3: null,
    population: '35,784',
    casesPastWeek: 43,
    casesIncreasingPercentage: 59.25925925925925,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 889,
    completeVaccinations: 22876,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26043: {
    label: 'Dickinson',
    iso3: null,
    population: '25,239',
    casesPastWeek: 32,
    casesIncreasingPercentage: 23.076923076923084,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1859,
    completeVaccinations: 17654,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26045: {
    label: 'Eaton',
    iso3: null,
    population: '110,268',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.200000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 990,
    completeVaccinations: 64345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26047: {
    label: 'Emmet',
    iso3: null,
    population: '33,415',
    casesPastWeek: 17,
    casesIncreasingPercentage: 142.85714285714283,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 697,
    completeVaccinations: 24812,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26049: {
    label: 'Genesee',
    iso3: null,
    population: '405,813',
    casesPastWeek: 303,
    casesIncreasingPercentage: 18.823529411764707,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6959,
    completeVaccinations: 209154,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26051: {
    label: 'Gladwin',
    iso3: null,
    population: '25,449',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 362,
    completeVaccinations: 12401,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26053: {
    label: 'Gogebic',
    iso3: null,
    population: '13,975',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 626,
    completeVaccinations: 9360,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26055: {
    label: 'Grand Traverse',
    iso3: null,
    population: '93,088',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2540,
    completeVaccinations: 63317,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26057: {
    label: 'Gratiot',
    iso3: null,
    population: '40,711',
    casesPastWeek: 48,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 93,
    completeVaccinations: 19119,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26059: {
    label: 'Hillsdale',
    iso3: null,
    population: '45,605',
    casesPastWeek: 60,
    casesIncreasingPercentage: 30.434782608695656,
    positiveCasePercentage: 27.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 698,
    completeVaccinations: 18131,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26061: {
    label: 'Houghton',
    iso3: null,
    population: '35,684',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 419,
    completeVaccinations: 19456,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26063: {
    label: 'Huron',
    iso3: null,
    population: '30,981',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 228,
    completeVaccinations: 17199,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26065: {
    label: 'Ingham',
    iso3: null,
    population: '292,406',
    casesPastWeek: 208,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6499,
    completeVaccinations: 171521,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26067: {
    label: 'Ionia',
    iso3: null,
    population: '64,697',
    casesPastWeek: 40,
    casesIncreasingPercentage: 37.93103448275863,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3558,
    completeVaccinations: 31169,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26069: {
    label: 'Iosco',
    iso3: null,
    population: '25,127',
    casesPastWeek: 8,
    casesIncreasingPercentage: 60.00000000000001,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 581,
    completeVaccinations: 14385,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26071: {
    label: 'Iron',
    iso3: null,
    population: '11,066',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 415,
    completeVaccinations: 6360,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26073: {
    label: 'Isabella',
    iso3: null,
    population: '69,872',
    casesPastWeek: 31,
    casesIncreasingPercentage: 47.61904761904763,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 829,
    completeVaccinations: 31997,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26075: {
    label: 'Jackson',
    iso3: null,
    population: '158,510',
    casesPastWeek: 135,
    casesIncreasingPercentage: 17.391304347826097,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9717,
    completeVaccinations: 85126,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26077: {
    label: 'Kalamazoo',
    iso3: null,
    population: '265,066',
    casesPastWeek: 141,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 611,
    completeVaccinations: 168683,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26079: {
    label: 'Kalkaska',
    iso3: null,
    population: '18,038',
    casesPastWeek: 13,
    casesIncreasingPercentage: 62.5,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 343,
    completeVaccinations: 8565,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26081: {
    label: 'Kent',
    iso3: null,
    population: '656,955',
    casesPastWeek: 290,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 47634,
    completeVaccinations: 417267,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26083: {
    label: 'Keweenaw',
    iso3: null,
    population: '2,116',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 80,
    completeVaccinations: 1277,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26085: {
    label: 'Lake',
    iso3: null,
    population: '11,853',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 209,
    completeVaccinations: 7772,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26087: {
    label: 'Lapeer',
    iso3: null,
    population: '87,607',
    casesPastWeek: 53,
    casesIncreasingPercentage: 39.47368421052631,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1354,
    completeVaccinations: 42167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26089: {
    label: 'Leelanau',
    iso3: null,
    population: '21,761',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1169,
    completeVaccinations: 18251,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26091: {
    label: 'Lenawee',
    iso3: null,
    population: '98,451',
    casesPastWeek: 96,
    casesIncreasingPercentage: 7.86516853932584,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2598,
    completeVaccinations: 53609,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26093: {
    label: 'Livingston',
    iso3: null,
    population: '191,995',
    casesPastWeek: 108,
    casesIncreasingPercentage: 0.9345794392523255,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1422,
    completeVaccinations: 117930,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26095: {
    label: 'Luce',
    iso3: null,
    population: '6,229',
    casesPastWeek: 6,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 172,
    completeVaccinations: 2848,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26097: {
    label: 'Mackinac',
    iso3: null,
    population: '10,799',
    casesPastWeek: 7,
    casesIncreasingPercentage: 133.33333333333334,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 892,
    completeVaccinations: 8239,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26099: {
    label: 'Macomb',
    iso3: null,
    population: '873,972',
    casesPastWeek: 784,
    casesIncreasingPercentage: 13.131313131313128,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10327,
    completeVaccinations: 504804,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26101: {
    label: 'Manistee',
    iso3: null,
    population: '24,558',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 117,
    completeVaccinations: 15098,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26103: {
    label: 'Marquette',
    iso3: null,
    population: '66,699',
    casesPastWeek: 108,
    casesIncreasingPercentage: 11.340206185567014,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1285,
    completeVaccinations: 44440,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26105: {
    label: 'Mason',
    iso3: null,
    population: '29,144',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2073,
    completeVaccinations: 18163,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26107: {
    label: 'Mecosta',
    iso3: null,
    population: '43,453',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2154,
    completeVaccinations: 18870,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26109: {
    label: 'Menominee',
    iso3: null,
    population: '22,780',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2555,
    completeVaccinations: 13725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26111: {
    label: 'Midland',
    iso3: null,
    population: '83,156',
    casesPastWeek: 50,
    casesIncreasingPercentage: 2.0408163265306145,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1193,
    completeVaccinations: 50427,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26113: {
    label: 'Missaukee',
    iso3: null,
    population: '15,118',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 856,
    completeVaccinations: 7502,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26115: {
    label: 'Monroe',
    iso3: null,
    population: '150,500',
    casesPastWeek: 113,
    casesIncreasingPercentage: 0.8928571428571397,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7029,
    completeVaccinations: 89598,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26117: {
    label: 'Montcalm',
    iso3: null,
    population: '63,888',
    casesPastWeek: 26,
    casesIncreasingPercentage: 23.809523809523814,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3178,
    completeVaccinations: 27840,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26119: {
    label: 'Montmorency',
    iso3: null,
    population: '9,328',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 118,
    completeVaccinations: 5184,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26121: {
    label: 'Muskegon',
    iso3: null,
    population: '173,566',
    casesPastWeek: 112,
    casesIncreasingPercentage: 13.131313131313128,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11200,
    completeVaccinations: 98117,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26123: {
    label: 'Newaygo',
    iso3: null,
    population: '48,980',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2559,
    completeVaccinations: 22422,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26125: {
    label: 'Oakland',
    iso3: null,
    population: '1,257,584',
    casesPastWeek: 786,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36089,
    completeVaccinations: 852952,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26127: {
    label: 'Oceana',
    iso3: null,
    population: '26,467',
    casesPastWeek: 30,
    casesIncreasingPercentage: 114.28571428571428,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1704,
    completeVaccinations: 14931,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26129: {
    label: 'Ogemaw',
    iso3: null,
    population: '20,997',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 183,
    completeVaccinations: 9777,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26131: {
    label: 'Ontonagon',
    iso3: null,
    population: '5,720',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 295,
    completeVaccinations: 4100,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26133: {
    label: 'Osceola',
    iso3: null,
    population: '23,460',
    casesPastWeek: 20,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 51,
    completeVaccinations: 10085,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26135: {
    label: 'Oscoda',
    iso3: null,
    population: '8,241',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 247,
    completeVaccinations: 3508,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26137: {
    label: 'Otsego',
    iso3: null,
    population: '24,668',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 423,
    completeVaccinations: 13969,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26139: {
    label: 'Ottawa',
    iso3: null,
    population: '291,830',
    casesPastWeek: 128,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 431,
    completeVaccinations: 173501,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26141: {
    label: 'Presque Isle',
    iso3: null,
    population: '12,592',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 140,
    completeVaccinations: 7727,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26143: {
    label: 'Roscommon',
    iso3: null,
    population: '24,019',
    casesPastWeek: 10,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 424,
    completeVaccinations: 13829,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26145: {
    label: 'Saginaw',
    iso3: null,
    population: '190,539',
    casesPastWeek: 95,
    casesIncreasingPercentage: 3.2608695652173836,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 940,
    completeVaccinations: 103059,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26147: {
    label: 'St. Clair',
    iso3: null,
    population: '159,128',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2665,
    completeVaccinations: 79850,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26149: {
    label: 'St. Joseph',
    iso3: null,
    population: '60,964',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1174,
    completeVaccinations: 27986,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26151: {
    label: 'Sanilac',
    iso3: null,
    population: '41,170',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 480,
    completeVaccinations: 17843,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26153: {
    label: 'Schoolcraft',
    iso3: null,
    population: '8,094',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 37,
    completeVaccinations: 4632,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_26155: {
    label: 'Shiawassee',
    iso3: null,
    population: '68,122',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 564,
    completeVaccinations: 35645,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26157: {
    label: 'Tuscola',
    iso3: null,
    population: '52,245',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 598,
    completeVaccinations: 24437,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26159: {
    label: 'Van Buren',
    iso3: null,
    population: '75,677',
    casesPastWeek: 46,
    casesIncreasingPercentage: 35.29411764705883,
    positiveCasePercentage: 19.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5094,
    completeVaccinations: 44625,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26161: {
    label: 'Washtenaw',
    iso3: null,
    population: '367,601',
    casesPastWeek: 218,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13564,
    completeVaccinations: 271230,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26163: {
    label: 'Wayne',
    iso3: null,
    population: '1,749,343',
    casesPastWeek: 1613,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 56312,
    completeVaccinations: 972259,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_26165: {
    label: 'Wexford',
    iso3: null,
    population: '33,631',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3,
    completeVaccinations: 17667,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_27001: {
    label: 'Aitkin',
    iso3: null,
    population: '15,886',
    casesPastWeek: 17,
    casesIncreasingPercentage: 750.0,
    positiveCasePercentage: 20.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 692,
    completeVaccinations: 9655,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27003: {
    label: 'Anoka',
    iso3: null,
    population: '356,921',
    casesPastWeek: 302,
    casesIncreasingPercentage: 34.22222222222222,
    positiveCasePercentage: 14.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14365,
    completeVaccinations: 221308,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27005: {
    label: 'Becker',
    iso3: null,
    population: '34,423',
    casesPastWeek: 33,
    casesIncreasingPercentage: 106.25,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2020,
    completeVaccinations: 19616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27007: {
    label: 'Beltrami',
    iso3: null,
    population: '47,188',
    casesPastWeek: 45,
    casesIncreasingPercentage: 32.35294117647059,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3568,
    completeVaccinations: 34054,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27009: {
    label: 'Benton',
    iso3: null,
    population: '40,889',
    casesPastWeek: 44,
    casesIncreasingPercentage: 46.66666666666666,
    positiveCasePercentage: 19.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1480,
    completeVaccinations: 18724,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27011: {
    label: 'Big Stone',
    iso3: null,
    population: '4,991',
    casesPastWeek: 10,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 26.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 197,
    completeVaccinations: 3284,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27013: {
    label: 'Blue Earth',
    iso3: null,
    population: '67,653',
    casesPastWeek: 78,
    casesIncreasingPercentage: 225.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2608,
    completeVaccinations: 42624,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27015: {
    label: 'Brown',
    iso3: null,
    population: '25,008',
    casesPastWeek: 29,
    casesIncreasingPercentage: 20.833333333333325,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 737,
    completeVaccinations: 16876,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27017: {
    label: 'Carlton',
    iso3: null,
    population: '35,871',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1706,
    completeVaccinations: 24087,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27019: {
    label: 'Carver',
    iso3: null,
    population: '105,089',
    casesPastWeek: 60,
    casesIncreasingPercentage: 30.434782608695656,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4981,
    completeVaccinations: 73663,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27021: {
    label: 'Cass',
    iso3: null,
    population: '29,779',
    casesPastWeek: 38,
    casesIncreasingPercentage: 137.5,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1952,
    completeVaccinations: 17753,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27023: {
    label: 'Chippewa',
    iso3: null,
    population: '11,800',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 448,
    completeVaccinations: 6592,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27025: {
    label: 'Chisago',
    iso3: null,
    population: '56,579',
    casesPastWeek: 41,
    casesIncreasingPercentage: 57.692307692307686,
    positiveCasePercentage: 13.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2450,
    completeVaccinations: 33750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27027: {
    label: 'Clay',
    iso3: null,
    population: '64,222',
    casesPastWeek: 55,
    casesIncreasingPercentage: 89.65517241379311,
    positiveCasePercentage: 15.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11594,
    completeVaccinations: 42471,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27029: {
    label: 'Clearwater',
    iso3: null,
    population: '8,818',
    casesPastWeek: 13,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 304,
    completeVaccinations: 3816,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27031: {
    label: 'Cook',
    iso3: null,
    population: '5,463',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 381,
    completeVaccinations: 4563,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27033: {
    label: 'Cottonwood',
    iso3: null,
    population: '11,196',
    casesPastWeek: 15,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 28.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 420,
    completeVaccinations: 6498,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27035: {
    label: 'Crow Wing',
    iso3: null,
    population: '65,055',
    casesPastWeek: 61,
    casesIncreasingPercentage: 125.92592592592591,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2742,
    completeVaccinations: 38019,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27037: {
    label: 'Dakota',
    iso3: null,
    population: '429,021',
    casesPastWeek: 377,
    casesIncreasingPercentage: 45.559845559845556,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24018,
    completeVaccinations: 312212,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27039: {
    label: 'Dodge',
    iso3: null,
    population: '20,934',
    casesPastWeek: 24,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 580,
    completeVaccinations: 13061,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27041: {
    label: 'Douglas',
    iso3: null,
    population: '38,141',
    casesPastWeek: 59,
    casesIncreasingPercentage: 96.66666666666666,
    positiveCasePercentage: 15.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1554,
    completeVaccinations: 22663,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27043: {
    label: 'Faribault',
    iso3: null,
    population: '13,653',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 426,
    completeVaccinations: 8039,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27045: {
    label: 'Fillmore',
    iso3: null,
    population: '21,067',
    casesPastWeek: 32,
    casesIncreasingPercentage: 113.33333333333333,
    positiveCasePercentage: 22.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1120,
    completeVaccinations: 14995,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27047: {
    label: 'Freeborn',
    iso3: null,
    population: '30,281',
    casesPastWeek: 27,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1107,
    completeVaccinations: 18085,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27049: {
    label: 'Goodhue',
    iso3: null,
    population: '46,340',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1948,
    completeVaccinations: 31896,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27051: {
    label: 'Grant',
    iso3: null,
    population: '5,972',
    casesPastWeek: 7,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 174,
    completeVaccinations: 3398,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27053: {
    label: 'Hennepin',
    iso3: null,
    population: '1,265,843',
    casesPastWeek: 1168,
    casesIncreasingPercentage: 52.679738562091494,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 92595,
    completeVaccinations: 987554,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27055: {
    label: 'Houston',
    iso3: null,
    population: '18,600',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2030,
    completeVaccinations: 11772,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27057: {
    label: 'Hubbard',
    iso3: null,
    population: '21,491',
    casesPastWeek: 17,
    casesIncreasingPercentage: 142.85714285714283,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 929,
    completeVaccinations: 10602,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27059: {
    label: 'Isanti',
    iso3: null,
    population: '40,596',
    casesPastWeek: 35,
    casesIncreasingPercentage: 34.61538461538463,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1361,
    completeVaccinations: 18176,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27061: {
    label: 'Itasca',
    iso3: null,
    population: '45,130',
    casesPastWeek: 55,
    casesIncreasingPercentage: 175.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1893,
    completeVaccinations: 26348,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27063: {
    label: 'Jackson',
    iso3: null,
    population: '9,846',
    casesPastWeek: 15,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 439,
    completeVaccinations: 5227,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27065: {
    label: 'Kanabec',
    iso3: null,
    population: '16,337',
    casesPastWeek: 22,
    casesIncreasingPercentage: 57.14285714285714,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 525,
    completeVaccinations: 7249,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27067: {
    label: 'Kandiyohi',
    iso3: null,
    population: '43,199',
    casesPastWeek: 42,
    casesIncreasingPercentage: 13.513513513513509,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1958,
    completeVaccinations: 24479,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27069: {
    label: 'Kittson',
    iso3: null,
    population: '4,298',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 310,
    completeVaccinations: 2670,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27071: {
    label: 'Koochiching',
    iso3: null,
    population: '12,229',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 425,
    completeVaccinations: 7396,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27073: {
    label: 'Lac qui Parle',
    iso3: null,
    population: '6,623',
    casesPastWeek: 7,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 181,
    completeVaccinations: 4047,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27075: {
    label: 'Lake',
    iso3: null,
    population: '10,641',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 325,
    completeVaccinations: 7272,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27077: {
    label: 'Lake of the Woods',
    iso3: null,
    population: '3,740',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 146,
    completeVaccinations: 2132,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27079: {
    label: 'Le Sueur',
    iso3: null,
    population: '28,887',
    casesPastWeek: 10,
    casesIncreasingPercentage: 42.85714285714286,
    positiveCasePercentage: 1.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 855,
    completeVaccinations: 14739,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27081: {
    label: 'Lincoln',
    iso3: null,
    population: '5,639',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 161,
    completeVaccinations: 3148,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27083: {
    label: 'Lyon',
    iso3: null,
    population: '25,474',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 995,
    completeVaccinations: 14968,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27085: {
    label: 'McLeod',
    iso3: null,
    population: '35,893',
    casesPastWeek: 35,
    casesIncreasingPercentage: 34.61538461538463,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1371,
    completeVaccinations: 21452,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27087: {
    label: 'Mahnomen',
    iso3: null,
    population: '5,527',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 552,
    completeVaccinations: 3497,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27089: {
    label: 'Marshall',
    iso3: null,
    population: '9,336',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 454,
    completeVaccinations: 4805,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27091: {
    label: 'Martin',
    iso3: null,
    population: '19,683',
    casesPastWeek: 17,
    casesIncreasingPercentage: 21.42857142857142,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 706,
    completeVaccinations: 11259,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27093: {
    label: 'Meeker',
    iso3: null,
    population: '23,222',
    casesPastWeek: 47,
    casesIncreasingPercentage: 370.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 684,
    completeVaccinations: 11530,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27095: {
    label: 'Mille Lacs',
    iso3: null,
    population: '26,277',
    casesPastWeek: 35,
    casesIncreasingPercentage: 59.09090909090908,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1352,
    completeVaccinations: 15728,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27097: {
    label: 'Morrison',
    iso3: null,
    population: '33,386',
    casesPastWeek: 37,
    casesIncreasingPercentage: 32.14285714285714,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1286,
    completeVaccinations: 17320,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27099: {
    label: 'Mower',
    iso3: null,
    population: '40,062',
    casesPastWeek: 23,
    casesIncreasingPercentage: 27.777777777777768,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1720,
    completeVaccinations: 25806,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27101: {
    label: 'Murray',
    iso3: null,
    population: '8,194',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 362,
    completeVaccinations: 4648,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27103: {
    label: 'Nicollet',
    iso3: null,
    population: '34,274',
    casesPastWeek: 19,
    casesIncreasingPercentage: 35.71428571428572,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1310,
    completeVaccinations: 22200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27105: {
    label: 'Nobles',
    iso3: null,
    population: '21,629',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1377,
    completeVaccinations: 13751,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27107: {
    label: 'Norman',
    iso3: null,
    population: '6,375',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 508,
    completeVaccinations: 3986,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27109: {
    label: 'Olmsted',
    iso3: null,
    population: '158,293',
    casesPastWeek: 182,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 15.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9444,
    completeVaccinations: 127828,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27111: {
    label: 'Otter Tail',
    iso3: null,
    population: '58,746',
    casesPastWeek: 109,
    casesIncreasingPercentage: 131.91489361702128,
    positiveCasePercentage: 15.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2848,
    completeVaccinations: 31329,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27113: {
    label: 'Pennington',
    iso3: null,
    population: '14,119',
    casesPastWeek: 14,
    casesIncreasingPercentage: 179.99999999999997,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 96,
    completeVaccinations: 7750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27115: {
    label: 'Pine',
    iso3: null,
    population: '29,579',
    casesPastWeek: 25,
    casesIncreasingPercentage: 316.6666666666667,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1202,
    completeVaccinations: 15007,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27117: {
    label: 'Pipestone',
    iso3: null,
    population: '9,126',
    casesPastWeek: 19,
    casesIncreasingPercentage: 5.555555555555558,
    positiveCasePercentage: 18.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 375,
    completeVaccinations: 5211,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27119: {
    label: 'Polk',
    iso3: null,
    population: '31,364',
    casesPastWeek: 42,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 23.799999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2392,
    completeVaccinations: 19029,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27121: {
    label: 'Pope',
    iso3: null,
    population: '11,249',
    casesPastWeek: 18,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 22.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 405,
    completeVaccinations: 6688,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27123: {
    label: 'Ramsey',
    iso3: null,
    population: '550,321',
    casesPastWeek: 486,
    casesIncreasingPercentage: 49.53846153846153,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 37523,
    completeVaccinations: 415125,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27125: {
    label: 'Red Lake',
    iso3: null,
    population: '4,055',
    casesPastWeek: 6,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 28.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 167,
    completeVaccinations: 2352,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27127: {
    label: 'Redwood',
    iso3: null,
    population: '15,170',
    casesPastWeek: 47,
    casesIncreasingPercentage: 113.63636363636363,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 445,
    completeVaccinations: 8548,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27129: {
    label: 'Renville',
    iso3: null,
    population: '14,548',
    casesPastWeek: 26,
    casesIncreasingPercentage: 36.8421052631579,
    positiveCasePercentage: 18.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 469,
    completeVaccinations: 8206,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27131: {
    label: 'Rice',
    iso3: null,
    population: '66,972',
    casesPastWeek: 36,
    casesIncreasingPercentage: 16.129032258064523,
    positiveCasePercentage: 3.5000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2991,
    completeVaccinations: 43959,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27133: {
    label: 'Rock',
    iso3: null,
    population: '9,315',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 725,
    completeVaccinations: 5346,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27135: {
    label: 'Roseau',
    iso3: null,
    population: '15,165',
    casesPastWeek: 10,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 673,
    completeVaccinations: 8083,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27137: {
    label: 'St. Louis',
    iso3: null,
    population: '199,070',
    casesPastWeek: 181,
    casesIncreasingPercentage: 135.06493506493507,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12731,
    completeVaccinations: 138666,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27139: {
    label: 'Scott',
    iso3: null,
    population: '149,013',
    casesPastWeek: 124,
    casesIncreasingPercentage: 85.07462686567165,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7414,
    completeVaccinations: 107471,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27141: {
    label: 'Sherburne',
    iso3: null,
    population: '97,238',
    casesPastWeek: 58,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3618,
    completeVaccinations: 46808,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27143: {
    label: 'Sibley',
    iso3: null,
    population: '14,865',
    casesPastWeek: 7,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 458,
    completeVaccinations: 7505,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27145: {
    label: 'Stearns',
    iso3: null,
    population: '161,075',
    casesPastWeek: 149,
    casesIncreasingPercentage: 46.07843137254901,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7511,
    completeVaccinations: 99084,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27147: {
    label: 'Steele',
    iso3: null,
    population: '36,649',
    casesPastWeek: 22,
    casesIncreasingPercentage: 46.66666666666666,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1358,
    completeVaccinations: 23064,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27149: {
    label: 'Stevens',
    iso3: null,
    population: '9,805',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 385,
    completeVaccinations: 5917,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27151: {
    label: 'Swift',
    iso3: null,
    population: '9,266',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 370,
    completeVaccinations: 5541,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27153: {
    label: 'Todd',
    iso3: null,
    population: '24,664',
    casesPastWeek: 27,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 17.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 666,
    completeVaccinations: 10931,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27155: {
    label: 'Traverse',
    iso3: null,
    population: '3,259',
    casesPastWeek: 18,
    casesIncreasingPercentage: 800.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 212,
    completeVaccinations: 2185,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27157: {
    label: 'Wabasha',
    iso3: null,
    population: '21,627',
    casesPastWeek: 15,
    casesIncreasingPercentage: 114.28571428571428,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1268,
    completeVaccinations: 16798,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27159: {
    label: 'Wadena',
    iso3: null,
    population: '13,682',
    casesPastWeek: 22,
    casesIncreasingPercentage: 120.00000000000001,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 547,
    completeVaccinations: 7004,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27161: {
    label: 'Waseca',
    iso3: null,
    population: '18,612',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 626,
    completeVaccinations: 10940,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27163: {
    label: 'Washington',
    iso3: null,
    population: '262,440',
    casesPastWeek: 217,
    casesIncreasingPercentage: 77.86885245901641,
    positiveCasePercentage: 14.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15035,
    completeVaccinations: 193749,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27165: {
    label: 'Watonwan',
    iso3: null,
    population: '10,897',
    casesPastWeek: 12,
    casesIncreasingPercentage: 71.42857142857142,
    positiveCasePercentage: 21.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 352,
    completeVaccinations: 6546,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27167: {
    label: 'Wilkin',
    iso3: null,
    population: '6,207',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 687,
    completeVaccinations: 3671,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27169: {
    label: 'Winona',
    iso3: null,
    population: '50,484',
    casesPastWeek: 58,
    casesIncreasingPercentage: 131.99999999999997,
    positiveCasePercentage: 18.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3762,
    completeVaccinations: 34584,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27171: {
    label: 'Wright',
    iso3: null,
    population: '138,377',
    casesPastWeek: 99,
    casesIncreasingPercentage: 64.99999999999999,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4385,
    completeVaccinations: 78814,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_27173: {
    label: 'Yellow Medicine',
    iso3: null,
    population: '9,709',
    casesPastWeek: 26,
    casesIncreasingPercentage: 85.71428571428572,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 330,
    completeVaccinations: 5668,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_28001: {
    label: 'Adams',
    iso3: null,
    population: '30,693',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3202,
    completeVaccinations: 17542,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28003: {
    label: 'Alcorn',
    iso3: null,
    population: '36,953',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2233,
    completeVaccinations: 15040,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28005: {
    label: 'Amite',
    iso3: null,
    population: '12,297',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 677,
    completeVaccinations: 5094,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28007: {
    label: 'Attala',
    iso3: null,
    population: '18,174',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1437,
    completeVaccinations: 10207,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28009: {
    label: 'Benton',
    iso3: null,
    population: '8,259',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 686,
    completeVaccinations: 4453,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28011: {
    label: 'Bolivar',
    iso3: null,
    population: '30,628',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2801,
    completeVaccinations: 18764,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28013: {
    label: 'Calhoun',
    iso3: null,
    population: '14,361',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 967,
    completeVaccinations: 6605,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28015: {
    label: 'Carroll',
    iso3: null,
    population: '9,947',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 476,
    completeVaccinations: 4277,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28017: {
    label: 'Chickasaw',
    iso3: null,
    population: '17,103',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1108,
    completeVaccinations: 8534,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28019: {
    label: 'Choctaw',
    iso3: null,
    population: '8,210',
    casesPastWeek: 9,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 481,
    completeVaccinations: 3071,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28021: {
    label: 'Claiborne',
    iso3: null,
    population: '8,988',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 766,
    completeVaccinations: 5053,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28023: {
    label: 'Clarke',
    iso3: null,
    population: '15,541',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1298,
    completeVaccinations: 9160,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28025: {
    label: 'Clay',
    iso3: null,
    population: '19,316',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1342,
    completeVaccinations: 9378,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28027: {
    label: 'Coahoma',
    iso3: null,
    population: '22,124',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1739,
    completeVaccinations: 11944,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28029: {
    label: 'Copiah',
    iso3: null,
    population: '28,065',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 28.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2068,
    completeVaccinations: 15802,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28031: {
    label: 'Covington',
    iso3: null,
    population: '18,636',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 804,
    completeVaccinations: 10851,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28033: {
    label: 'DeSoto',
    iso3: null,
    population: '184,945',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19728,
    completeVaccinations: 106380,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28035: {
    label: 'Forrest',
    iso3: null,
    population: '74,897',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5185,
    completeVaccinations: 34169,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28037: {
    label: 'Franklin',
    iso3: null,
    population: '7,713',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 508,
    completeVaccinations: 3839,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28039: {
    label: 'George',
    iso3: null,
    population: '24,500',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1582,
    completeVaccinations: 10135,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28041: {
    label: 'Greene',
    iso3: null,
    population: '13,586',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 636,
    completeVaccinations: 5177,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28043: {
    label: 'Grenada',
    iso3: null,
    population: '20,758',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1376,
    completeVaccinations: 11265,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28045: {
    label: 'Hancock',
    iso3: null,
    population: '47,632',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3994,
    completeVaccinations: 22017,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28047: {
    label: 'Harrison',
    iso3: null,
    population: '208,080',
    casesPastWeek: 99,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19286,
    completeVaccinations: 119736,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28049: {
    label: 'Hinds',
    iso3: null,
    population: '231,840',
    casesPastWeek: 143,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19262,
    completeVaccinations: 140258,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28051: {
    label: 'Holmes',
    iso3: null,
    population: '17,010',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1327,
    completeVaccinations: 10631,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28053: {
    label: 'Humphreys',
    iso3: null,
    population: '8,064',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 826,
    completeVaccinations: 4904,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28055: {
    label: 'Issaquena',
    iso3: null,
    population: '1,327',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 83,
    completeVaccinations: 604,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_28057: {
    label: 'Itawamba',
    iso3: null,
    population: '23,390',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1105,
    completeVaccinations: 8365,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28059: {
    label: 'Jackson',
    iso3: null,
    population: '143,617',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11865,
    completeVaccinations: 70827,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28061: {
    label: 'Jasper',
    iso3: null,
    population: '16,383',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1280,
    completeVaccinations: 8688,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28063: {
    label: 'Jefferson',
    iso3: null,
    population: '6,990',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 733,
    completeVaccinations: 5119,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_28065: {
    label: 'Jefferson Davis',
    iso3: null,
    population: '11,128',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 585,
    completeVaccinations: 5221,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28067: {
    label: 'Jones',
    iso3: null,
    population: '68,098',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4722,
    completeVaccinations: 30594,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28069: {
    label: 'Kemper',
    iso3: null,
    population: '9,742',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 705,
    completeVaccinations: 4712,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28071: {
    label: 'Lafayette',
    iso3: null,
    population: '54,019',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3466,
    completeVaccinations: 30522,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28073: {
    label: 'Lamar',
    iso3: null,
    population: '63,343',
    casesPastWeek: 49,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4517,
    completeVaccinations: 38856,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28075: {
    label: 'Lauderdale',
    iso3: null,
    population: '74,125',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6075,
    completeVaccinations: 39194,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28077: {
    label: 'Lawrence',
    iso3: null,
    population: '12,586',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1037,
    completeVaccinations: 7198,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28079: {
    label: 'Leake',
    iso3: null,
    population: '22,786',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1809,
    completeVaccinations: 11596,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28081: {
    label: 'Lee',
    iso3: null,
    population: '85,436',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4890,
    completeVaccinations: 38396,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28083: {
    label: 'Leflore',
    iso3: null,
    population: '28,183',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2572,
    completeVaccinations: 18021,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28085: {
    label: 'Lincoln',
    iso3: null,
    population: '34,153',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2079,
    completeVaccinations: 13767,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28087: {
    label: 'Lowndes',
    iso3: null,
    population: '58,595',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4039,
    completeVaccinations: 31153,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28089: {
    label: 'Madison',
    iso3: null,
    population: '106,272',
    casesPastWeek: 96,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8415,
    completeVaccinations: 69622,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28091: {
    label: 'Marion',
    iso3: null,
    population: '24,573',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1526,
    completeVaccinations: 11734,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28093: {
    label: 'Marshall',
    iso3: null,
    population: '35,294',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3636,
    completeVaccinations: 20204,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28095: {
    label: 'Monroe',
    iso3: null,
    population: '35,252',
    casesPastWeek: 41,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2128,
    completeVaccinations: 18358,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28097: {
    label: 'Montgomery',
    iso3: null,
    population: '9,775',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 590,
    completeVaccinations: 5570,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28099: {
    label: 'Neshoba',
    iso3: null,
    population: '29,118',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 39.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2502,
    completeVaccinations: 12917,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28101: {
    label: 'Newton',
    iso3: null,
    population: '21,018',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 38.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1813,
    completeVaccinations: 11699,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28103: {
    label: 'Noxubee',
    iso3: null,
    population: '10,417',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 609,
    completeVaccinations: 5923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28105: {
    label: 'Oktibbeha',
    iso3: null,
    population: '49,587',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3232,
    completeVaccinations: 26125,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28107: {
    label: 'Panola',
    iso3: null,
    population: '34,192',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2275,
    completeVaccinations: 16899,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28109: {
    label: 'Pearl River',
    iso3: null,
    population: '55,535',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4941,
    completeVaccinations: 27648,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28111: {
    label: 'Perry',
    iso3: null,
    population: '11,973',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 606,
    completeVaccinations: 4681,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28113: {
    label: 'Pike',
    iso3: null,
    population: '39,288',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3136,
    completeVaccinations: 20560,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28115: {
    label: 'Pontotoc',
    iso3: null,
    population: '32,174',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1766,
    completeVaccinations: 13759,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28117: {
    label: 'Prentiss',
    iso3: null,
    population: '25,126',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1349,
    completeVaccinations: 11045,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28119: {
    label: 'Quitman',
    iso3: null,
    population: '6,792',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 563,
    completeVaccinations: 3775,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28121: {
    label: 'Rankin',
    iso3: null,
    population: '155,271',
    casesPastWeek: 71,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9691,
    completeVaccinations: 81835,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28123: {
    label: 'Scott',
    iso3: null,
    population: '28,124',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2228,
    completeVaccinations: 14752,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28125: {
    label: 'Sharkey',
    iso3: null,
    population: '4,321',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 316,
    completeVaccinations: 2489,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_28127: {
    label: 'Simpson',
    iso3: null,
    population: '26,658',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1774,
    completeVaccinations: 12465,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28129: {
    label: 'Smith',
    iso3: null,
    population: '15,916',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 800,
    completeVaccinations: 5628,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28131: {
    label: 'Stone',
    iso3: null,
    population: '18,336',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1350,
    completeVaccinations: 8708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28133: {
    label: 'Sunflower',
    iso3: null,
    population: '25,110',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1774,
    completeVaccinations: 13763,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28135: {
    label: 'Tallahatchie',
    iso3: null,
    population: '13,809',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 825,
    completeVaccinations: 5904,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28137: {
    label: 'Tate',
    iso3: null,
    population: '28,321',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1825,
    completeVaccinations: 13173,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28139: {
    label: 'Tippah',
    iso3: null,
    population: '22,015',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1454,
    completeVaccinations: 9539,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28141: {
    label: 'Tishomingo',
    iso3: null,
    population: '19,383',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1281,
    completeVaccinations: 7716,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28143: {
    label: 'Tunica',
    iso3: null,
    population: '9,632',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 835,
    completeVaccinations: 5177,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_28145: {
    label: 'Union',
    iso3: null,
    population: '28,815',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1360,
    completeVaccinations: 11645,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28147: {
    label: 'Walthall',
    iso3: null,
    population: '14,286',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 840,
    completeVaccinations: 6100,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28149: {
    label: 'Warren',
    iso3: null,
    population: '45,381',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3710,
    completeVaccinations: 27028,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28151: {
    label: 'Washington',
    iso3: null,
    population: '43,909',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3721,
    completeVaccinations: 23148,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28153: {
    label: 'Wayne',
    iso3: null,
    population: '20,183',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1211,
    completeVaccinations: 8135,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28155: {
    label: 'Webster',
    iso3: null,
    population: '9,689',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 542,
    completeVaccinations: 4781,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28157: {
    label: 'Wilkinson',
    iso3: null,
    population: '8,630',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 812,
    completeVaccinations: 5872,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_28159: {
    label: 'Winston',
    iso3: null,
    population: '17,955',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 66.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1228,
    completeVaccinations: 8912,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_28161: {
    label: 'Yalobusha',
    iso3: null,
    population: '12,108',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 992,
    completeVaccinations: 8159,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_28163: {
    label: 'Yazoo',
    iso3: null,
    population: '29,690',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2025,
    completeVaccinations: 15200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_Missouri_Kansas_City: {
    label: 'Kansas City',
    iso3: null,
    population: '488,943',
    casesPastWeek: 262,
    casesIncreasingPercentage: 22.429906542056077,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29001: {
    label: 'Adair',
    iso3: null,
    population: '25,343',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1746,
    completeVaccinations: 12125,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29003: {
    label: 'Andrew',
    iso3: null,
    population: '17,712',
    casesPastWeek: 11,
    casesIncreasingPercentage: 57.14285714285714,
    positiveCasePercentage: 69.19999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 832,
    completeVaccinations: 7192,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29005: {
    label: 'Atchison',
    iso3: null,
    population: '5,143',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 474,
    completeVaccinations: 3008,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_29007: {
    label: 'Audrain',
    iso3: null,
    population: '25,388',
    casesPastWeek: 18,
    casesIncreasingPercentage: 125.0,
    positiveCasePercentage: 23.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1321,
    completeVaccinations: 10054,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29009: {
    label: 'Barry',
    iso3: null,
    population: '35,789',
    casesPastWeek: 22,
    casesIncreasingPercentage: 83.33333333333333,
    positiveCasePercentage: 39.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3089,
    completeVaccinations: 16531,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29011: {
    label: 'Barton',
    iso3: null,
    population: '11,754',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 601,
    completeVaccinations: 4051,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29013: {
    label: 'Bates',
    iso3: null,
    population: '16,172',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 880,
    completeVaccinations: 6527,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29015: {
    label: 'Benton',
    iso3: null,
    population: '19,443',
    casesPastWeek: 12,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 20.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1371,
    completeVaccinations: 9774,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29017: {
    label: 'Bollinger',
    iso3: null,
    population: '12,133',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 514,
    completeVaccinations: 4149,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29019: {
    label: 'Boone',
    iso3: null,
    population: '180,463',
    casesPastWeek: 139,
    casesIncreasingPercentage: 8.59375,
    positiveCasePercentage: 17.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19250,
    completeVaccinations: 119262,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29021: {
    label: 'Buchanan',
    iso3: null,
    population: '87,364',
    casesPastWeek: 45,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 55.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5346,
    completeVaccinations: 35344,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29023: {
    label: 'Butler',
    iso3: null,
    population: '42,478',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2836,
    completeVaccinations: 15295,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29025: {
    label: 'Caldwell',
    iso3: null,
    population: '9,020',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 503,
    completeVaccinations: 3650,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29027: {
    label: 'Callaway',
    iso3: null,
    population: '44,743',
    casesPastWeek: 63,
    casesIncreasingPercentage: 270.5882352941177,
    positiveCasePercentage: 17.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2824,
    completeVaccinations: 22448,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29029: {
    label: 'Camden',
    iso3: null,
    population: '46,305',
    casesPastWeek: 21,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3072,
    completeVaccinations: 21950,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29031: {
    label: 'Cape Girardeau',
    iso3: null,
    population: '78,871',
    casesPastWeek: 34,
    casesIncreasingPercentage: 30.76923076923077,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5625,
    completeVaccinations: 40096,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29033: {
    label: 'Carroll',
    iso3: null,
    population: '8,679',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 71.39999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 454,
    completeVaccinations: 4417,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29035: {
    label: 'Carter',
    iso3: null,
    population: '5,982',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 417,
    completeVaccinations: 2057,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29037: {
    label: 'Cass',
    iso3: null,
    population: '105,780',
    casesPastWeek: 44,
    casesIncreasingPercentage: 25.71428571428571,
    positiveCasePercentage: 20.200000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10186,
    completeVaccinations: 58210,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29039: {
    label: 'Cedar',
    iso3: null,
    population: '14,349',
    casesPastWeek: 22,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 643,
    completeVaccinations: 4701,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29041: {
    label: 'Chariton',
    iso3: null,
    population: '7,426',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 351,
    completeVaccinations: 2940,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29043: {
    label: 'Christian',
    iso3: null,
    population: '88,595',
    casesPastWeek: 52,
    casesIncreasingPercentage: 40.54054054054055,
    positiveCasePercentage: 32.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5764,
    completeVaccinations: 42237,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29045: {
    label: 'Clark',
    iso3: null,
    population: '6,797',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 384,
    completeVaccinations: 2243,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29047: {
    label: 'Clay',
    iso3: null,
    population: '249,948',
    casesPastWeek: 60,
    casesIncreasingPercentage: 15.384615384615374,
    positiveCasePercentage: 34.699999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22002,
    completeVaccinations: 142407,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29049: {
    label: 'Clinton',
    iso3: null,
    population: '20,387',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 29.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1892,
    completeVaccinations: 10260,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29051: {
    label: 'Cole',
    iso3: null,
    population: '76,745',
    casesPastWeek: 182,
    casesIncreasingPercentage: 304.44444444444446,
    positiveCasePercentage: 23.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5741,
    completeVaccinations: 42152,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29053: {
    label: 'Cooper',
    iso3: null,
    population: '17,709',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1115,
    completeVaccinations: 8265,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29055: {
    label: 'Crawford',
    iso3: null,
    population: '23,920',
    casesPastWeek: 25,
    casesIncreasingPercentage: 38.888888888888886,
    positiveCasePercentage: 45.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1153,
    completeVaccinations: 8300,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29057: {
    label: 'Dade',
    iso3: null,
    population: '7,561',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 415,
    completeVaccinations: 3377,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29059: {
    label: 'Dallas',
    iso3: null,
    population: '16,878',
    casesPastWeek: 9,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 927,
    completeVaccinations: 6124,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29061: {
    label: 'Daviess',
    iso3: null,
    population: '8,278',
    casesPastWeek: 4,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 355,
    completeVaccinations: 2885,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29063: {
    label: 'DeKalb',
    iso3: null,
    population: '12,547',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 449,
    completeVaccinations: 3646,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29065: {
    label: 'Dent',
    iso3: null,
    population: '15,573',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 66.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1092,
    completeVaccinations: 5568,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29067: {
    label: 'Douglas',
    iso3: null,
    population: '13,185',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 421,
    completeVaccinations: 3274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29069: {
    label: 'Dunklin',
    iso3: null,
    population: '29,131',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 53.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1988,
    completeVaccinations: 11366,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29071: {
    label: 'Franklin',
    iso3: null,
    population: '103,967',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6749,
    completeVaccinations: 58111,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29073: {
    label: 'Gasconade',
    iso3: null,
    population: '14,706',
    casesPastWeek: 8,
    casesIncreasingPercentage: 60.00000000000001,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 774,
    completeVaccinations: 7639,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29075: {
    label: 'Gentry',
    iso3: null,
    population: '6,571',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 50.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 364,
    completeVaccinations: 3003,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29077: {
    label: 'Greene',
    iso3: null,
    population: '293,086',
    casesPastWeek: 151,
    casesIncreasingPercentage: 4.137931034482767,
    positiveCasePercentage: 33.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22412,
    completeVaccinations: 150846,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29079: {
    label: 'Grundy',
    iso3: null,
    population: '9,850',
    casesPastWeek: 9,
    casesIncreasingPercentage: 125.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 549,
    completeVaccinations: 3669,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29081: {
    label: 'Harrison',
    iso3: null,
    population: '8,352',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 549,
    completeVaccinations: 2989,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_29083: {
    label: 'Henry',
    iso3: null,
    population: '21,824',
    casesPastWeek: 8,
    casesIncreasingPercentage: 60.00000000000001,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1430,
    completeVaccinations: 10428,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29085: {
    label: 'Hickory',
    iso3: null,
    population: '9,544',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 512,
    completeVaccinations: 3769,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29087: {
    label: 'Holt',
    iso3: null,
    population: '4,403',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 255,
    completeVaccinations: 1926,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_29089: {
    label: 'Howard',
    iso3: null,
    population: '10,001',
    casesPastWeek: 15,
    casesIncreasingPercentage: 87.5,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 662,
    completeVaccinations: 4477,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29091: {
    label: 'Howell',
    iso3: null,
    population: '40,117',
    casesPastWeek: 19,
    casesIncreasingPercentage: 11.764705882352944,
    positiveCasePercentage: 42.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2268,
    completeVaccinations: 13882,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29093: {
    label: 'Iron',
    iso3: null,
    population: '10,125',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 501,
    completeVaccinations: 4064,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29095: {
    label: 'Jackson',
    iso3: null,
    population: '703,011',
    casesPastWeek: 318,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 97756,
    completeVaccinations: 428512,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_29097: {
    label: 'Jasper',
    iso3: null,
    population: '121,328',
    casesPastWeek: 73,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9439,
    completeVaccinations: 59716,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29099: {
    label: 'Jefferson',
    iso3: null,
    population: '225,081',
    casesPastWeek: 148,
    casesIncreasingPercentage: 8.823529411764696,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14941,
    completeVaccinations: 105203,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29101: {
    label: 'Johnson',
    iso3: null,
    population: '54,062',
    casesPastWeek: 26,
    casesIncreasingPercentage: 52.941176470588225,
    positiveCasePercentage: 17.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4608,
    completeVaccinations: 25317,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29103: {
    label: 'Knox',
    iso3: null,
    population: '3,959',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 152,
    completeVaccinations: 1631,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29105: {
    label: 'Laclede',
    iso3: null,
    population: '35,723',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2075,
    completeVaccinations: 13951,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29107: {
    label: 'Lafayette',
    iso3: null,
    population: '32,708',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2060,
    completeVaccinations: 15530,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29109: {
    label: 'Lawrence',
    iso3: null,
    population: '38,355',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2117,
    completeVaccinations: 15126,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29111: {
    label: 'Lewis',
    iso3: null,
    population: '9,776',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 814,
    completeVaccinations: 3492,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29113: {
    label: 'Lincoln',
    iso3: null,
    population: '59,013',
    casesPastWeek: 29,
    casesIncreasingPercentage: 11.538461538461542,
    positiveCasePercentage: 22.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3158,
    completeVaccinations: 24026,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29115: {
    label: 'Linn',
    iso3: null,
    population: '11,920',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 789,
    completeVaccinations: 5828,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_29117: {
    label: 'Livingston',
    iso3: null,
    population: '15,227',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 821,
    completeVaccinations: 6751,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29119: {
    label: 'McDonald',
    iso3: null,
    population: '22,837',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 36.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2437,
    completeVaccinations: 8044,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29121: {
    label: 'Macon',
    iso3: null,
    population: '15,117',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.9000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 621,
    completeVaccinations: 6415,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29123: {
    label: 'Madison',
    iso3: null,
    population: '12,088',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 732,
    completeVaccinations: 5022,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29125: {
    label: 'Maries',
    iso3: null,
    population: '8,697',
    casesPastWeek: 10,
    casesIncreasingPercentage: 900.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 487,
    completeVaccinations: 3972,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29127: {
    label: 'Marion',
    iso3: null,
    population: '28,530',
    casesPastWeek: 24,
    casesIncreasingPercentage: 41.176470588235304,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1485,
    completeVaccinations: 12392,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29129: {
    label: 'Mercer',
    iso3: null,
    population: '3,617',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 227,
    completeVaccinations: 1367,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_29131: {
    label: 'Miller',
    iso3: null,
    population: '25,619',
    casesPastWeek: 32,
    casesIncreasingPercentage: 28.000000000000004,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1210,
    completeVaccinations: 8845,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29133: {
    label: 'Mississippi',
    iso3: null,
    population: '13,180',
    casesPastWeek: 11,
    casesIncreasingPercentage: 37.5,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 759,
    completeVaccinations: 5839,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29135: {
    label: 'Moniteau',
    iso3: null,
    population: '16,132',
    casesPastWeek: 21,
    casesIncreasingPercentage: 2000.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 701,
    completeVaccinations: 5997,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29137: {
    label: 'Monroe',
    iso3: null,
    population: '8,644',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 584,
    completeVaccinations: 3990,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29139: {
    label: 'Montgomery',
    iso3: null,
    population: '11,551',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 751,
    completeVaccinations: 5815,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29141: {
    label: 'Morgan',
    iso3: null,
    population: '20,627',
    casesPastWeek: 11,
    casesIncreasingPercentage: 57.14285714285714,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1065,
    completeVaccinations: 7909,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29143: {
    label: 'New Madrid',
    iso3: null,
    population: '17,076',
    casesPastWeek: 15,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 892,
    completeVaccinations: 5962,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29145: {
    label: 'Newton',
    iso3: null,
    population: '58,236',
    casesPastWeek: 30,
    casesIncreasingPercentage: 7.14285714285714,
    positiveCasePercentage: 33.800000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2533,
    completeVaccinations: 15803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29147: {
    label: 'Nodaway',
    iso3: null,
    population: '22,092',
    casesPastWeek: 8,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 79.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1321,
    completeVaccinations: 11161,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29149: {
    label: 'Oregon',
    iso3: null,
    population: '10,529',
    casesPastWeek: 8,
    casesIncreasingPercentage: 166.66666666666666,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 616,
    completeVaccinations: 3450,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29151: {
    label: 'Osage',
    iso3: null,
    population: '13,615',
    casesPastWeek: 25,
    casesIncreasingPercentage: 257.14285714285717,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 554,
    completeVaccinations: 5554,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29153: {
    label: 'Ozark',
    iso3: null,
    population: '9,174',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 706,
    completeVaccinations: 3051,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29155: {
    label: 'Pemiscot',
    iso3: null,
    population: '15,805',
    casesPastWeek: 16,
    casesIncreasingPercentage: 60.00000000000001,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1491,
    completeVaccinations: 5217,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29157: {
    label: 'Perry',
    iso3: null,
    population: '19,136',
    casesPastWeek: 11,
    casesIncreasingPercentage: 57.14285714285714,
    positiveCasePercentage: 32.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 839,
    completeVaccinations: 7789,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29159: {
    label: 'Pettis',
    iso3: null,
    population: '42,339',
    casesPastWeek: 25,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 24.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2853,
    completeVaccinations: 18924,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29161: {
    label: 'Phelps',
    iso3: null,
    population: '44,573',
    casesPastWeek: 33,
    casesIncreasingPercentage: 26.923076923076916,
    positiveCasePercentage: 51.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2883,
    completeVaccinations: 20354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29163: {
    label: 'Pike',
    iso3: null,
    population: '18,302',
    casesPastWeek: 16,
    casesIncreasingPercentage: 128.57142857142856,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 912,
    completeVaccinations: 7060,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29165: {
    label: 'Platte',
    iso3: null,
    population: '104,418',
    casesPastWeek: 25,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 35.099999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11187,
    completeVaccinations: 62991,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29167: {
    label: 'Polk',
    iso3: null,
    population: '32,149',
    casesPastWeek: 15,
    casesIncreasingPercentage: 7.14285714285714,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1848,
    completeVaccinations: 12943,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29169: {
    label: 'Pulaski',
    iso3: null,
    population: '52,607',
    casesPastWeek: 21,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 35.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3145,
    completeVaccinations: 32530,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29171: {
    label: 'Putnam',
    iso3: null,
    population: '4,696',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 253,
    completeVaccinations: 1676,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29173: {
    label: 'Ralls',
    iso3: null,
    population: '10,309',
    casesPastWeek: 4,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 322,
    completeVaccinations: 3052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29175: {
    label: 'Randolph',
    iso3: null,
    population: '24,748',
    casesPastWeek: 19,
    casesIncreasingPercentage: 18.75,
    positiveCasePercentage: 46.800000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1622,
    completeVaccinations: 9813,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29177: {
    label: 'Ray',
    iso3: null,
    population: '23,018',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1134,
    completeVaccinations: 9508,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29179: {
    label: 'Reynolds',
    iso3: null,
    population: '6,270',
    casesPastWeek: 8,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 281,
    completeVaccinations: 1775,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29181: {
    label: 'Ripley',
    iso3: null,
    population: '13,288',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 744,
    completeVaccinations: 4063,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29183: {
    label: 'St. Charles',
    iso3: null,
    population: '402,022',
    casesPastWeek: 306,
    casesIncreasingPercentage: 34.801762114537446,
    positiveCasePercentage: 33.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 33659,
    completeVaccinations: 258190,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29185: {
    label: 'St. Clair',
    iso3: null,
    population: '9,397',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 501,
    completeVaccinations: 3342,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29186: {
    label: 'Ste. Genevieve',
    iso3: null,
    population: '17,894',
    casesPastWeek: 8,
    casesIncreasingPercentage: 60.00000000000001,
    positiveCasePercentage: 54.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 780,
    completeVaccinations: 8286,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29187: {
    label: 'St. Francois',
    iso3: null,
    population: '67,215',
    casesPastWeek: 84,
    casesIncreasingPercentage: 21.739130434782616,
    positiveCasePercentage: 64.60000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3991,
    completeVaccinations: 30294,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29189: {
    label: 'St. Louis',
    iso3: null,
    population: '994,205',
    casesPastWeek: 799,
    casesIncreasingPercentage: 8.265582655826552,
    positiveCasePercentage: 32.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 111827,
    completeVaccinations: 679211,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_29195: {
    label: 'Saline',
    iso3: null,
    population: '22,761',
    casesPastWeek: 9,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 35.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1544,
    completeVaccinations: 10931,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29197: {
    label: 'Schuyler',
    iso3: null,
    population: '4,660',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 183,
    completeVaccinations: 1296,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29199: {
    label: 'Scotland',
    iso3: null,
    population: '4,902',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 203,
    completeVaccinations: 1340,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29201: {
    label: 'Scott',
    iso3: null,
    population: '38,280',
    casesPastWeek: 28,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2452,
    completeVaccinations: 17138,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29203: {
    label: 'Shannon',
    iso3: null,
    population: '8,166',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 248,
    completeVaccinations: 2587,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29205: {
    label: 'Shelby',
    iso3: null,
    population: '5,930',
    casesPastWeek: 6,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 268,
    completeVaccinations: 2864,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29207: {
    label: 'Stoddard',
    iso3: null,
    population: '29,025',
    casesPastWeek: 18,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 18.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1657,
    completeVaccinations: 11942,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29209: {
    label: 'Stone',
    iso3: null,
    population: '31,952',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 42.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1734,
    completeVaccinations: 12475,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29211: {
    label: 'Sullivan',
    iso3: null,
    population: '6,089',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 408,
    completeVaccinations: 2718,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29213: {
    label: 'Taney',
    iso3: null,
    population: '55,928',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 57.599999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4253,
    completeVaccinations: 23497,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29215: {
    label: 'Texas',
    iso3: null,
    population: '25,398',
    casesPastWeek: 35,
    casesIncreasingPercentage: 775.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1238,
    completeVaccinations: 8566,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29217: {
    label: 'Vernon',
    iso3: null,
    population: '20,563',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 50.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1150,
    completeVaccinations: 7521,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29219: {
    label: 'Warren',
    iso3: null,
    population: '35,649',
    casesPastWeek: 20,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 18.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1702,
    completeVaccinations: 15400,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29221: {
    label: 'Washington',
    iso3: null,
    population: '24,730',
    casesPastWeek: 13,
    casesIncreasingPercentage: 8.333333333333325,
    positiveCasePercentage: 47.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1339,
    completeVaccinations: 8182,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29223: {
    label: 'Wayne',
    iso3: null,
    population: '12,873',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 50.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 761,
    completeVaccinations: 4831,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29225: {
    label: 'Webster',
    iso3: null,
    population: '39,592',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2259,
    completeVaccinations: 17244,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29227: {
    label: 'Worth',
    iso3: null,
    population: '2,013',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 133,
    completeVaccinations: 857,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29229: {
    label: 'Wright',
    iso3: null,
    population: '18,289',
    casesPastWeek: 7,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 34.599999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1072,
    completeVaccinations: 6720,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_29510: {
    label: 'St. Louis City',
    iso3: null,
    population: '300,576',
    casesPastWeek: 205,
    casesIncreasingPercentage: 19.883040935672526,
    positiveCasePercentage: 31.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 43373,
    completeVaccinations: 191310,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_30001: {
    label: 'Beaverhead',
    iso3: null,
    population: '9,453',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 14.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 482,
    completeVaccinations: 5248,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30003: {
    label: 'Big Horn',
    iso3: null,
    population: '13,319',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2168,
    completeVaccinations: 12503,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30005: {
    label: 'Blaine',
    iso3: null,
    population: '6,681',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 914,
    completeVaccinations: 5971,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30007: {
    label: 'Broadwater',
    iso3: null,
    population: '6,237',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 527,
    completeVaccinations: 2189,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30009: {
    label: 'Carbon',
    iso3: null,
    population: '10,725',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 575,
    completeVaccinations: 5246,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30011: {
    label: 'Carter',
    iso3: null,
    population: '1,252',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 57,
    completeVaccinations: 407,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30013: {
    label: 'Cascade',
    iso3: null,
    population: '81,366',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6197,
    completeVaccinations: 46233,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30015: {
    label: 'Chouteau',
    iso3: null,
    population: '5,635',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 180,
    completeVaccinations: 1946,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30017: {
    label: 'Custer',
    iso3: null,
    population: '11,402',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 569,
    completeVaccinations: 5194,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30019: {
    label: 'Daniels',
    iso3: null,
    population: '1,690',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 50,
    completeVaccinations: 720,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30021: {
    label: 'Dawson',
    iso3: null,
    population: '8,613',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 312,
    completeVaccinations: 3342,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30023: {
    label: 'Deer Lodge',
    iso3: null,
    population: '9,140',
    casesPastWeek: 18,
    casesIncreasingPercentage: 260.0,
    positiveCasePercentage: 19.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 465,
    completeVaccinations: 5473,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30025: {
    label: 'Fallon',
    iso3: null,
    population: '2,846',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 80,
    completeVaccinations: 1003,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_30027: {
    label: 'Fergus',
    iso3: null,
    population: '11,050',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 508,
    completeVaccinations: 5385,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30029: {
    label: 'Flathead',
    iso3: null,
    population: '103,806',
    casesPastWeek: 48,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7766,
    completeVaccinations: 46550,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30031: {
    label: 'Gallatin',
    iso3: null,
    population: '114,434',
    casesPastWeek: 122,
    casesIncreasingPercentage: 3.3898305084745672,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12873,
    completeVaccinations: 70794,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30033: {
    label: 'Garfield',
    iso3: null,
    population: '1,258',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 32,
    completeVaccinations: 251,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_30035: {
    label: 'Glacier',
    iso3: null,
    population: '13,753',
    casesPastWeek: 18,
    casesIncreasingPercentage: 350.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30037: {
    label: 'Golden Valley',
    iso3: null,
    population: '821',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 55,
    completeVaccinations: 393,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30039: {
    label: 'Granite',
    iso3: null,
    population: '3,379',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 168,
    completeVaccinations: 1307,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30041: {
    label: 'Hill',
    iso3: null,
    population: '16,484',
    casesPastWeek: 11,
    casesIncreasingPercentage: 83.33333333333333,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1537,
    completeVaccinations: 12384,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30043: {
    label: 'Jefferson',
    iso3: null,
    population: '12,221',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 881,
    completeVaccinations: 6449,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30045: {
    label: 'Judith Basin',
    iso3: null,
    population: '2,007',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 78,
    completeVaccinations: 827,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_30047: {
    label: 'Lake',
    iso3: null,
    population: '30,458',
    casesPastWeek: 28,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 23.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2449,
    completeVaccinations: 18061,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30049: {
    label: 'Lewis and Clark',
    iso3: null,
    population: '69,432',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 40.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6275,
    completeVaccinations: 43571,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30051: {
    label: 'Liberty',
    iso3: null,
    population: '2,337',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3,
    completeVaccinations: 816,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_30053: {
    label: 'Lincoln',
    iso3: null,
    population: '19,980',
    casesPastWeek: 14,
    casesIncreasingPercentage: 27.27272727272727,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1017,
    completeVaccinations: 7353,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30055: {
    label: 'McCone',
    iso3: null,
    population: '1,664',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9,
    completeVaccinations: 308,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30057: {
    label: 'Madison',
    iso3: null,
    population: '8,600',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 555,
    completeVaccinations: 3999,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30059: {
    label: 'Meagher',
    iso3: null,
    population: '1,862',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 62,
    completeVaccinations: 969,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_30061: {
    label: 'Mineral',
    iso3: null,
    population: '4,397',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 361,
    completeVaccinations: 2490,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30063: {
    label: 'Missoula',
    iso3: null,
    population: '119,600',
    casesPastWeek: 86,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8430,
    completeVaccinations: 80401,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30065: {
    label: 'Musselshell',
    iso3: null,
    population: '4,633',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 175,
    completeVaccinations: 1660,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30067: {
    label: 'Park',
    iso3: null,
    population: '16,606',
    casesPastWeek: 8,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1997,
    completeVaccinations: 10234,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30069: {
    label: 'Petroleum',
    iso3: null,
    population: '487',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10,
    completeVaccinations: 155,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30071: {
    label: 'Phillips',
    iso3: null,
    population: '3,954',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 221,
    completeVaccinations: 1819,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30073: {
    label: 'Pondera',
    iso3: null,
    population: '5,911',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 545,
    completeVaccinations: 3355,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30075: {
    label: 'Powder River',
    iso3: null,
    population: '1,682',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 47,
    completeVaccinations: 398,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30077: {
    label: 'Powell',
    iso3: null,
    population: '6,890',
    casesPastWeek: 5,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 280,
    completeVaccinations: 3222,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30079: {
    label: 'Prairie',
    iso3: null,
    population: '1,077',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 39,
    completeVaccinations: 418,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_30081: {
    label: 'Ravalli',
    iso3: null,
    population: '43,806',
    casesPastWeek: 34,
    casesIncreasingPercentage: 30.76923076923077,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2668,
    completeVaccinations: 22268,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30083: {
    label: 'Richland',
    iso3: null,
    population: '10,803',
    casesPastWeek: 12,
    casesIncreasingPercentage: 71.42857142857142,
    positiveCasePercentage: 22.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 331,
    completeVaccinations: 4438,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30085: {
    label: 'Roosevelt',
    iso3: null,
    population: '11,004',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1281,
    completeVaccinations: 9674,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30087: {
    label: 'Rosebud',
    iso3: null,
    population: '8,937',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1423,
    completeVaccinations: 7858,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_30089: {
    label: 'Sanders',
    iso3: null,
    population: '12,113',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 458,
    completeVaccinations: 4859,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30091: {
    label: 'Sheridan',
    iso3: null,
    population: '3,309',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 88,
    completeVaccinations: 1639,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30093: {
    label: 'Silver Bow',
    iso3: null,
    population: '34,915',
    casesPastWeek: 20,
    casesIncreasingPercentage: 81.81818181818181,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1945,
    completeVaccinations: 22136,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30095: {
    label: 'Stillwater',
    iso3: null,
    population: '9,642',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 440,
    completeVaccinations: 4195,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30097: {
    label: 'Sweet Grass',
    iso3: null,
    population: '3,737',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 133,
    completeVaccinations: 1570,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30099: {
    label: 'Teton',
    iso3: null,
    population: '6,147',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 358,
    completeVaccinations: 2670,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30101: {
    label: 'Toole',
    iso3: null,
    population: '4,736',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 179,
    completeVaccinations: 2250,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30103: {
    label: 'Treasure',
    iso3: null,
    population: '696',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 32,
    completeVaccinations: 345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_30105: {
    label: 'Valley',
    iso3: null,
    population: '7,396',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 289,
    completeVaccinations: 3610,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_30107: {
    label: 'Wheatland',
    iso3: null,
    population: '2,126',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 97,
    completeVaccinations: 786,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_30109: {
    label: 'Wibaux',
    iso3: null,
    population: '969',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21,
    completeVaccinations: 282,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_30111: {
    label: 'Yellowstone',
    iso3: null,
    population: '161,300',
    casesPastWeek: 130,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11048,
    completeVaccinations: 86427,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31001: {
    label: 'Adams',
    iso3: null,
    population: '31,363',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1478,
    completeVaccinations: 13725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31003: {
    label: 'Antelope',
    iso3: null,
    population: '6,298',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 259,
    completeVaccinations: 2406,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31005: {
    label: 'Arthur',
    iso3: null,
    population: '463',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10,
    completeVaccinations: 93,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31007: {
    label: 'Banner',
    iso3: null,
    population: '745',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27,
    completeVaccinations: 252,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31009: {
    label: 'Blaine',
    iso3: null,
    population: '465',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13,
    completeVaccinations: 125,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31011: {
    label: 'Boone',
    iso3: null,
    population: '5,192',
    casesPastWeek: 18,
    casesIncreasingPercentage: 157.14285714285717,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 281,
    completeVaccinations: 2616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31013: {
    label: 'Box Butte',
    iso3: null,
    population: '10,783',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 412,
    completeVaccinations: 3829,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31015: {
    label: 'Boyd',
    iso3: null,
    population: '1,919',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 75,
    completeVaccinations: 705,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31017: {
    label: 'Brown',
    iso3: null,
    population: '2,955',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 121,
    completeVaccinations: 1127,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31019: {
    label: 'Buffalo',
    iso3: null,
    population: '49,659',
    casesPastWeek: 51,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2359,
    completeVaccinations: 21909,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31021: {
    label: 'Burt',
    iso3: null,
    population: '6,459',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 422,
    completeVaccinations: 3923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31023: {
    label: 'Butler',
    iso3: null,
    population: '8,016',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 455,
    completeVaccinations: 4232,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31025: {
    label: 'Cass',
    iso3: null,
    population: '26,248',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1827,
    completeVaccinations: 16967,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31027: {
    label: 'Cedar',
    iso3: null,
    population: '8,402',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 389,
    completeVaccinations: 3617,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31029: {
    label: 'Chase',
    iso3: null,
    population: '3,924',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 146,
    completeVaccinations: 1356,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31031: {
    label: 'Cherry',
    iso3: null,
    population: '5,689',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 222,
    completeVaccinations: 2069,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31033: {
    label: 'Cheyenne',
    iso3: null,
    population: '8,910',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 424,
    completeVaccinations: 3943,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31035: {
    label: 'Clay',
    iso3: null,
    population: '6,203',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 285,
    completeVaccinations: 2653,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31037: {
    label: 'Colfax',
    iso3: null,
    population: '10,709',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 53.800000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 568,
    completeVaccinations: 5279,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31039: {
    label: 'Cuming',
    iso3: null,
    population: '8,846',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 519,
    completeVaccinations: 4824,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31041: {
    label: 'Custer',
    iso3: null,
    population: '10,777',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 457,
    completeVaccinations: 4245,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31043: {
    label: 'Dakota',
    iso3: null,
    population: '20,026',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1480,
    completeVaccinations: 13750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31045: {
    label: 'Dawes',
    iso3: null,
    population: '8,589',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 381,
    completeVaccinations: 3543,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31047: {
    label: 'Dawson',
    iso3: null,
    population: '23,595',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1310,
    completeVaccinations: 12170,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31049: {
    label: 'Deuel',
    iso3: null,
    population: '1,794',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 70,
    completeVaccinations: 657,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31051: {
    label: 'Dixon',
    iso3: null,
    population: '5,636',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 276,
    completeVaccinations: 2566,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31053: {
    label: 'Dodge',
    iso3: null,
    population: '36,565',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2208,
    completeVaccinations: 20506,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31055: {
    label: 'Douglas',
    iso3: null,
    population: '571,327',
    casesPastWeek: 449,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 43327,
    completeVaccinations: 402323,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31057: {
    label: 'Dundy',
    iso3: null,
    population: '1,693',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 72,
    completeVaccinations: 675,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31059: {
    label: 'Fillmore',
    iso3: null,
    population: '5,462',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 316,
    completeVaccinations: 2936,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31061: {
    label: 'Franklin',
    iso3: null,
    population: '2,979',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 127,
    completeVaccinations: 1187,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31063: {
    label: 'Frontier',
    iso3: null,
    population: '2,627',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 82,
    completeVaccinations: 767,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31065: {
    label: 'Furnas',
    iso3: null,
    population: '4,676',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 233,
    completeVaccinations: 2167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31067: {
    label: 'Gage',
    iso3: null,
    population: '21,513',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1098,
    completeVaccinations: 10201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31069: {
    label: 'Garden',
    iso3: null,
    population: '1,837',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 92,
    completeVaccinations: 857,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31071: {
    label: 'Garfield',
    iso3: null,
    population: '1,969',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 66,
    completeVaccinations: 615,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31073: {
    label: 'Gosper',
    iso3: null,
    population: '1,990',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 88,
    completeVaccinations: 823,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31075: {
    label: 'Grant',
    iso3: null,
    population: '623',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14,
    completeVaccinations: 136,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31077: {
    label: 'Greeley',
    iso3: null,
    population: '2,356',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 105,
    completeVaccinations: 979,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31079: {
    label: 'Hall',
    iso3: null,
    population: '61,353',
    casesPastWeek: 69,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3586,
    completeVaccinations: 33306,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31081: {
    label: 'Hamilton',
    iso3: null,
    population: '9,324',
    casesPastWeek: 43,
    casesIncreasingPercentage: 95.45454545454545,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 481,
    completeVaccinations: 4467,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31083: {
    label: 'Harlan',
    iso3: null,
    population: '3,380',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 149,
    completeVaccinations: 1392,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31085: {
    label: 'Hayes',
    iso3: null,
    population: '922',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26,
    completeVaccinations: 243,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31087: {
    label: 'Hitchcock',
    iso3: null,
    population: '2,762',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 105,
    completeVaccinations: 975,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31089: {
    label: 'Holt',
    iso3: null,
    population: '10,067',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 479,
    completeVaccinations: 4457,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31091: {
    label: 'Hooker',
    iso3: null,
    population: '682',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31,
    completeVaccinations: 295,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31093: {
    label: 'Howard',
    iso3: null,
    population: '6,445',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 304,
    completeVaccinations: 2827,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31095: {
    label: 'Jefferson',
    iso3: null,
    population: '7,046',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 432,
    completeVaccinations: 4014,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31097: {
    label: 'Johnson',
    iso3: null,
    population: '5,071',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 305,
    completeVaccinations: 2838,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31099: {
    label: 'Kearney',
    iso3: null,
    population: '6,495',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 339,
    completeVaccinations: 3153,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31101: {
    label: 'Keith',
    iso3: null,
    population: '8,034',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 337,
    completeVaccinations: 3135,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31103: {
    label: 'Keya Paha',
    iso3: null,
    population: '806',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26,
    completeVaccinations: 247,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31105: {
    label: 'Kimball',
    iso3: null,
    population: '3,632',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 141,
    completeVaccinations: 1316,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31107: {
    label: 'Knox',
    iso3: null,
    population: '8,332',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 470,
    completeVaccinations: 4367,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31109: {
    label: 'Lancaster',
    iso3: null,
    population: '319,090',
    casesPastWeek: 282,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 23772,
    completeVaccinations: 220743,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31111: {
    label: 'Lincoln',
    iso3: null,
    population: '34,914',
    casesPastWeek: 33,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1304,
    completeVaccinations: 12116,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31113: {
    label: 'Logan',
    iso3: null,
    population: '748',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18,
    completeVaccinations: 168,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31115: {
    label: 'Loup',
    iso3: null,
    population: '664',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21,
    completeVaccinations: 200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31117: {
    label: 'McPherson',
    iso3: null,
    population: '494',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8,
    completeVaccinations: 77,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31119: {
    label: 'Madison',
    iso3: null,
    population: '35,099',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 50.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1816,
    completeVaccinations: 16863,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31121: {
    label: 'Merrick',
    iso3: null,
    population: '7,755',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 377,
    completeVaccinations: 3502,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31123: {
    label: 'Morrill',
    iso3: null,
    population: '4,642',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 183,
    completeVaccinations: 1705,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31125: {
    label: 'Nance',
    iso3: null,
    population: '3,519',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 113,
    completeVaccinations: 1052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31127: {
    label: 'Nemaha',
    iso3: null,
    population: '6,972',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 389,
    completeVaccinations: 3616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31129: {
    label: 'Nuckolls',
    iso3: null,
    population: '4,148',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 228,
    completeVaccinations: 2126,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31131: {
    label: 'Otoe',
    iso3: null,
    population: '16,012',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1004,
    completeVaccinations: 9324,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31133: {
    label: 'Pawnee',
    iso3: null,
    population: '2,613',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 125,
    completeVaccinations: 1165,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31135: {
    label: 'Perkins',
    iso3: null,
    population: '2,891',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 130,
    completeVaccinations: 1213,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31137: {
    label: 'Phelps',
    iso3: null,
    population: '9,034',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 407,
    completeVaccinations: 3784,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31139: {
    label: 'Pierce',
    iso3: null,
    population: '7,148',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 294,
    completeVaccinations: 2730,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31141: {
    label: 'Platte',
    iso3: null,
    population: '33,470',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1768,
    completeVaccinations: 16420,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31143: {
    label: 'Polk',
    iso3: null,
    population: '5,213',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 239,
    completeVaccinations: 2226,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31145: {
    label: 'Red Willow',
    iso3: null,
    population: '10,724',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 437,
    completeVaccinations: 4065,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31147: {
    label: 'Richardson',
    iso3: null,
    population: '7,865',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 459,
    completeVaccinations: 4266,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31149: {
    label: 'Rock',
    iso3: null,
    population: '1,357',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 56,
    completeVaccinations: 524,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31151: {
    label: 'Saline',
    iso3: null,
    population: '14,224',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 846,
    completeVaccinations: 7863,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31153: {
    label: 'Sarpy',
    iso3: null,
    population: '187,196',
    casesPastWeek: 113,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14026,
    completeVaccinations: 130243,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31155: {
    label: 'Saunders',
    iso3: null,
    population: '21,578',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 28.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1277,
    completeVaccinations: 11863,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31157: {
    label: 'Scotts Bluff',
    iso3: null,
    population: '35,618',
    casesPastWeek: 63,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1659,
    completeVaccinations: 15411,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31159: {
    label: 'Seward',
    iso3: null,
    population: '17,284',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 957,
    completeVaccinations: 8893,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31161: {
    label: 'Sheridan',
    iso3: null,
    population: '5,246',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 194,
    completeVaccinations: 1809,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31163: {
    label: 'Sherman',
    iso3: null,
    population: '3,001',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 132,
    completeVaccinations: 1227,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31165: {
    label: 'Sioux',
    iso3: null,
    population: '1,166',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 33,
    completeVaccinations: 308,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31167: {
    label: 'Stanton',
    iso3: null,
    population: '5,920',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 187,
    completeVaccinations: 1737,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31169: {
    label: 'Thayer',
    iso3: null,
    population: '5,003',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 263,
    completeVaccinations: 2450,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31171: {
    label: 'Thomas',
    iso3: null,
    population: '722',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19,
    completeVaccinations: 185,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31173: {
    label: 'Thurston',
    iso3: null,
    population: '7,224',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 652,
    completeVaccinations: 6062,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31175: {
    label: 'Valley',
    iso3: null,
    population: '4,158',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 154,
    completeVaccinations: 1430,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31177: {
    label: 'Washington',
    iso3: null,
    population: '20,729',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1298,
    completeVaccinations: 12056,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31179: {
    label: 'Wayne',
    iso3: null,
    population: '9,385',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 433,
    completeVaccinations: 4024,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_31181: {
    label: 'Webster',
    iso3: null,
    population: '3,487',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 149,
    completeVaccinations: 1387,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_31183: {
    label: 'Wheeler',
    iso3: null,
    population: '783',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27,
    completeVaccinations: 258,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_31185: {
    label: 'York',
    iso3: null,
    population: '13,679',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 791,
    completeVaccinations: 7351,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_32001: {
    label: 'Churchill',
    iso3: null,
    population: '24,909',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2204,
    completeVaccinations: 14013,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32003: {
    label: 'Clark',
    iso3: null,
    population: '2,266,715',
    casesPastWeek: 925,
    casesIncreasingPercentage: 8.568075117370899,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 302692,
    completeVaccinations: 1374599,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32005: {
    label: 'Douglas',
    iso3: null,
    population: '48,905',
    casesPastWeek: 13,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4153,
    completeVaccinations: 26108,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32007: {
    label: 'Elko',
    iso3: null,
    population: '52,778',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 47.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4331,
    completeVaccinations: 23603,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32009: {
    label: 'Esmeralda',
    iso3: null,
    population: '873',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 96,
    completeVaccinations: 373,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_32011: {
    label: 'Eureka',
    iso3: null,
    population: '2,029',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 94,
    completeVaccinations: 569,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32013: {
    label: 'Humboldt',
    iso3: null,
    population: '16,831',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1030,
    completeVaccinations: 7419,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32015: {
    label: 'Lander',
    iso3: null,
    population: '5,532',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 266,
    completeVaccinations: 2295,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_32017: {
    label: 'Lincoln',
    iso3: null,
    population: '5,183',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 215,
    completeVaccinations: 2035,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_32019: {
    label: 'Lyon',
    iso3: null,
    population: '57,510',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3823,
    completeVaccinations: 25258,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32021: {
    label: 'Mineral',
    iso3: null,
    population: '4,505',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 400,
    completeVaccinations: 2809,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32023: {
    label: 'Nye',
    iso3: null,
    population: '46,523',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3404,
    completeVaccinations: 22683,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32027: {
    label: 'Pershing',
    iso3: null,
    population: '6,725',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 414,
    completeVaccinations: 2535,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32029: {
    label: 'Storey',
    iso3: null,
    population: '4,123',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 267,
    completeVaccinations: 1039,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32031: {
    label: 'Washoe',
    iso3: null,
    population: '471,519',
    casesPastWeek: 133,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 50968,
    completeVaccinations: 317738,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32033: {
    label: 'White Pine',
    iso3: null,
    population: '9,580',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 630,
    completeVaccinations: 5280,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_32510: {
    label: 'Carson City',
    iso3: null,
    population: '55,916',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5352,
    completeVaccinations: 37270,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33001: {
    label: 'Belknap',
    iso3: null,
    population: '61,303',
    casesPastWeek: 52,
    casesIncreasingPercentage: 26.82926829268293,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_33003: {
    label: 'Carroll',
    iso3: null,
    population: '48,910',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33005: {
    label: 'Cheshire',
    iso3: null,
    population: '76,085',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33007: {
    label: 'Coos',
    iso3: null,
    population: '31,563',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_33009: {
    label: 'Grafton',
    iso3: null,
    population: '89,886',
    casesPastWeek: 69,
    casesIncreasingPercentage: 16.94915254237288,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_33011: {
    label: 'Hillsborough',
    iso3: null,
    population: '417,025',
    casesPastWeek: 166,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33013: {
    label: 'Merrimack',
    iso3: null,
    population: '151,391',
    casesPastWeek: 63,
    casesIncreasingPercentage: 1.6129032258064502,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33015: {
    label: 'Rockingham',
    iso3: null,
    population: '309,769',
    casesPastWeek: 171,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33017: {
    label: 'Strafford',
    iso3: null,
    population: '130,633',
    casesPastWeek: 91,
    casesIncreasingPercentage: 28.169014084507047,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_33019: {
    label: 'Sullivan',
    iso3: null,
    population: '43,146',
    casesPastWeek: 22,
    casesIncreasingPercentage: 4.761904761904767,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_34001: {
    label: 'Atlantic',
    iso3: null,
    population: '263,670',
    casesPastWeek: 209,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36513,
    completeVaccinations: 185812,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34003: {
    label: 'Bergen',
    iso3: null,
    population: '932,202',
    casesPastWeek: 568,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 155439,
    completeVaccinations: 760800,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34005: {
    label: 'Burlington',
    iso3: null,
    population: '445,349',
    casesPastWeek: 307,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 57619,
    completeVaccinations: 350558,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34007: {
    label: 'Camden',
    iso3: null,
    population: '506,471',
    casesPastWeek: 349,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 71414,
    completeVaccinations: 369982,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34009: {
    label: 'Cape May',
    iso3: null,
    population: '92,039',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11860,
    completeVaccinations: 72562,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34011: {
    label: 'Cumberland',
    iso3: null,
    population: '149,527',
    casesPastWeek: 99,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22349,
    completeVaccinations: 82881,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34013: {
    label: 'Essex',
    iso3: null,
    population: '798,975',
    casesPastWeek: 362,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 146160,
    completeVaccinations: 612531,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34015: {
    label: 'Gloucester',
    iso3: null,
    population: '291,636',
    casesPastWeek: 199,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 38950,
    completeVaccinations: 189660,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34017: {
    label: 'Hudson',
    iso3: null,
    population: '672,391',
    casesPastWeek: 317,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 141347,
    completeVaccinations: 553481,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34019: {
    label: 'Hunterdon',
    iso3: null,
    population: '124,371',
    casesPastWeek: 58,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21083,
    completeVaccinations: 93199,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34021: {
    label: 'Mercer',
    iso3: null,
    population: '367,430',
    casesPastWeek: 220,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 73312,
    completeVaccinations: 277736,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34023: {
    label: 'Middlesex',
    iso3: null,
    population: '825,062',
    casesPastWeek: 485,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 138966,
    completeVaccinations: 635131,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34025: {
    label: 'Monmouth',
    iso3: null,
    population: '618,795',
    casesPastWeek: 382,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 85882,
    completeVaccinations: 439962,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34027: {
    label: 'Morris',
    iso3: null,
    population: '491,845',
    casesPastWeek: 270,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 69788,
    completeVaccinations: 399460,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34029: {
    label: 'Ocean',
    iso3: null,
    population: '607,186',
    casesPastWeek: 358,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 61474,
    completeVaccinations: 344727,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34031: {
    label: 'Passaic',
    iso3: null,
    population: '501,826',
    casesPastWeek: 281,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 74884,
    completeVaccinations: 366575,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34033: {
    label: 'Salem',
    iso3: null,
    population: '62,385',
    casesPastWeek: 62,
    casesIncreasingPercentage: 37.77777777777778,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9104,
    completeVaccinations: 36382,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34035: {
    label: 'Somerset',
    iso3: null,
    population: '328,934',
    casesPastWeek: 170,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 56456,
    completeVaccinations: 263033,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34037: {
    label: 'Sussex',
    iso3: null,
    population: '140,488',
    casesPastWeek: 46,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17296,
    completeVaccinations: 91661,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34039: {
    label: 'Union',
    iso3: null,
    population: '556,341',
    casesPastWeek: 265,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95096,
    completeVaccinations: 417471,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_34041: {
    label: 'Warren',
    iso3: null,
    population: '105,267',
    casesPastWeek: 48,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16763,
    completeVaccinations: 75171,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35001: {
    label: 'Bernalillo',
    iso3: null,
    population: '679,121',
    casesPastWeek: 399,
    casesIncreasingPercentage: 6.117021276595747,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 52633,
    completeVaccinations: 430810,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35003: {
    label: 'Catron',
    iso3: null,
    population: '3,527',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 198,
    completeVaccinations: 1513,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_35005: {
    label: 'Chaves',
    iso3: null,
    population: '64,615',
    casesPastWeek: 20,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 2.8000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3164,
    completeVaccinations: 27211,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35006: {
    label: 'Cibola',
    iso3: null,
    population: '26,675',
    casesPastWeek: 37,
    casesIncreasingPercentage: 76.19047619047619,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3262,
    completeVaccinations: 16647,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35007: {
    label: 'Colfax',
    iso3: null,
    population: '11,941',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 697,
    completeVaccinations: 7076,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_35009: {
    label: 'Curry',
    iso3: null,
    population: '48,954',
    casesPastWeek: 34,
    casesIncreasingPercentage: 47.82608695652173,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3272,
    completeVaccinations: 19137,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35011: {
    label: 'De Baca',
    iso3: null,
    population: '1,748',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 71,
    completeVaccinations: 807,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_35013: {
    label: 'Dona Ana',
    iso3: null,
    population: '218,195',
    casesPastWeek: 125,
    casesIncreasingPercentage: 95.3125,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21202,
    completeVaccinations: 142950,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35015: {
    label: 'Eddy',
    iso3: null,
    population: '58,460',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2981,
    completeVaccinations: 25191,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_35017: {
    label: 'Grant',
    iso3: null,
    population: '26,998',
    casesPastWeek: 33,
    casesIncreasingPercentage: 32.00000000000001,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1714,
    completeVaccinations: 17122,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35019: {
    label: 'Guadalupe',
    iso3: null,
    population: '4,300',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 157,
    completeVaccinations: 2672,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35021: {
    label: 'Harding',
    iso3: null,
    population: '625',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16,
    completeVaccinations: 298,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_35023: {
    label: 'Hidalgo',
    iso3: null,
    population: '4,198',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 293,
    completeVaccinations: 2267,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_35025: {
    label: 'Lea',
    iso3: null,
    population: '71,070',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4626,
    completeVaccinations: 29652,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35027: {
    label: 'Lincoln',
    iso3: null,
    population: '19,572',
    casesPastWeek: 12,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1524,
    completeVaccinations: 10506,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35028: {
    label: 'Los Alamos',
    iso3: null,
    population: '19,369',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1342,
    completeVaccinations: 14452,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_35029: {
    label: 'Luna',
    iso3: null,
    population: '23,709',
    casesPastWeek: 5,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2219,
    completeVaccinations: 16011,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_35031: {
    label: 'McKinley',
    iso3: null,
    population: '71,367',
    casesPastWeek: 117,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18647,
    completeVaccinations: 53605,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35033: {
    label: 'Mora',
    iso3: null,
    population: '4,521',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 258,
    completeVaccinations: 2679,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35035: {
    label: 'Otero',
    iso3: null,
    population: '67,490',
    casesPastWeek: 26,
    casesIncreasingPercentage: 8.333333333333325,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3880,
    completeVaccinations: 27637,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35037: {
    label: 'Quay',
    iso3: null,
    population: '8,253',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 438,
    completeVaccinations: 3707,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_35039: {
    label: 'Rio Arriba',
    iso3: null,
    population: '38,921',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3566,
    completeVaccinations: 27395,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35041: {
    label: 'Roosevelt',
    iso3: null,
    population: '18,500',
    casesPastWeek: 16,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 887,
    completeVaccinations: 6815,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_35043: {
    label: 'Sandoval',
    iso3: null,
    population: '146,748',
    casesPastWeek: 85,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13697,
    completeVaccinations: 97731,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35045: {
    label: 'San Juan',
    iso3: null,
    population: '123,958',
    casesPastWeek: 93,
    casesIncreasingPercentage: 17.721518987341778,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13982,
    completeVaccinations: 82622,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35047: {
    label: 'San Miguel',
    iso3: null,
    population: '27,277',
    casesPastWeek: 9,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 2.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1532,
    completeVaccinations: 16910,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35049: {
    label: 'Santa Fe',
    iso3: null,
    population: '150,358',
    casesPastWeek: 67,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14586,
    completeVaccinations: 111641,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35051: {
    label: 'Sierra',
    iso3: null,
    population: '10,791',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 772,
    completeVaccinations: 6811,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35053: {
    label: 'Socorro',
    iso3: null,
    population: '16,637',
    casesPastWeek: 12,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 0.7000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 993,
    completeVaccinations: 9746,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35055: {
    label: 'Taos',
    iso3: null,
    population: '32,723',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2896,
    completeVaccinations: 24530,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35057: {
    label: 'Torrance',
    iso3: null,
    population: '15,461',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 690,
    completeVaccinations: 6488,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_35059: {
    label: 'Union',
    iso3: null,
    population: '4,059',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 211,
    completeVaccinations: 1841,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_35061: {
    label: 'Valencia',
    iso3: null,
    population: '76,688',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4895,
    completeVaccinations: 43351,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36001: {
    label: 'Albany',
    iso3: null,
    population: '305,506',
    casesPastWeek: 169,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31460,
    completeVaccinations: 237612,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36003: {
    label: 'Allegany',
    iso3: null,
    population: '46,091',
    casesPastWeek: 42,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1747,
    completeVaccinations: 21202,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36005: {
    label: 'Bronx',
    iso3: null,
    population: '1,418,207',
    casesPastWeek: 660,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.1000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 186835,
    completeVaccinations: 1109234,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36007: {
    label: 'Broome',
    iso3: null,
    population: '190,488',
    casesPastWeek: 201,
    casesIncreasingPercentage: 3.6082474226804218,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10065,
    completeVaccinations: 125262,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36009: {
    label: 'Cattaraugus',
    iso3: null,
    population: '76,117',
    casesPastWeek: 50,
    casesIncreasingPercentage: 8.695652173913038,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4747,
    completeVaccinations: 44298,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36011: {
    label: 'Cayuga',
    iso3: null,
    population: '76,576',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2732,
    completeVaccinations: 46728,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36013: {
    label: 'Chautauqua',
    iso3: null,
    population: '126,903',
    casesPastWeek: 74,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8216,
    completeVaccinations: 78372,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36015: {
    label: 'Chemung',
    iso3: null,
    population: '83,456',
    casesPastWeek: 64,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6594,
    completeVaccinations: 53562,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36017: {
    label: 'Chenango',
    iso3: null,
    population: '47,207',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1998,
    completeVaccinations: 29701,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36019: {
    label: 'Clinton',
    iso3: null,
    population: '80,485',
    casesPastWeek: 109,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.299999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5305,
    completeVaccinations: 60292,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36021: {
    label: 'Columbia',
    iso3: null,
    population: '59,461',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.200000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7689,
    completeVaccinations: 46130,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36023: {
    label: 'Cortland',
    iso3: null,
    population: '47,581',
    casesPastWeek: 48,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1786,
    completeVaccinations: 29167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36025: {
    label: 'Delaware',
    iso3: null,
    population: '44,135',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2410,
    completeVaccinations: 26103,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36027: {
    label: 'Dutchess',
    iso3: null,
    population: '294,218',
    casesPastWeek: 135,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28333,
    completeVaccinations: 215669,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36029: {
    label: 'Erie',
    iso3: null,
    population: '918,702',
    casesPastWeek: 585,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 52334,
    completeVaccinations: 665321,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36031: {
    label: 'Essex',
    iso3: null,
    population: '36,885',
    casesPastWeek: 50,
    casesIncreasingPercentage: 31.578947368421062,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2184,
    completeVaccinations: 27378,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36033: {
    label: 'Franklin',
    iso3: null,
    population: '50,022',
    casesPastWeek: 51,
    casesIncreasingPercentage: 2.0000000000000018,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2416,
    completeVaccinations: 36221,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36035: {
    label: 'Fulton',
    iso3: null,
    population: '53,383',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2742,
    completeVaccinations: 29873,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36037: {
    label: 'Genesee',
    iso3: null,
    population: '57,280',
    casesPastWeek: 50,
    casesIncreasingPercentage: 6.382978723404253,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2256,
    completeVaccinations: 35252,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36039: {
    label: 'Greene',
    iso3: null,
    population: '47,188',
    casesPastWeek: 30,
    casesIncreasingPercentage: 7.14285714285714,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3645,
    completeVaccinations: 30571,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36041: {
    label: 'Hamilton',
    iso3: null,
    population: '4,416',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 400,
    completeVaccinations: 3760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_36043: {
    label: 'Herkimer',
    iso3: null,
    population: '61,319',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3946,
    completeVaccinations: 37417,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36045: {
    label: 'Jefferson',
    iso3: null,
    population: '109,834',
    casesPastWeek: 56,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14737,
    completeVaccinations: 84614,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36047: {
    label: 'Kings',
    iso3: null,
    population: '2,559,903',
    casesPastWeek: 1295,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 360442,
    completeVaccinations: 1957367,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36049: {
    label: 'Lewis',
    iso3: null,
    population: '26,296',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 731,
    completeVaccinations: 14023,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_36051: {
    label: 'Livingston',
    iso3: null,
    population: '62,914',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2366,
    completeVaccinations: 38494,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36053: {
    label: 'Madison',
    iso3: null,
    population: '70,941',
    casesPastWeek: 55,
    casesIncreasingPercentage: 44.736842105263165,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2889,
    completeVaccinations: 44549,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36055: {
    label: 'Monroe',
    iso3: null,
    population: '741,770',
    casesPastWeek: 496,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 40787,
    completeVaccinations: 558633,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36057: {
    label: 'Montgomery',
    iso3: null,
    population: '49,221',
    casesPastWeek: 36,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 16.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3245,
    completeVaccinations: 33960,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36059: {
    label: 'Nassau',
    iso3: null,
    population: '1,356,924',
    casesPastWeek: 610,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 211327,
    completeVaccinations: 1177515,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36061: {
    label: 'New York',
    iso3: null,
    population: '1,628,706',
    casesPastWeek: 993,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 394745,
    completeVaccinations: 1515443,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36063: {
    label: 'Niagara',
    iso3: null,
    population: '209,281',
    casesPastWeek: 144,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 20.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9644,
    completeVaccinations: 143130,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36065: {
    label: 'Oneida',
    iso3: null,
    population: '228,671',
    casesPastWeek: 116,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14720,
    completeVaccinations: 148450,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36067: {
    label: 'Onondaga',
    iso3: null,
    population: '460,528',
    casesPastWeek: 297,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26370,
    completeVaccinations: 351076,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36069: {
    label: 'Ontario',
    iso3: null,
    population: '109,777',
    casesPastWeek: 86,
    casesIncreasingPercentage: 32.30769230769231,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5262,
    completeVaccinations: 77622,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36071: {
    label: 'Orange',
    iso3: null,
    population: '384,940',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 34392,
    completeVaccinations: 261198,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_36073: {
    label: 'Orleans',
    iso3: null,
    population: '40,352',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1465,
    completeVaccinations: 22875,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36075: {
    label: 'Oswego',
    iso3: null,
    population: '117,124',
    casesPastWeek: 80,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4236,
    completeVaccinations: 71720,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36077: {
    label: 'Otsego',
    iso3: null,
    population: '59,493',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3613,
    completeVaccinations: 37950,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36079: {
    label: 'Putnam',
    iso3: null,
    population: '98,320',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9285,
    completeVaccinations: 76133,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36081: {
    label: 'Queens',
    iso3: null,
    population: '2,253,858',
    casesPastWeek: 1167,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 416760,
    completeVaccinations: 2053740,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36083: {
    label: 'Rensselaer',
    iso3: null,
    population: '158,714',
    casesPastWeek: 107,
    casesIncreasingPercentage: 4.90196078431373,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14622,
    completeVaccinations: 115964,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36085: {
    label: 'Richmond',
    iso3: null,
    population: '476,143',
    casesPastWeek: 353,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 44685,
    completeVaccinations: 364417,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36087: {
    label: 'Rockland',
    iso3: null,
    population: '325,789',
    casesPastWeek: 121,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 30473,
    completeVaccinations: 219851,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36089: {
    label: 'St. Lawrence',
    iso3: null,
    population: '107,740',
    casesPastWeek: 112,
    casesIncreasingPercentage: 28.735632183908045,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4229,
    completeVaccinations: 67371,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36091: {
    label: 'Saratoga',
    iso3: null,
    population: '229,863',
    casesPastWeek: 86,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24800,
    completeVaccinations: 188745,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36093: {
    label: 'Schenectady',
    iso3: null,
    population: '155,299',
    casesPastWeek: 98,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14011,
    completeVaccinations: 117633,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36095: {
    label: 'Schoharie',
    iso3: null,
    population: '30,999',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1578,
    completeVaccinations: 18147,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36097: {
    label: 'Schuyler',
    iso3: null,
    population: '17,807',
    casesPastWeek: 15,
    casesIncreasingPercentage: 87.5,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1026,
    completeVaccinations: 11556,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36099: {
    label: 'Seneca',
    iso3: null,
    population: '34,016',
    casesPastWeek: 32,
    casesIncreasingPercentage: 23.076923076923084,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1298,
    completeVaccinations: 18790,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36101: {
    label: 'Steuben',
    iso3: null,
    population: '95,379',
    casesPastWeek: 94,
    casesIncreasingPercentage: 4.444444444444451,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6891,
    completeVaccinations: 60430,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36103: {
    label: 'Suffolk',
    iso3: null,
    population: '1,476,601',
    casesPastWeek: 692,
    casesIncreasingPercentage: 5.167173252279644,
    positiveCasePercentage: 4.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 147175,
    completeVaccinations: 1164194,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36105: {
    label: 'Sullivan',
    iso3: null,
    population: '75,432',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5869,
    completeVaccinations: 47724,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36107: {
    label: 'Tioga',
    iso3: null,
    population: '48,203',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4353,
    completeVaccinations: 30612,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36109: {
    label: 'Tompkins',
    iso3: null,
    population: '102,180',
    casesPastWeek: 93,
    casesIncreasingPercentage: 10.71428571428572,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11493,
    completeVaccinations: 85757,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36111: {
    label: 'Ulster',
    iso3: null,
    population: '177,573',
    casesPastWeek: 86,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19036,
    completeVaccinations: 134682,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36113: {
    label: 'Warren',
    iso3: null,
    population: '63,944',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5681,
    completeVaccinations: 51212,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36115: {
    label: 'Washington',
    iso3: null,
    population: '61,204',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6304,
    completeVaccinations: 42409,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36117: {
    label: 'Wayne',
    iso3: null,
    population: '89,918',
    casesPastWeek: 65,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3577,
    completeVaccinations: 58054,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36119: {
    label: 'Westchester',
    iso3: null,
    population: '967,506',
    casesPastWeek: 365,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 163464,
    completeVaccinations: 845943,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36121: {
    label: 'Wyoming',
    iso3: null,
    population: '39,859',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1153,
    completeVaccinations: 21155,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_36123: {
    label: 'Yates',
    iso3: null,
    population: '24,913',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1023,
    completeVaccinations: 13710,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37001: {
    label: 'Alamance',
    iso3: null,
    population: '169,509',
    casesPastWeek: 106,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5214,
    completeVaccinations: 103168,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37003: {
    label: 'Alexander',
    iso3: null,
    population: '37,497',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 961,
    completeVaccinations: 17224,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37005: {
    label: 'Alleghany',
    iso3: null,
    population: '11,137',
    casesPastWeek: 11,
    casesIncreasingPercentage: 266.66666666666663,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 391,
    completeVaccinations: 6623,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37007: {
    label: 'Anson',
    iso3: null,
    population: '24,446',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 852,
    completeVaccinations: 11647,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37009: {
    label: 'Ashe',
    iso3: null,
    population: '27,203',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 988,
    completeVaccinations: 15521,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37011: {
    label: 'Avery',
    iso3: null,
    population: '17,557',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 35.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 567,
    completeVaccinations: 9725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37013: {
    label: 'Beaufort',
    iso3: null,
    population: '46,994',
    casesPastWeek: 44,
    casesIncreasingPercentage: 12.82051282051282,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1485,
    completeVaccinations: 26331,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37015: {
    label: 'Bertie',
    iso3: null,
    population: '18,947',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 799,
    completeVaccinations: 10219,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37017: {
    label: 'Bladen',
    iso3: null,
    population: '32,722',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2895,
    completeVaccinations: 21430,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37019: {
    label: 'Brunswick',
    iso3: null,
    population: '142,820',
    casesPastWeek: 52,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4155,
    completeVaccinations: 91215,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37021: {
    label: 'Buncombe',
    iso3: null,
    population: '261,191',
    casesPastWeek: 96,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8947,
    completeVaccinations: 176748,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37023: {
    label: 'Burke',
    iso3: null,
    population: '90,485',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2321,
    completeVaccinations: 42504,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37025: {
    label: 'Cabarrus',
    iso3: null,
    population: '216,453',
    casesPastWeek: 121,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7549,
    completeVaccinations: 121489,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37027: {
    label: 'Caldwell',
    iso3: null,
    population: '82,178',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2090,
    completeVaccinations: 39641,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37029: {
    label: 'Camden',
    iso3: null,
    population: '10,867',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 993,
    completeVaccinations: 5854,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37031: {
    label: 'Carteret',
    iso3: null,
    population: '69,473',
    casesPastWeek: 25,
    casesIncreasingPercentage: 4.166666666666674,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3315,
    completeVaccinations: 45677,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37033: {
    label: 'Caswell',
    iso3: null,
    population: '22,604',
    casesPastWeek: 20,
    casesIncreasingPercentage: 566.6666666666667,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 756,
    completeVaccinations: 10904,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37035: {
    label: 'Catawba',
    iso3: null,
    population: '159,551',
    casesPastWeek: 112,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3979,
    completeVaccinations: 88052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37037: {
    label: 'Chatham',
    iso3: null,
    population: '74,470',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3343,
    completeVaccinations: 46203,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37039: {
    label: 'Cherokee',
    iso3: null,
    population: '28,612',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 837,
    completeVaccinations: 13313,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37041: {
    label: 'Chowan',
    iso3: null,
    population: '13,943',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 864,
    completeVaccinations: 8003,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37043: {
    label: 'Clay',
    iso3: null,
    population: '11,231',
    casesPastWeek: 10,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 318,
    completeVaccinations: 5352,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37045: {
    label: 'Cleveland',
    iso3: null,
    population: '97,947',
    casesPastWeek: 46,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3035,
    completeVaccinations: 49450,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37047: {
    label: 'Columbus',
    iso3: null,
    population: '55,508',
    casesPastWeek: 18,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1666,
    completeVaccinations: 24234,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37049: {
    label: 'Craven',
    iso3: null,
    population: '102,139',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3793,
    completeVaccinations: 65192,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37051: {
    label: 'Cumberland',
    iso3: null,
    population: '335,509',
    casesPastWeek: 169,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27652,
    completeVaccinations: 207222,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37053: {
    label: 'Currituck',
    iso3: null,
    population: '27,763',
    casesPastWeek: 16,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1464,
    completeVaccinations: 12313,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37055: {
    label: 'Dare',
    iso3: null,
    population: '37,009',
    casesPastWeek: 29,
    casesIncreasingPercentage: 38.095238095238095,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1086,
    completeVaccinations: 27378,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37057: {
    label: 'Davidson',
    iso3: null,
    population: '167,609',
    casesPastWeek: 107,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6094,
    completeVaccinations: 83330,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37059: {
    label: 'Davie',
    iso3: null,
    population: '42,846',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 972,
    completeVaccinations: 25194,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37061: {
    label: 'Duplin',
    iso3: null,
    population: '58,741',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2506,
    completeVaccinations: 27684,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37063: {
    label: 'Durham',
    iso3: null,
    population: '321,488',
    casesPastWeek: 203,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13247,
    completeVaccinations: 237771,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37065: {
    label: 'Edgecombe',
    iso3: null,
    population: '51,472',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2064,
    completeVaccinations: 24923,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37067: {
    label: 'Forsyth',
    iso3: null,
    population: '382,295',
    casesPastWeek: 196,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15808,
    completeVaccinations: 243147,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37069: {
    label: 'Franklin',
    iso3: null,
    population: '69,685',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2471,
    completeVaccinations: 38797,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37071: {
    label: 'Gaston',
    iso3: null,
    population: '224,529',
    casesPastWeek: 109,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8070,
    completeVaccinations: 114211,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37073: {
    label: 'Gates',
    iso3: null,
    population: '11,562',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 345,
    completeVaccinations: 4659,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37075: {
    label: 'Graham',
    iso3: null,
    population: '8,441',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 362,
    completeVaccinations: 3939,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37077: {
    label: 'Granville',
    iso3: null,
    population: '60,443',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2596,
    completeVaccinations: 38455,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37079: {
    label: 'Greene',
    iso3: null,
    population: '21,069',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1341,
    completeVaccinations: 14321,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37081: {
    label: 'Guilford',
    iso3: null,
    population: '537,174',
    casesPastWeek: 271,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 18145,
    completeVaccinations: 337167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37083: {
    label: 'Halifax',
    iso3: null,
    population: '50,010',
    casesPastWeek: 43,
    casesIncreasingPercentage: 34.375,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1930,
    completeVaccinations: 27148,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37085: {
    label: 'Harnett',
    iso3: null,
    population: '135,976',
    casesPastWeek: 46,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5461,
    completeVaccinations: 59372,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37087: {
    label: 'Haywood',
    iso3: null,
    population: '62,317',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1949,
    completeVaccinations: 36389,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37089: {
    label: 'Henderson',
    iso3: null,
    population: '117,417',
    casesPastWeek: 54,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2834,
    completeVaccinations: 70704,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37091: {
    label: 'Hertford',
    iso3: null,
    population: '23,677',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 848,
    completeVaccinations: 11518,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37093: {
    label: 'Hoke',
    iso3: null,
    population: '55,234',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3336,
    completeVaccinations: 23782,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37095: {
    label: 'Hyde',
    iso3: null,
    population: '4,937',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 117,
    completeVaccinations: 3381,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37097: {
    label: 'Iredell',
    iso3: null,
    population: '181,806',
    casesPastWeek: 115,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4699,
    completeVaccinations: 97797,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37099: {
    label: 'Jackson',
    iso3: null,
    population: '43,938',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1939,
    completeVaccinations: 23869,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37101: {
    label: 'Johnston',
    iso3: null,
    population: '209,339',
    casesPastWeek: 122,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7891,
    completeVaccinations: 116316,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37103: {
    label: 'Jones',
    iso3: null,
    population: '9,419',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 468,
    completeVaccinations: 5354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37105: {
    label: 'Lee',
    iso3: null,
    population: '61,779',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2639,
    completeVaccinations: 35288,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37107: {
    label: 'Lenoir',
    iso3: null,
    population: '55,949',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2073,
    completeVaccinations: 31556,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37109: {
    label: 'Lincoln',
    iso3: null,
    population: '86,111',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2094,
    completeVaccinations: 42699,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37111: {
    label: 'McDowell',
    iso3: null,
    population: '45,756',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1462,
    completeVaccinations: 23970,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37113: {
    label: 'Macon',
    iso3: null,
    population: '35,858',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 962,
    completeVaccinations: 21328,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37115: {
    label: 'Madison',
    iso3: null,
    population: '21,755',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 435,
    completeVaccinations: 12453,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37117: {
    label: 'Martin',
    iso3: null,
    population: '22,440',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 800,
    completeVaccinations: 11044,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37119: {
    label: 'Mecklenburg',
    iso3: null,
    population: '1,110,356',
    casesPastWeek: 507,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 43523,
    completeVaccinations: 708025,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37121: {
    label: 'Mitchell',
    iso3: null,
    population: '14,964',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 516,
    completeVaccinations: 7371,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37123: {
    label: 'Montgomery',
    iso3: null,
    population: '27,173',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 836,
    completeVaccinations: 11974,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37125: {
    label: 'Moore',
    iso3: null,
    population: '100,880',
    casesPastWeek: 54,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 36.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4342,
    completeVaccinations: 60026,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37127: {
    label: 'Nash',
    iso3: null,
    population: '94,298',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3221,
    completeVaccinations: 54850,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37129: {
    label: 'New Hanover',
    iso3: null,
    population: '234,473',
    casesPastWeek: 69,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7407,
    completeVaccinations: 151722,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37131: {
    label: 'Northampton',
    iso3: null,
    population: '19,483',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 659,
    completeVaccinations: 8687,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37133: {
    label: 'Onslow',
    iso3: null,
    population: '197,938',
    casesPastWeek: 67,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12484,
    completeVaccinations: 121268,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37135: {
    label: 'Orange',
    iso3: null,
    population: '148,476',
    casesPastWeek: 73,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3901,
    completeVaccinations: 117444,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37137: {
    label: 'Pamlico',
    iso3: null,
    population: '12,726',
    casesPastWeek: 9,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 1.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 369,
    completeVaccinations: 7032,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37139: {
    label: 'Pasquotank',
    iso3: null,
    population: '39,824',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1969,
    completeVaccinations: 21333,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37141: {
    label: 'Pender',
    iso3: null,
    population: '63,060',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2253,
    completeVaccinations: 32957,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37143: {
    label: 'Perquimans',
    iso3: null,
    population: '13,463',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 506,
    completeVaccinations: 6232,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37145: {
    label: 'Person',
    iso3: null,
    population: '39,490',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1163,
    completeVaccinations: 22720,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37147: {
    label: 'Pitt',
    iso3: null,
    population: '180,742',
    casesPastWeek: 151,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6013,
    completeVaccinations: 99381,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37149: {
    label: 'Polk',
    iso3: null,
    population: '20,724',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 542,
    completeVaccinations: 8563,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37151: {
    label: 'Randolph',
    iso3: null,
    population: '143,667',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3530,
    completeVaccinations: 64357,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37153: {
    label: 'Richmond',
    iso3: null,
    population: '44,829',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1817,
    completeVaccinations: 22212,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37155: {
    label: 'Robeson',
    iso3: null,
    population: '130,625',
    casesPastWeek: 97,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5899,
    completeVaccinations: 56840,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37157: {
    label: 'Rockingham',
    iso3: null,
    population: '91,010',
    casesPastWeek: 68,
    casesIncreasingPercentage: 11.475409836065564,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2510,
    completeVaccinations: 47007,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37159: {
    label: 'Rowan',
    iso3: null,
    population: '142,088',
    casesPastWeek: 86,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4877,
    completeVaccinations: 62747,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37161: {
    label: 'Rutherford',
    iso3: null,
    population: '67,029',
    casesPastWeek: 12,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 4.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1897,
    completeVaccinations: 28541,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37163: {
    label: 'Sampson',
    iso3: null,
    population: '63,531',
    casesPastWeek: 44,
    casesIncreasingPercentage: 4.761904761904767,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2628,
    completeVaccinations: 33228,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37165: {
    label: 'Scotland',
    iso3: null,
    population: '34,823',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1474,
    completeVaccinations: 17471,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37167: {
    label: 'Stanly',
    iso3: null,
    population: '62,806',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1678,
    completeVaccinations: 28043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37169: {
    label: 'Stokes',
    iso3: null,
    population: '45,591',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1201,
    completeVaccinations: 22043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37171: {
    label: 'Surry',
    iso3: null,
    population: '71,783',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2054,
    completeVaccinations: 36650,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37173: {
    label: 'Swain',
    iso3: null,
    population: '14,271',
    casesPastWeek: 22,
    casesIncreasingPercentage: 83.33333333333333,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1335,
    completeVaccinations: 8359,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37175: {
    label: 'Transylvania',
    iso3: null,
    population: '34,385',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 466,
    completeVaccinations: 19495,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37177: {
    label: 'Tyrrell',
    iso3: null,
    population: '4,016',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 92,
    completeVaccinations: 2002,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37179: {
    label: 'Union',
    iso3: null,
    population: '239,859',
    casesPastWeek: 108,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5635,
    completeVaccinations: 133007,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37181: {
    label: 'Vance',
    iso3: null,
    population: '44,535',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2439,
    completeVaccinations: 26359,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37183: {
    label: 'Wake',
    iso3: null,
    population: '1,111,761',
    casesPastWeek: 545,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36597,
    completeVaccinations: 838332,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37185: {
    label: 'Warren',
    iso3: null,
    population: '19,731',
    casesPastWeek: 21,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 683,
    completeVaccinations: 11019,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37187: {
    label: 'Washington',
    iso3: null,
    population: '11,580',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 588,
    completeVaccinations: 6003,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37189: {
    label: 'Watauga',
    iso3: null,
    population: '56,177',
    casesPastWeek: 31,
    casesIncreasingPercentage: 6.896551724137923,
    positiveCasePercentage: 14.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1737,
    completeVaccinations: 31289,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37191: {
    label: 'Wayne',
    iso3: null,
    population: '123,131',
    casesPastWeek: 112,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4424,
    completeVaccinations: 62160,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37193: {
    label: 'Wilkes',
    iso3: null,
    population: '68,412',
    casesPastWeek: 52,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2430,
    completeVaccinations: 32473,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37195: {
    label: 'Wilson',
    iso3: null,
    population: '81,801',
    casesPastWeek: 49,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2454,
    completeVaccinations: 44521,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37197: {
    label: 'Yadkin',
    iso3: null,
    population: '37,667',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 910,
    completeVaccinations: 18938,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_37199: {
    label: 'Yancey',
    iso3: null,
    population: '18,069',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 521,
    completeVaccinations: 9271,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_38001: {
    label: 'Adams',
    iso3: null,
    population: '2,216',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 183,
    completeVaccinations: 1107,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38003: {
    label: 'Barnes',
    iso3: null,
    population: '10,415',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 804,
    completeVaccinations: 6300,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38005: {
    label: 'Benson',
    iso3: null,
    population: '6,832',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 528,
    completeVaccinations: 3841,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  US_38007: {
    label: 'Billings',
    iso3: null,
    population: '928',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 55,
    completeVaccinations: 208,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_38009: {
    label: 'Bottineau',
    iso3: null,
    population: '6,282',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 495,
    completeVaccinations: 3356,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38011: {
    label: 'Bowman',
    iso3: null,
    population: '3,024',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 246,
    completeVaccinations: 1203,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38013: {
    label: 'Burke',
    iso3: null,
    population: '2,115',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 121,
    completeVaccinations: 874,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38015: {
    label: 'Burleigh',
    iso3: null,
    population: '95,626',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8415,
    completeVaccinations: 51529,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38017: {
    label: 'Cass',
    iso3: null,
    population: '181,923',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19291,
    completeVaccinations: 117294,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38019: {
    label: 'Cavalier',
    iso3: null,
    population: '3,762',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 197,
    completeVaccinations: 2371,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38021: {
    label: 'Dickey',
    iso3: null,
    population: '4,872',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 245,
    completeVaccinations: 2706,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_38023: {
    label: 'Divide',
    iso3: null,
    population: '2,264',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 106,
    completeVaccinations: 974,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_38025: {
    label: 'Dunn',
    iso3: null,
    population: '4,424',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 247,
    completeVaccinations: 1184,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  US_38027: {
    label: 'Eddy',
    iso3: null,
    population: '2,287',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 66,
    completeVaccinations: 1230,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38029: {
    label: 'Emmons',
    iso3: null,
    population: '3,241',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 234,
    completeVaccinations: 1149,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38031: {
    label: 'Foster',
    iso3: null,
    population: '3,210',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 28.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 161,
    completeVaccinations: 1812,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38033: {
    label: 'Golden Valley',
    iso3: null,
    population: '1,761',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 153,
    completeVaccinations: 550,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_38035: {
    label: 'Grand Forks',
    iso3: null,
    population: '69,451',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7714,
    completeVaccinations: 43480,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38037: {
    label: 'Grant',
    iso3: null,
    population: '2,274',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95,
    completeVaccinations: 564,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_38039: {
    label: 'Griggs',
    iso3: null,
    population: '2,231',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 120,
    completeVaccinations: 1069,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_38041: {
    label: 'Hettinger',
    iso3: null,
    population: '2,499',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 150,
    completeVaccinations: 1018,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38043: {
    label: 'Kidder',
    iso3: null,
    population: '2,480',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 194,
    completeVaccinations: 854,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_38045: {
    label: 'LaMoure',
    iso3: null,
    population: '4,046',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 238,
    completeVaccinations: 2044,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  US_38047: {
    label: 'Logan',
    iso3: null,
    population: '1,850',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 110,
    completeVaccinations: 728,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38049: {
    label: 'McHenry',
    iso3: null,
    population: '5,745',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 283,
    completeVaccinations: 2498,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38051: {
    label: 'McIntosh',
    iso3: null,
    population: '2,497',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 180,
    completeVaccinations: 1132,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38053: {
    label: 'McKenzie',
    iso3: null,
    population: '15,024',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1067,
    completeVaccinations: 3597,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_38055: {
    label: 'McLean',
    iso3: null,
    population: '9,450',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 898,
    completeVaccinations: 4598,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38057: {
    label: 'Mercer',
    iso3: null,
    population: '8,187',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 376,
    completeVaccinations: 3313,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_38059: {
    label: 'Morton',
    iso3: null,
    population: '31,364',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2452,
    completeVaccinations: 14975,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_38061: {
    label: 'Mountrail',
    iso3: null,
    population: '10,545',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2072,
    completeVaccinations: 4863,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38063: {
    label: 'Nelson',
    iso3: null,
    population: '2,879',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 163,
    completeVaccinations: 2121,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_38065: {
    label: 'Oliver',
    iso3: null,
    population: '1,959',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 47,
    completeVaccinations: 526,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_38067: {
    label: 'Pembina',
    iso3: null,
    population: '6,801',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 666,
    completeVaccinations: 3957,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38069: {
    label: 'Pierce',
    iso3: null,
    population: '3,975',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 192,
    completeVaccinations: 1863,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 24, 2023',
  },
  US_38071: {
    label: 'Ramsey',
    iso3: null,
    population: '11,519',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 734,
    completeVaccinations: 6948,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_38073: {
    label: 'Ransom',
    iso3: null,
    population: '5,218',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 395,
    completeVaccinations: 3279,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38075: {
    label: 'Renville',
    iso3: null,
    population: '2,327',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 125,
    completeVaccinations: 954,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_38077: {
    label: 'Richland',
    iso3: null,
    population: '16,177',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1249,
    completeVaccinations: 8913,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38079: {
    label: 'Rolette',
    iso3: null,
    population: '14,176',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 44.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3255,
    completeVaccinations: 11367,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38081: {
    label: 'Sargent',
    iso3: null,
    population: '3,898',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 239,
    completeVaccinations: 2405,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38083: {
    label: 'Sheridan',
    iso3: null,
    population: '1,315',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 53,
    completeVaccinations: 501,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38085: {
    label: 'Sioux',
    iso3: null,
    population: '4,230',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 568,
    completeVaccinations: 2811,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38087: {
    label: 'Slope',
    iso3: null,
    population: '750',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25,
    completeVaccinations: 85,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  US_38089: {
    label: 'Stark',
    iso3: null,
    population: '31,489',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2417,
    completeVaccinations: 12143,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38091: {
    label: 'Steele',
    iso3: null,
    population: '1,890',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 162,
    completeVaccinations: 924,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_38093: {
    label: 'Stutsman',
    iso3: null,
    population: '20,704',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1826,
    completeVaccinations: 11609,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38095: {
    label: 'Towner',
    iso3: null,
    population: '2,189',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 63,
    completeVaccinations: 1092,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38097: {
    label: 'Traill',
    iso3: null,
    population: '8,036',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 647,
    completeVaccinations: 4536,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38099: {
    label: 'Walsh',
    iso3: null,
    population: '10,641',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 32.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 991,
    completeVaccinations: 6457,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38101: {
    label: 'Ward',
    iso3: null,
    population: '67,641',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5469,
    completeVaccinations: 36139,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38103: {
    label: 'Wells',
    iso3: null,
    population: '3,834',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 212,
    completeVaccinations: 1719,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_38105: {
    label: 'Williams',
    iso3: null,
    population: '37,589',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2506,
    completeVaccinations: 11837,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39001: {
    label: 'Adams',
    iso3: null,
    population: '27,698',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1124,
    completeVaccinations: 10103,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39003: {
    label: 'Allen',
    iso3: null,
    population: '102,351',
    casesPastWeek: 103,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 28.999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3225,
    completeVaccinations: 46484,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39005: {
    label: 'Ashland',
    iso3: null,
    population: '53,484',
    casesPastWeek: 38,
    casesIncreasingPercentage: 15.15151515151516,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1709,
    completeVaccinations: 24031,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39007: {
    label: 'Ashtabula',
    iso3: null,
    population: '97,241',
    casesPastWeek: 86,
    casesIncreasingPercentage: 1.17647058823529,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4017,
    completeVaccinations: 53371,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39009: {
    label: 'Athens',
    iso3: null,
    population: '65,327',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2776,
    completeVaccinations: 34029,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39011: {
    label: 'Auglaize',
    iso3: null,
    population: '45,656',
    casesPastWeek: 51,
    casesIncreasingPercentage: 15.909090909090917,
    positiveCasePercentage: 17.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 781,
    completeVaccinations: 18361,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39013: {
    label: 'Belmont',
    iso3: null,
    population: '67,006',
    casesPastWeek: 45,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4197,
    completeVaccinations: 33814,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39015: {
    label: 'Brown',
    iso3: null,
    population: '43,432',
    casesPastWeek: 47,
    casesIncreasingPercentage: 6.818181818181812,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1353,
    completeVaccinations: 18490,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39017: {
    label: 'Butler',
    iso3: null,
    population: '383,134',
    casesPastWeek: 236,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17363,
    completeVaccinations: 226162,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39019: {
    label: 'Carroll',
    iso3: null,
    population: '26,914',
    casesPastWeek: 17,
    casesIncreasingPercentage: 54.54545454545454,
    positiveCasePercentage: 16.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 650,
    completeVaccinations: 12163,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39021: {
    label: 'Champaign',
    iso3: null,
    population: '38,885',
    casesPastWeek: 21,
    casesIncreasingPercentage: 5.000000000000004,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1212,
    completeVaccinations: 18294,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39023: {
    label: 'Clark',
    iso3: null,
    population: '134,083',
    casesPastWeek: 102,
    casesIncreasingPercentage: 10.869565217391308,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5440,
    completeVaccinations: 72847,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39025: {
    label: 'Clermont',
    iso3: null,
    population: '206,428',
    casesPastWeek: 148,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8505,
    completeVaccinations: 122024,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39027: {
    label: 'Clinton',
    iso3: null,
    population: '41,968',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1797,
    completeVaccinations: 21324,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39029: {
    label: 'Columbiana',
    iso3: null,
    population: '101,883',
    casesPastWeek: 109,
    casesIncreasingPercentage: 39.74358974358974,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4150,
    completeVaccinations: 51820,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39031: {
    label: 'Coshocton',
    iso3: null,
    population: '36,600',
    casesPastWeek: 17,
    casesIncreasingPercentage: 6.25,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 967,
    completeVaccinations: 14778,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39033: {
    label: 'Crawford',
    iso3: null,
    population: '41,494',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 903,
    completeVaccinations: 19267,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39035: {
    label: 'Cuyahoga',
    iso3: null,
    population: '1,235,072',
    casesPastWeek: 1230,
    casesIncreasingPercentage: 10.910730387736688,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 68752,
    completeVaccinations: 838474,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39037: {
    label: 'Darke',
    iso3: null,
    population: '51,113',
    casesPastWeek: 36,
    casesIncreasingPercentage: 2.857142857142847,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1486,
    completeVaccinations: 21003,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39039: {
    label: 'Defiance',
    iso3: null,
    population: '38,087',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1010,
    completeVaccinations: 19306,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39041: {
    label: 'Delaware',
    iso3: null,
    population: '209,177',
    casesPastWeek: 127,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8208,
    completeVaccinations: 164073,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39043: {
    label: 'Erie',
    iso3: null,
    population: '74,266',
    casesPastWeek: 56,
    casesIncreasingPercentage: 3.703703703703698,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2584,
    completeVaccinations: 45198,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39045: {
    label: 'Fairfield',
    iso3: null,
    population: '157,574',
    casesPastWeek: 95,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5854,
    completeVaccinations: 94880,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39047: {
    label: 'Fayette',
    iso3: null,
    population: '28,525',
    casesPastWeek: 18,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 975,
    completeVaccinations: 13044,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39049: {
    label: 'Franklin',
    iso3: null,
    population: '1,316,756',
    casesPastWeek: 852,
    casesIncreasingPercentage: 6.633291614518155,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 63315,
    completeVaccinations: 881718,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39051: {
    label: 'Fulton',
    iso3: null,
    population: '42,126',
    casesPastWeek: 61,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1310,
    completeVaccinations: 22790,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39053: {
    label: 'Gallia',
    iso3: null,
    population: '29,898',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1560,
    completeVaccinations: 14422,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39055: {
    label: 'Geauga',
    iso3: null,
    population: '93,649',
    casesPastWeek: 61,
    casesIncreasingPercentage: 38.63636363636365,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3052,
    completeVaccinations: 59309,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39057: {
    label: 'Greene',
    iso3: null,
    population: '168,937',
    casesPastWeek: 126,
    casesIncreasingPercentage: 9.565217391304337,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10839,
    completeVaccinations: 110201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39059: {
    label: 'Guernsey',
    iso3: null,
    population: '38,875',
    casesPastWeek: 32,
    casesIncreasingPercentage: 10.344827586206895,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1197,
    completeVaccinations: 17701,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39061: {
    label: 'Hamilton',
    iso3: null,
    population: '817,473',
    casesPastWeek: 536,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 51256,
    completeVaccinations: 534740,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39063: {
    label: 'Hancock',
    iso3: null,
    population: '75,783',
    casesPastWeek: 136,
    casesIncreasingPercentage: 86.30136986301369,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2974,
    completeVaccinations: 39726,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39065: {
    label: 'Hardin',
    iso3: null,
    population: '31,365',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 839,
    completeVaccinations: 12448,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39067: {
    label: 'Harrison',
    iso3: null,
    population: '15,040',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 577,
    completeVaccinations: 6640,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39069: {
    label: 'Henry',
    iso3: null,
    population: '27,006',
    casesPastWeek: 51,
    casesIncreasingPercentage: 96.15384615384615,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 543,
    completeVaccinations: 14941,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39071: {
    label: 'Highland',
    iso3: null,
    population: '43,161',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1428,
    completeVaccinations: 16823,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39073: {
    label: 'Hocking',
    iso3: null,
    population: '28,264',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 706,
    completeVaccinations: 14159,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39075: {
    label: 'Holmes',
    iso3: null,
    population: '43,960',
    casesPastWeek: 37,
    casesIncreasingPercentage: 208.33333333333334,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 575,
    completeVaccinations: 8182,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39077: {
    label: 'Huron',
    iso3: null,
    population: '58,266',
    casesPastWeek: 26,
    casesIncreasingPercentage: 18.181818181818187,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1632,
    completeVaccinations: 28668,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39079: {
    label: 'Jackson',
    iso3: null,
    population: '32,413',
    casesPastWeek: 39,
    casesIncreasingPercentage: 30.000000000000004,
    positiveCasePercentage: 14.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1108,
    completeVaccinations: 15898,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39081: {
    label: 'Jefferson',
    iso3: null,
    population: '65,325',
    casesPastWeek: 54,
    casesIncreasingPercentage: 14.893617021276606,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3885,
    completeVaccinations: 33803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39083: {
    label: 'Knox',
    iso3: null,
    population: '62,322',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1783,
    completeVaccinations: 27810,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39085: {
    label: 'Lake',
    iso3: null,
    population: '230,149',
    casesPastWeek: 174,
    casesIncreasingPercentage: 6.748466257668717,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8377,
    completeVaccinations: 155266,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39087: {
    label: 'Lawrence',
    iso3: null,
    population: '59,463',
    casesPastWeek: 107,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 35.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7281,
    completeVaccinations: 31455,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39089: {
    label: 'Licking',
    iso3: null,
    population: '176,862',
    casesPastWeek: 133,
    casesIncreasingPercentage: 9.016393442622949,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4889,
    completeVaccinations: 99894,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39091: {
    label: 'Logan',
    iso3: null,
    population: '45,672',
    casesPastWeek: 43,
    casesIncreasingPercentage: 86.95652173913044,
    positiveCasePercentage: 15.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1096,
    completeVaccinations: 20162,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39093: {
    label: 'Lorain',
    iso3: null,
    population: '309,833',
    casesPastWeek: 352,
    casesIncreasingPercentage: 11.041009463722396,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12833,
    completeVaccinations: 204921,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39095: {
    label: 'Lucas',
    iso3: null,
    population: '428,348',
    casesPastWeek: 316,
    casesIncreasingPercentage: 14.079422382671481,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20423,
    completeVaccinations: 256872,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39097: {
    label: 'Madison',
    iso3: null,
    population: '44,731',
    casesPastWeek: 27,
    casesIncreasingPercentage: 22.72727272727273,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1308,
    completeVaccinations: 24408,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39099: {
    label: 'Mahoning',
    iso3: null,
    population: '228,683',
    casesPastWeek: 262,
    casesIncreasingPercentage: 25.35885167464116,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10723,
    completeVaccinations: 135019,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39101: {
    label: 'Marion',
    iso3: null,
    population: '65,093',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2143,
    completeVaccinations: 32951,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39103: {
    label: 'Medina',
    iso3: null,
    population: '179,746',
    casesPastWeek: 162,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5838,
    completeVaccinations: 118845,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39105: {
    label: 'Meigs',
    iso3: null,
    population: '22,907',
    casesPastWeek: 28,
    casesIncreasingPercentage: 3.703703703703698,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1540,
    completeVaccinations: 11181,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39107: {
    label: 'Mercer',
    iso3: null,
    population: '41,172',
    casesPastWeek: 33,
    casesIncreasingPercentage: 3.125,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 971,
    completeVaccinations: 16136,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39109: {
    label: 'Miami',
    iso3: null,
    population: '106,987',
    casesPastWeek: 91,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3481,
    completeVaccinations: 52633,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39111: {
    label: 'Monroe',
    iso3: null,
    population: '13,654',
    casesPastWeek: 21,
    casesIncreasingPercentage: 31.25,
    positiveCasePercentage: 27.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 798,
    completeVaccinations: 6779,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39113: {
    label: 'Montgomery',
    iso3: null,
    population: '531,687',
    casesPastWeek: 514,
    casesIncreasingPercentage: 16.289592760181005,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28665,
    completeVaccinations: 310876,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39115: {
    label: 'Morgan',
    iso3: null,
    population: '14,508',
    casesPastWeek: 10,
    casesIncreasingPercentage: 233.33333333333334,
    positiveCasePercentage: 35.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 356,
    completeVaccinations: 6404,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39117: {
    label: 'Morrow',
    iso3: null,
    population: '35,328',
    casesPastWeek: 11,
    casesIncreasingPercentage: 10.000000000000009,
    positiveCasePercentage: 16.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 830,
    completeVaccinations: 15232,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39119: {
    label: 'Muskingum',
    iso3: null,
    population: '86,215',
    casesPastWeek: 125,
    casesIncreasingPercentage: 31.578947368421062,
    positiveCasePercentage: 28.799999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2261,
    completeVaccinations: 42813,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39121: {
    label: 'Noble',
    iso3: null,
    population: '14,424',
    casesPastWeek: 8,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 305,
    completeVaccinations: 6934,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39123: {
    label: 'Ottawa',
    iso3: null,
    population: '40,525',
    casesPastWeek: 32,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1699,
    completeVaccinations: 25688,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39125: {
    label: 'Paulding',
    iso3: null,
    population: '18,672',
    casesPastWeek: 10,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 574,
    completeVaccinations: 8137,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39127: {
    label: 'Perry',
    iso3: null,
    population: '36,134',
    casesPastWeek: 28,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: 34.599999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 885,
    completeVaccinations: 15278,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39129: {
    label: 'Pickaway',
    iso3: null,
    population: '58,457',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2029,
    completeVaccinations: 30735,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39131: {
    label: 'Pike',
    iso3: null,
    population: '27,772',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1102,
    completeVaccinations: 12882,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39133: {
    label: 'Portage',
    iso3: null,
    population: '162,466',
    casesPastWeek: 151,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5717,
    completeVaccinations: 96040,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39135: {
    label: 'Preble',
    iso3: null,
    population: '40,882',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1762,
    completeVaccinations: 18126,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39137: {
    label: 'Putnam',
    iso3: null,
    population: '33,861',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 742,
    completeVaccinations: 16977,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39139: {
    label: 'Richland',
    iso3: null,
    population: '121,154',
    casesPastWeek: 51,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3192,
    completeVaccinations: 55891,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39141: {
    label: 'Ross',
    iso3: null,
    population: '76,666',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3186,
    completeVaccinations: 40148,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39143: {
    label: 'Sandusky',
    iso3: null,
    population: '58,518',
    casesPastWeek: 75,
    casesIncreasingPercentage: 102.70270270270272,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1518,
    completeVaccinations: 31564,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39145: {
    label: 'Scioto',
    iso3: null,
    population: '75,314',
    casesPastWeek: 54,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4532,
    completeVaccinations: 39330,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39147: {
    label: 'Seneca',
    iso3: null,
    population: '55,178',
    casesPastWeek: 34,
    casesIncreasingPercentage: 21.42857142857142,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1491,
    completeVaccinations: 27876,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39149: {
    label: 'Shelby',
    iso3: null,
    population: '48,590',
    casesPastWeek: 39,
    casesIncreasingPercentage: 34.48275862068966,
    positiveCasePercentage: 18.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 983,
    completeVaccinations: 18002,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39151: {
    label: 'Stark',
    iso3: null,
    population: '370,606',
    casesPastWeek: 243,
    casesIncreasingPercentage: 27.89473684210526,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13143,
    completeVaccinations: 209693,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39153: {
    label: 'Summit',
    iso3: null,
    population: '541,013',
    casesPastWeek: 439,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24555,
    completeVaccinations: 356887,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39155: {
    label: 'Trumbull',
    iso3: null,
    population: '197,974',
    casesPastWeek: 141,
    casesIncreasingPercentage: 6.818181818181812,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9201,
    completeVaccinations: 112346,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39157: {
    label: 'Tuscarawas',
    iso3: null,
    population: '91,987',
    casesPastWeek: 84,
    casesIncreasingPercentage: 2.4390243902439046,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2557,
    completeVaccinations: 42288,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39159: {
    label: 'Union',
    iso3: null,
    population: '58,988',
    casesPastWeek: 51,
    casesIncreasingPercentage: 2.0000000000000018,
    positiveCasePercentage: 27.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1824,
    completeVaccinations: 38935,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39161: {
    label: 'Van Wert',
    iso3: null,
    population: '28,275',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1063,
    completeVaccinations: 12670,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39163: {
    label: 'Vinton',
    iso3: null,
    population: '13,085',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 296,
    completeVaccinations: 5253,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39165: {
    label: 'Warren',
    iso3: null,
    population: '234,602',
    casesPastWeek: 127,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10364,
    completeVaccinations: 157585,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39167: {
    label: 'Washington',
    iso3: null,
    population: '59,911',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3481,
    completeVaccinations: 34551,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39169: {
    label: 'Wayne',
    iso3: null,
    population: '115,710',
    casesPastWeek: 78,
    casesIncreasingPercentage: 13.043478260869556,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3054,
    completeVaccinations: 54400,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39171: {
    label: 'Williams',
    iso3: null,
    population: '36,692',
    casesPastWeek: 55,
    casesIncreasingPercentage: 17.021276595744684,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1378,
    completeVaccinations: 16843,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39173: {
    label: 'Wood',
    iso3: null,
    population: '130,817',
    casesPastWeek: 84,
    casesIncreasingPercentage: 1.2048192771084265,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4551,
    completeVaccinations: 82226,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_39175: {
    label: 'Wyandot',
    iso3: null,
    population: '21,772',
    casesPastWeek: 30,
    casesIncreasingPercentage: 36.36363636363635,
    positiveCasePercentage: 17.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 483,
    completeVaccinations: 10622,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40001: {
    label: 'Adair',
    iso3: null,
    population: '22,194',
    casesPastWeek: 30,
    casesIncreasingPercentage: 233.33333333333334,
    positiveCasePercentage: 76.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3972,
    completeVaccinations: 8547,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40003: {
    label: 'Alfalfa',
    iso3: null,
    population: '5,702',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 353,
    completeVaccinations: 2672,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40005: {
    label: 'Atoka',
    iso3: null,
    population: '13,758',
    casesPastWeek: 13,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 722,
    completeVaccinations: 5201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40007: {
    label: 'Beaver',
    iso3: null,
    population: '5,311',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 362,
    completeVaccinations: 1777,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40009: {
    label: 'Beckham',
    iso3: null,
    population: '21,859',
    casesPastWeek: 18,
    casesIncreasingPercentage: 5.882352941176472,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1404,
    completeVaccinations: 9326,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40011: {
    label: 'Blaine',
    iso3: null,
    population: '9,429',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 719,
    completeVaccinations: 4555,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40013: {
    label: 'Bryan',
    iso3: null,
    population: '47,995',
    casesPastWeek: 50,
    casesIncreasingPercentage: 6.382978723404253,
    positiveCasePercentage: 37.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4347,
    completeVaccinations: 21803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40015: {
    label: 'Caddo',
    iso3: null,
    population: '28,762',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 47.599999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3116,
    completeVaccinations: 19635,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40017: {
    label: 'Canadian',
    iso3: null,
    population: '148,306',
    casesPastWeek: 86,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.799999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21016,
    completeVaccinations: 91619,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40019: {
    label: 'Carter',
    iso3: null,
    population: '48,111',
    casesPastWeek: 59,
    casesIncreasingPercentage: 28.260869565217384,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5179,
    completeVaccinations: 22202,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40021: {
    label: 'Cherokee',
    iso3: null,
    population: '48,657',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 52.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8939,
    completeVaccinations: 24757,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40023: {
    label: 'Choctaw',
    iso3: null,
    population: '14,672',
    casesPastWeek: 16,
    casesIncreasingPercentage: 23.076923076923084,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 962,
    completeVaccinations: 5408,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40025: {
    label: 'Cimarron',
    iso3: null,
    population: '2,137',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 164,
    completeVaccinations: 745,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40027: {
    label: 'Cleveland',
    iso3: null,
    population: '284,014',
    casesPastWeek: 267,
    casesIncreasingPercentage: 7.6612903225806495,
    positiveCasePercentage: 29.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 43360,
    completeVaccinations: 171851,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40029: {
    label: 'Coal',
    iso3: null,
    population: '5,495',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 335,
    completeVaccinations: 2353,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40031: {
    label: 'Comanche',
    iso3: null,
    population: '120,749',
    casesPastWeek: 71,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14960,
    completeVaccinations: 80637,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40033: {
    label: 'Cotton',
    iso3: null,
    population: '5,666',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 528,
    completeVaccinations: 2920,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40035: {
    label: 'Craig',
    iso3: null,
    population: '14,142',
    casesPastWeek: 16,
    casesIncreasingPercentage: 77.77777777777777,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1947,
    completeVaccinations: 7872,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40037: {
    label: 'Creek',
    iso3: null,
    population: '71,522',
    casesPastWeek: 64,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5271,
    completeVaccinations: 32770,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40039: {
    label: 'Custer',
    iso3: null,
    population: '29,003',
    casesPastWeek: 25,
    casesIncreasingPercentage: 4.166666666666674,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2486,
    completeVaccinations: 14613,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40041: {
    label: 'Delaware',
    iso3: null,
    population: '43,009',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5285,
    completeVaccinations: 19060,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40043: {
    label: 'Dewey',
    iso3: null,
    population: '4,891',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 169,
    completeVaccinations: 1270,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40045: {
    label: 'Ellis',
    iso3: null,
    population: '3,859',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 204,
    completeVaccinations: 1393,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40047: {
    label: 'Garfield',
    iso3: null,
    population: '61,056',
    casesPastWeek: 56,
    casesIncreasingPercentage: 12.00000000000001,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5787,
    completeVaccinations: 32088,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40049: {
    label: 'Garvin',
    iso3: null,
    population: '27,711',
    casesPastWeek: 27,
    casesIncreasingPercentage: 35.00000000000001,
    positiveCasePercentage: 35.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2290,
    completeVaccinations: 13374,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40051: {
    label: 'Grady',
    iso3: null,
    population: '55,834',
    casesPastWeek: 47,
    casesIncreasingPercentage: 56.666666666666664,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4821,
    completeVaccinations: 22435,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40053: {
    label: 'Grant',
    iso3: null,
    population: '4,333',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 301,
    completeVaccinations: 1887,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40055: {
    label: 'Greer',
    iso3: null,
    population: '5,712',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 319,
    completeVaccinations: 2167,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40057: {
    label: 'Harmon',
    iso3: null,
    population: '2,653',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 236,
    completeVaccinations: 1347,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40059: {
    label: 'Harper',
    iso3: null,
    population: '3,688',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 205,
    completeVaccinations: 1716,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40061: {
    label: 'Haskell',
    iso3: null,
    population: '12,627',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 888,
    completeVaccinations: 5439,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40063: {
    label: 'Hughes',
    iso3: null,
    population: '13,279',
    casesPastWeek: 14,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1089,
    completeVaccinations: 6377,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40065: {
    label: 'Jackson',
    iso3: null,
    population: '24,530',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2271,
    completeVaccinations: 13774,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40067: {
    label: 'Jefferson',
    iso3: null,
    population: '6,002',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 500,
    completeVaccinations: 2583,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40069: {
    label: 'Johnston',
    iso3: null,
    population: '11,085',
    casesPastWeek: 17,
    casesIncreasingPercentage: 88.88888888888889,
    positiveCasePercentage: 31.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 965,
    completeVaccinations: 4928,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40071: {
    label: 'Kay',
    iso3: null,
    population: '43,538',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3621,
    completeVaccinations: 19751,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40073: {
    label: 'Kingfisher',
    iso3: null,
    population: '15,765',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1148,
    completeVaccinations: 7795,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40075: {
    label: 'Kiowa',
    iso3: null,
    population: '8,708',
    casesPastWeek: 4,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 690,
    completeVaccinations: 4597,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40077: {
    label: 'Latimer',
    iso3: null,
    population: '10,073',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 505,
    completeVaccinations: 3610,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40079: {
    label: 'Le Flore',
    iso3: null,
    population: '49,853',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3711,
    completeVaccinations: 20447,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40081: {
    label: 'Lincoln',
    iso3: null,
    population: '34,877',
    casesPastWeek: 28,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 21.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2831,
    completeVaccinations: 14722,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40083: {
    label: 'Logan',
    iso3: null,
    population: '48,011',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3515,
    completeVaccinations: 17738,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40085: {
    label: 'Love',
    iso3: null,
    population: '10,253',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1063,
    completeVaccinations: 4518,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40087: {
    label: 'McClain',
    iso3: null,
    population: '40,474',
    casesPastWeek: 42,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 22.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4121,
    completeVaccinations: 20388,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40089: {
    label: 'McCurtain',
    iso3: null,
    population: '32,832',
    casesPastWeek: 21,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2535,
    completeVaccinations: 12348,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40091: {
    label: 'McIntosh',
    iso3: null,
    population: '19,596',
    casesPastWeek: 6,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1614,
    completeVaccinations: 10982,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40093: {
    label: 'Major',
    iso3: null,
    population: '7,629',
    casesPastWeek: 10,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 453,
    completeVaccinations: 3931,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40095: {
    label: 'Marshall',
    iso3: null,
    population: '16,931',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1917,
    completeVaccinations: 7938,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40097: {
    label: 'Mayes',
    iso3: null,
    population: '41,100',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 38.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4342,
    completeVaccinations: 18647,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40099: {
    label: 'Murray',
    iso3: null,
    population: '14,073',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 40.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1115,
    completeVaccinations: 6526,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40101: {
    label: 'Muskogee',
    iso3: null,
    population: '67,997',
    casesPastWeek: 56,
    casesIncreasingPercentage: 51.35135135135136,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9973,
    completeVaccinations: 36807,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40103: {
    label: 'Noble',
    iso3: null,
    population: '11,131',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1173,
    completeVaccinations: 7300,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40105: {
    label: 'Nowata',
    iso3: null,
    population: '10,076',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 41.699999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1403,
    completeVaccinations: 4613,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40107: {
    label: 'Okfuskee',
    iso3: null,
    population: '11,993',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 728,
    completeVaccinations: 5845,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40109: {
    label: 'Oklahoma',
    iso3: null,
    population: '797,434',
    casesPastWeek: 488,
    casesIncreasingPercentage: 16.1904761904762,
    positiveCasePercentage: 24.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 140757,
    completeVaccinations: 554303,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40111: {
    label: 'Okmulgee',
    iso3: null,
    population: '38,465',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2579,
    completeVaccinations: 17074,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40113: {
    label: 'Osage',
    iso3: null,
    population: '46,963',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3508,
    completeVaccinations: 17173,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40115: {
    label: 'Ottawa',
    iso3: null,
    population: '31,127',
    casesPastWeek: 44,
    casesIncreasingPercentage: 131.57894736842107,
    positiveCasePercentage: 22.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2797,
    completeVaccinations: 12771,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40117: {
    label: 'Pawnee',
    iso3: null,
    population: '16,376',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1552,
    completeVaccinations: 8397,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40119: {
    label: 'Payne',
    iso3: null,
    population: '81,784',
    casesPastWeek: 85,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9236,
    completeVaccinations: 42362,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40121: {
    label: 'Pittsburg',
    iso3: null,
    population: '43,654',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4022,
    completeVaccinations: 20470,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40123: {
    label: 'Pontotoc',
    iso3: null,
    population: '38,284',
    casesPastWeek: 71,
    casesIncreasingPercentage: 16.393442622950815,
    positiveCasePercentage: 17.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4646,
    completeVaccinations: 20429,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40125: {
    label: 'Pottawatomie',
    iso3: null,
    population: '72,592',
    casesPastWeek: 71,
    casesIncreasingPercentage: 5.970149253731338,
    positiveCasePercentage: 47.699999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8883,
    completeVaccinations: 38151,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40127: {
    label: 'Pushmataha',
    iso3: null,
    population: '11,096',
    casesPastWeek: 12,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 562,
    completeVaccinations: 4143,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40129: {
    label: 'Roger Mills',
    iso3: null,
    population: '3,583',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 165,
    completeVaccinations: 1364,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40131: {
    label: 'Rogers',
    iso3: null,
    population: '92,459',
    casesPastWeek: 77,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7969,
    completeVaccinations: 45296,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40133: {
    label: 'Seminole',
    iso3: null,
    population: '24,258',
    casesPastWeek: 24,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 48.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2551,
    completeVaccinations: 13346,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40135: {
    label: 'Sequoyah',
    iso3: null,
    population: '41,569',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5588,
    completeVaccinations: 17639,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40137: {
    label: 'Stephens',
    iso3: null,
    population: '43,143',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4276,
    completeVaccinations: 21411,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40139: {
    label: 'Texas',
    iso3: null,
    population: '19,983',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2150,
    completeVaccinations: 9453,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40141: {
    label: 'Tillman',
    iso3: null,
    population: '7,250',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 684,
    completeVaccinations: 3762,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40143: {
    label: 'Tulsa',
    iso3: null,
    population: '651,552',
    casesPastWeek: 439,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95452,
    completeVaccinations: 428064,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40145: {
    label: 'Wagoner',
    iso3: null,
    population: '81,289',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7907,
    completeVaccinations: 40289,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40147: {
    label: 'Washington',
    iso3: null,
    population: '51,527',
    casesPastWeek: 35,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6597,
    completeVaccinations: 24034,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40149: {
    label: 'Washita',
    iso3: null,
    population: '10,916',
    casesPastWeek: 14,
    casesIncreasingPercentage: 27.27272727272727,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 792,
    completeVaccinations: 4433,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40151: {
    label: 'Woods',
    iso3: null,
    population: '8,793',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 613,
    completeVaccinations: 3856,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_40153: {
    label: 'Woodward',
    iso3: null,
    population: '20,211',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1192,
    completeVaccinations: 7942,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 23, 2023',
  },
  US_41001: {
    label: 'Baker',
    iso3: null,
    population: '16,124',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2925,
    completeVaccinations: 11576,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41003: {
    label: 'Benton',
    iso3: null,
    population: '93,053',
    casesPastWeek: 49,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6914,
    completeVaccinations: 72310,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41005: {
    label: 'Clackamas',
    iso3: null,
    population: '418,187',
    casesPastWeek: 190,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27941,
    completeVaccinations: 299887,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41007: {
    label: 'Clatsop',
    iso3: null,
    population: '40,224',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4025,
    completeVaccinations: 28147,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41009: {
    label: 'Columbia',
    iso3: null,
    population: '52,354',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4595,
    completeVaccinations: 33253,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41011: {
    label: 'Coos',
    iso3: null,
    population: '64,487',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.1000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4345,
    completeVaccinations: 38169,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41013: {
    label: 'Crook',
    iso3: null,
    population: '24,404',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1455,
    completeVaccinations: 12937,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41015: {
    label: 'Curry',
    iso3: null,
    population: '22,925',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2030,
    completeVaccinations: 13359,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41017: {
    label: 'Deschutes',
    iso3: null,
    population: '197,692',
    casesPastWeek: 96,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16283,
    completeVaccinations: 142520,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41019: {
    label: 'Douglas',
    iso3: null,
    population: '110,980',
    casesPastWeek: 53,
    casesIncreasingPercentage: 15.217391304347828,
    positiveCasePercentage: 5.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8525,
    completeVaccinations: 60533,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41021: {
    label: 'Gilliam',
    iso3: null,
    population: '1,912',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 107,
    completeVaccinations: 888,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41023: {
    label: 'Grant',
    iso3: null,
    population: '7,199',
    casesPastWeek: 15,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 321,
    completeVaccinations: 3335,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41025: {
    label: 'Harney',
    iso3: null,
    population: '7,393',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 463,
    completeVaccinations: 3489,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41027: {
    label: 'Hood River',
    iso3: null,
    population: '23,382',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2430,
    completeVaccinations: 20748,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41029: {
    label: 'Jackson',
    iso3: null,
    population: '220,944',
    casesPastWeek: 129,
    casesIncreasingPercentage: 15.17857142857142,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15294,
    completeVaccinations: 132924,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41031: {
    label: 'Jefferson',
    iso3: null,
    population: '24,658',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1969,
    completeVaccinations: 16240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41033: {
    label: 'Josephine',
    iso3: null,
    population: '87,487',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4973,
    completeVaccinations: 45276,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41035: {
    label: 'Klamath',
    iso3: null,
    population: '68,238',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4067,
    completeVaccinations: 35779,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41037: {
    label: 'Lake',
    iso3: null,
    population: '7,869',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 241,
    completeVaccinations: 3186,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_41039: {
    label: 'Lane',
    iso3: null,
    population: '382,067',
    casesPastWeek: 259,
    casesIncreasingPercentage: 23.33333333333334,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31041,
    completeVaccinations: 267834,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41041: {
    label: 'Lincoln',
    iso3: null,
    population: '49,962',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4155,
    completeVaccinations: 36846,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41043: {
    label: 'Linn',
    iso3: null,
    population: '129,749',
    casesPastWeek: 65,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7415,
    completeVaccinations: 74215,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41045: {
    label: 'Malheur',
    iso3: null,
    population: '30,571',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2355,
    completeVaccinations: 15391,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41047: {
    label: 'Marion',
    iso3: null,
    population: '347,818',
    casesPastWeek: 204,
    casesIncreasingPercentage: 18.60465116279071,
    positiveCasePercentage: 7.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24874,
    completeVaccinations: 227474,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41049: {
    label: 'Morrow',
    iso3: null,
    population: '11,603',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 854,
    completeVaccinations: 6745,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41051: {
    label: 'Multnomah',
    iso3: null,
    population: '812,855',
    casesPastWeek: 365,
    casesIncreasingPercentage: 10.942249240121571,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 103722,
    completeVaccinations: 675676,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41053: {
    label: 'Polk',
    iso3: null,
    population: '86,085',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4683,
    completeVaccinations: 56358,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41055: {
    label: 'Sherman',
    iso3: null,
    population: '1,780',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 134,
    completeVaccinations: 1064,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_41057: {
    label: 'Tillamook',
    iso3: null,
    population: '27,036',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1932,
    completeVaccinations: 18086,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41059: {
    label: 'Umatilla',
    iso3: null,
    population: '77,950',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6376,
    completeVaccinations: 45151,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41061: {
    label: 'Union',
    iso3: null,
    population: '26,835',
    casesPastWeek: 14,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1588,
    completeVaccinations: 13750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41063: {
    label: 'Wallowa',
    iso3: null,
    population: '7,208',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 496,
    completeVaccinations: 4434,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41065: {
    label: 'Wasco',
    iso3: null,
    population: '26,682',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2007,
    completeVaccinations: 18287,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41067: {
    label: 'Washington',
    iso3: null,
    population: '601,592',
    casesPastWeek: 223,
    casesIncreasingPercentage: 12.62626262626263,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 51855,
    completeVaccinations: 479202,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_41069: {
    label: 'Wheeler',
    iso3: null,
    population: '1,332',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 62,
    completeVaccinations: 741,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_41071: {
    label: 'Yamhill',
    iso3: null,
    population: '107,100',
    casesPastWeek: 49,
    casesIncreasingPercentage: 63.33333333333333,
    positiveCasePercentage: 4.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7245,
    completeVaccinations: 70175,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42001: {
    label: 'Adams',
    iso3: null,
    population: '103,009',
    casesPastWeek: 105,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5347,
    completeVaccinations: 55290,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42003: {
    label: 'Allegheny',
    iso3: null,
    population: '1,216,045',
    casesPastWeek: 698,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 130872,
    completeVaccinations: 903462,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42005: {
    label: 'Armstrong',
    iso3: null,
    population: '64,735',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3674,
    completeVaccinations: 40337,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42007: {
    label: 'Beaver',
    iso3: null,
    population: '163,929',
    casesPastWeek: 77,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11681,
    completeVaccinations: 95099,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42009: {
    label: 'Bedford',
    iso3: null,
    population: '47,888',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1952,
    completeVaccinations: 18393,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42011: {
    label: 'Berks',
    iso3: null,
    population: '421,164',
    casesPastWeek: 207,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 32497,
    completeVaccinations: 261360,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42013: {
    label: 'Blair',
    iso3: null,
    population: '121,829',
    casesPastWeek: 71,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7623,
    completeVaccinations: 64069,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42015: {
    label: 'Bradford',
    iso3: null,
    population: '60,323',
    casesPastWeek: 56,
    casesIncreasingPercentage: 3.703703703703698,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3859,
    completeVaccinations: 25541,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42017: {
    label: 'Bucks',
    iso3: null,
    population: '628,270',
    casesPastWeek: 278,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 68552,
    completeVaccinations: 473754,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42019: {
    label: 'Butler',
    iso3: null,
    population: '187,853',
    casesPastWeek: 130,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13088,
    completeVaccinations: 124277,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42021: {
    label: 'Cambria',
    iso3: null,
    population: '130,192',
    casesPastWeek: 198,
    casesIncreasingPercentage: 81.65137614678899,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7934,
    completeVaccinations: 73855,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42023: {
    label: 'Cameron',
    iso3: null,
    population: '4,447',
    casesPastWeek: 9,
    casesIncreasingPercentage: 125.0,
    positiveCasePercentage: 20.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 204,
    completeVaccinations: 2519,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42025: {
    label: 'Carbon',
    iso3: null,
    population: '64,182',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4546,
    completeVaccinations: 40162,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42027: {
    label: 'Centre',
    iso3: null,
    population: '162,385',
    casesPastWeek: 124,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13055,
    completeVaccinations: 102952,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42029: {
    label: 'Chester',
    iso3: null,
    population: '524,989',
    casesPastWeek: 179,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 67650,
    completeVaccinations: 422661,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42031: {
    label: 'Clarion',
    iso3: null,
    population: '38,438',
    casesPastWeek: 27,
    casesIncreasingPercentage: 28.57142857142858,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1876,
    completeVaccinations: 17319,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42033: {
    label: 'Clearfield',
    iso3: null,
    population: '79,255',
    casesPastWeek: 60,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3851,
    completeVaccinations: 40553,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42035: {
    label: 'Clinton',
    iso3: null,
    population: '38,632',
    casesPastWeek: 33,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2035,
    completeVaccinations: 18486,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42037: {
    label: 'Columbia',
    iso3: null,
    population: '64,964',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3502,
    completeVaccinations: 40247,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42039: {
    label: 'Crawford',
    iso3: null,
    population: '84,629',
    casesPastWeek: 108,
    casesIncreasingPercentage: 8.000000000000007,
    positiveCasePercentage: 17.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5005,
    completeVaccinations: 42144,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42041: {
    label: 'Cumberland',
    iso3: null,
    population: '253,370',
    casesPastWeek: 84,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28247,
    completeVaccinations: 181373,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42043: {
    label: 'Dauphin',
    iso3: null,
    population: '278,299',
    casesPastWeek: 102,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27861,
    completeVaccinations: 181666,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42045: {
    label: 'Delaware',
    iso3: null,
    population: '566,747',
    casesPastWeek: 258,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 67577,
    completeVaccinations: 440833,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42047: {
    label: 'Elk',
    iso3: null,
    population: '29,910',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1549,
    completeVaccinations: 17141,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42049: {
    label: 'Erie',
    iso3: null,
    population: '269,728',
    casesPastWeek: 218,
    casesIncreasingPercentage: 6.341463414634152,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20502,
    completeVaccinations: 167805,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42051: {
    label: 'Fayette',
    iso3: null,
    population: '129,274',
    casesPastWeek: 89,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8959,
    completeVaccinations: 73708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42053: {
    label: 'Forest',
    iso3: null,
    population: '7,247',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 402,
    completeVaccinations: 5307,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42055: {
    label: 'Franklin',
    iso3: null,
    population: '155,027',
    casesPastWeek: 89,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7383,
    completeVaccinations: 73139,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42057: {
    label: 'Fulton',
    iso3: null,
    population: '14,530',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 336,
    completeVaccinations: 4558,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42059: {
    label: 'Greene',
    iso3: null,
    population: '36,233',
    casesPastWeek: 23,
    casesIncreasingPercentage: 21.052631578947366,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2056,
    completeVaccinations: 17193,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42061: {
    label: 'Huntingdon',
    iso3: null,
    population: '45,144',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2805,
    completeVaccinations: 22721,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42063: {
    label: 'Indiana',
    iso3: null,
    population: '84,073',
    casesPastWeek: 96,
    casesIncreasingPercentage: 123.25581395348837,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4559,
    completeVaccinations: 38819,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42065: {
    label: 'Jefferson',
    iso3: null,
    population: '43,425',
    casesPastWeek: 41,
    casesIncreasingPercentage: 17.14285714285715,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1862,
    completeVaccinations: 21358,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42067: {
    label: 'Juniata',
    iso3: null,
    population: '24,763',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 803,
    completeVaccinations: 10398,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42069: {
    label: 'Lackawanna',
    iso3: null,
    population: '209,674',
    casesPastWeek: 137,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17617,
    completeVaccinations: 153457,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42071: {
    label: 'Lancaster',
    iso3: null,
    population: '545,724',
    casesPastWeek: 284,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 35120,
    completeVaccinations: 334842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42073: {
    label: 'Lawrence',
    iso3: null,
    population: '85,512',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6197,
    completeVaccinations: 46457,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42075: {
    label: 'Lebanon',
    iso3: null,
    population: '141,793',
    casesPastWeek: 99,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8851,
    completeVaccinations: 78943,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42077: {
    label: 'Lehigh',
    iso3: null,
    population: '369,318',
    casesPastWeek: 158,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 30709,
    completeVaccinations: 275622,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42079: {
    label: 'Luzerne',
    iso3: null,
    population: '317,417',
    casesPastWeek: 205,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 23979,
    completeVaccinations: 209834,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42081: {
    label: 'Lycoming',
    iso3: null,
    population: '113,299',
    casesPastWeek: 103,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6657,
    completeVaccinations: 62967,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42083: {
    label: 'McKean',
    iso3: null,
    population: '40,625',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3644,
    completeVaccinations: 19781,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42085: {
    label: 'Mercer',
    iso3: null,
    population: '109,424',
    casesPastWeek: 83,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6753,
    completeVaccinations: 58430,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42087: {
    label: 'Mifflin',
    iso3: null,
    population: '46,138',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1567,
    completeVaccinations: 23265,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42089: {
    label: 'Monroe',
    iso3: null,
    population: '170,271',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13684,
    completeVaccinations: 102535,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42091: {
    label: 'Montgomery',
    iso3: null,
    population: '830,915',
    casesPastWeek: 304,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 102335,
    completeVaccinations: 669903,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42093: {
    label: 'Montour',
    iso3: null,
    population: '18,230',
    casesPastWeek: 17,
    casesIncreasingPercentage: 30.76923076923077,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1448,
    completeVaccinations: 14961,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42095: {
    label: 'Northampton',
    iso3: null,
    population: '305,285',
    casesPastWeek: 148,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24078,
    completeVaccinations: 212273,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42097: {
    label: 'Northumberland',
    iso3: null,
    population: '90,843',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4490,
    completeVaccinations: 53982,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42099: {
    label: 'Perry',
    iso3: null,
    population: '46,272',
    casesPastWeek: 34,
    casesIncreasingPercentage: 78.94736842105263,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2543,
    completeVaccinations: 23519,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42101: {
    label: 'Philadelphia',
    iso3: null,
    population: '1,584,064',
    casesPastWeek: 721,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 222430,
    completeVaccinations: 1077693,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42103: {
    label: 'Pike',
    iso3: null,
    population: '55,809',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5853,
    completeVaccinations: 31459,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42105: {
    label: 'Potter',
    iso3: null,
    population: '16,526',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1046,
    completeVaccinations: 6595,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42107: {
    label: 'Schuylkill',
    iso3: null,
    population: '141,359',
    casesPastWeek: 97,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8606,
    completeVaccinations: 86606,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42109: {
    label: 'Snyder',
    iso3: null,
    population: '40,372',
    casesPastWeek: 32,
    casesIncreasingPercentage: 6.666666666666665,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1694,
    completeVaccinations: 17957,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42111: {
    label: 'Somerset',
    iso3: null,
    population: '73,447',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4145,
    completeVaccinations: 36787,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42113: {
    label: 'Sullivan',
    iso3: null,
    population: '6,066',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 473,
    completeVaccinations: 3370,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42115: {
    label: 'Susquehanna',
    iso3: null,
    population: '40,328',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2318,
    completeVaccinations: 18332,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42117: {
    label: 'Tioga',
    iso3: null,
    population: '40,591',
    casesPastWeek: 24,
    casesIncreasingPercentage: 4.347826086956519,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2420,
    completeVaccinations: 19028,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42119: {
    label: 'Union',
    iso3: null,
    population: '44,923',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2196,
    completeVaccinations: 23334,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42121: {
    label: 'Venango',
    iso3: null,
    population: '50,668',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3003,
    completeVaccinations: 24626,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42123: {
    label: 'Warren',
    iso3: null,
    population: '39,191',
    casesPastWeek: 43,
    casesIncreasingPercentage: 43.333333333333336,
    positiveCasePercentage: 18.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2548,
    completeVaccinations: 19026,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42125: {
    label: 'Washington',
    iso3: null,
    population: '206,865',
    casesPastWeek: 102,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17219,
    completeVaccinations: 136022,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42127: {
    label: 'Wayne',
    iso3: null,
    population: '51,361',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4391,
    completeVaccinations: 31626,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42129: {
    label: 'Westmoreland',
    iso3: null,
    population: '348,899',
    casesPastWeek: 248,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 23931,
    completeVaccinations: 209626,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42131: {
    label: 'Wyoming',
    iso3: null,
    population: '26,794',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2142,
    completeVaccinations: 16962,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_42133: {
    label: 'York',
    iso3: null,
    population: '449,058',
    casesPastWeek: 241,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27852,
    completeVaccinations: 265043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_44001: {
    label: 'Bristol',
    iso3: null,
    population: '48,479',
    casesPastWeek: 27,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4997,
    completeVaccinations: 40315,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_44003: {
    label: 'Kent',
    iso3: null,
    population: '164,292',
    casesPastWeek: 122,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12951,
    completeVaccinations: 134528,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_44005: {
    label: 'Newport',
    iso3: null,
    population: '82,082',
    casesPastWeek: 53,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12463,
    completeVaccinations: 65621,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_44007: {
    label: 'Providence',
    iso3: null,
    population: '638,931',
    casesPastWeek: 467,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 62423,
    completeVaccinations: 472482,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_44009: {
    label: 'Washington',
    iso3: null,
    population: '125,577',
    casesPastWeek: 79,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13111,
    completeVaccinations: 104749,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_45001: {
    label: 'Abbeville',
    iso3: null,
    population: '24,527',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1154,
    completeVaccinations: 10240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45003: {
    label: 'Aiken',
    iso3: null,
    population: '170,872',
    casesPastWeek: 76,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16730,
    completeVaccinations: 83328,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45005: {
    label: 'Allendale',
    iso3: null,
    population: '8,688',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 931,
    completeVaccinations: 5311,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45007: {
    label: 'Anderson',
    iso3: null,
    population: '202,558',
    casesPastWeek: 109,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11231,
    completeVaccinations: 84842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45009: {
    label: 'Bamberg',
    iso3: null,
    population: '14,066',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.0999999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 996,
    completeVaccinations: 7434,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45011: {
    label: 'Barnwell',
    iso3: null,
    population: '20,866',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1817,
    completeVaccinations: 12118,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45013: {
    label: 'Beaufort',
    iso3: null,
    population: '192,122',
    casesPastWeek: 78,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 23447,
    completeVaccinations: 138756,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45015: {
    label: 'Berkeley',
    iso3: null,
    population: '227,907',
    casesPastWeek: 89,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25172,
    completeVaccinations: 125195,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45017: {
    label: 'Calhoun',
    iso3: null,
    population: '14,553',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1124,
    completeVaccinations: 6254,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45019: {
    label: 'Charleston',
    iso3: null,
    population: '411,406',
    casesPastWeek: 191,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 50969,
    completeVaccinations: 282877,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45021: {
    label: 'Cherokee',
    iso3: null,
    population: '57,300',
    casesPastWeek: 30,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3435,
    completeVaccinations: 20335,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45023: {
    label: 'Chester',
    iso3: null,
    population: '32,244',
    casesPastWeek: 25,
    casesIncreasingPercentage: 31.578947368421062,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2479,
    completeVaccinations: 15437,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45025: {
    label: 'Chesterfield',
    iso3: null,
    population: '45,650',
    casesPastWeek: 25,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4101,
    completeVaccinations: 17744,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45027: {
    label: 'Clarendon',
    iso3: null,
    population: '33,745',
    casesPastWeek: 27,
    casesIncreasingPercentage: 92.85714285714286,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2858,
    completeVaccinations: 17161,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45029: {
    label: 'Colleton',
    iso3: null,
    population: '37,677',
    casesPastWeek: 22,
    casesIncreasingPercentage: 4.761904761904767,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3441,
    completeVaccinations: 19052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45031: {
    label: 'Darlington',
    iso3: null,
    population: '66,618',
    casesPastWeek: 39,
    casesIncreasingPercentage: 5.405405405405395,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5345,
    completeVaccinations: 33676,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45033: {
    label: 'Dillon',
    iso3: null,
    population: '30,479',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2394,
    completeVaccinations: 14253,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45035: {
    label: 'Dorchester',
    iso3: null,
    population: '162,809',
    casesPastWeek: 141,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15982,
    completeVaccinations: 90699,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45037: {
    label: 'Edgefield',
    iso3: null,
    population: '27,260',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3596,
    completeVaccinations: 17196,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45039: {
    label: 'Fairfield',
    iso3: null,
    population: '22,347',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2866,
    completeVaccinations: 15133,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45041: {
    label: 'Florence',
    iso3: null,
    population: '138,293',
    casesPastWeek: 71,
    casesIncreasingPercentage: 16.393442622950815,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11706,
    completeVaccinations: 78803,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45043: {
    label: 'Georgetown',
    iso3: null,
    population: '62,680',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6552,
    completeVaccinations: 35478,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45045: {
    label: 'Greenville',
    iso3: null,
    population: '523,542',
    casesPastWeek: 398,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 49637,
    completeVaccinations: 302864,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45047: {
    label: 'Greenwood',
    iso3: null,
    population: '70,811',
    casesPastWeek: 61,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 28.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4097,
    completeVaccinations: 35102,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45049: {
    label: 'Hampton',
    iso3: null,
    population: '19,222',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1962,
    completeVaccinations: 12721,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45051: {
    label: 'Horry',
    iso3: null,
    population: '354,081',
    casesPastWeek: 161,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 35843,
    completeVaccinations: 209173,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45053: {
    label: 'Jasper',
    iso3: null,
    population: '30,073',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3168,
    completeVaccinations: 12120,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45055: {
    label: 'Kershaw',
    iso3: null,
    population: '66,551',
    casesPastWeek: 50,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6992,
    completeVaccinations: 40777,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45057: {
    label: 'Lancaster',
    iso3: null,
    population: '98,012',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14439,
    completeVaccinations: 49378,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45059: {
    label: 'Laurens',
    iso3: null,
    population: '67,493',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3410,
    completeVaccinations: 27002,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45061: {
    label: 'Lee',
    iso3: null,
    population: '16,828',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1406,
    completeVaccinations: 8395,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45063: {
    label: 'Lexington',
    iso3: null,
    population: '298,750',
    casesPastWeek: 180,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 27608,
    completeVaccinations: 173425,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45065: {
    label: 'McCormick',
    iso3: null,
    population: '9,463',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 803,
    completeVaccinations: 5819,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45067: {
    label: 'Marion',
    iso3: null,
    population: '30,657',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2311,
    completeVaccinations: 15493,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45069: {
    label: 'Marlboro',
    iso3: null,
    population: '26,118',
    casesPastWeek: 21,
    casesIncreasingPercentage: 23.529411764705888,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2233,
    completeVaccinations: 12213,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45071: {
    label: 'Newberry',
    iso3: null,
    population: '38,440',
    casesPastWeek: 26,
    casesIncreasingPercentage: 4.0000000000000036,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3101,
    completeVaccinations: 21005,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45073: {
    label: 'Oconee',
    iso3: null,
    population: '79,546',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5424,
    completeVaccinations: 37215,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45075: {
    label: 'Orangeburg',
    iso3: null,
    population: '86,175',
    casesPastWeek: 53,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7400,
    completeVaccinations: 50034,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45077: {
    label: 'Pickens',
    iso3: null,
    population: '126,884',
    casesPastWeek: 87,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8357,
    completeVaccinations: 61261,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45079: {
    label: 'Richland',
    iso3: null,
    population: '415,759',
    casesPastWeek: 244,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 45777,
    completeVaccinations: 267165,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45081: {
    label: 'Saluda',
    iso3: null,
    population: '20,473',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1480,
    completeVaccinations: 6480,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45083: {
    label: 'Spartanburg',
    iso3: null,
    population: '319,785',
    casesPastWeek: 219,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21248,
    completeVaccinations: 145370,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45085: {
    label: 'Sumter',
    iso3: null,
    population: '106,721',
    casesPastWeek: 69,
    casesIncreasingPercentage: 27.777777777777768,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11569,
    completeVaccinations: 63213,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45087: {
    label: 'Union',
    iso3: null,
    population: '27,316',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1838,
    completeVaccinations: 11838,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45089: {
    label: 'Williamsburg',
    iso3: null,
    population: '30,368',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2398,
    completeVaccinations: 17937,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_45091: {
    label: 'York',
    iso3: null,
    population: '280,979',
    casesPastWeek: 86,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 37137,
    completeVaccinations: 149794,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_46003: {
    label: 'Aurora',
    iso3: null,
    population: '2,751',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 275,
    completeVaccinations: 1606,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46005: {
    label: 'Beadle',
    iso3: null,
    population: '18,453',
    casesPastWeek: 9,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2779,
    completeVaccinations: 9759,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46007: {
    label: 'Bennett',
    iso3: null,
    population: '3,365',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 504,
    completeVaccinations: 2300,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46009: {
    label: 'Bon Homme',
    iso3: null,
    population: '6,901',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 614,
    completeVaccinations: 4208,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46011: {
    label: 'Brookings',
    iso3: null,
    population: '35,077',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 36.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3868,
    completeVaccinations: 19201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46013: {
    label: 'Brown',
    iso3: null,
    population: '38,839',
    casesPastWeek: 43,
    casesIncreasingPercentage: 22.857142857142865,
    positiveCasePercentage: 41.099999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4613,
    completeVaccinations: 23530,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46015: {
    label: 'Brule',
    iso3: null,
    population: '5,297',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 50.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1282,
    completeVaccinations: 3321,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46017: {
    label: 'Buffalo',
    iso3: null,
    population: '1,962',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 53.800000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 147,
    completeVaccinations: 1264,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_46019: {
    label: 'Butte',
    iso3: null,
    population: '10,429',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 802,
    completeVaccinations: 4416,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46021: {
    label: 'Campbell',
    iso3: null,
    population: '1,376',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 141,
    completeVaccinations: 678,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_46023: {
    label: 'Charles Mix',
    iso3: null,
    population: '9,292',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1697,
    completeVaccinations: 5871,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46025: {
    label: 'Clark',
    iso3: null,
    population: '3,736',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 70.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 413,
    completeVaccinations: 1991,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46027: {
    label: 'Clay',
    iso3: null,
    population: '14,070',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 47.099999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2382,
    completeVaccinations: 10193,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46029: {
    label: 'Codington',
    iso3: null,
    population: '28,009',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 55.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3678,
    completeVaccinations: 16467,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46031: {
    label: 'Corson',
    iso3: null,
    population: '4,086',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 630,
    completeVaccinations: 2472,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46033: {
    label: 'Custer',
    iso3: null,
    population: '8,972',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1063,
    completeVaccinations: 4779,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46035: {
    label: 'Davison',
    iso3: null,
    population: '19,775',
    casesPastWeek: 20,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 24.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3730,
    completeVaccinations: 13345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46037: {
    label: 'Day',
    iso3: null,
    population: '5,424',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 46.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 695,
    completeVaccinations: 3853,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46039: {
    label: 'Deuel',
    iso3: null,
    population: '4,351',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 47.599999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 363,
    completeVaccinations: 2131,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46041: {
    label: 'Dewey',
    iso3: null,
    population: '5,892',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 828,
    completeVaccinations: 5003,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46043: {
    label: 'Douglas',
    iso3: null,
    population: '2,921',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 292,
    completeVaccinations: 1651,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46045: {
    label: 'Edmunds',
    iso3: null,
    population: '3,829',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 379,
    completeVaccinations: 1853,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46047: {
    label: 'Fall River',
    iso3: null,
    population: '6,713',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 779,
    completeVaccinations: 4344,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46049: {
    label: 'Faulk',
    iso3: null,
    population: '2,299',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 202,
    completeVaccinations: 1324,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_46051: {
    label: 'Grant',
    iso3: null,
    population: '7,052',
    casesPastWeek: 11,
    casesIncreasingPercentage: 120.00000000000001,
    positiveCasePercentage: 31.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1146,
    completeVaccinations: 3746,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46053: {
    label: 'Gregory',
    iso3: null,
    population: '4,185',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 27.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 385,
    completeVaccinations: 2232,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46055: {
    label: 'Haakon',
    iso3: null,
    population: '1,899',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 127,
    completeVaccinations: 837,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_46057: {
    label: 'Hamlin',
    iso3: null,
    population: '6,164',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 55.60000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 697,
    completeVaccinations: 3030,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46059: {
    label: 'Hand',
    iso3: null,
    population: '3,191',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 177,
    completeVaccinations: 1615,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46061: {
    label: 'Hanson',
    iso3: null,
    population: '3,453',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 487,
    completeVaccinations: 1620,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46063: {
    label: 'Harding',
    iso3: null,
    population: '1,298',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 28,
    completeVaccinations: 255,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_46065: {
    label: 'Hughes',
    iso3: null,
    population: '17,526',
    casesPastWeek: 17,
    casesIncreasingPercentage: 70.0,
    positiveCasePercentage: 19.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2887,
    completeVaccinations: 13444,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46067: {
    label: 'Hutchinson',
    iso3: null,
    population: '7,291',
    casesPastWeek: 12,
    casesIncreasingPercentage: 140.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 528,
    completeVaccinations: 4103,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46069: {
    label: 'Hyde',
    iso3: null,
    population: '1,301',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 138,
    completeVaccinations: 741,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_46071: {
    label: 'Jackson',
    iso3: null,
    population: '3,344',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 430,
    completeVaccinations: 2001,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46073: {
    label: 'Jerauld',
    iso3: null,
    population: '2,013',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 182,
    completeVaccinations: 1097,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_46075: {
    label: 'Jones',
    iso3: null,
    population: '903',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 240,
    completeVaccinations: 743,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_46077: {
    label: 'Kingsbury',
    iso3: null,
    population: '4,939',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 881,
    completeVaccinations: 3567,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46079: {
    label: 'Lake',
    iso3: null,
    population: '12,797',
    casesPastWeek: 8,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1270,
    completeVaccinations: 6895,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46081: {
    label: 'Lawrence',
    iso3: null,
    population: '25,844',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3790,
    completeVaccinations: 16080,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46083: {
    label: 'Lincoln',
    iso3: null,
    population: '61,128',
    casesPastWeek: 41,
    casesIncreasingPercentage: 41.37931034482758,
    positiveCasePercentage: 33.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12807,
    completeVaccinations: 39505,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46085: {
    label: 'Lyman',
    iso3: null,
    population: '3,781',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 765,
    completeVaccinations: 2668,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46087: {
    label: 'McCook',
    iso3: null,
    population: '5,586',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 57.099999999999994,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 831,
    completeVaccinations: 3396,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46089: {
    label: 'McPherson',
    iso3: null,
    population: '2,379',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 98,
    completeVaccinations: 990,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46091: {
    label: 'Marshall',
    iso3: null,
    population: '4,935',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 446,
    completeVaccinations: 2730,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46093: {
    label: 'Meade',
    iso3: null,
    population: '28,332',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3832,
    completeVaccinations: 16243,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46095: {
    label: 'Mellette',
    iso3: null,
    population: '2,061',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 158,
    completeVaccinations: 871,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_46097: {
    label: 'Miner',
    iso3: null,
    population: '2,216',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 250,
    completeVaccinations: 1262,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46099: {
    label: 'Minnehaha',
    iso3: null,
    population: '193,134',
    casesPastWeek: 131,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 32.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 43720,
    completeVaccinations: 140462,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46101: {
    label: 'Moody',
    iso3: null,
    population: '6,576',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1035,
    completeVaccinations: 3495,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46102: {
    label: 'Oglala Lakota',
    iso3: null,
    population: '14,177',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2395,
    completeVaccinations: 12805,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46103: {
    label: 'Pennington',
    iso3: null,
    population: '113,775',
    casesPastWeek: 61,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22146,
    completeVaccinations: 72216,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46105: {
    label: 'Perkins',
    iso3: null,
    population: '2,865',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 131,
    completeVaccinations: 936,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46107: {
    label: 'Potter',
    iso3: null,
    population: '2,153',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 205,
    completeVaccinations: 1354,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46109: {
    label: 'Roberts',
    iso3: null,
    population: '10,394',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1831,
    completeVaccinations: 8531,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46111: {
    label: 'Sanborn',
    iso3: null,
    population: '2,344',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 287,
    completeVaccinations: 1359,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46115: {
    label: 'Spink',
    iso3: null,
    population: '6,376',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 33.300000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 414,
    completeVaccinations: 3486,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46117: {
    label: 'Stanley',
    iso3: null,
    population: '3,098',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 420,
    completeVaccinations: 1960,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46119: {
    label: 'Sully',
    iso3: null,
    population: '1,391',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 143,
    completeVaccinations: 658,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46121: {
    label: 'Todd',
    iso3: null,
    population: '10,177',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2185,
    completeVaccinations: 8308,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46123: {
    label: 'Tripp',
    iso3: null,
    population: '5,441',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 467,
    completeVaccinations: 2576,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46125: {
    label: 'Turner',
    iso3: null,
    population: '8,384',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 52.900000000000006,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 983,
    completeVaccinations: 4725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46127: {
    label: 'Union',
    iso3: null,
    population: '15,932',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 37.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2496,
    completeVaccinations: 9027,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46129: {
    label: 'Walworth',
    iso3: null,
    population: '5,435',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 438,
    completeVaccinations: 2845,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_46135: {
    label: 'Yankton',
    iso3: null,
    population: '22,814',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3282,
    completeVaccinations: 15797,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_46137: {
    label: 'Ziebach',
    iso3: null,
    population: '2,756',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 166,
    completeVaccinations: 1077,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_47001: {
    label: 'Anderson',
    iso3: null,
    population: '76,978',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5264,
    completeVaccinations: 44290,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47003: {
    label: 'Bedford',
    iso3: null,
    population: '49,713',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3531,
    completeVaccinations: 22251,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47005: {
    label: 'Benton',
    iso3: null,
    population: '16,160',
    casesPastWeek: 50,
    casesIncreasingPercentage: 78.57142857142858,
    positiveCasePercentage: 28.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 772,
    completeVaccinations: 7384,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47007: {
    label: 'Bledsoe',
    iso3: null,
    population: '15,064',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 832,
    completeVaccinations: 5738,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47009: {
    label: 'Blount',
    iso3: null,
    population: '133,088',
    casesPastWeek: 121,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8173,
    completeVaccinations: 71757,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47011: {
    label: 'Bradley',
    iso3: null,
    population: '108,110',
    casesPastWeek: 80,
    casesIncreasingPercentage: 3.8961038961038863,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5888,
    completeVaccinations: 46649,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47013: {
    label: 'Campbell',
    iso3: null,
    population: '39,842',
    casesPastWeek: 142,
    casesIncreasingPercentage: 18.333333333333336,
    positiveCasePercentage: 15.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2471,
    completeVaccinations: 18064,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47015: {
    label: 'Cannon',
    iso3: null,
    population: '14,678',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 693,
    completeVaccinations: 4877,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47017: {
    label: 'Carroll',
    iso3: null,
    population: '27,767',
    casesPastWeek: 32,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1882,
    completeVaccinations: 13969,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47019: {
    label: 'Carter',
    iso3: null,
    population: '56,391',
    casesPastWeek: 67,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3213,
    completeVaccinations: 23577,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47021: {
    label: 'Cheatham',
    iso3: null,
    population: '40,667',
    casesPastWeek: 24,
    casesIncreasingPercentage: 4.347826086956519,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3708,
    completeVaccinations: 24801,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47023: {
    label: 'Chester',
    iso3: null,
    population: '17,297',
    casesPastWeek: 30,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 21.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 661,
    completeVaccinations: 6384,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47025: {
    label: 'Claiborne',
    iso3: null,
    population: '31,959',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2452,
    completeVaccinations: 15310,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47027: {
    label: 'Clay',
    iso3: null,
    population: '7,615',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 277,
    completeVaccinations: 2333,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47029: {
    label: 'Cocke',
    iso3: null,
    population: '36,004',
    casesPastWeek: 52,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2196,
    completeVaccinations: 18584,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47031: {
    label: 'Coffee',
    iso3: null,
    population: '56,520',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3730,
    completeVaccinations: 26988,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47033: {
    label: 'Crockett',
    iso3: null,
    population: '14,230',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 877,
    completeVaccinations: 7108,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47035: {
    label: 'Cumberland',
    iso3: null,
    population: '60,520',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3368,
    completeVaccinations: 29443,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47037: {
    label: 'Davidson',
    iso3: null,
    population: '694,144',
    casesPastWeek: 399,
    casesIncreasingPercentage: 3.100775193798455,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 65213,
    completeVaccinations: 472957,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47039: {
    label: 'Decatur',
    iso3: null,
    population: '11,663',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 461,
    completeVaccinations: 4569,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47041: {
    label: 'DeKalb',
    iso3: null,
    population: '20,490',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1240,
    completeVaccinations: 8740,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47043: {
    label: 'Dickson',
    iso3: null,
    population: '53,948',
    casesPastWeek: 53,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3494,
    completeVaccinations: 27091,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47045: {
    label: 'Dyer',
    iso3: null,
    population: '37,159',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2203,
    completeVaccinations: 15546,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47047: {
    label: 'Fayette',
    iso3: null,
    population: '41,133',
    casesPastWeek: 27,
    casesIncreasingPercentage: 22.72727272727273,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3875,
    completeVaccinations: 24241,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47049: {
    label: 'Fentress',
    iso3: null,
    population: '18,523',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 794,
    completeVaccinations: 6489,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47051: {
    label: 'Franklin',
    iso3: null,
    population: '42,208',
    casesPastWeek: 63,
    casesIncreasingPercentage: 6.779661016949157,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2397,
    completeVaccinations: 18078,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47053: {
    label: 'Gibson',
    iso3: null,
    population: '49,133',
    casesPastWeek: 59,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2776,
    completeVaccinations: 23073,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47055: {
    label: 'Giles',
    iso3: null,
    population: '29,464',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1641,
    completeVaccinations: 11989,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47057: {
    label: 'Grainger',
    iso3: null,
    population: '23,320',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1268,
    completeVaccinations: 11745,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47059: {
    label: 'Greene',
    iso3: null,
    population: '69,069',
    casesPastWeek: 56,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3574,
    completeVaccinations: 32778,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47061: {
    label: 'Grundy',
    iso3: null,
    population: '13,427',
    casesPastWeek: 7,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 631,
    completeVaccinations: 4223,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47063: {
    label: 'Hamblen',
    iso3: null,
    population: '64,934',
    casesPastWeek: 48,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3847,
    completeVaccinations: 28486,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47065: {
    label: 'Hamilton',
    iso3: null,
    population: '367,804',
    casesPastWeek: 189,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 29163,
    completeVaccinations: 215248,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47067: {
    label: 'Hancock',
    iso3: null,
    population: '6,620',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 241,
    completeVaccinations: 2205,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47069: {
    label: 'Hardeman',
    iso3: null,
    population: '25,050',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1523,
    completeVaccinations: 12119,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47071: {
    label: 'Hardin',
    iso3: null,
    population: '25,652',
    casesPastWeek: 38,
    casesIncreasingPercentage: 5.555555555555558,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1505,
    completeVaccinations: 10742,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47073: {
    label: 'Hawkins',
    iso3: null,
    population: '56,786',
    casesPastWeek: 50,
    casesIncreasingPercentage: 4.166666666666674,
    positiveCasePercentage: 12.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2992,
    completeVaccinations: 26031,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47075: {
    label: 'Haywood',
    iso3: null,
    population: '17,304',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1367,
    completeVaccinations: 9393,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47077: {
    label: 'Henderson',
    iso3: null,
    population: '28,117',
    casesPastWeek: 31,
    casesIncreasingPercentage: 14.814814814814813,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1444,
    completeVaccinations: 11890,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47079: {
    label: 'Henry',
    iso3: null,
    population: '32,345',
    casesPastWeek: 28,
    casesIncreasingPercentage: 47.36842105263157,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1978,
    completeVaccinations: 15900,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47081: {
    label: 'Hickman',
    iso3: null,
    population: '25,178',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1223,
    completeVaccinations: 10984,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47083: {
    label: 'Houston',
    iso3: null,
    population: '8,201',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 352,
    completeVaccinations: 3651,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47085: {
    label: 'Humphreys',
    iso3: null,
    population: '18,582',
    casesPastWeek: 21,
    casesIncreasingPercentage: 23.529411764705888,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 988,
    completeVaccinations: 7881,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47087: {
    label: 'Jackson',
    iso3: null,
    population: '11,786',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 471,
    completeVaccinations: 4254,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47089: {
    label: 'Jefferson',
    iso3: null,
    population: '54,495',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3111,
    completeVaccinations: 28088,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47091: {
    label: 'Johnson',
    iso3: null,
    population: '17,788',
    casesPastWeek: 14,
    casesIncreasingPercentage: 7.692307692307687,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1192,
    completeVaccinations: 6893,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47093: {
    label: 'Knox',
    iso3: null,
    population: '470,313',
    casesPastWeek: 328,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 32406,
    completeVaccinations: 291816,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47095: {
    label: 'Lake',
    iso3: null,
    population: '7,016',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 333,
    completeVaccinations: 2548,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47097: {
    label: 'Lauderdale',
    iso3: null,
    population: '25,633',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1369,
    completeVaccinations: 9929,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47099: {
    label: 'Lawrence',
    iso3: null,
    population: '44,142',
    casesPastWeek: 16,
    casesIncreasingPercentage: 6.666666666666665,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2154,
    completeVaccinations: 16738,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47101: {
    label: 'Lewis',
    iso3: null,
    population: '12,268',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 498,
    completeVaccinations: 4485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47103: {
    label: 'Lincoln',
    iso3: null,
    population: '34,366',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1761,
    completeVaccinations: 14570,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47105: {
    label: 'Loudon',
    iso3: null,
    population: '54,068',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3843,
    completeVaccinations: 35504,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47107: {
    label: 'McMinn',
    iso3: null,
    population: '53,794',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2532,
    completeVaccinations: 22207,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47109: {
    label: 'McNairy',
    iso3: null,
    population: '25,694',
    casesPastWeek: 22,
    casesIncreasingPercentage: 10.000000000000009,
    positiveCasePercentage: 50.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1860,
    completeVaccinations: 12611,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47111: {
    label: 'Macon',
    iso3: null,
    population: '24,602',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 989,
    completeVaccinations: 8343,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47113: {
    label: 'Madison',
    iso3: null,
    population: '97,984',
    casesPastWeek: 157,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5835,
    completeVaccinations: 54835,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47115: {
    label: 'Marion',
    iso3: null,
    population: '28,907',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1910,
    completeVaccinations: 13712,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47117: {
    label: 'Marshall',
    iso3: null,
    population: '34,375',
    casesPastWeek: 20,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2431,
    completeVaccinations: 15142,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47119: {
    label: 'Maury',
    iso3: null,
    population: '96,387',
    casesPastWeek: 58,
    casesIncreasingPercentage: 11.538461538461542,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7215,
    completeVaccinations: 64033,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47121: {
    label: 'Meigs',
    iso3: null,
    population: '12,422',
    casesPastWeek: 10,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 956,
    completeVaccinations: 8688,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47123: {
    label: 'Monroe',
    iso3: null,
    population: '46,545',
    casesPastWeek: 78,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2071,
    completeVaccinations: 20725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47125: {
    label: 'Montgomery',
    iso3: null,
    population: '208,993',
    casesPastWeek: 191,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24620,
    completeVaccinations: 129862,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47127: {
    label: 'Moore',
    iso3: null,
    population: '6,488',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 202,
    completeVaccinations: 1457,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47129: {
    label: 'Morgan',
    iso3: null,
    population: '21,403',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 29.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1355,
    completeVaccinations: 11355,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47131: {
    label: 'Obion',
    iso3: null,
    population: '30,069',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1853,
    completeVaccinations: 13078,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47133: {
    label: 'Overton',
    iso3: null,
    population: '22,241',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 24.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 853,
    completeVaccinations: 8090,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47135: {
    label: 'Perry',
    iso3: null,
    population: '8,076',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 362,
    completeVaccinations: 3397,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47137: {
    label: 'Pickett',
    iso3: null,
    population: '5,048',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 226,
    completeVaccinations: 2326,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47139: {
    label: 'Polk',
    iso3: null,
    population: '16,832',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 731,
    completeVaccinations: 7139,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47141: {
    label: 'Putnam',
    iso3: null,
    population: '80,245',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4390,
    completeVaccinations: 42045,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47143: {
    label: 'Rhea',
    iso3: null,
    population: '33,167',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1756,
    completeVaccinations: 14680,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47145: {
    label: 'Roane',
    iso3: null,
    population: '53,382',
    casesPastWeek: 58,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2422,
    completeVaccinations: 24296,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47147: {
    label: 'Robertson',
    iso3: null,
    population: '71,813',
    casesPastWeek: 65,
    casesIncreasingPercentage: 6.557377049180335,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4884,
    completeVaccinations: 38775,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47149: {
    label: 'Rutherford',
    iso3: null,
    population: '332,285',
    casesPastWeek: 208,
    casesIncreasingPercentage: 14.917127071823199,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 23955,
    completeVaccinations: 186932,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47151: {
    label: 'Scott',
    iso3: null,
    population: '22,068',
    casesPastWeek: 43,
    casesIncreasingPercentage: 10.256410256410264,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 913,
    completeVaccinations: 8325,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47153: {
    label: 'Sequatchie',
    iso3: null,
    population: '15,026',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 798,
    completeVaccinations: 5366,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47155: {
    label: 'Sevier',
    iso3: null,
    population: '98,250',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6909,
    completeVaccinations: 50866,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47157: {
    label: 'Shelby',
    iso3: null,
    population: '937,166',
    casesPastWeek: 390,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 109908,
    completeVaccinations: 542861,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47159: {
    label: 'Smith',
    iso3: null,
    population: '20,157',
    casesPastWeek: 14,
    casesIncreasingPercentage: 133.33333333333334,
    positiveCasePercentage: 7.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 971,
    completeVaccinations: 6866,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47161: {
    label: 'Stewart',
    iso3: null,
    population: '13,715',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 787,
    completeVaccinations: 6270,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47163: {
    label: 'Sullivan',
    iso3: null,
    population: '158,348',
    casesPastWeek: 158,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11937,
    completeVaccinations: 92333,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47165: {
    label: 'Sumner',
    iso3: null,
    population: '191,283',
    casesPastWeek: 149,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12355,
    completeVaccinations: 95809,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47167: {
    label: 'Tipton',
    iso3: null,
    population: '61,599',
    casesPastWeek: 31,
    casesIncreasingPercentage: 3.3333333333333437,
    positiveCasePercentage: 6.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3678,
    completeVaccinations: 25726,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47169: {
    label: 'Trousdale',
    iso3: null,
    population: '11,284',
    casesPastWeek: 7,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 463,
    completeVaccinations: 4257,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47171: {
    label: 'Unicoi',
    iso3: null,
    population: '17,883',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1247,
    completeVaccinations: 9959,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47173: {
    label: 'Union',
    iso3: null,
    population: '19,972',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 872,
    completeVaccinations: 7157,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47175: {
    label: 'Van Buren',
    iso3: null,
    population: '5,872',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 406,
    completeVaccinations: 3506,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47177: {
    label: 'Warren',
    iso3: null,
    population: '41,277',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2151,
    completeVaccinations: 17569,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47179: {
    label: 'Washington',
    iso3: null,
    population: '129,375',
    casesPastWeek: 97,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9882,
    completeVaccinations: 80050,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47181: {
    label: 'Wayne',
    iso3: null,
    population: '16,673',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 773,
    completeVaccinations: 6979,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47183: {
    label: 'Weakley',
    iso3: null,
    population: '33,328',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1648,
    completeVaccinations: 13152,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47185: {
    label: 'White',
    iso3: null,
    population: '27,345',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1300,
    completeVaccinations: 11892,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47187: {
    label: 'Williamson',
    iso3: null,
    population: '238,412',
    casesPastWeek: 135,
    casesIncreasingPercentage: 22.72727272727273,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 16965,
    completeVaccinations: 163333,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_47189: {
    label: 'Wilson',
    iso3: null,
    population: '144,657',
    casesPastWeek: 98,
    casesIncreasingPercentage: 15.294117647058814,
    positiveCasePercentage: 9.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8585,
    completeVaccinations: 79991,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48001: {
    label: 'Anderson',
    iso3: null,
    population: '57,735',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3111,
    completeVaccinations: 23365,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48003: {
    label: 'Andrews',
    iso3: null,
    population: '18,705',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1090,
    completeVaccinations: 8464,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48005: {
    label: 'Angelina',
    iso3: null,
    population: '86,715',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5398,
    completeVaccinations: 41203,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48007: {
    label: 'Aransas',
    iso3: null,
    population: '23,510',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2232,
    completeVaccinations: 13649,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48009: {
    label: 'Archer',
    iso3: null,
    population: '8,553',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 731,
    completeVaccinations: 5159,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48011: {
    label: 'Armstrong',
    iso3: null,
    population: '1,887',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 105,
    completeVaccinations: 760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48013: {
    label: 'Atascosa',
    iso3: null,
    population: '51,153',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4785,
    completeVaccinations: 27386,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48015: {
    label: 'Austin',
    iso3: null,
    population: '30,032',
    casesPastWeek: 13,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2194,
    completeVaccinations: 14423,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48017: {
    label: 'Bailey',
    iso3: null,
    population: '7,000',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 561,
    completeVaccinations: 2725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48019: {
    label: 'Bandera',
    iso3: null,
    population: '23,112',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2395,
    completeVaccinations: 12052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48021: {
    label: 'Bastrop',
    iso3: null,
    population: '88,723',
    casesPastWeek: 54,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10512,
    completeVaccinations: 53026,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48023: {
    label: 'Baylor',
    iso3: null,
    population: '3,509',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 161,
    completeVaccinations: 1436,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48025: {
    label: 'Bee',
    iso3: null,
    population: '32,565',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3252,
    completeVaccinations: 16934,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48027: {
    label: 'Bell',
    iso3: null,
    population: '362,924',
    casesPastWeek: 131,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 46124,
    completeVaccinations: 228431,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48029: {
    label: 'Bexar',
    iso3: null,
    population: '2,003,554',
    casesPastWeek: 993,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 306296,
    completeVaccinations: 1455432,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48031: {
    label: 'Blanco',
    iso3: null,
    population: '11,931',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1269,
    completeVaccinations: 6921,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48033: {
    label: 'Borden',
    iso3: null,
    population: '654',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26,
    completeVaccinations: 198,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48035: {
    label: 'Bosque',
    iso3: null,
    population: '18,685',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1113,
    completeVaccinations: 8501,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48037: {
    label: 'Bowie',
    iso3: null,
    population: '93,245',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8048,
    completeVaccinations: 37920,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48039: {
    label: 'Brazoria',
    iso3: null,
    population: '374,264',
    casesPastWeek: 146,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 30203,
    completeVaccinations: 224848,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48041: {
    label: 'Brazos',
    iso3: null,
    population: '229,211',
    casesPastWeek: 122,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 19587,
    completeVaccinations: 120618,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48043: {
    label: 'Brewster',
    iso3: null,
    population: '9,203',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1040,
    completeVaccinations: 5208,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48045: {
    label: 'Briscoe',
    iso3: null,
    population: '1,546',
    casesPastWeek: 4,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 70,
    completeVaccinations: 664,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48047: {
    label: 'Brooks',
    iso3: null,
    population: '7,093',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26010,
    completeVaccinations: 7138,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48049: {
    label: 'Brown',
    iso3: null,
    population: '37,864',
    casesPastWeek: 29,
    casesIncreasingPercentage: 38.095238095238095,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2128,
    completeVaccinations: 15850,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48051: {
    label: 'Burleson',
    iso3: null,
    population: '18,443',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1045,
    completeVaccinations: 9187,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48053: {
    label: 'Burnet',
    iso3: null,
    population: '48,155',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3674,
    completeVaccinations: 26564,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48055: {
    label: 'Caldwell',
    iso3: null,
    population: '43,664',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4984,
    completeVaccinations: 25269,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48057: {
    label: 'Calhoun',
    iso3: null,
    population: '21,290',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1628,
    completeVaccinations: 11029,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48059: {
    label: 'Callahan',
    iso3: null,
    population: '13,943',
    casesPastWeek: 7,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1020,
    completeVaccinations: 5935,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48061: {
    label: 'Cameron',
    iso3: null,
    population: '423,163',
    casesPastWeek: 243,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 112132,
    completeVaccinations: 345616,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48063: {
    label: 'Camp',
    iso3: null,
    population: '13,094',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 906,
    completeVaccinations: 5363,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48065: {
    label: 'Carson',
    iso3: null,
    population: '5,926',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 310,
    completeVaccinations: 2137,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48067: {
    label: 'Cass',
    iso3: null,
    population: '30,026',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 22.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1823,
    completeVaccinations: 10839,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48069: {
    label: 'Castro',
    iso3: null,
    population: '7,530',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 649,
    completeVaccinations: 3349,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48071: {
    label: 'Chambers',
    iso3: null,
    population: '43,837',
    casesPastWeek: 42,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2951,
    completeVaccinations: 21477,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48073: {
    label: 'Cherokee',
    iso3: null,
    population: '52,646',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2877,
    completeVaccinations: 21215,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48075: {
    label: 'Childress',
    iso3: null,
    population: '7,306',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 294,
    completeVaccinations: 3493,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48077: {
    label: 'Clay',
    iso3: null,
    population: '10,471',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 508,
    completeVaccinations: 4737,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48079: {
    label: 'Cochran',
    iso3: null,
    population: '2,853',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 163,
    completeVaccinations: 1113,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48081: {
    label: 'Coke',
    iso3: null,
    population: '3,387',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 236,
    completeVaccinations: 1627,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48083: {
    label: 'Coleman',
    iso3: null,
    population: '8,175',
    casesPastWeek: 10,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 28.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 363,
    completeVaccinations: 2998,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48085: {
    label: 'Collin',
    iso3: null,
    population: '1,034,730',
    casesPastWeek: 475,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 107318,
    completeVaccinations: 719146,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48087: {
    label: 'Collingsworth',
    iso3: null,
    population: '2,920',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 222,
    completeVaccinations: 997,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48089: {
    label: 'Colorado',
    iso3: null,
    population: '21,493',
    casesPastWeek: 39,
    casesIncreasingPercentage: 333.3333333333333,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1445,
    completeVaccinations: 11119,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48091: {
    label: 'Comal',
    iso3: null,
    population: '156,209',
    casesPastWeek: 50,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15821,
    completeVaccinations: 100089,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48093: {
    label: 'Comanche',
    iso3: null,
    population: '13,635',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 737,
    completeVaccinations: 6359,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48095: {
    label: 'Concho',
    iso3: null,
    population: '2,726',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 333,
    completeVaccinations: 1433,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48097: {
    label: 'Cooke',
    iso3: null,
    population: '41,257',
    casesPastWeek: 26,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 4.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3092,
    completeVaccinations: 16014,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48099: {
    label: 'Coryell',
    iso3: null,
    population: '75,951',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7006,
    completeVaccinations: 37653,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48101: {
    label: 'Cottle',
    iso3: null,
    population: '1,398',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 59,
    completeVaccinations: 443,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48103: {
    label: 'Crane',
    iso3: null,
    population: '4,797',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 531,
    completeVaccinations: 1799,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48105: {
    label: 'Crockett',
    iso3: null,
    population: '3,464',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 292,
    completeVaccinations: 1649,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48107: {
    label: 'Crosby',
    iso3: null,
    population: '5,737',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 299,
    completeVaccinations: 2490,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48109: {
    label: 'Culberson',
    iso3: null,
    population: '2,171',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 206,
    completeVaccinations: 1129,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48111: {
    label: 'Dallam',
    iso3: null,
    population: '7,287',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1210,
    completeVaccinations: 3248,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48113: {
    label: 'Dallas',
    iso3: null,
    population: '2,635,516',
    casesPastWeek: 984,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 292347,
    completeVaccinations: 1634445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48115: {
    label: 'Dawson',
    iso3: null,
    population: '12,728',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 650,
    completeVaccinations: 4378,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48117: {
    label: 'Deaf Smith',
    iso3: null,
    population: '18,546',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1241,
    completeVaccinations: 8145,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48119: {
    label: 'Delta',
    iso3: null,
    population: '5,331',
    casesPastWeek: 6,
    casesIncreasingPercentage: 500.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 329,
    completeVaccinations: 1899,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48121: {
    label: 'Denton',
    iso3: null,
    population: '887,207',
    casesPastWeek: 332,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 75744,
    completeVaccinations: 557178,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_48123: {
    label: 'DeWitt',
    iso3: null,
    population: '20,160',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1046,
    completeVaccinations: 9280,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48125: {
    label: 'Dickens',
    iso3: null,
    population: '2,211',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 80,
    completeVaccinations: 722,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48127: {
    label: 'Dimmit',
    iso3: null,
    population: '10,124',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4480,
    completeVaccinations: 7200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48129: {
    label: 'Donley',
    iso3: null,
    population: '3,278',
    casesPastWeek: 8,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 165,
    completeVaccinations: 1106,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48131: {
    label: 'Duval',
    iso3: null,
    population: '11,157',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1315,
    completeVaccinations: 7137,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48133: {
    label: 'Eastland',
    iso3: null,
    population: '18,360',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1074,
    completeVaccinations: 6842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48135: {
    label: 'Ector',
    iso3: null,
    population: '166,223',
    casesPastWeek: 102,
    casesIncreasingPercentage: 13.33333333333333,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13623,
    completeVaccinations: 72992,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48137: {
    label: 'Edwards',
    iso3: null,
    population: '1,932',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 333,
    completeVaccinations: 1726,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48139: {
    label: 'Ellis',
    iso3: null,
    population: '184,826',
    casesPastWeek: 64,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14387,
    completeVaccinations: 103106,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48141: {
    label: 'El Paso',
    iso3: null,
    population: '839,238',
    casesPastWeek: 256,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 244200,
    completeVaccinations: 720964,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48143: {
    label: 'Erath',
    iso3: null,
    population: '42,698',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2174,
    completeVaccinations: 16305,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48145: {
    label: 'Falls',
    iso3: null,
    population: '17,297',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1414,
    completeVaccinations: 8081,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48147: {
    label: 'Fannin',
    iso3: null,
    population: '35,514',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2336,
    completeVaccinations: 16089,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48149: {
    label: 'Fayette',
    iso3: null,
    population: '25,346',
    casesPastWeek: 11,
    casesIncreasingPercentage: 37.5,
    positiveCasePercentage: 19.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1885,
    completeVaccinations: 12651,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48151: {
    label: 'Fisher',
    iso3: null,
    population: '3,830',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 214,
    completeVaccinations: 1398,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48153: {
    label: 'Floyd',
    iso3: null,
    population: '5,712',
    casesPastWeek: 7,
    casesIncreasingPercentage: 250.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 308,
    completeVaccinations: 2588,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48155: {
    label: 'Foard',
    iso3: null,
    population: '1,155',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 84,
    completeVaccinations: 484,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48157: {
    label: 'Fort Bend',
    iso3: null,
    population: '811,688',
    casesPastWeek: 266,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 85450,
    completeVaccinations: 602073,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48159: {
    label: 'Franklin',
    iso3: null,
    population: '10,725',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 601,
    completeVaccinations: 3938,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48161: {
    label: 'Freestone',
    iso3: null,
    population: '19,717',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 956,
    completeVaccinations: 7751,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48163: {
    label: 'Frio',
    iso3: null,
    population: '20,306',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3536,
    completeVaccinations: 14191,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48165: {
    label: 'Gaines',
    iso3: null,
    population: '21,492',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 780,
    completeVaccinations: 4772,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48167: {
    label: 'Galveston',
    iso3: null,
    population: '342,139',
    casesPastWeek: 162,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.900000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25622,
    completeVaccinations: 211327,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48169: {
    label: 'Garza',
    iso3: null,
    population: '6,229',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 213,
    completeVaccinations: 3258,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48171: {
    label: 'Gillespie',
    iso3: null,
    population: '26,988',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2059,
    completeVaccinations: 14824,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48173: {
    label: 'Glasscock',
    iso3: null,
    population: '1,409',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 113,
    completeVaccinations: 629,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48175: {
    label: 'Goliad',
    iso3: null,
    population: '7,658',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 461,
    completeVaccinations: 3272,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48177: {
    label: 'Gonzales',
    iso3: null,
    population: '20,837',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1277,
    completeVaccinations: 10617,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48179: {
    label: 'Gray',
    iso3: null,
    population: '21,886',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1208,
    completeVaccinations: 9043,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48181: {
    label: 'Grayson',
    iso3: null,
    population: '136,212',
    casesPastWeek: 57,
    casesIncreasingPercentage: 7.547169811320753,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10339,
    completeVaccinations: 63198,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48183: {
    label: 'Gregg',
    iso3: null,
    population: '123,945',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8172,
    completeVaccinations: 57264,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48185: {
    label: 'Grimes',
    iso3: null,
    population: '28,880',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2101,
    completeVaccinations: 15756,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48187: {
    label: 'Guadalupe',
    iso3: null,
    population: '166,847',
    casesPastWeek: 54,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 15868,
    completeVaccinations: 94994,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48189: {
    label: 'Hale',
    iso3: null,
    population: '33,406',
    casesPastWeek: 31,
    casesIncreasingPercentage: 19.23076923076923,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1980,
    completeVaccinations: 14691,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48191: {
    label: 'Hall',
    iso3: null,
    population: '2,964',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 167,
    completeVaccinations: 1287,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48193: {
    label: 'Hamilton',
    iso3: null,
    population: '8,461',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 2.8000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 418,
    completeVaccinations: 4231,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48195: {
    label: 'Hansford',
    iso3: null,
    population: '5,399',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 300,
    completeVaccinations: 2313,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48197: {
    label: 'Hardeman',
    iso3: null,
    population: '3,933',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 179,
    completeVaccinations: 1838,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48199: {
    label: 'Hardin',
    iso3: null,
    population: '57,602',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3035,
    completeVaccinations: 22056,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48201: {
    label: 'Harris',
    iso3: null,
    population: '4,713,325',
    casesPastWeek: 1491,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 566881,
    completeVaccinations: 3097182,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48203: {
    label: 'Harrison',
    iso3: null,
    population: '66,553',
    casesPastWeek: 37,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3912,
    completeVaccinations: 27382,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48205: {
    label: 'Hartley',
    iso3: null,
    population: '5,576',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 632,
    completeVaccinations: 2652,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48207: {
    label: 'Haskell',
    iso3: null,
    population: '5,658',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 377,
    completeVaccinations: 2211,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48209: {
    label: 'Hays',
    iso3: null,
    population: '230,191',
    casesPastWeek: 102,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 26854,
    completeVaccinations: 149777,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_48211: {
    label: 'Hemphill',
    iso3: null,
    population: '3,819',
    casesPastWeek: 12,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 220,
    completeVaccinations: 1604,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48213: {
    label: 'Henderson',
    iso3: null,
    population: '82,737',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4796,
    completeVaccinations: 32868,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48215: {
    label: 'Hidalgo',
    iso3: null,
    population: '868,707',
    casesPastWeek: 405,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 363679,
    completeVaccinations: 695160,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48217: {
    label: 'Hill',
    iso3: null,
    population: '36,649',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2577,
    completeVaccinations: 15445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48219: {
    label: 'Hockley',
    iso3: null,
    population: '23,021',
    casesPastWeek: 18,
    casesIncreasingPercentage: 38.46153846153846,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1449,
    completeVaccinations: 9864,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48221: {
    label: 'Hood',
    iso3: null,
    population: '61,643',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3917,
    completeVaccinations: 29946,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48223: {
    label: 'Hopkins',
    iso3: null,
    population: '37,084',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2095,
    completeVaccinations: 14786,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48225: {
    label: 'Houston',
    iso3: null,
    population: '22,968',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1345,
    completeVaccinations: 9701,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48227: {
    label: 'Howard',
    iso3: null,
    population: '36,664',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2323,
    completeVaccinations: 14170,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48229: {
    label: 'Hudspeth',
    iso3: null,
    population: '4,886',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 651,
    completeVaccinations: 3345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48231: {
    label: 'Hunt',
    iso3: null,
    population: '98,594',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6280,
    completeVaccinations: 43213,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48233: {
    label: 'Hutchinson',
    iso3: null,
    population: '20,938',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1169,
    completeVaccinations: 7477,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48235: {
    label: 'Irion',
    iso3: null,
    population: '1,536',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 458,
    completeVaccinations: 2257,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48237: {
    label: 'Jack',
    iso3: null,
    population: '8,935',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 487,
    completeVaccinations: 3674,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48239: {
    label: 'Jackson',
    iso3: null,
    population: '14,760',
    casesPastWeek: 7,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 807,
    completeVaccinations: 6686,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48241: {
    label: 'Jasper',
    iso3: null,
    population: '35,529',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1723,
    completeVaccinations: 13132,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48243: {
    label: 'Jeff Davis',
    iso3: null,
    population: '2,274',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 170,
    completeVaccinations: 1170,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48245: {
    label: 'Jefferson',
    iso3: null,
    population: '251,565',
    casesPastWeek: 89,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20030,
    completeVaccinations: 131189,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48247: {
    label: 'Jim Hogg',
    iso3: null,
    population: '5,200',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 804,
    completeVaccinations: 3069,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48249: {
    label: 'Jim Wells',
    iso3: null,
    population: '40,482',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4250,
    completeVaccinations: 22362,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48251: {
    label: 'Johnson',
    iso3: null,
    population: '175,817',
    casesPastWeek: 76,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12321,
    completeVaccinations: 83090,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48253: {
    label: 'Jones',
    iso3: null,
    population: '20,083',
    casesPastWeek: 10,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1756,
    completeVaccinations: 8815,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48255: {
    label: 'Karnes',
    iso3: null,
    population: '15,601',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1274,
    completeVaccinations: 8776,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48257: {
    label: 'Kaufman',
    iso3: null,
    population: '136,154',
    casesPastWeek: 51,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11673,
    completeVaccinations: 73706,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48259: {
    label: 'Kendall',
    iso3: null,
    population: '47,431',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4638,
    completeVaccinations: 30009,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48261: {
    label: 'Kenedy',
    iso3: null,
    population: '404',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 31,
    completeVaccinations: 213,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48263: {
    label: 'Kent',
    iso3: null,
    population: '762',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 42,
    completeVaccinations: 303,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48265: {
    label: 'Kerr',
    iso3: null,
    population: '52,600',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4875,
    completeVaccinations: 27567,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48267: {
    label: 'Kimble',
    iso3: null,
    population: '4,337',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 250,
    completeVaccinations: 1860,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48269: {
    label: 'King',
    iso3: null,
    population: '272',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11,
    completeVaccinations: 59,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48271: {
    label: 'Kinney',
    iso3: null,
    population: '3,667',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 362,
    completeVaccinations: 1966,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48273: {
    label: 'Kleberg',
    iso3: null,
    population: '30,680',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 30.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3410,
    completeVaccinations: 19134,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48275: {
    label: 'Knox',
    iso3: null,
    population: '3,664',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 170,
    completeVaccinations: 1452,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48277: {
    label: 'Lamar',
    iso3: null,
    population: '49,859',
    casesPastWeek: 34,
    casesIncreasingPercentage: 30.76923076923077,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2771,
    completeVaccinations: 20024,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48279: {
    label: 'Lamb',
    iso3: null,
    population: '12,893',
    casesPastWeek: 86,
    casesIncreasingPercentage: 2050.0,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 851,
    completeVaccinations: 5632,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48281: {
    label: 'Lampasas',
    iso3: null,
    population: '21,428',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1675,
    completeVaccinations: 9985,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48283: {
    label: 'La Salle',
    iso3: null,
    population: '7,520',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 856,
    completeVaccinations: 4792,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48285: {
    label: 'Lavaca',
    iso3: null,
    population: '20,154',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 852,
    completeVaccinations: 8549,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48287: {
    label: 'Lee',
    iso3: null,
    population: '17,239',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 26.700000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1228,
    completeVaccinations: 8163,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48289: {
    label: 'Leon',
    iso3: null,
    population: '17,404',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 935,
    completeVaccinations: 6468,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48291: {
    label: 'Liberty',
    iso3: null,
    population: '88,219',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6653,
    completeVaccinations: 38350,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48293: {
    label: 'Limestone',
    iso3: null,
    population: '23,437',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 34.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1391,
    completeVaccinations: 8921,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48295: {
    label: 'Lipscomb',
    iso3: null,
    population: '3,233',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 216,
    completeVaccinations: 1120,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48297: {
    label: 'Live Oak',
    iso3: null,
    population: '12,207',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 723,
    completeVaccinations: 5315,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48299: {
    label: 'Llano',
    iso3: null,
    population: '21,795',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1268,
    completeVaccinations: 11314,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48301: {
    label: 'Loving',
    iso3: null,
    population: '169',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11,
    completeVaccinations: 32,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48303: {
    label: 'Lubbock',
    iso3: null,
    population: '310,569',
    casesPastWeek: 267,
    casesIncreasingPercentage: 8.536585365853666,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 22797,
    completeVaccinations: 155111,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48305: {
    label: 'Lynn',
    iso3: null,
    population: '5,951',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 341,
    completeVaccinations: 2384,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48307: {
    label: 'McCulloch',
    iso3: null,
    population: '7,984',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 356,
    completeVaccinations: 3065,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48309: {
    label: 'McLennan',
    iso3: null,
    population: '256,623',
    casesPastWeek: 72,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 21241,
    completeVaccinations: 135966,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48311: {
    label: 'McMullen',
    iso3: null,
    population: '743',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 34,
    completeVaccinations: 332,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48313: {
    label: 'Madison',
    iso3: null,
    population: '14,284',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 949,
    completeVaccinations: 5988,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48315: {
    label: 'Marion',
    iso3: null,
    population: '9,854',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 496,
    completeVaccinations: 3999,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48317: {
    label: 'Martin',
    iso3: null,
    population: '5,771',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 386,
    completeVaccinations: 1877,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48319: {
    label: 'Mason',
    iso3: null,
    population: '4,274',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 169,
    completeVaccinations: 2024,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48321: {
    label: 'Matagorda',
    iso3: null,
    population: '36,643',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2928,
    completeVaccinations: 17348,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48323: {
    label: 'Maverick',
    iso3: null,
    population: '58,722',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 52043,
    completeVaccinations: 60692,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48325: {
    label: 'Medina',
    iso3: null,
    population: '51,584',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4536,
    completeVaccinations: 29266,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48327: {
    label: 'Menard',
    iso3: null,
    population: '2,138',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 112,
    completeVaccinations: 1132,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48329: {
    label: 'Midland',
    iso3: null,
    population: '176,832',
    casesPastWeek: 89,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13348,
    completeVaccinations: 78053,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48331: {
    label: 'Milam',
    iso3: null,
    population: '24,823',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1357,
    completeVaccinations: 11348,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48333: {
    label: 'Mills',
    iso3: null,
    population: '4,873',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 231,
    completeVaccinations: 2124,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48335: {
    label: 'Mitchell',
    iso3: null,
    population: '8,545',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 337,
    completeVaccinations: 3102,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48337: {
    label: 'Montague',
    iso3: null,
    population: '19,818',
    casesPastWeek: 38,
    casesIncreasingPercentage: 533.3333333333333,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1184,
    completeVaccinations: 7028,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48339: {
    label: 'Montgomery',
    iso3: null,
    population: '607,391',
    casesPastWeek: 221,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 59798,
    completeVaccinations: 346620,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48341: {
    label: 'Moore',
    iso3: null,
    population: '20,940',
    casesPastWeek: 12,
    casesIncreasingPercentage: 140.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2890,
    completeVaccinations: 9990,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48343: {
    label: 'Morris',
    iso3: null,
    population: '12,388',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 826,
    completeVaccinations: 5410,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48345: {
    label: 'Motley',
    iso3: null,
    population: '1,200',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 127,
    completeVaccinations: 340,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48347: {
    label: 'Nacogdoches',
    iso3: null,
    population: '65,204',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4941,
    completeVaccinations: 30415,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48349: {
    label: 'Navarro',
    iso3: null,
    population: '50,113',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3367,
    completeVaccinations: 23063,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48351: {
    label: 'Newton',
    iso3: null,
    population: '13,595',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 486,
    completeVaccinations: 3423,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48353: {
    label: 'Nolan',
    iso3: null,
    population: '14,714',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 625,
    completeVaccinations: 6200,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48355: {
    label: 'Nueces',
    iso3: null,
    population: '362,294',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36665,
    completeVaccinations: 212551,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48357: {
    label: 'Ochiltree',
    iso3: null,
    population: '9,836',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 554,
    completeVaccinations: 3826,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48359: {
    label: 'Oldham',
    iso3: null,
    population: '2,112',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 114,
    completeVaccinations: 754,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48361: {
    label: 'Orange',
    iso3: null,
    population: '83,396',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5025,
    completeVaccinations: 30823,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48363: {
    label: 'Palo Pinto',
    iso3: null,
    population: '29,189',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1367,
    completeVaccinations: 10978,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48365: {
    label: 'Panola',
    iso3: null,
    population: '23,194',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1299,
    completeVaccinations: 8850,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48367: {
    label: 'Parker',
    iso3: null,
    population: '142,878',
    casesPastWeek: 36,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9257,
    completeVaccinations: 67561,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48369: {
    label: 'Parmer',
    iso3: null,
    population: '9,605',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 577,
    completeVaccinations: 3928,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48371: {
    label: 'Pecos',
    iso3: null,
    population: '15,823',
    casesPastWeek: 9,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1265,
    completeVaccinations: 9289,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48373: {
    label: 'Polk',
    iso3: null,
    population: '51,353',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6096,
    completeVaccinations: 25657,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48375: {
    label: 'Potter',
    iso3: null,
    population: '117,415',
    casesPastWeek: 107,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.199999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10401,
    completeVaccinations: 55760,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48377: {
    label: 'Presidio',
    iso3: null,
    population: '6,704',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1710,
    completeVaccinations: 6407,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48379: {
    label: 'Rains',
    iso3: null,
    population: '12,514',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 574,
    completeVaccinations: 4657,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48381: {
    label: 'Randall',
    iso3: null,
    population: '137,713',
    casesPastWeek: 39,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9551,
    completeVaccinations: 65548,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48383: {
    label: 'Reagan',
    iso3: null,
    population: '3,849',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 213,
    completeVaccinations: 1523,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48385: {
    label: 'Real',
    iso3: null,
    population: '3,452',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 250,
    completeVaccinations: 1761,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48387: {
    label: 'Red River',
    iso3: null,
    population: '12,023',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 727,
    completeVaccinations: 5022,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48389: {
    label: 'Reeves',
    iso3: null,
    population: '15,976',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17921,
    completeVaccinations: 14260,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48391: {
    label: 'Refugio',
    iso3: null,
    population: '6,948',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 504,
    completeVaccinations: 3751,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48393: {
    label: 'Roberts',
    iso3: null,
    population: '854',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 33,
    completeVaccinations: 268,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_48395: {
    label: 'Robertson',
    iso3: null,
    population: '17,074',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1100,
    completeVaccinations: 7690,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48397: {
    label: 'Rockwall',
    iso3: null,
    population: '104,915',
    casesPastWeek: 48,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9447,
    completeVaccinations: 63421,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48399: {
    label: 'Runnels',
    iso3: null,
    population: '10,264',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 523,
    completeVaccinations: 4559,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48401: {
    label: 'Rusk',
    iso3: null,
    population: '54,406',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2987,
    completeVaccinations: 21648,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48403: {
    label: 'Sabine',
    iso3: null,
    population: '10,542',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 620,
    completeVaccinations: 3900,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48405: {
    label: 'San Augustine',
    iso3: null,
    population: '8,237',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 535,
    completeVaccinations: 3340,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48407: {
    label: 'San Jacinto',
    iso3: null,
    population: '28,859',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1543,
    completeVaccinations: 10009,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48409: {
    label: 'San Patricio',
    iso3: null,
    population: '66,730',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6927,
    completeVaccinations: 37896,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48411: {
    label: 'San Saba',
    iso3: null,
    population: '6,055',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 432,
    completeVaccinations: 2498,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48413: {
    label: 'Schleicher',
    iso3: null,
    population: '2,793',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 108,
    completeVaccinations: 1179,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48415: {
    label: 'Scurry',
    iso3: null,
    population: '16,703',
    casesPastWeek: 18,
    casesIncreasingPercentage: 63.63636363636365,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1096,
    completeVaccinations: 7864,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48417: {
    label: 'Shackelford',
    iso3: null,
    population: '3,265',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 153,
    completeVaccinations: 1211,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48419: {
    label: 'Shelby',
    iso3: null,
    population: '25,274',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1640,
    completeVaccinations: 9240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48421: {
    label: 'Sherman',
    iso3: null,
    population: '3,022',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 308,
    completeVaccinations: 1051,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48423: {
    label: 'Smith',
    iso3: null,
    population: '232,751',
    casesPastWeek: 102,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14811,
    completeVaccinations: 109592,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48425: {
    label: 'Somervell',
    iso3: null,
    population: '9,128',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 551,
    completeVaccinations: 4176,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48427: {
    label: 'Starr',
    iso3: null,
    population: '64,633',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20093,
    completeVaccinations: 66421,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48429: {
    label: 'Stephens',
    iso3: null,
    population: '9,366',
    casesPastWeek: 5,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 433,
    completeVaccinations: 3480,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48431: {
    label: 'Sterling',
    iso3: null,
    population: '1,291',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 71,
    completeVaccinations: 502,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48433: {
    label: 'Stonewall',
    iso3: null,
    population: '1,350',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 51,
    completeVaccinations: 558,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48435: {
    label: 'Sutton',
    iso3: null,
    population: '3,776',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 222,
    completeVaccinations: 1674,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48437: {
    label: 'Swisher',
    iso3: null,
    population: '7,397',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 397,
    completeVaccinations: 3037,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48439: {
    label: 'Tarrant',
    iso3: null,
    population: '2,102,515',
    casesPastWeek: 1155,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 178383,
    completeVaccinations: 1219961,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48441: {
    label: 'Taylor',
    iso3: null,
    population: '138,034',
    casesPastWeek: 106,
    casesIncreasingPercentage: 3.9215686274509887,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11377,
    completeVaccinations: 71036,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48443: {
    label: 'Terrell',
    iso3: null,
    population: '776',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 95,
    completeVaccinations: 410,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48445: {
    label: 'Terry',
    iso3: null,
    population: '12,337',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 758,
    completeVaccinations: 5224,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48447: {
    label: 'Throckmorton',
    iso3: null,
    population: '1,501',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 86,
    completeVaccinations: 611,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48449: {
    label: 'Titus',
    iso3: null,
    population: '32,750',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2435,
    completeVaccinations: 13211,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48451: {
    label: 'Tom Green',
    iso3: null,
    population: '119,200',
    casesPastWeek: 170,
    casesIncreasingPercentage: 3.658536585365857,
    positiveCasePercentage: 16.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7127,
    completeVaccinations: 60853,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48453: {
    label: 'Travis',
    iso3: null,
    population: '1,273,954',
    casesPastWeek: 553,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 164238,
    completeVaccinations: 921236,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48455: {
    label: 'Trinity',
    iso3: null,
    population: '14,651',
    casesPastWeek: 9,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 3.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 886,
    completeVaccinations: 5773,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48457: {
    label: 'Tyler',
    iso3: null,
    population: '21,672',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 955,
    completeVaccinations: 8005,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48459: {
    label: 'Upshur',
    iso3: null,
    population: '41,753',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2090,
    completeVaccinations: 14510,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48461: {
    label: 'Upton',
    iso3: null,
    population: '3,657',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 215,
    completeVaccinations: 1472,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_48463: {
    label: 'Uvalde',
    iso3: null,
    population: '26,741',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2699,
    completeVaccinations: 14905,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48465: {
    label: 'Val Verde',
    iso3: null,
    population: '49,025',
    casesPastWeek: 12,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 10606,
    completeVaccinations: 34906,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48467: {
    label: 'Van Zandt',
    iso3: null,
    population: '56,590',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2765,
    completeVaccinations: 21554,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48469: {
    label: 'Victoria',
    iso3: null,
    population: '92,084',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.899999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5763,
    completeVaccinations: 45709,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48471: {
    label: 'Walker',
    iso3: null,
    population: '72,971',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5018,
    completeVaccinations: 35752,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48473: {
    label: 'Waller',
    iso3: null,
    population: '55,246',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3821,
    completeVaccinations: 23347,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48475: {
    label: 'Ward',
    iso3: null,
    population: '11,998',
    casesPastWeek: 9,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 875,
    completeVaccinations: 4309,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48477: {
    label: 'Washington',
    iso3: null,
    population: '35,882',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2066,
    completeVaccinations: 18420,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48479: {
    label: 'Webb',
    iso3: null,
    population: '276,652',
    casesPastWeek: 197,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 219334,
    completeVaccinations: 311539,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48481: {
    label: 'Wharton',
    iso3: null,
    population: '41,556',
    casesPastWeek: 34,
    casesIncreasingPercentage: 61.904761904761905,
    positiveCasePercentage: 3.5999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3544,
    completeVaccinations: 21146,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48483: {
    label: 'Wheeler',
    iso3: null,
    population: '5,056',
    casesPastWeek: 3,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 333,
    completeVaccinations: 2131,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48485: {
    label: 'Wichita',
    iso3: null,
    population: '132,230',
    casesPastWeek: 93,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9133,
    completeVaccinations: 70536,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48487: {
    label: 'Wilbarger',
    iso3: null,
    population: '12,769',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 702,
    completeVaccinations: 6772,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48489: {
    label: 'Willacy',
    iso3: null,
    population: '21,358',
    casesPastWeek: 19,
    casesIncreasingPercentage: 216.66666666666666,
    positiveCasePercentage: 1.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4362,
    completeVaccinations: 14610,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48491: {
    label: 'Williamson',
    iso3: null,
    population: '590,551',
    casesPastWeek: 265,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 63205,
    completeVaccinations: 421084,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48493: {
    label: 'Wilson',
    iso3: null,
    population: '51,070',
    casesPastWeek: 29,
    casesIncreasingPercentage: 38.095238095238095,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3845,
    completeVaccinations: 27424,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48495: {
    label: 'Winkler',
    iso3: null,
    population: '8,010',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 572,
    completeVaccinations: 3151,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48497: {
    label: 'Wise',
    iso3: null,
    population: '69,984',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3720,
    completeVaccinations: 27753,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48499: {
    label: 'Wood',
    iso3: null,
    population: '45,539',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2231,
    completeVaccinations: 19950,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48501: {
    label: 'Yoakum',
    iso3: null,
    population: '8,713',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 654,
    completeVaccinations: 3556,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48503: {
    label: 'Young',
    iso3: null,
    population: '18,010',
    casesPastWeek: 14,
    casesIncreasingPercentage: 39.99999999999999,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 867,
    completeVaccinations: 7463,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48505: {
    label: 'Zapata',
    iso3: null,
    population: '14,179',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1565,
    completeVaccinations: 8663,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_48507: {
    label: 'Zavala',
    iso3: null,
    population: '11,840',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1047,
    completeVaccinations: 6241,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_Utah_Bear_River: {
    label: 'Bear River',
    iso3: null,
    population: '186,818',
    casesPastWeek: 90,
    casesIncreasingPercentage: 3.4482758620689724,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_Utah_Central_Utah: {
    label: 'Central Utah',
    iso3: null,
    population: '81,954',
    casesPastWeek: 35,
    casesIncreasingPercentage: 45.83333333333333,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_Utah_Southeast_Utah: {
    label: 'Southeast Utah',
    iso3: null,
    population: '40,229',
    casesPastWeek: 20,
    casesIncreasingPercentage: 233.33333333333334,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_Utah_Southwest_Utah: {
    label: 'Southwest Utah',
    iso3: null,
    population: '252,042',
    casesPastWeek: 83,
    casesIncreasingPercentage: 29.6875,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_Utah_TriCounty: {
    label: 'TriCounty',
    iso3: null,
    population: '56,622',
    casesPastWeek: 34,
    casesIncreasingPercentage: 466.6666666666667,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_Utah_Weber_Morgan: {
    label: 'Weber-Morgan',
    iso3: null,
    population: '272,337',
    casesPastWeek: 187,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_49011: {
    label: 'Davis',
    iso3: null,
    population: '355,481',
    casesPastWeek: 227,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 23598,
    completeVaccinations: 247575,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_49035: {
    label: 'Salt Lake',
    iso3: null,
    population: '1,160,437',
    casesPastWeek: 685,
    casesIncreasingPercentage: 9.250398724082931,
    positiveCasePercentage: 22.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 105873,
    completeVaccinations: 846261,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_49037: {
    label: 'San Juan',
    iso3: null,
    population: '15,308',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1681,
    completeVaccinations: 10385,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_49043: {
    label: 'Summit',
    iso3: null,
    population: '42,145',
    casesPastWeek: 25,
    casesIncreasingPercentage: 4.166666666666674,
    positiveCasePercentage: 22.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7302,
    completeVaccinations: 39162,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_49045: {
    label: 'Tooele',
    iso3: null,
    population: '72,259',
    casesPastWeek: 33,
    casesIncreasingPercentage: 10.000000000000009,
    positiveCasePercentage: 18.099999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5425,
    completeVaccinations: 44582,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_49049: {
    label: 'Utah',
    iso3: null,
    population: '636,235',
    casesPastWeek: 234,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 55269,
    completeVaccinations: 384079,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_49051: {
    label: 'Wasatch',
    iso3: null,
    population: '34,091',
    casesPastWeek: 18,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2386,
    completeVaccinations: 21434,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  US_50001: {
    label: 'Addison',
    iso3: null,
    population: '36,777',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1760,
    completeVaccinations: 30284,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50003: {
    label: 'Bennington',
    iso3: null,
    population: '35,470',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2368,
    completeVaccinations: 27400,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50005: {
    label: 'Caledonia',
    iso3: null,
    population: '29,993',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1143,
    completeVaccinations: 21713,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50007: {
    label: 'Chittenden',
    iso3: null,
    population: '163,774',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9379,
    completeVaccinations: 139122,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50009: {
    label: 'Essex',
    iso3: null,
    population: '6,163',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 235,
    completeVaccinations: 3532,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50011: {
    label: 'Franklin',
    iso3: null,
    population: '49,402',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1861,
    completeVaccinations: 36281,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50013: {
    label: 'Grand Isle',
    iso3: null,
    population: '7,235',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 345,
    completeVaccinations: 6210,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50015: {
    label: 'Lamoille',
    iso3: null,
    population: '25,362',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 39.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1926,
    completeVaccinations: 20945,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50017: {
    label: 'Orange',
    iso3: null,
    population: '28,892',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1377,
    completeVaccinations: 21207,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50019: {
    label: 'Orleans',
    iso3: null,
    population: '27,037',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1287,
    completeVaccinations: 19306,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50021: {
    label: 'Rutland',
    iso3: null,
    population: '58,191',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2781,
    completeVaccinations: 45613,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50023: {
    label: 'Washington',
    iso3: null,
    population: '58,409',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2784,
    completeVaccinations: 48998,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50025: {
    label: 'Windham',
    iso3: null,
    population: '42,222',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3230,
    completeVaccinations: 32301,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_50027: {
    label: 'Windsor',
    iso3: null,
    population: '55,062',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4214,
    completeVaccinations: 41611,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_51001: {
    label: 'Accomack',
    iso3: null,
    population: '32,316',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1576,
    completeVaccinations: 22556,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51003: {
    label: 'Albemarle',
    iso3: null,
    population: '109,330',
    casesPastWeek: 56,
    casesIncreasingPercentage: 3.703703703703698,
    positiveCasePercentage: 4.3999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7876,
    completeVaccinations: 90958,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51005: {
    label: 'Alleghany',
    iso3: null,
    population: '14,860',
    casesPastWeek: 16,
    casesIncreasingPercentage: 23.076923076923084,
    positiveCasePercentage: 17.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 906,
    completeVaccinations: 8152,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51007: {
    label: 'Amelia',
    iso3: null,
    population: '13,145',
    casesPastWeek: 10,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 17.599999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 569,
    completeVaccinations: 7092,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51009: {
    label: 'Amherst',
    iso3: null,
    population: '31,605',
    casesPastWeek: 24,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1296,
    completeVaccinations: 17037,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51011: {
    label: 'Appomattox',
    iso3: null,
    population: '15,911',
    casesPastWeek: 12,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 656,
    completeVaccinations: 8154,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51013: {
    label: 'Arlington',
    iso3: null,
    population: '236,842',
    casesPastWeek: 80,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24076,
    completeVaccinations: 216640,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51015: {
    label: 'Augusta',
    iso3: null,
    population: '75,558',
    casesPastWeek: 62,
    casesIncreasingPercentage: 14.814814814814813,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2771,
    completeVaccinations: 44991,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51017: {
    label: 'Bath',
    iso3: null,
    population: '4,147',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 213,
    completeVaccinations: 2275,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_51019: {
    label: 'Bedford',
    iso3: null,
    population: '78,997',
    casesPastWeek: 58,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3833,
    completeVaccinations: 43914,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51021: {
    label: 'Bland',
    iso3: null,
    population: '6,280',
    casesPastWeek: 22,
    casesIncreasingPercentage: 1000.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 330,
    completeVaccinations: 3220,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51023: {
    label: 'Botetourt',
    iso3: null,
    population: '33,419',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1613,
    completeVaccinations: 21456,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51025: {
    label: 'Brunswick',
    iso3: null,
    population: '16,231',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1187,
    completeVaccinations: 10497,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51027: {
    label: 'Buchanan',
    iso3: null,
    population: '21,004',
    casesPastWeek: 19,
    casesIncreasingPercentage: 216.66666666666666,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 993,
    completeVaccinations: 10588,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51029: {
    label: 'Buckingham',
    iso3: null,
    population: '17,148',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 736,
    completeVaccinations: 9731,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51031: {
    label: 'Campbell',
    iso3: null,
    population: '54,885',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2127,
    completeVaccinations: 29029,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51033: {
    label: 'Caroline',
    iso3: null,
    population: '30,725',
    casesPastWeek: 13,
    casesIncreasingPercentage: 116.66666666666666,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1777,
    completeVaccinations: 18882,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51035: {
    label: 'Carroll',
    iso3: null,
    population: '29,791',
    casesPastWeek: 28,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 18.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1277,
    completeVaccinations: 12985,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51036: {
    label: 'Charles City',
    iso3: null,
    population: '6,963',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 293,
    completeVaccinations: 9390,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51037: {
    label: 'Charlotte',
    iso3: null,
    population: '11,880',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 518,
    completeVaccinations: 6561,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51041: {
    label: 'Chesterfield',
    iso3: null,
    population: '352,802',
    casesPastWeek: 179,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24123,
    completeVaccinations: 256449,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51043: {
    label: 'Clarke',
    iso3: null,
    population: '14,619',
    casesPastWeek: 26,
    casesIncreasingPercentage: 271.42857142857144,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1013,
    completeVaccinations: 9833,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51045: {
    label: 'Craig',
    iso3: null,
    population: '5,131',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 40.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 235,
    completeVaccinations: 2435,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_51047: {
    label: 'Culpeper',
    iso3: null,
    population: '52,605',
    casesPastWeek: 50,
    casesIncreasingPercentage: 92.3076923076923,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2687,
    completeVaccinations: 34819,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51049: {
    label: 'Cumberland',
    iso3: null,
    population: '9,932',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 497,
    completeVaccinations: 5286,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51051: {
    label: 'Dickenson',
    iso3: null,
    population: '14,318',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 774,
    completeVaccinations: 7345,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51053: {
    label: 'Dinwiddie',
    iso3: null,
    population: '28,544',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1410,
    completeVaccinations: 19874,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51057: {
    label: 'Essex',
    iso3: null,
    population: '10,953',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 564,
    completeVaccinations: 6969,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51059: {
    label: 'Fairfax',
    iso3: null,
    population: '1,147,532',
    casesPastWeek: 407,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 99745,
    completeVaccinations: 959654,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_51061: {
    label: 'Fauquier',
    iso3: null,
    population: '71,222',
    casesPastWeek: 19,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4115,
    completeVaccinations: 54147,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51063: {
    label: 'Floyd',
    iso3: null,
    population: '15,749',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 753,
    completeVaccinations: 8290,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51065: {
    label: 'Fluvanna',
    iso3: null,
    population: '27,270',
    casesPastWeek: 16,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 6.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1443,
    completeVaccinations: 19442,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51067: {
    label: 'Franklin',
    iso3: null,
    population: '56,042',
    casesPastWeek: 23,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2641,
    completeVaccinations: 29000,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51069: {
    label: 'Frederick',
    iso3: null,
    population: '89,313',
    casesPastWeek: 64,
    casesIncreasingPercentage: 18.518518518518512,
    positiveCasePercentage: 5.8999999999999995,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5556,
    completeVaccinations: 53534,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51071: {
    label: 'Giles',
    iso3: null,
    population: '16,720',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.7000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 774,
    completeVaccinations: 9335,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51073: {
    label: 'Gloucester',
    iso3: null,
    population: '37,348',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1747,
    completeVaccinations: 25321,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51075: {
    label: 'Goochland',
    iso3: null,
    population: '23,753',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1214,
    completeVaccinations: 18425,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51077: {
    label: 'Grayson',
    iso3: null,
    population: '15,550',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 731,
    completeVaccinations: 7578,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51079: {
    label: 'Greene',
    iso3: null,
    population: '19,819',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1090,
    completeVaccinations: 13443,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51081: {
    label: 'Greensville',
    iso3: null,
    population: '11,336',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 609,
    completeVaccinations: 6134,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51083: {
    label: 'Halifax',
    iso3: null,
    population: '33,911',
    casesPastWeek: 17,
    casesIncreasingPercentage: 30.76923076923077,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1694,
    completeVaccinations: 20026,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51085: {
    label: 'Hanover',
    iso3: null,
    population: '107,766',
    casesPastWeek: 61,
    casesIncreasingPercentage: 29.78723404255319,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5121,
    completeVaccinations: 78171,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51087: {
    label: 'Henrico',
    iso3: null,
    population: '330,818',
    casesPastWeek: 317,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20759,
    completeVaccinations: 241737,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51089: {
    label: 'Henry',
    iso3: null,
    population: '50,557',
    casesPastWeek: 29,
    casesIncreasingPercentage: 7.407407407407418,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2524,
    completeVaccinations: 26468,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51091: {
    label: 'Highland',
    iso3: null,
    population: '2,190',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 85,
    completeVaccinations: 1295,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_51093: {
    label: 'Isle of Wight',
    iso3: null,
    population: '37,109',
    casesPastWeek: 16,
    casesIncreasingPercentage: 166.66666666666666,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2152,
    completeVaccinations: 27349,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51095: {
    label: 'James City',
    iso3: null,
    population: '76,523',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5626,
    completeVaccinations: 62221,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51097: {
    label: 'King and Queen',
    iso3: null,
    population: '7,025',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.6000000000000005,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 321,
    completeVaccinations: 4373,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51099: {
    label: 'King George',
    iso3: null,
    population: '26,836',
    casesPastWeek: 17,
    casesIncreasingPercentage: 750.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1662,
    completeVaccinations: 17800,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51101: {
    label: 'King William',
    iso3: null,
    population: '17,148',
    casesPastWeek: 14,
    casesIncreasingPercentage: 1300.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 768,
    completeVaccinations: 10320,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51103: {
    label: 'Lancaster',
    iso3: null,
    population: '10,603',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 637,
    completeVaccinations: 7734,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51105: {
    label: 'Lee',
    iso3: null,
    population: '23,423',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1092,
    completeVaccinations: 11605,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51107: {
    label: 'Loudoun',
    iso3: null,
    population: '413,538',
    casesPastWeek: 176,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 29035,
    completeVaccinations: 329498,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51109: {
    label: 'Louisa',
    iso3: null,
    population: '37,591',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1841,
    completeVaccinations: 22542,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51111: {
    label: 'Lunenburg',
    iso3: null,
    population: '12,196',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.1999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 485,
    completeVaccinations: 6963,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51113: {
    label: 'Madison',
    iso3: null,
    population: '13,261',
    casesPastWeek: 6,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 2.9000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 545,
    completeVaccinations: 8009,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51115: {
    label: 'Mathews',
    iso3: null,
    population: '8,834',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 404,
    completeVaccinations: 5902,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51117: {
    label: 'Mecklenburg',
    iso3: null,
    population: '30,587',
    casesPastWeek: 21,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1555,
    completeVaccinations: 18257,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51119: {
    label: 'Middlesex',
    iso3: null,
    population: '10,582',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 534,
    completeVaccinations: 7255,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51121: {
    label: 'Montgomery',
    iso3: null,
    population: '98,535',
    casesPastWeek: 70,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6084,
    completeVaccinations: 54490,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51125: {
    label: 'Nelson',
    iso3: null,
    population: '14,930',
    casesPastWeek: 9,
    casesIncreasingPercentage: 350.0,
    positiveCasePercentage: 3.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 803,
    completeVaccinations: 10551,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51127: {
    label: 'New Kent',
    iso3: null,
    population: '23,091',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1060,
    completeVaccinations: 15033,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51131: {
    label: 'Northampton',
    iso3: null,
    population: '11,710',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 521,
    completeVaccinations: 9438,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51133: {
    label: 'Northumberland',
    iso3: null,
    population: '12,095',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 651,
    completeVaccinations: 8285,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51135: {
    label: 'Nottoway',
    iso3: null,
    population: '15,232',
    casesPastWeek: 8,
    casesIncreasingPercentage: 14.28571428571428,
    positiveCasePercentage: 1.7999999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1000,
    completeVaccinations: 8905,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51137: {
    label: 'Orange',
    iso3: null,
    population: '37,051',
    casesPastWeek: 16,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 3.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1909,
    completeVaccinations: 22721,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51139: {
    label: 'Page',
    iso3: null,
    population: '23,902',
    casesPastWeek: 17,
    casesIncreasingPercentage: 6.25,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 842,
    completeVaccinations: 12128,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51141: {
    label: 'Patrick',
    iso3: null,
    population: '17,608',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 790,
    completeVaccinations: 8052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51143: {
    label: 'Pittsylvania',
    iso3: null,
    population: '60,354',
    casesPastWeek: 48,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2835,
    completeVaccinations: 32161,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51145: {
    label: 'Powhatan',
    iso3: null,
    population: '29,652',
    casesPastWeek: 12,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 1.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1258,
    completeVaccinations: 17432,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51147: {
    label: 'Prince Edward',
    iso3: null,
    population: '22,802',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1091,
    completeVaccinations: 10703,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51149: {
    label: 'Prince George',
    iso3: null,
    population: '38,353',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3856,
    completeVaccinations: 33705,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51153: {
    label: 'Prince William',
    iso3: null,
    population: '470,335',
    casesPastWeek: 207,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 34745,
    completeVaccinations: 376218,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51155: {
    label: 'Pulaski',
    iso3: null,
    population: '34,027',
    casesPastWeek: 40,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1647,
    completeVaccinations: 18595,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51157: {
    label: 'Rappahannock',
    iso3: null,
    population: '7,370',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 390,
    completeVaccinations: 4620,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51159: {
    label: 'Richmond',
    iso3: null,
    population: '9,023',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.9000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 444,
    completeVaccinations: 5469,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51161: {
    label: 'Roanoke',
    iso3: null,
    population: '94,186',
    casesPastWeek: 55,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5102,
    completeVaccinations: 66366,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51163: {
    label: 'Rockbridge',
    iso3: null,
    population: '22,573',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 974,
    completeVaccinations: 13705,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51165: {
    label: 'Rockingham',
    iso3: null,
    population: '81,948',
    casesPastWeek: 55,
    casesIncreasingPercentage: 12.244897959183664,
    positiveCasePercentage: 8.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2898,
    completeVaccinations: 46534,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51167: {
    label: 'Russell',
    iso3: null,
    population: '26,586',
    casesPastWeek: 61,
    casesIncreasingPercentage: 144.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1228,
    completeVaccinations: 13684,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51169: {
    label: 'Scott',
    iso3: null,
    population: '21,566',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1095,
    completeVaccinations: 10496,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51171: {
    label: 'Shenandoah',
    iso3: null,
    population: '43,616',
    casesPastWeek: 38,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1935,
    completeVaccinations: 24911,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51173: {
    label: 'Smyth',
    iso3: null,
    population: '30,104',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1554,
    completeVaccinations: 16292,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51175: {
    label: 'Southampton',
    iso3: null,
    population: '17,631',
    casesPastWeek: 12,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 3.3000000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 989,
    completeVaccinations: 10290,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51177: {
    label: 'Spotsylvania',
    iso3: null,
    population: '136,215',
    casesPastWeek: 69,
    casesIncreasingPercentage: 32.692307692307686,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8033,
    completeVaccinations: 84151,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51179: {
    label: 'Stafford',
    iso3: null,
    population: '152,882',
    casesPastWeek: 93,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11618,
    completeVaccinations: 102254,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51181: {
    label: 'Surry',
    iso3: null,
    population: '6,422',
    casesPastWeek: 5,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 4.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 306,
    completeVaccinations: 4138,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51183: {
    label: 'Sussex',
    iso3: null,
    population: '11,159',
    casesPastWeek: 5,
    casesIncreasingPercentage: 150.0,
    positiveCasePercentage: 0.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 605,
    completeVaccinations: 5916,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51185: {
    label: 'Tazewell',
    iso3: null,
    population: '40,595',
    casesPastWeek: 147,
    casesIncreasingPercentage: 374.1935483870968,
    positiveCasePercentage: 24.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2416,
    completeVaccinations: 18507,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51187: {
    label: 'Warren',
    iso3: null,
    population: '40,164',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2039,
    completeVaccinations: 20965,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51191: {
    label: 'Washington',
    iso3: null,
    population: '53,740',
    casesPastWeek: 44,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2621,
    completeVaccinations: 29750,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51193: {
    label: 'Westmoreland',
    iso3: null,
    population: '18,015',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1091,
    completeVaccinations: 11400,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51195: {
    label: 'Wise',
    iso3: null,
    population: '37,383',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2083,
    completeVaccinations: 18428,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51197: {
    label: 'Wythe',
    iso3: null,
    population: '28,684',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1280,
    completeVaccinations: 13550,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51199: {
    label: 'York',
    iso3: null,
    population: '68,280',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4175,
    completeVaccinations: 58512,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51510: {
    label: 'Alexandria',
    iso3: null,
    population: '159,428',
    casesPastWeek: 57,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17137,
    completeVaccinations: 127081,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51520: {
    label: 'Bristol',
    iso3: null,
    population: '16,762',
    casesPastWeek: 27,
    casesIncreasingPercentage: 8.000000000000007,
    positiveCasePercentage: 18.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1076,
    completeVaccinations: 8964,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51530: {
    label: 'Buena Vista',
    iso3: null,
    population: '6,478',
    casesPastWeek: 9,
    casesIncreasingPercentage: 80.0,
    positiveCasePercentage: 17.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 317,
    completeVaccinations: 3706,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51540: {
    label: 'Charlottesville',
    iso3: null,
    population: '47,266',
    casesPastWeek: 21,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 1.7000000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3286,
    completeVaccinations: 32745,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51550: {
    label: 'Chesapeake',
    iso3: null,
    population: '244,835',
    casesPastWeek: 101,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 17218,
    completeVaccinations: 193055,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51570: {
    label: 'Colonial Heights',
    iso3: null,
    population: '17,370',
    casesPastWeek: 19,
    casesIncreasingPercentage: 171.42857142857144,
    positiveCasePercentage: 2.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1060,
    completeVaccinations: 10283,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51580: {
    label: 'Covington',
    iso3: null,
    population: '5,538',
    casesPastWeek: 10,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 397,
    completeVaccinations: 2859,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51590: {
    label: 'Danville',
    iso3: null,
    population: '40,044',
    casesPastWeek: 49,
    casesIncreasingPercentage: 96.0,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2572,
    completeVaccinations: 22237,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51595: {
    label: 'Emporia',
    iso3: null,
    population: '5,346',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 367,
    completeVaccinations: 3012,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51600: {
    label: 'Fairfax City',
    iso3: null,
    population: '24,019',
    casesPastWeek: 14,
    casesIncreasingPercentage: 16.666666666666675,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2456,
    completeVaccinations: 24017,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51610: {
    label: 'Falls Church',
    iso3: null,
    population: '14,617',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1269,
    completeVaccinations: 11784,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51620: {
    label: 'Franklin City',
    iso3: null,
    population: '7,967',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 548,
    completeVaccinations: 5320,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51630: {
    label: 'Fredericksburg',
    iso3: null,
    population: '29,036',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1811,
    completeVaccinations: 17278,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51640: {
    label: 'Galax',
    iso3: null,
    population: '6,347',
    casesPastWeek: 13,
    casesIncreasingPercentage: 30.000000000000004,
    positiveCasePercentage: 3.6999999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 375,
    completeVaccinations: 3568,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51650: {
    label: 'Hampton',
    iso3: null,
    population: '134,510',
    casesPastWeek: 76,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8012,
    completeVaccinations: 114921,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51660: {
    label: 'Harrisonburg',
    iso3: null,
    population: '53,016',
    casesPastWeek: 49,
    casesIncreasingPercentage: 8.888888888888879,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2751,
    completeVaccinations: 29590,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51670: {
    label: 'Hopewell',
    iso3: null,
    population: '22,529',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1416,
    completeVaccinations: 12344,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51678: {
    label: 'Lexington',
    iso3: null,
    population: '7,446',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 445,
    completeVaccinations: 4129,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51680: {
    label: 'Lynchburg',
    iso3: null,
    population: '82,168',
    casesPastWeek: 76,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3595,
    completeVaccinations: 39114,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51683: {
    label: 'Manassas',
    iso3: null,
    population: '41,085',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51685: {
    label: 'Manassas Park',
    iso3: null,
    population: '17,478',
    casesPastWeek: 12,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 40.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51690: {
    label: 'Martinsville',
    iso3: null,
    population: '12,554',
    casesPastWeek: 21,
    casesIncreasingPercentage: 162.5,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 832,
    completeVaccinations: 7868,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51700: {
    label: 'Newport News',
    iso3: null,
    population: '179,225',
    casesPastWeek: 80,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11674,
    completeVaccinations: 125024,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51710: {
    label: 'Norfolk',
    iso3: null,
    population: '242,742',
    casesPastWeek: 74,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8528,
    completeVaccinations: 242187,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51720: {
    label: 'Norton',
    iso3: null,
    population: '3,981',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 263,
    completeVaccinations: 2095,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51730: {
    label: 'Petersburg',
    iso3: null,
    population: '31,346',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.4000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2158,
    completeVaccinations: 18264,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51735: {
    label: 'Poquoson',
    iso3: null,
    population: '12,271',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 649,
    completeVaccinations: 8308,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51740: {
    label: 'Portsmouth',
    iso3: null,
    population: '94,398',
    casesPastWeek: 43,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5855,
    completeVaccinations: 62648,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51750: {
    label: 'Radford',
    iso3: null,
    population: '18,249',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 874,
    completeVaccinations: 8814,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51760: {
    label: 'Richmond City',
    iso3: null,
    population: '230,436',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 14579,
    completeVaccinations: 138559,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51770: {
    label: 'Roanoke City',
    iso3: null,
    population: '99,143',
    casesPastWeek: 43,
    casesIncreasingPercentage: 26.470588235294112,
    positiveCasePercentage: 16.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6236,
    completeVaccinations: 59980,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51775: {
    label: 'Salem',
    iso3: null,
    population: '25,301',
    casesPastWeek: 10,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 467,
    completeVaccinations: 18201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51790: {
    label: 'Staunton',
    iso3: null,
    population: '24,932',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1194,
    completeVaccinations: 16665,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51800: {
    label: 'Suffolk',
    iso3: null,
    population: '92,108',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6324,
    completeVaccinations: 60801,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51810: {
    label: 'Virginia Beach',
    iso3: null,
    population: '449,974',
    casesPastWeek: 176,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25422,
    completeVaccinations: 356844,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51820: {
    label: 'Waynesboro',
    iso3: null,
    population: '22,630',
    casesPastWeek: 16,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.400000000000002,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1073,
    completeVaccinations: 14240,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51830: {
    label: 'Williamsburg',
    iso3: null,
    population: '14,954',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1084,
    completeVaccinations: 8990,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_51840: {
    label: 'Winchester',
    iso3: null,
    population: '28,078',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2080,
    completeVaccinations: 17432,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_53001: {
    label: 'Adams',
    iso3: null,
    population: '19,983',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1403,
    completeVaccinations: 12438,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53003: {
    label: 'Asotin',
    iso3: null,
    population: '22,582',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1329,
    completeVaccinations: 9972,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53005: {
    label: 'Benton',
    iso3: null,
    population: '204,390',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12588,
    completeVaccinations: 120483,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53007: {
    label: 'Chelan',
    iso3: null,
    population: '77,200',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5451,
    completeVaccinations: 56149,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53009: {
    label: 'Clallam',
    iso3: null,
    population: '77,331',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5143,
    completeVaccinations: 54823,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53011: {
    label: 'Clark',
    iso3: null,
    population: '488,241',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 38527,
    completeVaccinations: 333277,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53013: {
    label: 'Columbia',
    iso3: null,
    population: '3,985',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 234,
    completeVaccinations: 1896,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53015: {
    label: 'Cowlitz',
    iso3: null,
    population: '110,593',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6401,
    completeVaccinations: 67800,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53017: {
    label: 'Douglas',
    iso3: null,
    population: '43,429',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2290,
    completeVaccinations: 27522,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53019: {
    label: 'Ferry',
    iso3: null,
    population: '7,627',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 436,
    completeVaccinations: 4474,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53021: {
    label: 'Franklin',
    iso3: null,
    population: '95,222',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6025,
    completeVaccinations: 52511,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53023: {
    label: 'Garfield',
    iso3: null,
    population: '2,225',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 264,
    completeVaccinations: 906,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_53025: {
    label: 'Grant',
    iso3: null,
    population: '97,733',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5562,
    completeVaccinations: 57436,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53027: {
    label: 'Grays Harbor',
    iso3: null,
    population: '75,061',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4264,
    completeVaccinations: 47663,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53029: {
    label: 'Island',
    iso3: null,
    population: '85,141',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9471,
    completeVaccinations: 67554,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53031: {
    label: 'Jefferson',
    iso3: null,
    population: '32,221',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2207,
    completeVaccinations: 25783,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53033: {
    label: 'King',
    iso3: null,
    population: '2,252,782',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 213240,
    completeVaccinations: 1928384,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53035: {
    label: 'Kitsap',
    iso3: null,
    population: '271,473',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25850,
    completeVaccinations: 201336,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53037: {
    label: 'Kittitas',
    iso3: null,
    population: '47,935',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3302,
    completeVaccinations: 26928,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53039: {
    label: 'Klickitat',
    iso3: null,
    population: '22,425',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1129,
    completeVaccinations: 11019,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53041: {
    label: 'Lewis',
    iso3: null,
    population: '80,707',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4642,
    completeVaccinations: 43090,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53043: {
    label: 'Lincoln',
    iso3: null,
    population: '10,939',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 488,
    completeVaccinations: 6574,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53045: {
    label: 'Mason',
    iso3: null,
    population: '66,768',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4935,
    completeVaccinations: 42550,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53047: {
    label: 'Okanogan',
    iso3: null,
    population: '42,243',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2766,
    completeVaccinations: 29317,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53049: {
    label: 'Pacific',
    iso3: null,
    population: '22,471',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1753,
    completeVaccinations: 13974,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53051: {
    label: 'Pend Oreille',
    iso3: null,
    population: '13,724',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 884,
    completeVaccinations: 5873,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53053: {
    label: 'Pierce',
    iso3: null,
    population: '904,980',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 64944,
    completeVaccinations: 626828,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53055: {
    label: 'San Juan',
    iso3: null,
    population: '17,582',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1720,
    completeVaccinations: 15060,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53057: {
    label: 'Skagit',
    iso3: null,
    population: '129,205',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9244,
    completeVaccinations: 90090,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53059: {
    label: 'Skamania',
    iso3: null,
    population: '12,083',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 457,
    completeVaccinations: 4874,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_53061: {
    label: 'Snohomish',
    iso3: null,
    population: '822,083',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 55921,
    completeVaccinations: 607005,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53063: {
    label: 'Spokane',
    iso3: null,
    population: '522,798',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 36785,
    completeVaccinations: 336352,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53065: {
    label: 'Stevens',
    iso3: null,
    population: '45,723',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2243,
    completeVaccinations: 18641,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53067: {
    label: 'Thurston',
    iso3: null,
    population: '290,536',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 24148,
    completeVaccinations: 212698,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53069: {
    label: 'Wahkiakum',
    iso3: null,
    population: '4,488',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 177,
    completeVaccinations: 2287,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  US_53071: {
    label: 'Walla Walla',
    iso3: null,
    population: '60,760',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4601,
    completeVaccinations: 40099,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53073: {
    label: 'Whatcom',
    iso3: null,
    population: '229,247',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20613,
    completeVaccinations: 172239,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53075: {
    label: 'Whitman',
    iso3: null,
    population: '50,104',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5857,
    completeVaccinations: 25291,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_53077: {
    label: 'Yakima',
    iso3: null,
    population: '250,873',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 20249,
    completeVaccinations: 163564,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_54001: {
    label: 'Barbour',
    iso3: null,
    population: '16,441',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 772,
    completeVaccinations: 8506,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54003: {
    label: 'Berkeley',
    iso3: null,
    population: '119,171',
    casesPastWeek: 84,
    casesIncreasingPercentage: 75.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13958,
    completeVaccinations: 68970,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54005: {
    label: 'Boone',
    iso3: null,
    population: '21,457',
    casesPastWeek: 36,
    casesIncreasingPercentage: 125.0,
    positiveCasePercentage: 14.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1256,
    completeVaccinations: 10449,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54007: {
    label: 'Braxton',
    iso3: null,
    population: '13,957',
    casesPastWeek: 9,
    casesIncreasingPercentage: 28.57142857142858,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 628,
    completeVaccinations: 6435,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54009: {
    label: 'Brooke',
    iso3: null,
    population: '21,939',
    casesPastWeek: 17,
    casesIncreasingPercentage: 240.0,
    positiveCasePercentage: 20.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1446,
    completeVaccinations: 11445,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54011: {
    label: 'Cabell',
    iso3: null,
    population: '91,945',
    casesPastWeek: 89,
    casesIncreasingPercentage: 74.50980392156863,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8760,
    completeVaccinations: 57503,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54013: {
    label: 'Calhoun',
    iso3: null,
    population: '7,109',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 395,
    completeVaccinations: 2544,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54015: {
    label: 'Clay',
    iso3: null,
    population: '8,508',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 472,
    completeVaccinations: 4887,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54017: {
    label: 'Doddridge',
    iso3: null,
    population: '8,448',
    casesPastWeek: 3,
    casesIncreasingPercentage: 50.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 552,
    completeVaccinations: 5186,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54019: {
    label: 'Fayette',
    iso3: null,
    population: '42,406',
    casesPastWeek: 61,
    casesIncreasingPercentage: 110.34482758620689,
    positiveCasePercentage: 11.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2955,
    completeVaccinations: 24449,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54021: {
    label: 'Gilmer',
    iso3: null,
    population: '7,823',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 392,
    completeVaccinations: 4892,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 01, 2023',
  },
  US_54023: {
    label: 'Grant',
    iso3: null,
    population: '11,568',
    casesPastWeek: 18,
    casesIncreasingPercentage: 157.14285714285717,
    positiveCasePercentage: 12.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 508,
    completeVaccinations: 5416,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54025: {
    label: 'Greenbrier',
    iso3: null,
    population: '34,662',
    casesPastWeek: 36,
    casesIncreasingPercentage: 111.76470588235294,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2485,
    completeVaccinations: 19639,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54027: {
    label: 'Hampshire',
    iso3: null,
    population: '23,175',
    casesPastWeek: 17,
    casesIncreasingPercentage: 70.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1701,
    completeVaccinations: 11246,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54029: {
    label: 'Hancock',
    iso3: null,
    population: '28,810',
    casesPastWeek: 42,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 16.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2704,
    completeVaccinations: 19929,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54031: {
    label: 'Hardy',
    iso3: null,
    population: '13,776',
    casesPastWeek: 13,
    casesIncreasingPercentage: 333.3333333333333,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 881,
    completeVaccinations: 8042,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54033: {
    label: 'Harrison',
    iso3: null,
    population: '67,256',
    casesPastWeek: 89,
    casesIncreasingPercentage: 128.2051282051282,
    positiveCasePercentage: 15.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3581,
    completeVaccinations: 36266,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54035: {
    label: 'Jackson',
    iso3: null,
    population: '28,576',
    casesPastWeek: 27,
    casesIncreasingPercentage: 170.00000000000003,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1493,
    completeVaccinations: 15332,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54037: {
    label: 'Jefferson',
    iso3: null,
    population: '57,146',
    casesPastWeek: 107,
    casesIncreasingPercentage: 529.4117647058823,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7766,
    completeVaccinations: 38320,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54039: {
    label: 'Kanawha',
    iso3: null,
    population: '178,124',
    casesPastWeek: 103,
    casesIncreasingPercentage: 68.85245901639345,
    positiveCasePercentage: 11.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13991,
    completeVaccinations: 120487,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54041: {
    label: 'Lewis',
    iso3: null,
    population: '15,907',
    casesPastWeek: 16,
    casesIncreasingPercentage: 220.00000000000003,
    positiveCasePercentage: 18.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 898,
    completeVaccinations: 9458,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54043: {
    label: 'Lincoln',
    iso3: null,
    population: '20,409',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1222,
    completeVaccinations: 10585,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54045: {
    label: 'Logan',
    iso3: null,
    population: '32,019',
    casesPastWeek: 35,
    casesIncreasingPercentage: 118.75,
    positiveCasePercentage: 8.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2385,
    completeVaccinations: 18407,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54047: {
    label: 'McDowell',
    iso3: null,
    population: '17,624',
    casesPastWeek: 39,
    casesIncreasingPercentage: 143.75,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1430,
    completeVaccinations: 9485,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54049: {
    label: 'Marion',
    iso3: null,
    population: '56,072',
    casesPastWeek: 57,
    casesIncreasingPercentage: 62.857142857142854,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3507,
    completeVaccinations: 33016,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54051: {
    label: 'Marshall',
    iso3: null,
    population: '30,531',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1205,
    completeVaccinations: 14123,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54053: {
    label: 'Mason',
    iso3: null,
    population: '26,516',
    casesPastWeek: 34,
    casesIncreasingPercentage: 183.33333333333334,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2038,
    completeVaccinations: 13795,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54055: {
    label: 'Mercer',
    iso3: null,
    population: '58,758',
    casesPastWeek: 96,
    casesIncreasingPercentage: 128.57142857142856,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6002,
    completeVaccinations: 33066,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54057: {
    label: 'Mineral',
    iso3: null,
    population: '26,868',
    casesPastWeek: 29,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2477,
    completeVaccinations: 14476,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54059: {
    label: 'Mingo',
    iso3: null,
    population: '23,424',
    casesPastWeek: 18,
    casesIncreasingPercentage: 12.5,
    positiveCasePercentage: 9.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2118,
    completeVaccinations: 9850,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54061: {
    label: 'Monongalia',
    iso3: null,
    population: '105,612',
    casesPastWeek: 105,
    casesIncreasingPercentage: 138.63636363636363,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7731,
    completeVaccinations: 64988,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54063: {
    label: 'Monroe',
    iso3: null,
    population: '13,275',
    casesPastWeek: 38,
    casesIncreasingPercentage: 375.0,
    positiveCasePercentage: 18.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 928,
    completeVaccinations: 6682,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54065: {
    label: 'Morgan',
    iso3: null,
    population: '17,884',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 952,
    completeVaccinations: 7429,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54067: {
    label: 'Nicholas',
    iso3: null,
    population: '24,496',
    casesPastWeek: 23,
    casesIncreasingPercentage: 109.09090909090908,
    positiveCasePercentage: 20.200000000000003,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1400,
    completeVaccinations: 13276,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54069: {
    label: 'Ohio',
    iso3: null,
    population: '41,411',
    casesPastWeek: 32,
    casesIncreasingPercentage: 190.9090909090909,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3213,
    completeVaccinations: 28394,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54071: {
    label: 'Pendleton',
    iso3: null,
    population: '6,969',
    casesPastWeek: 6,
    casesIncreasingPercentage: 19.999999999999996,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 435,
    completeVaccinations: 4235,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54073: {
    label: 'Pleasants',
    iso3: null,
    population: '7,460',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 538,
    completeVaccinations: 4052,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54075: {
    label: 'Pocahontas',
    iso3: null,
    population: '8,247',
    casesPastWeek: 1,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 52.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 568,
    completeVaccinations: 4471,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54077: {
    label: 'Preston',
    iso3: null,
    population: '33,432',
    casesPastWeek: 24,
    casesIncreasingPercentage: 71.42857142857142,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2106,
    completeVaccinations: 19701,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54079: {
    label: 'Putnam',
    iso3: null,
    population: '56,450',
    casesPastWeek: 31,
    casesIncreasingPercentage: 3.3333333333333437,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3745,
    completeVaccinations: 33925,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54081: {
    label: 'Raleigh',
    iso3: null,
    population: '73,361',
    casesPastWeek: 95,
    casesIncreasingPercentage: 66.66666666666667,
    positiveCasePercentage: 14.399999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4994,
    completeVaccinations: 40579,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54083: {
    label: 'Randolph',
    iso3: null,
    population: '28,695',
    casesPastWeek: 34,
    casesIncreasingPercentage: 30.76923076923077,
    positiveCasePercentage: 5.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1734,
    completeVaccinations: 16234,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54085: {
    label: 'Ritchie',
    iso3: null,
    population: '9,554',
    casesPastWeek: 4,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 536,
    completeVaccinations: 4876,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54087: {
    label: 'Roane',
    iso3: null,
    population: '13,688',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 837,
    completeVaccinations: 6727,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54089: {
    label: 'Summers',
    iso3: null,
    population: '12,573',
    casesPastWeek: 22,
    casesIncreasingPercentage: 37.5,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 780,
    completeVaccinations: 8160,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54091: {
    label: 'Taylor',
    iso3: null,
    population: '16,695',
    casesPastWeek: 26,
    casesIncreasingPercentage: 271.42857142857144,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 642,
    completeVaccinations: 8121,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54093: {
    label: 'Tucker',
    iso3: null,
    population: '6,839',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 21.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 385,
    completeVaccinations: 4299,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54095: {
    label: 'Tyler',
    iso3: null,
    population: '8,591',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 6.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 378,
    completeVaccinations: 3714,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54097: {
    label: 'Upshur',
    iso3: null,
    population: '24,176',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.000000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1247,
    completeVaccinations: 12482,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54099: {
    label: 'Wayne',
    iso3: null,
    population: '39,402',
    casesPastWeek: 30,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 13.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3423,
    completeVaccinations: 22219,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54101: {
    label: 'Webster',
    iso3: null,
    population: '8,114',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 376,
    completeVaccinations: 3971,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54103: {
    label: 'Wetzel',
    iso3: null,
    population: '15,065',
    casesPastWeek: 19,
    casesIncreasingPercentage: 171.42857142857144,
    positiveCasePercentage: 3.5000000000000004,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 825,
    completeVaccinations: 8798,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54105: {
    label: 'Wirt',
    iso3: null,
    population: '5,821',
    casesPastWeek: 2,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 364,
    completeVaccinations: 2900,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54107: {
    label: 'Wood',
    iso3: null,
    population: '83,518',
    casesPastWeek: 35,
    casesIncreasingPercentage: 9.375,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7637,
    completeVaccinations: 51602,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_54109: {
    label: 'Wyoming',
    iso3: null,
    population: '20,394',
    casesPastWeek: 28,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1305,
    completeVaccinations: 10898,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  US_55001: {
    label: 'Adams',
    iso3: null,
    population: '20,220',
    casesPastWeek: 12,
    casesIncreasingPercentage: 33.33333333333333,
    positiveCasePercentage: 2.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 894,
    completeVaccinations: 11890,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55003: {
    label: 'Ashland',
    iso3: null,
    population: '15,562',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1390,
    completeVaccinations: 11737,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_55005: {
    label: 'Barron',
    iso3: null,
    population: '45,244',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1813,
    completeVaccinations: 24989,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55007: {
    label: 'Bayfield',
    iso3: null,
    population: '15,036',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1375,
    completeVaccinations: 12995,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 06, 2023',
  },
  US_55009: {
    label: 'Brown',
    iso3: null,
    population: '264,542',
    casesPastWeek: 203,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 13124,
    completeVaccinations: 178708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55011: {
    label: 'Buffalo',
    iso3: null,
    population: '13,031',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 16.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 630,
    completeVaccinations: 6776,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55013: {
    label: 'Burnett',
    iso3: null,
    population: '15,414',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.499999999999998,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1243,
    completeVaccinations: 9295,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55015: {
    label: 'Calumet',
    iso3: null,
    population: '50,089',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1687,
    completeVaccinations: 29063,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55017: {
    label: 'Chippewa',
    iso3: null,
    population: '64,658',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2314,
    completeVaccinations: 38041,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55019: {
    label: 'Clark',
    iso3: null,
    population: '34,774',
    casesPastWeek: 27,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 15.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 823,
    completeVaccinations: 13486,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55021: {
    label: 'Columbia',
    iso3: null,
    population: '57,532',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2672,
    completeVaccinations: 39765,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55023: {
    label: 'Crawford',
    iso3: null,
    population: '16,131',
    casesPastWeek: 13,
    casesIncreasingPercentage: 116.66666666666666,
    positiveCasePercentage: 5.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 619,
    completeVaccinations: 9708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55025: {
    label: 'Dane',
    iso3: null,
    population: '546,695',
    casesPastWeek: 420,
    casesIncreasingPercentage: 6.598984771573613,
    positiveCasePercentage: 9.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 47598,
    completeVaccinations: 476049,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55027: {
    label: 'Dodge',
    iso3: null,
    population: '87,839',
    casesPastWeek: 84,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3286,
    completeVaccinations: 47408,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55029: {
    label: 'Door',
    iso3: null,
    population: '27,668',
    casesPastWeek: 24,
    casesIncreasingPercentage: 140.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1635,
    completeVaccinations: 22641,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55031: {
    label: 'Douglas',
    iso3: null,
    population: '43,150',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5003,
    completeVaccinations: 21324,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55033: {
    label: 'Dunn',
    iso3: null,
    population: '45,368',
    casesPastWeek: 33,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1551,
    completeVaccinations: 23107,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55035: {
    label: 'Eau Claire',
    iso3: null,
    population: '104,646',
    casesPastWeek: 73,
    casesIncreasingPercentage: 19.672131147540984,
    positiveCasePercentage: 13.200000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3747,
    completeVaccinations: 66934,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55037: {
    label: 'Florence',
    iso3: null,
    population: '4,295',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 190,
    completeVaccinations: 2412,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_55039: {
    label: 'Fond du Lac',
    iso3: null,
    population: '103,403',
    casesPastWeek: 62,
    casesIncreasingPercentage: 12.72727272727272,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5253,
    completeVaccinations: 59805,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55041: {
    label: 'Forest',
    iso3: null,
    population: '9,004',
    casesPastWeek: 16,
    casesIncreasingPercentage: 220.00000000000003,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 659,
    completeVaccinations: 5664,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55043: {
    label: 'Grant',
    iso3: null,
    population: '51,439',
    casesPastWeek: 34,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2611,
    completeVaccinations: 29322,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55045: {
    label: 'Green',
    iso3: null,
    population: '36,960',
    casesPastWeek: 48,
    casesIncreasingPercentage: 71.42857142857142,
    positiveCasePercentage: 10.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1368,
    completeVaccinations: 24366,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55047: {
    label: 'Green Lake',
    iso3: null,
    population: '18,913',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 759,
    completeVaccinations: 10900,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55049: {
    label: 'Iowa',
    iso3: null,
    population: '23,678',
    casesPastWeek: 15,
    casesIncreasingPercentage: 25.0,
    positiveCasePercentage: 5.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1114,
    completeVaccinations: 16721,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55051: {
    label: 'Iron',
    iso3: null,
    population: '5,687',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 464,
    completeVaccinations: 3938,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55053: {
    label: 'Jackson',
    iso3: null,
    population: '20,643',
    casesPastWeek: 22,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 31.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 737,
    completeVaccinations: 10708,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55055: {
    label: 'Jefferson',
    iso3: null,
    population: '84,769',
    casesPastWeek: 53,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3579,
    completeVaccinations: 50431,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55057: {
    label: 'Juneau',
    iso3: null,
    population: '26,687',
    casesPastWeek: 19,
    casesIncreasingPercentage: 11.764705882352944,
    positiveCasePercentage: 5.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1127,
    completeVaccinations: 14842,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55059: {
    label: 'Kenosha',
    iso3: null,
    population: '169,561',
    casesPastWeek: 115,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 12391,
    completeVaccinations: 107021,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55061: {
    label: 'Kewaunee',
    iso3: null,
    population: '20,434',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 556,
    completeVaccinations: 10954,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55063: {
    label: 'La Crosse',
    iso3: null,
    population: '118,016',
    casesPastWeek: 99,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6204,
    completeVaccinations: 84957,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55065: {
    label: 'Lafayette',
    iso3: null,
    population: '16,665',
    casesPastWeek: 17,
    casesIncreasingPercentage: 142.85714285714283,
    positiveCasePercentage: 11.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 840,
    completeVaccinations: 10382,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_55067: {
    label: 'Langlade',
    iso3: null,
    population: '19,189',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 528,
    completeVaccinations: 10632,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55069: {
    label: 'Lincoln',
    iso3: null,
    population: '27,593',
    casesPastWeek: 37,
    casesIncreasingPercentage: 23.33333333333334,
    positiveCasePercentage: 11.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 887,
    completeVaccinations: 15705,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55071: {
    label: 'Manitowoc',
    iso3: null,
    population: '78,981',
    casesPastWeek: 42,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3680,
    completeVaccinations: 49463,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55073: {
    label: 'Marathon',
    iso3: null,
    population: '135,692',
    casesPastWeek: 130,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 7447,
    completeVaccinations: 84421,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55075: {
    label: 'Marinette',
    iso3: null,
    population: '40,350',
    casesPastWeek: 47,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1806,
    completeVaccinations: 22039,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55077: {
    label: 'Marquette',
    iso3: null,
    population: '15,574',
    casesPastWeek: 2,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 459,
    completeVaccinations: 8577,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55078: {
    label: 'Menominee',
    iso3: null,
    population: '4,556',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 23.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 196,
    completeVaccinations: 3796,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55079: {
    label: 'Milwaukee',
    iso3: null,
    population: '945,726',
    casesPastWeek: 698,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 12.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 77386,
    completeVaccinations: 640303,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55081: {
    label: 'Monroe',
    iso3: null,
    population: '46,253',
    casesPastWeek: 26,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2905,
    completeVaccinations: 26201,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55083: {
    label: 'Oconto',
    iso3: null,
    population: '37,930',
    casesPastWeek: 22,
    casesIncreasingPercentage: 10.000000000000009,
    positiveCasePercentage: 12.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1235,
    completeVaccinations: 20759,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55085: {
    label: 'Oneida',
    iso3: null,
    population: '35,595',
    casesPastWeek: 30,
    casesIncreasingPercentage: 11.111111111111116,
    positiveCasePercentage: 8.6,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1621,
    completeVaccinations: 24093,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55087: {
    label: 'Outagamie',
    iso3: null,
    population: '187,885',
    casesPastWeek: 85,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 9913,
    completeVaccinations: 125393,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55089: {
    label: 'Ozaukee',
    iso3: null,
    population: '89,221',
    casesPastWeek: 61,
    casesIncreasingPercentage: 29.78723404255319,
    positiveCasePercentage: 12.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5801,
    completeVaccinations: 66604,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55091: {
    label: 'Pepin',
    iso3: null,
    population: '7,287',
    casesPastWeek: 8,
    casesIncreasingPercentage: 300.0,
    positiveCasePercentage: 10.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 273,
    completeVaccinations: 3725,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55093: {
    label: 'Pierce',
    iso3: null,
    population: '42,754',
    casesPastWeek: 13,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.600000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2579,
    completeVaccinations: 19755,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_55095: {
    label: 'Polk',
    iso3: null,
    population: '43,783',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2320,
    completeVaccinations: 23276,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55097: {
    label: 'Portage',
    iso3: null,
    population: '70,772',
    casesPastWeek: 51,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4988,
    completeVaccinations: 46115,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55099: {
    label: 'Price',
    iso3: null,
    population: '13,351',
    casesPastWeek: 25,
    casesIncreasingPercentage: 13.636363636363647,
    positiveCasePercentage: 19.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 523,
    completeVaccinations: 8504,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55101: {
    label: 'Racine',
    iso3: null,
    population: '196,311',
    casesPastWeek: 110,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11423,
    completeVaccinations: 123788,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55103: {
    label: 'Richland',
    iso3: null,
    population: '17,252',
    casesPastWeek: 12,
    casesIncreasingPercentage: 9.090909090909083,
    positiveCasePercentage: 3.9,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 711,
    completeVaccinations: 10401,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_55105: {
    label: 'Rock',
    iso3: null,
    population: '163,354',
    casesPastWeek: 129,
    casesIncreasingPercentage: 21.698113207547177,
    positiveCasePercentage: 8.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 11657,
    completeVaccinations: 117364,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55107: {
    label: 'Rusk',
    iso3: null,
    population: '14,178',
    casesPastWeek: 7,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 456,
    completeVaccinations: 6022,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_55109: {
    label: 'St. Croix',
    iso3: null,
    population: '90,687',
    casesPastWeek: 55,
    casesIncreasingPercentage: 7.843137254901955,
    positiveCasePercentage: 17.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 6693,
    completeVaccinations: 48419,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55111: {
    label: 'Sauk',
    iso3: null,
    population: '64,442',
    casesPastWeek: 46,
    casesIncreasingPercentage: 4.545454545454541,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3123,
    completeVaccinations: 42229,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55113: {
    label: 'Sawyer',
    iso3: null,
    population: '16,558',
    casesPastWeek: 17,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.5,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1609,
    completeVaccinations: 10363,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55115: {
    label: 'Shawano',
    iso3: null,
    population: '40,899',
    casesPastWeek: 24,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 10.100000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1744,
    completeVaccinations: 22510,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55117: {
    label: 'Sheboygan',
    iso3: null,
    population: '115,340',
    casesPastWeek: 93,
    casesIncreasingPercentage: 30.98591549295775,
    positiveCasePercentage: 13.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5405,
    completeVaccinations: 74509,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55119: {
    label: 'Taylor',
    iso3: null,
    population: '20,343',
    casesPastWeek: 11,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 393,
    completeVaccinations: 7399,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55121: {
    label: 'Trempealeau',
    iso3: null,
    population: '29,649',
    casesPastWeek: 42,
    casesIncreasingPercentage: 90.90909090909092,
    positiveCasePercentage: 15.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1491,
    completeVaccinations: 20318,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55123: {
    label: 'Vernon',
    iso3: null,
    population: '30,822',
    casesPastWeek: 29,
    casesIncreasingPercentage: 52.63157894736843,
    positiveCasePercentage: 15.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1033,
    completeVaccinations: 17486,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55125: {
    label: 'Vilas',
    iso3: null,
    population: '22,195',
    casesPastWeek: 16,
    casesIncreasingPercentage: 45.45454545454546,
    positiveCasePercentage: 5.800000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1421,
    completeVaccinations: 15320,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55127: {
    label: 'Walworth',
    iso3: null,
    population: '103,868',
    casesPastWeek: 77,
    casesIncreasingPercentage: 1.3157894736842035,
    positiveCasePercentage: 10.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5798,
    completeVaccinations: 58914,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55129: {
    label: 'Washburn',
    iso3: null,
    population: '15,720',
    casesPastWeek: 9,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 17.299999999999997,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 797,
    completeVaccinations: 10313,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55131: {
    label: 'Washington',
    iso3: null,
    population: '136,034',
    casesPastWeek: 97,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5805,
    completeVaccinations: 81016,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55133: {
    label: 'Waukesha',
    iso3: null,
    population: '404,198',
    casesPastWeek: 236,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 25935,
    completeVaccinations: 293612,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55135: {
    label: 'Waupaca',
    iso3: null,
    population: '50,990',
    casesPastWeek: 41,
    casesIncreasingPercentage: 36.66666666666667,
    positiveCasePercentage: 10.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1497,
    completeVaccinations: 28595,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55137: {
    label: 'Waushara',
    iso3: null,
    population: '24,443',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 797,
    completeVaccinations: 11332,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55139: {
    label: 'Winnebago',
    iso3: null,
    population: '171,907',
    casesPastWeek: 133,
    casesIncreasingPercentage: 15.652173913043477,
    positiveCasePercentage: 12.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8908,
    completeVaccinations: 109676,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_55141: {
    label: 'Wood',
    iso3: null,
    population: '72,999',
    casesPastWeek: 83,
    casesIncreasingPercentage: 53.703703703703695,
    positiveCasePercentage: 9.2,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4434,
    completeVaccinations: 46766,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  US_56001: {
    label: 'Albany',
    iso3: null,
    population: '38,880',
    casesPastWeek: 18,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 20.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2709,
    completeVaccinations: 22783,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56003: {
    label: 'Big Horn',
    iso3: null,
    population: '11,790',
    casesPastWeek: 6,
    casesIncreasingPercentage: 100.0,
    positiveCasePercentage: 9.700000000000001,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 519,
    completeVaccinations: 4839,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56005: {
    label: 'Campbell',
    iso3: null,
    population: '46,341',
    casesPastWeek: 15,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 28.999999999999996,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2370,
    completeVaccinations: 14955,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56007: {
    label: 'Carbon',
    iso3: null,
    population: '14,800',
    casesPastWeek: 3,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1566,
    completeVaccinations: 7162,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56009: {
    label: 'Converse',
    iso3: null,
    population: '13,822',
    casesPastWeek: 9,
    casesIncreasingPercentage: 200.0,
    positiveCasePercentage: 2.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 510,
    completeVaccinations: 4896,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56011: {
    label: 'Crook',
    iso3: null,
    population: '7,584',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 427,
    completeVaccinations: 2449,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56013: {
    label: 'Fremont',
    iso3: null,
    population: '39,261',
    casesPastWeek: 51,
    casesIncreasingPercentage: 37.83783783783783,
    positiveCasePercentage: 15.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 5092,
    completeVaccinations: 21926,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56015: {
    label: 'Goshen',
    iso3: null,
    population: '13,211',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 9.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 706,
    completeVaccinations: 5516,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56017: {
    label: 'Hot Springs',
    iso3: null,
    population: '4,413',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.7,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 124,
    completeVaccinations: 2274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  US_56019: {
    label: 'Johnson',
    iso3: null,
    population: '8,445',
    casesPastWeek: 15,
    casesIncreasingPercentage: 7.14285714285714,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 428,
    completeVaccinations: 3759,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56021: {
    label: 'Laramie',
    iso3: null,
    population: '99,500',
    casesPastWeek: 20,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.4,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 8366,
    completeVaccinations: 58896,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56023: {
    label: 'Lincoln',
    iso3: null,
    population: '19,830',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1151,
    completeVaccinations: 9081,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56025: {
    label: 'Natrona',
    iso3: null,
    population: '79,858',
    casesPastWeek: 12,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 2.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 4541,
    completeVaccinations: 38096,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56027: {
    label: 'Niobrara',
    iso3: null,
    population: '2,356',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 72,
    completeVaccinations: 758,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56029: {
    label: 'Park',
    iso3: null,
    population: '29,194',
    casesPastWeek: 13,
    casesIncreasingPercentage: 44.44444444444444,
    positiveCasePercentage: 4.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1677,
    completeVaccinations: 14009,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56031: {
    label: 'Platte',
    iso3: null,
    population: '8,393',
    casesPastWeek: 22,
    casesIncreasingPercentage: 450.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 261,
    completeVaccinations: 3611,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56033: {
    label: 'Sheridan',
    iso3: null,
    population: '30,485',
    casesPastWeek: 31,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 13.3,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 2222,
    completeVaccinations: 16791,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56035: {
    label: 'Sublette',
    iso3: null,
    population: '9,831',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 560,
    completeVaccinations: 3833,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56037: {
    label: 'Sweetwater',
    iso3: null,
    population: '42,343',
    casesPastWeek: 8,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 11.799999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3926,
    completeVaccinations: 22338,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56039: {
    label: 'Teton',
    iso3: null,
    population: '23,464',
    casesPastWeek: 4,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 14.299999999999999,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 3947,
    completeVaccinations: 22727,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56041: {
    label: 'Uinta',
    iso3: null,
    population: '20,226',
    casesPastWeek: 5,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 25.8,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 1893,
    completeVaccinations: 10902,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56043: {
    label: 'Washakie',
    iso3: null,
    population: '7,805',
    casesPastWeek: 5,
    casesIncreasingPercentage: 400.0,
    positiveCasePercentage: 25.0,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 239,
    completeVaccinations: 3522,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  US_56045: {
    label: 'Weston',
    iso3: null,
    population: '6,927',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 7.1,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: 233,
    completeVaccinations: 2702,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 28, 2023',
  },
  Uganda: {
    label: 'Uganda',
    iso3: 'UGA',
    population: '45,741,000',
    casesPastWeek: 40,
    casesIncreasingPercentage: 53.846153846153854,
    positiveCasePercentage: 6.460000000000001,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 2923216,
    completeVaccinations: 16564888,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Ukraine: {
    label: 'Ukraine',
    iso3: 'UKR',
    population: '43,733,759',
    casesPastWeek: 10569,
    casesIncreasingPercentage: 32.29440480660908,
    positiveCasePercentage: 60.27,
    topLevelGroup: 'Countries',
    subdivisions: [
      'Ukraine_Cherkasy_Oblast',
      'Ukraine_Chernihiv_Oblast',
      'Ukraine_Chernivtsi_Oblast',
      'Ukraine_Crimea_Republic_',
      'Ukraine_Dnipropetrovsk_Oblast',
      'Ukraine_Donetsk_Oblast',
      'Ukraine_Ivano_Frankivsk_Oblast',
      'Ukraine_Kharkiv_Oblast',
      'Ukraine_Kherson_Oblast',
      'Ukraine_Khmelnytskyi_Oblast',
      'Ukraine_Kiev',
      'Ukraine_Kiev_Oblast',
      'Ukraine_Kirovohrad_Oblast',
      'Ukraine_Luhansk_Oblast',
      'Ukraine_Lviv_Oblast',
      'Ukraine_Mykolaiv_Oblast',
      'Ukraine_Odessa_Oblast',
      'Ukraine_Poltava_Oblast',
      'Ukraine_Rivne_Oblast',
      'Ukraine_Sevastopol_',
      'Ukraine_Sumy_Oblast',
      'Ukraine_Ternopil_Oblast',
      'Ukraine_Vinnytsia_Oblast',
      'Ukraine_Volyn_Oblast',
      'Ukraine_Zakarpattia_Oblast',
      'Ukraine_Zaporizhia_Oblast',
      'Ukraine_Zhytomyr_Oblast',
    ],
    incompleteVaccinations: 2366145,
    completeVaccinations: 13408155,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Ukraine_Cherkasy_Oblast: {
    label: 'Cherkasy Oblast',
    iso3: null,
    population: '1,206,351',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Chernihiv_Oblast: {
    label: 'Chernihiv Oblast',
    iso3: null,
    population: '1,005,745',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Chernivtsi_Oblast: {
    label: 'Chernivtsi Oblast',
    iso3: null,
    population: '904,374',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Crimea_Republic_: {
    label: 'Crimea Republic*',
    iso3: null,
    population: '1,913,731',
    casesPastWeek: 414,
    casesIncreasingPercentage: 43.252595155709336,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Ukraine_Dnipropetrovsk_Oblast: {
    label: 'Dnipropetrovsk Oblast',
    iso3: null,
    population: '3,206,477',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Donetsk_Oblast: {
    label: 'Donetsk Oblast',
    iso3: null,
    population: '4,165,901',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Ivano_Frankivsk_Oblast: {
    label: 'Ivano-Frankivsk Oblast',
    iso3: null,
    population: '1,373,252',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Kharkiv_Oblast: {
    label: 'Kharkiv Oblast',
    iso3: null,
    population: '2,675,598',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Kherson_Oblast: {
    label: 'Kherson Oblast',
    iso3: null,
    population: '1,037,640',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Khmelnytskyi_Oblast: {
    label: 'Khmelnytskyi Oblast',
    iso3: null,
    population: '1,264,705',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Kiev: {
    label: 'Kiev',
    iso3: null,
    population: '2,950,800',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Kiev_Oblast: {
    label: 'Kiev Oblast',
    iso3: null,
    population: '1,767,940',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Kirovohrad_Oblast: {
    label: 'Kirovohrad Oblast',
    iso3: null,
    population: '945,549',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Luhansk_Oblast: {
    label: 'Luhansk Oblast',
    iso3: null,
    population: '2,151,833',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Lviv_Oblast: {
    label: 'Lviv Oblast',
    iso3: null,
    population: '2,522,021',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Mykolaiv_Oblast: {
    label: 'Mykolaiv Oblast',
    iso3: null,
    population: '2,522,021',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Odessa_Oblast: {
    label: 'Odessa Oblast',
    iso3: null,
    population: '2,380,308',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Poltava_Oblast: {
    label: 'Poltava Oblast',
    iso3: null,
    population: '1,400,439',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Rivne_Oblast: {
    label: 'Rivne Oblast',
    iso3: null,
    population: '1,157,301',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Sevastopol_: {
    label: 'Sevastopol*',
    iso3: null,
    population: '443,211',
    casesPastWeek: 363,
    casesIncreasingPercentage: 1.96629213483146,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Ukraine_Sumy_Oblast: {
    label: 'Sumy Oblast',
    iso3: null,
    population: '1,081,418',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Ternopil_Oblast: {
    label: 'Ternopil Oblast',
    iso3: null,
    population: '1,045,879',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Vinnytsia_Oblast: {
    label: 'Vinnytsia Oblast',
    iso3: null,
    population: '1,560,394',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Volyn_Oblast: {
    label: 'Volyn Oblast',
    iso3: null,
    population: '1,035,330',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Zakarpattia_Oblast: {
    label: 'Zakarpattia Oblast',
    iso3: null,
    population: '1,256,802',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Zaporizhia_Oblast: {
    label: 'Zaporizhia Oblast',
    iso3: null,
    population: '1,705,836',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Ukraine_Zhytomyr_Oblast: {
    label: 'Zhytomyr Oblast',
    iso3: null,
    population: '1,220,193',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 60.27,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Arab_Emirates: {
    label: 'United Arab Emirates',
    iso3: 'ARE',
    population: '9,890,400',
    casesPastWeek: 946,
    casesIncreasingPercentage: 20.356234096692116,
    positiveCasePercentage: 0.49,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1498663,
    completeVaccinations: 8492426,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  United_Kingdom: {
    label: 'United Kingdom',
    iso3: 'GBR',
    population: '67,886,004',
    casesPastWeek: 26472,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: 'Countries',
    subdivisions: [
      'United_Kingdom_England',
      'United_Kingdom_Northern_Ireland',
      'United_Kingdom_Scotland',
      'United_Kingdom_Wales',
      'United_Kingdom_Bermuda',
      'United_Kingdom_British_Virgin_Islands',
      'United_Kingdom_Cayman_Islands',
      'United_Kingdom_Channel_Islands',
      'United_Kingdom_Guernsey',
      'United_Kingdom_Jersey',
      'United_Kingdom_Falkland_Islands__Malvinas_',
      'United_Kingdom_Gibraltar',
      'United_Kingdom_Isle_of_Man',
      'United_Kingdom_Montserrat',
      'United_Kingdom_Turks_and_Caicos_Islands',
      'United_Kingdom_Pitcairn_Islands',
      'United_Kingdom_Anguilla',
      'United_Kingdom_Saint_Helena__Ascension_and_Tristan_da_Cunha',
    ],
    incompleteVaccinations: 8072024,
    completeVaccinations: 45741467,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  United_Kingdom_England: {
    label: 'England',
    iso3: null,
    population: '55,977,200',
    casesPastWeek: 24219,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  United_Kingdom_Northern_Ireland: {
    label: 'Northern Ireland',
    iso3: null,
    population: '1,881,600',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Scotland: {
    label: 'Scotland',
    iso3: null,
    population: '5,463,300',
    casesPastWeek: 1733,
    casesIncreasingPercentage: 5.413625304136249,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  United_Kingdom_Wales: {
    label: 'Wales',
    iso3: null,
    population: '3,138,600',
    casesPastWeek: 428,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 02, 2023',
  },
  United_Kingdom_Bermuda: {
    label: 'Bermuda',
    iso3: null,
    population: '62,273',
    casesPastWeek: 14,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  United_Kingdom_British_Virgin_Islands: {
    label: 'British Virgin Islands',
    iso3: null,
    population: '30,237',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Cayman_Islands: {
    label: 'Cayman Islands',
    iso3: null,
    population: '65,720',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Channel_Islands: {
    label: 'Channel Islands',
    iso3: null,
    population: '170,499',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Guernsey: {
    label: 'Guernsey',
    iso3: null,
    population: '63,000',
    casesPastWeek: 62,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  United_Kingdom_Jersey: {
    label: 'Jersey',
    iso3: null,
    population: '109,300',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Falkland_Islands__Malvinas_: {
    label: 'Falkland Islands (Malvinas)',
    iso3: null,
    population: '3,483',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Gibraltar: {
    label: 'Gibraltar',
    iso3: null,
    population: '33,691',
    casesPastWeek: 10,
    casesIncreasingPercentage: 233.33333333333334,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 03, 2023',
  },
  United_Kingdom_Isle_of_Man: {
    label: 'Isle of Man',
    iso3: null,
    population: '85,032',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Montserrat: {
    label: 'Montserrat',
    iso3: null,
    population: '4,999',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Turks_and_Caicos_Islands: {
    label: 'Turks and Caicos Islands',
    iso3: null,
    population: '38,718',
    casesPastWeek: 6,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  United_Kingdom_Pitcairn_Islands: {
    label: 'Pitcairn Islands',
    iso3: null,
    population: '67',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Anguilla: {
    label: 'Anguilla',
    iso3: null,
    population: '15,002',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  United_Kingdom_Saint_Helena__Ascension_and_Tristan_da_Cunha: {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    iso3: null,
    population: '5,661',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 3.26,
    topLevelGroup: null,
    subdivisions: [],
    incompleteVaccinations: null,
    completeVaccinations: null,
    unvaccinatedPrevalenceRatio: null,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Uruguay: {
    label: 'Uruguay',
    iso3: 'URY',
    population: '3,473,727',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 8.36,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 450999,
    completeVaccinations: 2555662,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 27, 2023',
  },
  Uzbekistan: {
    label: 'Uzbekistan',
    iso3: 'UZB',
    population: '33,469,199',
    casesPastWeek: 176,
    casesIncreasingPercentage: 26.618705035971214,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3251223,
    completeVaccinations: 18423600,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  Vanuatu: {
    label: 'Vanuatu',
    iso3: 'VUT',
    population: '292,680',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 1.32,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 26494,
    completeVaccinations: 150130,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Venezuela: {
    label: 'Venezuela',
    iso3: 'VEN',
    population: '28,435,943',
    casesPastWeek: 171,
    casesIncreasingPercentage: 271.7391304347826,
    positiveCasePercentage: null,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 3323585,
    completeVaccinations: 18833647,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 07, 2023',
  },
  Vietnam: {
    label: 'Vietnam',
    iso3: 'VNM',
    population: '97,338,583',
    casesPastWeek: 68,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 95.23,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 13570042,
    completeVaccinations: 76896905,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  West_Bank_and_Gaza: {
    label: 'West Bank and Gaza',
    iso3: 'PSE',
    population: '5,101,416',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 19.62,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 301915,
    completeVaccinations: 1710852,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Yemen: {
    label: 'Yemen',
    iso3: 'YEM',
    population: '29,825,968',
    casesPastWeek: 0,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 0.0,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 152567,
    completeVaccinations: 864549,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'February 21, 2023',
  },
  Zambia: {
    label: 'Zambia',
    iso3: 'ZMB',
    population: '18,383,956',
    casesPastWeek: 123,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 5.82,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 1661495,
    completeVaccinations: 9415141,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 05, 2023',
  },
  Zimbabwe: {
    label: 'Zimbabwe',
    iso3: 'ZWE',
    population: '14,862,927',
    casesPastWeek: 149,
    casesIncreasingPercentage: 0.0,
    positiveCasePercentage: 4.21,
    topLevelGroup: 'Countries',
    subdivisions: [],
    incompleteVaccinations: 990754,
    completeVaccinations: 5614274,
    unvaccinatedPrevalenceRatio: 1.0,
    averageFullyVaccinatedMultiplier: 1.0,
    updatedAt: 'March 08, 2023',
  },
  // update_prevalence locations end
}
